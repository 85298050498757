import { Currency } from 'dinero.js';
import FirestoreService from './firestore.service';
import { firestore } from './persistence';
import { Coordinate } from './operatingAreas.service';

const OperatingCitiesCollection = 'operating_cities';

export type OperatingCity = {
    id?: string;
    code: string;
    currency: Currency;
    name: string;
    timezone: string;
    bounding_box_coordinates: {
        northEast: Coordinate;
        southWest: Coordinate;
    };
};

const OperatingCitiesService = {
    async getAll() {
        const response = await FirestoreService.getAll(OperatingCitiesCollection);
        return response;
    },

    async get(operatingCityId: string): Promise<OperatingCity | null> {
        const result = await firestore.collection(OperatingCitiesCollection).doc(operatingCityId).get();
        if (!result.exists) return null;
        return { id: operatingCityId, ...result.data() } as OperatingCity;
    },
};

export default OperatingCitiesService;
