import React from 'react';

import { ReactComponent as Icon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronVariant2 } from 'assets/icons/chevron_down.svg';

import { IconProps } from '..';

export const ChevDownIcon = (props: IconProps & { variant?: 'variant1' | 'variant2' }) => {
    const { className, color, variant, ...rest } = props;

    switch (variant) {
        case 'variant2':
            return <ChevronVariant2 className={className} {...rest} />;
        case 'variant1':
        default:
            return <Icon className={className} stroke={color || 'white'} {...rest} />;
    }
};
