import React from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/en-gb';

import { TimeFormat } from 'models/timeFormat';
import i18n from 'language/i18n';
import { Box, Input, Text } from '@chakra-ui/react';
import Row from '../row';
import Division from '../v1/division';

import CSSStyles from './index.module.css';
import styles from './styles';

export type DateRangeProps = {
    startDate: Moment | null;
    endDate: Moment | null;
    startTime?: string;
    endTime?: string;
    timeFormat?: TimeFormat;
    className?: string;
    noBorder?: boolean;
};

function DateRangeDisplay(props: DateRangeProps) {
    const { startDate, endDate, startTime, endTime, timeFormat, noBorder } = props;
    const removeBorder = noBorder ? CSSStyles.noBorder : '';

    function renderDate(date: Moment | null, time?: string) {
        if (!date) {
            return <Text {...styles.text}>{i18n.t('dateRangeDisplay.noDateText')}</Text>;
        }

        const formattedTime = moment(time, 'HH:mm').format(timeFormat);
        return (
            <Text {...styles.text}>
                {date?.format('MMM DD')}
                <Text as="span" {...styles.span}>
                    {formattedTime}
                </Text>
            </Text>
        );
    }

    return (
        <Row className={[CSSStyles.container, removeBorder, props.className].join(' ')}>
            {renderDate(startDate, startTime || startDate?.format('HH:mm'))}
            <Division />
            {renderDate(endDate, endTime || endDate?.format('HH:mm'))}
        </Row>
    );
}

export default DateRangeDisplay;
