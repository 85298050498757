import { Moment } from 'moment';
import { OccupiedAssetsAction } from 'models/asset/getOccupiedDatesAction';
import { functions, firestore, DocumentReference } from './persistence';
import { GetAllResponse } from './types';
import { PriceObject } from './price.formatter';
import { Extra } from './extras.service';

const AssetsCollection = 'assets';

export interface Asset {
    id: string;
    brand: string;
    model: string;
    fleetRef: string;
    pricePerDay: PriceObject;
    thumbs: string[];
    displayPhoto: string;
    photos: string[];
    extras: Extra[];
    category?: string;
    transmission?: string;
    propulsionType?: string;
    seats?: string;
    doors?: string;
    suitcases?: string;
    luggages?: string;
    description?: string;
    operatingCityRef?: DocumentReference | string;
}

const AssetService = {
    /**
     * GET all assets
     */
    async getAll(criteria?: any): Promise<GetAllResponse<Asset>> {
        const response = await functions.httpsCallable('searchAvailableAssets')(criteria || {});
        return response.data;
    },

    /* Get asset from firestore
     * @param uid
     * @param callback
     */
    async get(uid: string): Promise<Asset | undefined | null> {
        try {
            const result = await functions.httpsCallable('searchAssetDetails')(uid);
            if (!result.data) return null;

            return result.data as Asset;
        } catch (err) {
            console.log(err);
            return null;
        }
    },

    async getOccupiedDates({
        uid,
        from,
        to,
        action,
        bookingId,
    }: {
        uid: string;
        from?: Moment;
        to?: Moment;
        action: OccupiedAssetsAction;
        bookingId?: string;
    }) {
        try {
            if (!action) {
                throw new Error('Missing or undefined action');
            }

            if (action === OccupiedAssetsAction.EXCLUDE_BOOKING_DATES && !bookingId) {
                throw new Error('Missing or undefined booking Id');
            }

            const criteria = {
                filters: {
                    id: uid,
                    ...(from !== undefined ? { from: from.format() } : {}),
                    ...(to !== undefined ? { to: to.format() } : {}),
                    action,
                    bookingId,
                },
            };

            const response = await functions.httpsCallable('getAssetsOccupiedDates')(criteria);

            return {
                dates: response.data.dates,
                events: response.data.events,
            };
        } catch (err) {
            console.log(err);
            return {
                dates: [],
                events: [],
            };
        }
    },
};

export default AssetService;
