import { Button } from '@chakra-ui/react';
import CloseIcon from 'components/base/icon/close';
import Loading from 'components/base/loading';
import { Modal } from 'components/base/modal';
import Row from 'components/base/row';
import AuthenticationModal from 'components/sections/standalone/authentication/modal';
import { calculateDuration } from 'helpers/calculateDuration';
import { extrasReceiptFormat } from 'helpers/extrasReceiptFormat';
import { insuranceReceiptFormat } from 'helpers/insuranceReceiptFormat';
import { useAuth, useOperatingAreas, useSharedData } from 'hooks';
import { BookingCancellationConfig } from 'hooks/remoteConfig';
import i18n from 'language/i18n';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Asset } from 'services/asset.service';
import { Insurance } from 'services/insurances.service';
import { PriceCalculator } from 'services/price.formatter';
import PriceBreakdown from '../price-breakdown';
import PriceDay from '../price-day';
import PriceTotal from '../price-total';
import styles from './index.module.css';

type MobilePriceDrawerProps = {
    asset: Asset;
    extras: string[];
    insurances: Insurance[];
    mode: 'details' | 'checkout-1' | 'checkout-2';
    onSubmit?: () => void;
    onDates?: () => void;
    className?: string;
    showAddInsurances?: boolean;
    isOpen: boolean;
    onToggle: () => void;
    onClose: () => void;
    isSubmitLoading?: boolean;
    bookingCancellationConfig: BookingCancellationConfig;
    onClickOpenEditModal?: () => void;
};

export default function MobilePriceDrawer(props: MobilePriceDrawerProps) {
    const {
        asset,
        extras,
        insurances,
        mode,
        onSubmit,
        showAddInsurances,
        isOpen,
        onToggle,
        onClose,
        isSubmitLoading,
        bookingCancellationConfig,
        onClickOpenEditModal,
    } = props;
    const context = useAuth();
    const history = useHistory();
    const [showAuth, setShowAuth] = useState(false);
    const { shared } = useSharedData();
    const { getUser } = useAuth();
    const { operatingCity } = useOperatingAreas();

    const [fixedDrawer, setFixedDrawer] = useState<boolean>(false);

    const trackScrolling = useCallback(() => {
        const footer = document.getElementById('footer');
        const isOnBottom = footer
            ? document.body.getBoundingClientRect().bottom - footer.clientHeight - 10 <= window.innerHeight
            : false;
        const isAboveController = footer ? footer.clientHeight <= window.innerHeight : false;

        if (isOnBottom) {
            setFixedDrawer(true);
        } else if (isAboveController) {
            setFixedDrawer(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
    }, [trackScrolling]);

    function _submit() {
        if (context.auth) {
            onSubmit?.();
        } else {
            setShowAuth(true);
        }
    }

    function isContinueDisabled() {
        const { start, end } = shared;

        return !start.date || !start.query || !start.location || !end.date || !end.query || !end.location;
    }

    function onMount() {
        getUser();
    }

    useEffect(onMount, []);

    function renderReceipt() {
        const assetPrice = new PriceCalculator(asset.pricePerDay);
        let prices: any = [];
        let total: any;

        if (shared.end.date && shared.start.date) {
            const duration = calculateDuration(shared.start.date, shared.end.date)!;

            const mappedInsurances = insuranceReceiptFormat(insurances, duration);
            const mappedExtras = extrasReceiptFormat(asset.extras, extras, duration);

            prices = [
                {
                    label: `${duration}-day rental`,
                    value: assetPrice.clone().multiply(duration),
                    info: `(${duration} x ${assetPrice.format(2).slice(1)})`,
                },
                ...mappedInsurances,
                ...mappedExtras,
            ];

            total = new PriceCalculator(0, operatingCity!.currency)
                .total(prices.map((price: any) => price.value))
                .format(2);
        }

        function isPageLoading() {
            const assetLoading = isSubmitLoading && !asset;
            return assetLoading;
        }

        if (isPageLoading()) {
            return <Loading spinnerSize={40} fullScreen />;
        }

        if (!isOpen) {
            const buttonMessage =
                mode === 'checkout-2'
                    ? i18n.t('pages.assetDetails.booking.submitButton')
                    : i18n.t('sections.assetDetails.booking.reserve');
            return (
                <Row className={[styles.drawer, fixedDrawer ? styles.fixed : ''].join(' ')}>
                    <PriceDay
                        onClickOpenEditModal={onClickOpenEditModal}
                        mode={mode}
                        price={assetPrice.format(2)}
                        onClick={onToggle}
                        total={total}
                    />
                    <Button
                        disabled={isContinueDisabled()}
                        size="md"
                        variant="solid"
                        colorScheme="black"
                        onClick={_submit}
                    >
                        {buttonMessage}
                    </Button>
                    <AuthenticationModal
                        history={history}
                        isOpen={showAuth}
                        onModalClosed={() => setShowAuth(false)}
                        section="login"
                    />
                </Row>
            );
        }

        return (
            <Modal size="bottom-fit" hideClose className={styles.modal} show>
                <CloseIcon className={styles.closeIcon} onClick={onClose} />
                <PriceBreakdown
                    open
                    title={i18n.t('sections.assetDetails.booking.priceBreakdown')}
                    titleInfo={i18n.t('sections.assetDetails.booking.insurenceIncludedMesssage')}
                    options={prices}
                    showAddInsurances={showAddInsurances}
                />
                <PriceTotal
                    price={total!}
                    info={i18n.t('pages.assetDetails.taxIncluded')}
                    title={i18n.t('sections.assetDetails.booking.totalPrice')}
                    message={
                        mode !== 'checkout-2'
                            ? i18n.t('sections.assetDetails.booking.cancelationInfo', {
                                  cancellationTime: bookingCancellationConfig.cancellation_time,
                              })
                            : undefined
                    }
                />
            </Modal>
        );
    }

    return renderReceipt();
}
