import { useState } from 'react';
import { firestore } from 'services/persistence';
import AssetService from '../services/asset.service';
import ExtrasService, { Extra } from '../services/extras.service';

/**
 *
 * @param assetId
 */
export function useAssetDetails(initialAssetId?: string) {
    const [assetId, setAssetId] = useState(initialAssetId);
    const [assetDetails, setAssetDetails] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);

    if (loading && alreadyLoaded === false && assetId) {
        setAlreadyLoaded(true);

        AssetService.get(assetId!).then(async data => {
            if (data && data.operatingCityRef && typeof data.operatingCityRef === 'string') {
                const splitData = data.operatingCityRef.split('/');
                // eslint-disable-next-line prefer-destructuring
                data.operatingCityRef = firestore.collection('operating_cities').doc(splitData[1]);
            }

            const asset = data;
            let extras: Extra[] = [];
            if (asset?.extras) {
                const extrasCriteria = {
                    from: 0,
                    filters: {
                        selectedIds: asset.extras,
                        enabled: true,
                    },
                };
                await ExtrasService.getAll(extrasCriteria).then(response => {
                    extras = response.result;
                });
            }

            setAssetDetails({ ...data, extras, id: assetId });
            setLoading(false);
        });
    }

    return { asset: assetDetails, loading, setAssetId };
}
