import React from 'react';
import { useHistory } from 'react-router';
import { Avatar, Menu, MenuButton, MenuList, MenuItem, Button as ChakraButton } from '@chakra-ui/react';

import { useAuth } from 'hooks';
import i18n from 'language/i18n';
import authService from 'services/auth.service';

export function HeaderMenu() {
    const history = useHistory();
    const { auth } = useAuth();

    const menuItems = [
        {
            title: i18n.t('sections.header.menu.profile'),
            action: () => history.push('/profile'),
            customStyles: {},
        },
        {
            title: i18n.t('sections.header.menu.bookings'),
            action: () => history.push('/bookings'),
            customStyles: {},
        },
        {
            title: i18n.t('sections.header.logoutBtn'),
            action: logout,
            customStyles: { fontWeight: 'medium' },
        },
    ];

    async function logout() {
        try {
            await authService.logout();
            history.push('/');
        } catch (error) {
            console.log(error);
        }
    }

    // function renderUserName() {
    //     if (breakpoint !== "base" && breakpoint !== "sm") {
    //         return user.name;
    //     }

    //     return null;
    // }

    function renderAvatarIcon() {
        return <Avatar name={auth?.displayName || ''} bg="brand.secondary.900" color="white" ml="2" />;
    }

    function renderMenuItems() {
        return menuItems.map(item => (
            <MenuItem key={item.title} px="6" justifyContent="flex-end" onClick={item.action} {...item.customStyles}>
                {item.title}
            </MenuItem>
        ));
    }

    return (
        <Menu autoSelect={false} placement="bottom-end">
            <MenuButton
                as={ChakraButton}
                rightIcon={renderAvatarIcon()}
                bg="transparent"
                w="fit-content"
                h="auto"
                p="0"
                justifySelf="end"
                fontSize="sm"
                fontWeight="light"
                _active={{ bg: 'transparent' }}
                _focus={{ border: 'none' }}
                _hover={{ bg: 'transparent' }}
            >
                {/* {renderUserName()} */}
            </MenuButton>
            <MenuList minW="0" w={160} py="4" px="0" zIndex={10}>
                {renderMenuItems()}
            </MenuList>
        </Menu>
    );
}
