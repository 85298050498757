import { SharedDataActionType } from '../SharedDataActions';
import { TimeSlots } from '../TimeSlots';

export enum ChangeTimeActionTimeType {
    START_TIME = 'startTime',
    END_TIME = 'endTime',
}

export type ChangeTimeActionPayload = {
    time: TimeSlots | undefined;
    timeType: ChangeTimeActionTimeType;
};

export type ChangeTimeAction = {
    type: SharedDataActionType.CHANGE_TIME;
    payload: ChangeTimeActionPayload;
};
