import React from 'react';

import styles from './index.module.css';

export type ColumnProps = {
    children?: React.ReactNode | string;
    className?: string;
    onClick?: (...args: any[]) => any;
    style?: React.CSSProperties;
    id?: string;
    justify?: 'start' | 'space-between' | 'space-around' | 'space-even' | 'end' | 'center';
    align?: 'start' | 'center' | 'stretch' | 'end';
    width?: number | string;
    flex?: number | string;
};

export default class Column extends React.Component<ColumnProps> {
    render() {
        const alignment = {
            ...(this.props.justify ? { justifyContent: this.props.justify } : {}),
            ...(this.props.align ? { alignItems: this.props.align } : {}),
            ...(this.props.width ? { maxWidth: this.props.width } : {}),
            ...(this.props.flex ? { flex: this.props.flex } : {}),
        };

        return (
            <div style={alignment} {...this.props} className={[styles.column, this.props.className].join(' ')}>
                {this.props.children}
            </div>
        );
    }
}
