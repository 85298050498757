import { TextProps, FlexProps, ButtonProps } from '@chakra-ui/react';

import { ChakraStyles } from 'theme';
import { colors } from 'styles/colors';

interface InputProps extends FlexProps {
    isFocused?: boolean;
}

export const styles: ChakraStyles<InputProps & ButtonProps & TextProps> & any = {
    mobileInput: () => ({
        zIndex: '1',
        borderRadius: '56px',
        backgroundColor: '#ffffff',
        padding: '16px 0',
        position: 'relative',
        flex: 1,
    }),
    input: (isFocused?: boolean) => ({
        w: '100%',
        h: '48px',
        minW: '168px',
        border: '1px solid',
        borderColor: isFocused ? colors.grayDark : colors.grayLight,
        borderRadius: 'xl',
        mt: '4',
        pl: '6',
        pr: '4',
        py: '2',
        align: 'center',
        position: 'relative',
        transition: 'border 0.2s ease-in-out',
        _hover: { borderColor: colors.grayDark },
    }),

    searchInput: {
        width: '100%',
        margin: 0,
        maxWidth: '100%',
    },

    inputText: (isPlaceholder?: boolean, isFocused?: boolean) => ({
        variant: 'sm',
        fontWeight: 'light',
        whiteSpace: 'nowrap',
        color: isPlaceholder && !isFocused ? colors.grayLight : colors.grayDark,
        transition: 'color 0.2s ease-in-out',
        _groupHover: { color: colors.grayDark },
    }),

    inputIcon: (isFocused?: boolean) => ({
        mr: '2',
        w: '15px',
        h: '16px',
        color: isFocused ? colors.grayDark : colors.grayLight,
        transition: 'color 0.2s ease-in-out',
        _groupHover: { color: colors.grayDark },
    }),

    title: {
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font-xxlarge)',
        lineHeight: 'var(--line)',
    },

    text: {
        fontWeight: 'var(--font-weight-light)',
        lineHeight: 'var(--line)',
        fontSize: 'var(--font)',
        fontFamily: 'var(--font-family-secondary)',
        marginRight: { base: '0px', lg: 'var(--margin-xlarge)' },
    },

    textGoBack: {
        fontWeight: 'var(--font-weight-light)',
        lineHeight: 'var(--line)',
        fontSize: 'var(--font-xsmall)',
        fontFamily: 'var(--font-family-secondary)',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    },

    addressInputWidth: {
        maxW: { base: '342px', xl: '458px', '2xl': '542px' },
    },

    dateInputWidth: {
        maxW: { base: '168px', xl: '218px', '2xl': '266px' },
    },

    submitButton: {
        h: '48px',
        width: { base: '114px', xl: '160px', '2xl': '180px' },
        mt: '38px',
        borderRadius: 'xl',
    },

    regionContainer: (isLastElement?: boolean) => ({
        margin: { base: '0px', lg: '0px' },
        display: 'flex',
        borderRadius: { base: '16px', lg: 'none' },
        width: { base: '100%', lg: '224px' },
        alignItems: 'center',
        justifyContent: 'center',
        height: { base: '104px', lg: '112px' },
        flex: 1,
        marginTop: 'var(--margin-large)',
        marginRight: isLastElement ? { base: '0px', lg: '0px' } : { base: '0px', lg: 'var(--margin-medium)' },
    }),

    mobileContainer: {
        display: 'flex',
        flexDir: { base: 'column', lg: '' },
        width: { base: '100%', lg: '' },
        borderRadius: { base: '16px', lg: 'none' },
    },

    mobileBoxes: {
        flexDir: 'column',
        display: 'flex',
        width: '100%',
    },

    cityButton: {
        pos: 'absolute',
        zIndex: 2,
        backgroundColor: 'va(--color-black)',
    },

    image: {
        maxHeight: '100%',
        width: '100%',
        height: { base: '103px', lg: 'unset' },
        objectFit: 'cover',
        borderRadius: '16px',
    },
};
