import React, { useEffect, useState } from 'react';
import { FiCheckCircle, FiCreditCard, FiPlus, FiX } from 'react-icons/fi';
import { Button, useToast } from '@chakra-ui/react';
import Column from 'components/base/column';
import ModalCancelation from 'components/base/modalCancelation';
import Row from 'components/base/row';
import { Spinner } from 'components/base/spinner';
import { H3 } from 'components/base/text/h3';
import { Text } from 'components/base/textV2';
import { DocumentValidationRequestStatus, ValidationStatus } from 'models/documents';
import { PersonaOnCompleteArguments, PersonaOnCompleteStatus } from 'models/persona';
import DocumentValidationRequestService from 'services/documentValidation.service';
import { useAuth, useRemoteConfig } from 'hooks';
import { usePersona } from 'hooks/persona';
import i18n from 'language/i18n';

import styles from './index.module.css';

export interface DocumentValidationRequest {
    id: string;
    issuedBy: string;
    status: string;
    creationDate: Date;
}

export interface ActionRequest {
    action: 'revoke' | 'approve';
}
export function DocumentsInfo() {
    const toast = useToast();
    const { client, createClient } = usePersona();
    const { claims, refreshCustomClaims, auth } = useAuth();
    const { documentValidationEnabled } = useRemoteConfig();
    const [validationStatus, setValidationStatus] = useState<ValidationStatus | null>(null);
    const [loading, setLoading] = useState(false);
    const [latestId, setLatestId] = useState<string | null>('');
    const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');

    const onUploadDocumentsClick = async () => {
        setLoading(true);
        try {
            let currentValidationId = latestId;
            if (!latestId) {
                const response = await DocumentValidationRequestService.create(auth!.uid);
                currentValidationId = response.id;
            }
            openPersonaModal(currentValidationId);
        } catch (error) {
            setLoading(false);
            toast({
                title: i18n.t('pages.profile.documents.toast.error.creation.title'),
                description: i18n.t('pages.profile.documents.toast.error.creation.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    function revokeDocuments() {
        setShowRevokeModal(true);
        setAction('revoke');
    }

    function onCloseRevokeModal() {
        setShowRevokeModal(false);
    }

    /**
     * @description This function is for revoking documents only.
     */
    async function onSubmit() {
        setLoading(true);
        try {
            if (auth) {
                const { uid } = auth;

                await DocumentValidationRequestService.revokeDocumentsValidationRequest(uid, latestId!, action);
                await refreshCustomClaims();
                setLoading(false);
                onCloseRevokeModal();
                toast({
                    title: i18n.t('pages.profile.documents.toast.revoked.title'),
                    description: i18n.t('pages.profile.documents.toast.revoked.description'),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
        } catch (error) {
            console.log('error while revoking documents:::', error);
            setLoading(false);
            toast({
                title: i18n.t('pages.profile.documents.toast.error.creation.title'),
                description: i18n.t('pages.profile.documents.toast.error.creation.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            onCloseRevokeModal();
        }
    }

    async function onPersonaSuccess(
        { fields, inquiryId }: PersonaOnCompleteArguments,
        currentValidationId: string | null,
    ) {
        try {
            if (!currentValidationId) {
                throw new Error('Missing currentValidationId');
            }
            await DocumentValidationRequestService.approveValidationRequest(auth!.uid, currentValidationId!, inquiryId);
            toast({
                title: i18n.t('pages.profile.documents.toast.success.creation.title'),
                description: i18n.t('pages.profile.documents.toast.success.creation.description'),
                status: 'success',
                duration: 4000,
                isClosable: true,
                position: 'top-right',
            });
            await DocumentValidationRequestService.updateUser(auth!.uid, fields);
            await refreshCustomClaims();
        } catch (error) {
            toast({
                title: i18n.t('pages.profile.documents.toast.error.onComplete.title'),
                description: i18n.t('pages.profile.documents.toast.error.onComplete.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    }

    function onPersonaComplete(args: PersonaOnCompleteArguments, currentValidationId: string | null) {
        switch (args.status) {
            case PersonaOnCompleteStatus.COMPLETED:
                return onPersonaSuccess(args, currentValidationId);
                break;
            default:
        }
    }

    function openPersonaModal(currentValidationId: string | null) {
        createClient({
            onCancel: () => setLoading(false),
            onComplete: args => onPersonaComplete(args, currentValidationId),
            referenceId: auth?.uid,
        });
        client.current!.open();
    }

    function onNext(snapshot: firebase.firestore.QuerySnapshot) {
        const validations: any[] = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));

        if (validations.length <= 0 || documentValidationEnabled === false) {
            setValidationStatus(null);
            setLatestId(null);
            return;
        }

        const hasProcessing = validations.find(
            validation => validation.status === DocumentValidationRequestStatus.PROCESSING,
        );
        const [latestValidation] = hasProcessing ? [hasProcessing] : validations;
        const { status, id } = latestValidation;

        switch (status) {
            case DocumentValidationRequestStatus.PROCESSING:
                setValidationStatus(ValidationStatus.PROCESSING);
                setLatestId(id);
                return;
            case DocumentValidationRequestStatus.APPROVED:
            case DocumentValidationRequestStatus.ACCEPTED:
                setValidationStatus(ValidationStatus.SUCCESS);
                setLatestId(id);
                return;
            case DocumentValidationRequestStatus.REJECTED:
            case DocumentValidationRequestStatus.PENDING:
            case DocumentValidationRequestStatus.FAILURE:
            default:
                setValidationStatus(ValidationStatus.PENDING);
                setLatestId(null);
        }
    }

    useEffect(() => {
        const unsubscribeFn = DocumentValidationRequestService.getAllByUser(auth!.uid, onNext);
        return () => {
            unsubscribeFn && unsubscribeFn();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderUploadButton() {
        let icon;
        if (loading) {
            icon = <Spinner className={styles.loadingIcon} color="red" />;
        } else {
            icon = <FiPlus className={styles.plusIcon} />;
        }

        return (
            <Button
                variant="outline"
                colorScheme="brand"
                size="md"
                marginBottom="var(--margin-medium)"
                onClick={onUploadDocumentsClick}
            >
                {icon}
                {i18n.t('pages.profile.documents.driverLicenseBtn')}
            </Button>
        );
    }

    function renderDriversLicenseButton() {
        if (
            validationStatus === ValidationStatus.SUCCESS ||
            (claims.isDriversLicenseVerified && claims.isIdentificationDocumentVerified)
        ) {
            return (
                <Button
                    _hover={{ borderColor: '#B1B5C0' }}
                    cursor="default"
                    variant="outline"
                    colorScheme="gray"
                    size="md"
                    className={styles.editDocumentButton}
                >
                    <FiCheckCircle size="20px" className={styles.redIcon} />
                    <FiCreditCard color="black" size="20px" />
                    <Text fontSize="md" fontWeight="light">
                        {i18n.t('pages.profile.documents.driverLicenseLabel')}
                    </Text>
                    <FiX size="24px" className={styles.closeIcon} onClick={() => revokeDocuments()} />
                </Button>
            );
        }

        return renderUploadButton();
    }

    return (
        <>
            <Row className={styles.cardHeader}>
                <H3>{i18n.t('pages.profile.documents.title')}</H3>
            </Row>
            <Text className={styles.description}>{i18n.t('pages.profile.documents.description')}</Text>
            <div className={styles.buttonsContainer}>
                <Column>
                    {renderDriversLicenseButton()}
                    <Text className={styles.textNote}>{i18n.t('pages.profile.documents.note')}</Text>
                </Column>
            </div>

            <ModalCancelation
                isOpen={showRevokeModal}
                onClose={onCloseRevokeModal}
                title={i18n.t('pages.profile.documents.revokeModal.title')}
                sureMessage={i18n.t('pages.profile.documents.revokeModal.sureMessage')}
                cancelButtonText={i18n.t('pages.profile.documents.revokeModal.cancelButton')}
                cancelSmallButtonText={i18n.t('pages.profile.documents.revokeModal.cancelSmallButton')}
                dontCancelButtonText={i18n.t('pages.profile.documents.revokeModal.dontCancelButton')}
                dontCancelSmallButtonText={i18n.t('pages.profile.documents.revokeModal.dontCancelSmallButton')}
                cancelText={i18n.t('pages.profile.documents.revokeModal.cancelText')}
                onClick={onSubmit}
                loading={loading}
            />
        </>
    );
}
