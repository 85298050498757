import { SetStateAction } from 'react';
import * as yup from 'yup';

import { YupSchemaObject } from './createSchema.service';

export interface Errors {
    [key: string]: string | any;
}

async function validateSchema(schema: YupSchemaObject, formData: any): Promise<object | undefined> {
    try {
        const schemaValidation = await schema.validate(formData, {
            abortEarly: false,
        });

        return schemaValidation;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

function clearInputErrors(inputName: string, formErrors: any, setFormErrors: SetStateAction<any>) {
    const isObject = inputName.indexOf('.') >= 0;

    if (isObject) {
        const rawPaths = inputName.split('.');
        let previousPath = '';
        const fullPath = rawPaths.reduce((completePath, path) => {
            let updatedCompletePath = completePath;
            if (previousPath) {
                updatedCompletePath = { [previousPath]: { [path]: undefined } };
                return updatedCompletePath;
            }

            previousPath = path;
            updatedCompletePath = { [path]: undefined };
            return updatedCompletePath;
        }, {});

        const updatedFormErrors = { ...formErrors, ...fullPath };
        setFormErrors(updatedFormErrors);
        return;
    }

    const updatedFormErrors = { ...formErrors, [inputName]: undefined };

    setFormErrors(updatedFormErrors);
}

function getValidationErrors(err: any, path?: string): Errors | void {
    if (err instanceof yup.ValidationError) {
        const validationErrors: Errors = {};

        err.inner.forEach(error => {
            const errorPath = path || error.path;

            if (errorPath) {
                const isObject = errorPath.indexOf('.') >= 0;

                if (isObject) {
                    const splittedPath = errorPath.split('.');
                    if (splittedPath.length === 2) {
                        validationErrors[splittedPath[0]] = {
                            [splittedPath[1]]: error.message,
                        };
                        return;
                    }
                }

                validationErrors[errorPath] = error.message;
            }
        });

        return validationErrors;
    }
    return undefined;
}

async function validateInput(
    schema: YupSchemaObject,
    inputName: string,
    inputValue: string,
    formErrors: any,
    setFormErrors: SetStateAction<any>,
) {
    try {
        await yup.reach(schema, inputName).validate(inputValue, { abortEarly: false });

        clearInputErrors(inputName, formErrors, setFormErrors);
    } catch (error) {
        const validationErrors = getValidationErrors(error, inputName);
        if (validationErrors) {
            setFormErrors({ ...formErrors, ...validationErrors });
        }
    }
}

export const Validator = {
    validateInput,
    validateSchema,
    getValidationErrors,
    clearInputErrors,
};
