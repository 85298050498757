import { Fields } from 'persona/dist/lib/interfaces';

export type PersonaOptions = {
    templateId: string;
    environment: PersonaEnvironment;
};

export type PersonaOnCompleteArguments = {
    inquiryId: string;
    status: PersonaOnCompleteStatus;
    fields: Fields;
};

export enum PersonaOnCompleteStatus {
    CREATED = 'created',
    PENDING = 'pending',
    COMPLETED = 'completed',
    FALIED = 'failed',
    EXPIRED = 'expired',
    NEEDS_REVIEW = 'needs_review',
    APPROVED = 'approved',
    DECLINED = 'declined',
}

export enum PersonaEnvironment {
    SANDBOX = 'sandbox',
    PRODUCTION = 'production',
}

export enum PersonaStatus {
    CLOSED = 'closed',
    LOADED = 'loaded',
    READY = 'ready',
    LOADING = 'loading',
    COMPLETED = 'completed',
}

export type PersonaEmptyFunction = () => void;
export type PersonaOnCompleteFunction = (response: PersonaOnCompleteArguments) => void;

export type ClientParameters = {
    onLoad?: PersonaEmptyFunction;
    onReady?: PersonaEmptyFunction;
    onCancel?: PersonaEmptyFunction;
    onComplete?: PersonaOnCompleteFunction;
    referenceId?: string;
};
