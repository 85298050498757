import React, { useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { ErrorText } from 'components/base/errorText';
import ModalNew from 'components/base/modalNew';
import { EmailIcon } from '@chakra-ui/icons';
import styles from './index.module.css';
import i18n from '../../../language/i18n';
import authService from '../../../services/auth.service';

export type EmailVerificationModalProps = {
    onModalClosed: () => void;
    user?: any;
    isOpen: boolean;
};

export default function EmailVerificationModal(props: EmailVerificationModalProps) {
    const { isOpen, user, onModalClosed } = props;
    const [error, setError] = useState<string | null>('');
    const [loading, setLoading] = useState<boolean>(false);

    async function resendVerificationEmail() {
        setLoading(false);
        try {
            setError(null);
            const { data }: any = await authService.sendEmailVerification();

            if (data.status === 'failure') {
                setError(i18n.t('errors.tooManyAttemps'));
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err.message);
            setLoading(false);
        }
    }

    return (
        <ModalNew isOpen={isOpen} onClose={onModalClosed} loading={loading}>
            <div className={styles.emailVerificationModal}>
                <EmailIcon h={20} w={20} />
                <Text marginBottom="24px" textAlign="center" fontSize="2xl" color="black" fontWeight="medium">
                    Verify your email
                </Text>
                <Text
                    marginBottom="4px"
                    textAlign="center"
                    fontSize="xl"
                    color="var(--color-gray-light)"
                    fontWeight="light"
                >
                    {i18n.t('emailVerification.message1')}
                </Text>
                <Text
                    marginBottom="4px"
                    textAlign="center"
                    fontSize="xl"
                    color="var(--color-gray-light)"
                    fontWeight="semibold"
                >
                    {`${user.auth ? user.auth.email : ''}`}
                </Text>
                <Text
                    marginBottom="24px"
                    textAlign="center"
                    fontSize="xl"
                    color="var(--color-gray-light)"
                    fontWeight="light"
                >
                    {i18n.t('emailVerification.message2')}
                </Text>
                <ErrorText>{error || ''}</ErrorText>{' '}
                <Button variant="solid" colorScheme="black" size="md" onClick={resendVerificationEmail}>
                    {i18n.t('emailVerification.resendBtn')}
                </Button>
            </div>
        </ModalNew>
    );
}
