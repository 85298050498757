import { BoxProps, FlexProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<FlexProps & BoxProps> = {
    container: {
        position: 'relative',
    },

    content: {
        flex: 'unset !important',
        marginBottom: 'var(--margin-xlarge)',
        marginTop: { base: 'var(--margin-medium)', md: 'var(--margin-xlarge)' },
        justifyContent: 'space-between',
    },

    panelContainer: {
        display: { base: 'none ', md: 'flex ' },
        flex: { base: '1', md: 'flex' },
        width: {
            md: 'calc(50% - var(--margin-xlarge) / 2)',
            lg: 'calc(50% - var(--margin-xxxlarge) / 2)',
        },
        maxWidth: '400px',
        alignItems: 'flex-start',
    },

    contentDetails: {
        width: {
            base: '100%',
            md: 'calc(50% - var(--margin-xlarge) / 2)',
            lg: 'calc(50% - var(--margin-xxxlarge) / 2)',
        },
        maxWidth: '800px',
        marginRight: {
            md: 'var(--margin-xlarge)',
            lg: 'var(--margin-xxxlarge)',
            xl: 'var(--margin-xxxxlarge)',
        },
    },
};

export default styles;
