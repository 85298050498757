import Grid from 'components/base/grid';
import Radio from 'components/base/radio';
import React, { useState } from 'react';
import useOuterClick from '../../../../../hooks/useOuterClick';
import i18n from '../../../../../language/i18n';
import { ChevDownIcon } from '../../../../base/icon/chev-down';
import { ChevUpIcon } from '../../../../base/icon/chev-up';
import styles from './index.module.css';

export type AssetFilterSortByProps = {
    items?: any[];
    value: string;
    onChange?: any;
};

function AssetFilterSortBy(props: AssetFilterSortByProps) {
    const { value, onChange } = props;
    const [isFocus, setFocus] = useState<boolean>(false);
    const [sortOption, setSortOption] = useState(value || 'asc');

    const options = [
        { label: 'Price: Lowest to Highest', value: 'asc' },
        { label: 'Price: Highest to Lowest', value: 'desc' },
    ];

    function onSortChange(newValue: string) {
        setSortOption(newValue);
        onChange && onChange(newValue);
    }

    function onBarClick(e: any) {
        setFocus(!isFocus);
    }

    const innerRef = useOuterClick((ev: any) => {
        setFocus(false);
    });

    const getOptionLabel = (selectedOption: string) => {
        const option = options.find(mappedOption => {
            return mappedOption.value === selectedOption;
        });
        return option?.label;
    };

    return (
        <div className={[styles.sortBarContainer, isFocus ? styles.focus : ''].join(' ')}>
            <div className={styles.sortBar} onClick={onBarClick} ref={innerRef as any}>
                <span>
                    {i18n.t('sections.assetList.sortBy.label')}: {getOptionLabel(sortOption)} &nbsp;
                </span>
                {isFocus ? <ChevUpIcon /> : <ChevDownIcon />}
            </div>
            <div className={styles.dropdown}>
                <Grid>
                    <Radio className={styles.radio} options={options} value={sortOption} onChange={onSortChange} />
                </Grid>
            </div>
        </div>
    );
}

export default AssetFilterSortBy;
