import React from 'react';

import styles from './index.module.css';

export class Logo extends React.Component<any> {
    render() {
        const { size, className, onClick } = this.props;

        let classes: Array<string>;

        switch (size) {
            case 'medium':
                classes = [styles.logoContainer, styles.medium];
                break;
            case 'large':
                classes = [styles.logoContainer, styles.large];
                break;
            default:
                classes = [styles.logoContainer];
        }

        if (className !== undefined) {
            classes.push(className);
        }

        return (
            <div className={classes.join(' ')} onClick={onClick}>
                {' '}
            </div>
        );
    }
}
