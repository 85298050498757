import { useAuth } from 'hooks/auth';
import React from 'react';
import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar } from '../../../../base/avatar';
import Column from '../../../../base/column';

import Row from '../../../../base/row';
import BodyText from '../../../../base/text/body';

import i18n from '../../../../../language/i18n';

import styles from './index.module.css';

export function ProfileReference(props: ProfileReferenceProps) {
    const { auth } = useAuth();

    if (!auth) {
        return null;
    }

    return (
        <Row className={styles.container}>
            <Avatar className={styles.avatar} source={auth.photoURL} />
            <Column>
                <BodyText>{auth.displayName || 'N/A'}</BodyText>
                <Link as={RouterLink} to="/profile" onClick={props.onProfileLinkClick}>
                    {i18n.t('sidebar.link.profile')}
                </Link>
            </Column>
        </Row>
    );
}

export type ProfileReferenceProps = {
    onProfileLinkClick?: (event: React.MouseEvent) => void;
};
