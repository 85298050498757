import i18n from 'language/i18n';
import { CreateSchema } from 'services/validator';

const schema = [
    {
        id: 'name',
        validationType: 'string',
        validations: [
            {
                type: 'strict',
                params: [false],
            },
            {
                type: 'trim',
                params: [],
            },
            {
                type: 'min',
                params: [
                    5,
                    i18n.t('errors.invalid.length', {
                        field: i18n.t('pages.profile.info.nameLabel').toLowerCase(),
                        chars: '5',
                    }),
                ],
            },
            {
                type: 'required',
                params: [i18n.t('errors.requiredInput')],
            },
        ],
    },
    {
        id: 'email',
        validationType: 'string',
        validations: [
            {
                type: 'email',
                params: [i18n.t('errors.invalid.email')],
            },
        ],
    },
    {
        id: 'birthdate',
        validationType: 'string',
        validations: [],
    },
    {
        id: 'phone',
        validationType: 'string',
        validations: [
            {
                type: 'required',
                params: [i18n.t('errors.requiredInput')],
            },
        ],
    },
];

export const yupSchema = CreateSchema(schema);
