import { extendTheme } from '@chakra-ui/react';

const LinkTheme = extendTheme({
    baseStyle: {
        color: '#E14436',
        fontSize: 'var(--font-small)',
        lineHeight: 'var(--line-small)',
        fontWeight: 'var(--font-weight-medium)',
        textDecoration: 'underline',
        disabled: {
            color: '#E2E3E7',
        },
        _hover: {},
    },
    sizes: {
        sm: { fontSize: 'var(--font-small)', lineHeight: 'var(--line-small)' },
    },
    variants: {
        primaryBlack: {
            color: 'black',
            _hover: {
                color: '#0B0B0D',
            },
        },
        primaryRed: {
            color: '#E14436',
            lineHeight: 'var(--line-small)',
            _hover: {
                color: '#AC362C',
            },
        },
        secondary: {
            color: '#0B0B0D',
            lineHeight: 'var(--line-small)',
            _hover: {
                fontWeight: 'var(--font-weight-light)',
            },
        },

        termsOfService: {
            color: '#9DA0AA',
            fontSize: 'var(--font-small)',
            lineHeight: 'var(--line-small)',
            fontWeight: 'var(--font-weight-light)',
            _hover: {
                color: '#E14436',
            },
            _disabled: {
                color: '#B1B5C0',
            },
        },
        noStyle: {
            color: '#3e424d',
            textDecoration: 'none',
            _hover: {
                opacity: 0.6,
                textDecoration: 'none',
            },
        },
    },
    defaultProps: { size: 'sm', variant: 'baseStyle', showIcon: false },
});

export default LinkTheme;
