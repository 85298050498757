import firebase from 'firebase';
import React, { useState, FC, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import EmailVerificationModal from 'components/auth/emailVerificationModal/index';
import DriverVerificationsModal from 'components/auth/driverVerificationsModal';

import authentication from 'services/auth.service';
import { auth, DocumentReference } from 'services/persistence';

import profileService from 'services/profile.service';
import Loading from 'components/base/loading';
import AuthenticationModal from 'components/sections/standalone/authentication/modal';

export type Phone = {
    code: string;
    country: string;
    number: string;
};

export type User = {
    name: string;
    email: string;
    emailVerified: boolean;
    phoneObject: Phone;
    license?: string;
    birthdate?: string;
    address?: string;
    companyRef?: DocumentReference;
    taxNumber?: string;
};

export type AuthenticationContextType = {
    loading: boolean;
    isProcessing: boolean;
    auth?: firebase.User;
    claims: {
        [key: string]: any;
    };
    verificationsModal: boolean;
    checkIfHavePendingVerifications: () => boolean;
    user: User;
    getUser: () => Promise<void>;
    refreshCustomClaims: () => Promise<void>;
    showAuth: (section?: 'login' | 'register' | 'forgotPassword') => void;
};

const AuthenticationContext = React.createContext<AuthenticationContextType>({} as AuthenticationContextType);

export const AuthProvider: FC = ({ children }) => {
    const history = useHistory();
    const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
    const [verificationsModalIsOpen, setVerificationsModalIsOpen] = useState(false);
    const [user, setUser] = useState<User>({} as User);
    const [loading, setLoading] = useState(true);
    const [showAuthSection, setShowAuthSection] = useState<'login' | 'register' | 'forgotPassword'>('login');
    const [showAuth, setShowAuth] = useState(false);
    const [authUser, setAuthUser] = useState<{
        auth?: firebase.User;
        lockAdmin: boolean;
        isProcessing: boolean;
        claims?: {
            [key: string]: any;
        };
    }>({
        lockAdmin: false,
        isProcessing: true,
        claims: {},
    });

    const getUser = useCallback(async () => {
        if (authUser.auth) {
            const firestoreUser = await profileService.get(authUser.auth?.uid);
            setUser({
                ...firestoreUser,
                emailVerified: authUser.auth.emailVerified,
            } as User);
        }
    }, [authUser.auth]);

    const emailVerificationListener = useCallback(() => {
        const interval = setInterval(() => {
            const current: firebase.User | null = auth.currentUser;
            if (current) {
                current.reload();
                if (current.emailVerified === true) {
                    setEmailModalIsOpen(false);
                    setAuthUser(prevState => ({
                        ...prevState,
                        isProcessing: false,
                        auth: current,
                    }));
                    setLoading(false);
                    clearInterval(interval);
                }
            }
        }, 3000);
    }, []);

    useEffect(() => {
        const unsubscribe = authentication.onAuthStateChanged(async authenticationUser => {
            if (!authenticationUser) {
                console.log('Welcome to Rummo. You are not authenticated.');
                setAuthUser({
                    lockAdmin: false,
                    isProcessing: false,
                    claims: {},
                });
                setUser({} as User);
                setLoading(false);
                return;
            }

            if (authenticationUser) {
                const token = await authenticationUser.getIdTokenResult(true);

                if (token.claims) {
                    // disable admin accounts from using the app
                    if (token.claims.admin === true) {
                        setAuthUser(prevState => ({
                            ...prevState,
                            isProcessing: false,
                            lockAdmin: true,
                        }));
                        await authentication.logout();
                        setLoading(false);
                        return;
                    }
                    if (!token.claims.isDriversLicenseVerified || !token.claims.isIdentificationDocumentVerified) {
                        setVerificationsModalIsOpen(true);
                    }
                }

                if (!authenticationUser.emailVerified) {
                    setEmailModalIsOpen(true);
                    emailVerificationListener();
                }

                // set the user ID

                setAuthUser(prevState => ({
                    ...prevState,
                    isProcessing: false,
                    auth: authenticationUser,
                    claims: token.claims,
                }));
                setLoading(false);
            }
        });

        return unsubscribe;
    }, [emailVerificationListener]);

    const skipEmailVerification = async () => {
        setEmailModalIsOpen(false);
        await authentication.logout();
    };

    const checkIfHavePendingVerifications = useCallback(() => {
        if (authUser.claims) {
            const { isDriversLicenseVerified, isIdentificationDocumentVerified } = authUser.claims;

            if (!isDriversLicenseVerified || !isIdentificationDocumentVerified) {
                setVerificationsModalIsOpen(true);
                return true;
            }

            return false;
        }

        return true;
    }, [authUser.claims]);

    async function refreshCustomClaims() {
        const currentUser = authUser.auth;
        currentUser && (await currentUser.reload());

        const claims = currentUser && (await currentUser.getIdTokenResult(true)).claims;

        setAuthUser(prevState => ({
            ...prevState,
            claims,
        }));
    }

    function goToProfile() {
        setVerificationsModalIsOpen(false);
        history.push('/profile');
    }

    function showAuthModal(section?: 'login' | 'register' | 'forgotPassword') {
        setShowAuthSection(section!);
        setShowAuth(!showAuth);
    }

    const closeVerificationsModal = () => {
        setVerificationsModalIsOpen(false);
    };

    if (loading) {
        return <Loading spinnerSize={40} fullScreen />;
    }

    return (
        <AuthenticationContext.Provider
            value={{
                loading,
                auth: authUser.auth,
                claims: authUser.claims!,
                isProcessing: authUser.isProcessing,
                verificationsModal: verificationsModalIsOpen,
                checkIfHavePendingVerifications,
                user,
                getUser,
                refreshCustomClaims,
                showAuth: showAuthModal,
            }}
        >
            {children}
            {authUser.auth?.emailVerified && (
                <DriverVerificationsModal
                    isOpen={verificationsModalIsOpen}
                    onConfirm={goToProfile}
                    onClose={closeVerificationsModal}
                />
            )}

            <EmailVerificationModal isOpen={emailModalIsOpen} onModalClosed={skipEmailVerification} user={authUser} />
            {authUser.auth === undefined && (
                <AuthenticationModal
                    history={history}
                    section={showAuthSection}
                    isOpen={showAuth}
                    onModalClosed={() => setShowAuth(false)}
                />
            )}
        </AuthenticationContext.Provider>
    );
};

export function useAuth(): AuthenticationContextType {
    const context = useContext(AuthenticationContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export default AuthenticationContext;
