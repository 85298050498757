import { Button, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import Column from 'components/base/column';
import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';
import Loading from 'components/base/loading';
import Margin from 'components/base/margin';
import Row from 'components/base/row';
import Splitter from 'components/base/splitter';
import BodyText from 'components/base/text/body';
import { H3 } from 'components/base/text/h3';
import DriverInfo from 'components/sections/asset-checkout/driver-info';
import AssetPaymentProfile from 'components/sections/asset-checkout/payment-profile';
import AssetPanel from 'components/sections/_layout/asset-panel';
import BillingInfo from 'components/sections/_layout/billing-info';
import LocationItem from 'components/sections/_layout/location-item';
import MobilePriceDrawer from 'components/sections/_layout/mobile-price-drawer';
import ModalError from 'components/sections/_layout/modal-error';
import PaymentPicker from 'components/sections/_layout/payment-picker';
import { useAssetDetails, useAuth, useInsurances, useRemoteConfig, useSharedData, useWindowSize } from 'hooks';
import i18n from 'language/i18n';
import { PaymentMethod } from 'models/paymentMethods';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BookingService from 'services/booking.service';
import CompanyService, { Company } from 'services/company.service';
import UtilityService from 'services/utility.service';
import LoadingAnimation from 'components/Lottie/loadingAnimation';
import { SharedData } from 'models/sharedData/SharedData';
import PaymentMethodService from '../../services/paymentMethods.service';
import styles from './index.module.css';

interface StateProps {
    startDate: Moment;
    endDate: Moment;
    paymentOption: string;
    selectedExtras: string[];
}

function AssetsCheckoutPage() {
    const { shared, assetDetailsBookingDataContext, sharedDataService } = useSharedData();

    const location = useLocation<StateProps>();
    const { state } = location;
    const history = useHistory();
    const context = useAuth();
    const { getUser, user } = useAuth();
    const params = useParams<{ id: string }>();
    const { asset, loading } = useAssetDetails(params.id);
    const insurances = useInsurances([shared.insurances.main!, ...shared.insurances.extras]);

    const auxSharedData = (assetDetailsBookingDataContext || shared) as SharedData;

    const { start, end } = auxSharedData;

    const windowSize = useWindowSize();
    const [isPriceDrawerOpen, setIsPriceDrawerOpen] = useState<boolean>(false);
    const unsubscribeSourcesListener = useRef<undefined | (() => void)>();
    const { paymentsEnabled } = useRemoteConfig();
    const toast = useToast();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto',
        });
    };

    const { bookingCancellationConfig } = useRemoteConfig();

    const [paymentProfile, setPaymentProfile] = useState<string>(paymentsEnabled ? 'personal' : 'business');
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>();
    const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(true);

    const [paymentSource, setPaymentSource] = useState<string>();

    const noContext = !start || !end;
    const noDates = !start.date || !end.date;
    const noLocation = !start.location || !end.location;

    if (!state || noContext || noDates || noLocation) {
        history.replace(`/`, state);
    }

    const [selectedExtras] = useState<string[]>(shared.extras || []);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [company, setCompany] = useState<Company | null | undefined>();
    const [loadingCompany, setLoadingCompany] = useState(true);

    function updateMethods(methods: PaymentMethod[]) {
        setPaymentMethods(prev => {
            if (!prev) {
                if (methods.length < 1) {
                    return undefined;
                }
                setPaymentSource(methods[0].paymentMethodId);
                return methods;
            }

            const diff = UtilityService.getArrayDiff(methods, prev || [], 'paymentMethodId');
            setPaymentSource(diff[0].paymentMethodId);
            return methods;
        });
        setPaymentMethodsLoading(false);
    }

    function getMethods() {
        const unsubFn = PaymentMethodService.getListener(updateMethods, errorMessage =>
            console.log('ERROR on payment service: ', errorMessage),
        );
        unsubscribeSourcesListener.current = unsubFn;
    }

    function openPriceDrawer() {
        setIsPriceDrawerOpen(true);
    }

    function closePriceDrawer() {
        setIsPriceDrawerOpen(false);
    }

    function determineEndQueryText() {
        if (!end.query || !end.location) {
            return undefined;
        }

        if (start.query === end.query) {
            return i18n.t('sections.assetDetails.booking.sameAddressAsDelivery');
        }

        return end.query;
    }

    function displayDate(date?: Moment | null, time?: string | null) {
        if (!date || !time) {
            return undefined;
        }

        const formattedTime = moment(time, 'HH:mm').format('hh:mma');

        return `${date?.format('dddd, MMM DD')}, ${formattedTime}`;
    }

    function onClickBackArrow() {
        history.goBack();
    }

    function onMount() {
        getUser();
        getMethods();

        return () => {
            unsubscribeSourcesListener.current && unsubscribeSourcesListener.current();
        };
    }

    function isPageLoading() {
        const assetLoading = loading && !asset;
        return assetLoading || insurances.insurancesLoading || submitLoading || loadingCompany || paymentMethodsLoading;
    }

    useEffect(onMount, []);

    useEffect(() => {
        try {
            if (user && user.companyRef) {
                const companyId = user.companyRef.id;
                CompanyService.get(companyId).then(result => {
                    setCompany(result);
                });
            }
        } finally {
            setLoadingCompany(false);
        }
    }, [user]);

    async function onSubmit() {
        const mapInsurances = insurances.data.map(item => {
            if (item.multipleOptions)
                return {
                    id: item.id,
                    selectedOption: item.options?.find(option => option.selected)?.optionId,
                };
            return { id: item.id };
        });

        const noPaymentMethods = !paymentMethods || (paymentMethods.length <= 0 && !paymentSource);

        if (noPaymentMethods) {
            toast({
                title: i18n.t('pages.profile.documents.toast.error.creation.title'),
                description: 'You should add at least one payment method to proceed',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
            return;
        }

        try {
            scrollToTop();
            setSubmitLoading(true);

            const isPersonal = paymentProfile === 'personal';

            const methodRef = isPersonal
                ? paymentMethods?.find(payment => payment.paymentMethodId === paymentSource)?.ref
                : undefined;

            const payment = {
                profile: paymentProfile,
                methodRef,
            };

            // request
            const result = await BookingService.create(
                context!.auth!.uid,
                params.id,
                start!,
                end!,
                state.paymentOption!,
                selectedExtras,
                mapInsurances,
                paymentsEnabled ? payment : undefined,
                asset.operatingCityRef,
            );
            const bookingId = result.data.bookingRef.id;

            const successObject = {
                startDate: start!.date?.toDate(),
                endDate: end!.date?.toDate(),
                selectedExtras,
                paymentOption: 'pay-per-day',
                insurances: insurances.data,
                creationDate: result.data.creationDate.toDate(),
            };

            const deliveryObj = start;
            const returnObj = end;
            const deliveryAdressObj = { location: start.location, query: start.query };
            const returnAdressObj = { location: end.location, query: end.query };

            // redirect and update shared data
            sharedDataService.updateDates(deliveryObj, returnObj);
            sharedDataService.updateDeliveryAddress(deliveryAdressObj);
            sharedDataService.updateReturnAddress(returnAdressObj);

            history.push(`/assets/${params.id}/checkout/${bookingId}/success`, successObject);
        } catch (err) {
            if (err.data && err.data.status) {
                setError(err.data.status);
            } else {
                setError(err.code);
            }

            setSubmitLoading(false);
            setShowModal(true);
        }
    }

    function _onClose() {
        setSubmitLoading(false);
        setShowModal(false);
        setError(null);
    }

    const submitDisabled = paymentProfile !== 'personal';

    if (isPageLoading()) {
        return submitLoading ? <LoadingAnimation /> : <Loading spinnerSize={40} halfScreen />;
    }

    return (
        <>
            <Column className={styles.container}>
                <ArrowLeftIcon className={styles.backIcon} onClick={onClickBackArrow} />
                <Text variant="h3" fontWeight="600">
                    {i18n.t('pages.assetDetails.booking.checkout.title')}
                </Text>
                <Margin size="xlarge" />
                <Row className={styles.content}>
                    <Column className={styles.contentDetails}>
                        <H3 className={styles.sectionTitle}>Booking Info</H3>
                        <LocationItem
                            inverted
                            title={i18n.t('pages.assetDetails.booking.deliveryInfo')}
                            location={start.query!}
                            date={displayDate(start.date, start.time)}
                        />

                        <LocationItem
                            inverted
                            title={i18n.t('pages.assetDetails.booking.returnInfo')}
                            location={determineEndQueryText()}
                            date={displayDate(end.date, end.time)}
                        />

                        <Splitter className={styles.splitter} spacing="xlarge" />

                        <H3 className={styles.sectionTitle}>
                            {i18n.t('pages.assetDetails.booking.driverDetails.title')}
                        </H3>
                        <DriverInfo user={user} hideLicense />

                        <Margin size="xlarge" />
                        <Splitter spacing="xlarge" />

                        {windowSize.width < 692 && (
                            <>
                                <Column flex="0" className={styles.panelContainer}>
                                    <AssetPanel
                                        mode="checkout-2"
                                        asset={asset}
                                        onSubmit={onSubmit}
                                        insurances={insurances.data}
                                        extras={selectedExtras}
                                        className={styles.assetPanel}
                                        bookingCancellationConfig={bookingCancellationConfig}
                                    />
                                </Column>
                                <Splitter spacing="xlarge" />
                            </>
                        )}

                        <H3 className={styles.sectionTitle}>
                            {i18n.t('pages.assetDetails.booking.paymentDetails.title')}
                        </H3>
                        <SimpleGrid columns={{ base: 1, xl: 2 }} spacingY={6}>
                            <AssetPaymentProfile
                                disabled={paymentsEnabled ? undefined : 'personal'}
                                profile={paymentProfile}
                                onChange={setPaymentProfile}
                                companyRef={user.companyRef}
                                title={i18n.t('pages.assetDetails.booking.paymentProfile.title')}
                            />
                            {paymentProfile === 'personal' && paymentsEnabled && (
                                <PaymentPicker
                                    onChange={setPaymentSource}
                                    selected={paymentSource}
                                    sources={paymentMethods}
                                />
                            )}
                        </SimpleGrid>
                        <Margin size="medium" />
                        <BillingInfo
                            company={company}
                            user={user}
                            paymentProfile={paymentProfile}
                            title={i18n.t('pages.assetDetails.booking.billingInfo.title')}
                        />
                        <Margin size="small" />
                        <Margin size="xlarge" />
                        <Splitter spacing="xlarge" />

                        <H3 className={styles.sectionTitle}>
                            {i18n.t('pages.assetDetails.booking.cancelationPolicy.title')}
                        </H3>
                        <BodyText className={styles.cancelationFirst}>
                            <Text className={styles.cancelationBodyBold}>
                                {i18n.t('pages.bookingDetails.cancelationPolicy.first', {
                                    cancellationTime: bookingCancellationConfig.cancellation_time,
                                })}{' '}
                            </Text>
                            {i18n.t('pages.bookingDetails.cancelationPolicy.second')}
                        </BodyText>

                        <Margin size="large" />
                        <Splitter spacing="xlarge" />

                        <BodyText className={styles.cancelationLast}>
                            {i18n.t('pages.assetDetails.booking.cancelationPolicy.second')}
                        </BodyText>
                        <Margin size="xlarge" />
                        <Button
                            variant="solid"
                            colorScheme="black"
                            size="xl"
                            disabled={submitDisabled}
                            onClick={onSubmit}
                        >
                            {i18n.t('pages.assetDetails.booking.submitButton')}
                        </Button>
                    </Column>
                    {windowSize.width >= 692 && (
                        <Column flex="0" className={styles.panelContainer}>
                            <AssetPanel
                                mode="checkout-2"
                                asset={asset}
                                onSubmit={onSubmit}
                                insurances={insurances.data}
                                extras={shared.extras}
                                className={styles.assetPanel}
                                bookingCancellationConfig={bookingCancellationConfig}
                            />
                        </Column>
                    )}
                </Row>
                {windowSize.width < 692 && (
                    <MobilePriceDrawer
                        asset={asset}
                        extras={selectedExtras}
                        insurances={insurances.data}
                        mode="checkout-2"
                        isOpen={isPriceDrawerOpen}
                        onToggle={openPriceDrawer}
                        onClose={closePriceDrawer}
                        onSubmit={onSubmit}
                        bookingCancellationConfig={bookingCancellationConfig}
                    />
                )}
            </Column>
            {showModal && <ModalError onClose={_onClose} error={`${error}`} />}
        </>
    );
}

export default AssetsCheckoutPage;
