import React, { useState, ChangeEvent, useEffect } from 'react';
import _ from 'lodash';

import Column from 'components/base/column';
import Row from 'components/base/row';
import ErrorText from 'components/base/text/error';
import { Input } from 'components/base/input';
import { Avatar } from 'components/base/avatar';
import { Spinner } from 'components/base/spinner';
import { PhoneInput, PhoneObject } from 'components/base/phoneInput';

import i18n from 'language/i18n';
import { Errors, Validator } from 'services/validator';
import profileService from 'services/profile.service';
import { useAuth } from 'hooks';
import { Button } from '@chakra-ui/react';
import { yupSchema } from './schema';
import { FormData } from './schema/form';

import styles from './index.module.css';

interface ProfileInfoEditProps {
    closeEditMode: () => void;
}

export function ProfileInfoEdit({ closeEditMode }: ProfileInfoEditProps) {
    const { auth, user, getUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [formData, setFormData] = useState<FormData>({ ...user });
    const [formErrors, setFormErrors] = useState<Errors>();
    const [submitError, setSubmitError] = useState('');

    function onInputChange(e: ChangeEvent<HTMLInputElement>) {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        Validator.validateInput(yupSchema, inputName, inputValue, formErrors, setFormErrors);

        // regex for double space
        const doubleSpace = /\s{2,}/;
        const formattedInputValue =
            inputValue.length <= 1
                ? inputValue.replace(/ /g, '')
                : inputValue.replace(doubleSpace, '').split('.').join('');

        setFormData({ ...formData, [inputName]: formattedInputValue });
    }

    function onPhoneInputChange(data: PhoneObject) {
        setFormErrors(prevState => ({ ...prevState, phone: undefined }));

        Validator.validateInput(yupSchema, 'phone', data.formatted, formErrors, setFormErrors);

        setFormData(prevState => ({
            ...prevState,
            phoneObject: {
                code: data.phoneCode,
                country: data.countryCode,
                number: data.phone,
            },
        }));
    }

    async function handleSubmit() {
        try {
            setFormErrors(undefined);

            const userData = {
                ...formData,
                phone: `${formData.phoneObject!.code} ${formData.phoneObject!.number}`,
            };

            await Validator.validateSchema(yupSchema, userData);

            setLoading(true);

            const data = { ...formData };

            delete data.birthdate;

            await profileService.edit(auth!.uid, data);

            setTimeout(async () => {
                await getUser();
                setLoading(false);
                closeEditMode();
            }, 2000);
        } catch (error) {
            setLoading(false);
            const validationErrors = Validator.getValidationErrors(error);
            if (validationErrors) {
                setFormErrors(validationErrors);
                return;
            }

            setSubmitError(error.message);
        }
    }

    useEffect(() => {
        const isUndefined = (currentValue: any) => currentValue === undefined;
        const isEqual = _.isEqual(formData, user);

        const shouldBeDisabled = [formErrors?.name, formErrors?.email, formErrors?.phone].every(isUndefined);
        setSubmitDisabled(!shouldBeDisabled || isEqual);
    }, [formData, formErrors, user]);

    if (loading) {
        return (
            <Column className={styles.loadingContainer}>
                <Spinner className={styles.loadingSpinner} color="red" />
            </Column>
        );
    }

    return (
        <Column>
            <Row>
                <Avatar className={styles.avatar} />
                <Column className={styles.container}>
                    <Column>
                        <strong>{i18n.t('pages.profile.info.nameLabel')}</strong>
                        <Input
                            name="name"
                            value={formData.name}
                            onChange={onInputChange}
                            errorMessage={formErrors?.name}
                        />
                    </Column>
                    <Column>
                        <strong>{i18n.t('pages.profile.info.emailLabel')}</strong>
                        <Input
                            disabled
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={onInputChange}
                            errorMessage={formErrors?.email}
                        />
                    </Column>
                    <Column>
                        <strong>{i18n.t('pages.profile.info.phoneLabel')}</strong>
                        <PhoneInput
                            country={formData.phoneObject?.country}
                            value={formData.phoneObject?.number}
                            onChange={onPhoneInputChange}
                            errorMessage={formErrors?.phone}
                        />
                    </Column>
                    `
                </Column>
            </Row>

            {submitError && <ErrorText>{submitError}</ErrorText>}

            <Row className={styles.editButtons} align="center" justify="space-between">
                <Button transition="filter 0.2s" variant="ghost" colorScheme="gray" size="sm" onClick={closeEditMode}>
                    {i18n.t('pages.profile.info.edit.cancel')}
                </Button>
                <Button variant="solid" colorScheme="black" size="md" onClick={handleSubmit} disabled={submitDisabled}>
                    {i18n.t('pages.profile.info.edit.save')}
                </Button>
            </Row>
        </Column>
    );
}
