import React from 'react';
import {
    InputGroup,
    Input,
    InputRightElement,
    useDisclosure,
    FormControl,
    Text,
    ChakraProps,
    Box,
    InputLeftElement,
    Flex,
} from '@chakra-ui/react';

import { ChevUpIcon } from 'components/base/icon/chev-up';
import { ChevDownIcon } from 'components/base/icon/chev-down';

import useOuterClick from 'hooks/useOuterClick';

import i18n from 'language/i18n';

import styles from './styles';
import { Spinner } from '../spinner';

export type SelectOption<T = any> = {
    label: string;
    value: string;
    icon?: JSX.Element | null;
    data?: T;
};

interface SelectProps {
    title?: string;
    placeholder?: string;
    soft?: boolean;
    loading?: boolean;
    searchable?: boolean;
    options?: SelectOption[];
    selected?: SelectOption;
    onChange?: (option: SelectOption) => void;
    onClick?: (e: React.MouseEventHandler<HTMLDivElement>) => void;
}

function Select({
    title,
    searchable,
    options,
    selected,
    onChange,
    soft,
    placeholder,
    onClick,
    loading,
    ...rest
}: SelectProps & ChakraProps) {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const ref = useOuterClick(onClose);

    const canToggle = options && options.length > 0;
    const option = options?.find(mappedOption => mappedOption.value === selected?.value);
    const selectedOption = option?.label;

    function onInputClick() {
        if (canToggle) {
            searchable ? onOpen() : onToggle();
        }
    }

    function _onChange(optionSelected: SelectOption) {
        onChange?.(optionSelected);
        onClose();
    }

    function handleOnToggle() {
        if (canToggle) {
            onToggle();
        }
    }

    return (
        <FormControl {...rest}>
            <Text {...styles.title(!option || !!option?.icon)}>{title}</Text>

            {loading ? (
                <Spinner size={35} />
            ) : (
                <InputGroup ref={ref} {...styles.inputGroup(isOpen)} onClick={onClick}>
                    {option?.icon && (
                        <InputLeftElement {...styles.inputIcon(true)} onClick={handleOnToggle}>
                            {option.icon}
                        </InputLeftElement>
                    )}

                    <Input
                        {...styles.input(isOpen, soft, !!option?.icon)}
                        onClick={onInputClick}
                        placeholder={
                            placeholder || i18n.t('location.search.modal.input.placeholder') || 'Enter location'
                        }
                        readOnly={!searchable}
                        value={selectedOption}
                    />

                    <InputRightElement {...styles.inputIcon()} onClick={handleOnToggle}>
                        {isOpen ? <ChevUpIcon color="black" /> : <ChevDownIcon color="black" />}
                    </InputRightElement>

                    {isOpen && (
                        <Box {...styles.inputMenu(soft)} id="dropdown">
                            {options?.map(mappedOption => (
                                <Box
                                    key={mappedOption.value}
                                    {...styles.inputMenuItem}
                                    onClick={() => _onChange(mappedOption)}
                                >
                                    <Flex maxWidth="max-content" alignItems="center" gap="2">
                                        {mappedOption.icon} {mappedOption.label}
                                    </Flex>
                                </Box>
                            ))}
                        </Box>
                    )}
                </InputGroup>
            )}
        </FormControl>
    );
}

export default Select;
