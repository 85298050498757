import React from 'react';

import styles from './index.module.css';

export interface IconProps {
    onClick?: (event: React.MouseEvent) => void;
    img?: string;
    color?: string;
    alt?: string;
    className?: string;
    style?: React.CSSProperties;
}

export default function Icon(props: IconProps) {
    const { img, alt, onClick, className } = props;

    return <img onClick={onClick} src={img} className={[styles.icon, className].join(' ')} alt={alt} />;
}
