import { ChangeDateAction } from './actions/ChangeDateAction';
import { ChangeSharedExtrasAction } from './actions/ChangeExtrasAction';
import { ChangeSharedInsurancesAction } from './actions/ChangeInsurancesAction';
import { ChangeLocationAction } from './actions/ChangeLocationAction';
import { ChangeSharedDataAction } from './actions/ChangeSharedData';
import { ChangeTimeAction } from './actions/ChangeTimeAction';

export enum SharedDataActionType {
    CHANGE_DATE = 'changeDate',
    CHANGE_TIME = 'changeTime',
    CHANGE_LOCATION = 'changeLocation',
    CHANGE_SHARED_DATA = 'changeSharedData',
    CHANGE_INSURANCES = 'changeSharedInsurances',
    CHANGE_EXTRAS = 'changeExtras',
}

export type SharedDataAction =
    | ChangeDateAction
    | ChangeTimeAction
    | ChangeLocationAction
    | ChangeSharedDataAction
    | ChangeSharedExtrasAction
    | ChangeSharedInsurancesAction;
