import { useRemoteConfig } from 'hooks';
import moment, { Moment } from 'moment';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';

import 'moment/locale/en-gb';

import React, { useEffect, useState } from 'react';
import { DateChange, ReturnBookingCalendarDatesChangesInformation } from 'helpers/handleBookingCalendarDatesChanges';
import checkIfIsOutsideRange from 'helpers/isOutsideRange';
import Row from '../row';

import styles from './index.module.css';

moment.updateLocale('en', {});

export type ScrollableDateRangeProps = {
    id: string;
    label?: string;
    startDateProps: Moment | null;
    endDateProps: Moment | null;
    isFocused?: boolean | null;
    containerStyle?: any;
    onBlur?: any;
    placeholder?: string;
    blockedDates?: string[];
    onChange?: (startDate: Moment | null, endDate: Moment | null) => ReturnBookingCalendarDatesChangesInformation;
    onChangeCurrentMonth?: (date: Moment) => void;
    onClearDates?: () => void;
    withClear?: boolean;
    renderCalendarInfo?: () => string | JSX.Element;
    orientation?: 'vertical' | 'horizontal';
    withPortal?: boolean;
    navPrev?: string | JSX.Element;
    navNext?: string | JSX.Element;
    customCloseIcon?: string | JSX.Element;
    setIsFocused?: (focused: boolean) => void;
    keepOpenOnDateSelect?: boolean;
};

function ScrollableDateRange(props: ScrollableDateRangeProps) {
    const { onChange, blockedDates, startDateProps, endDateProps, renderCalendarInfo } = props;
    const [rangeDateFocus, setRangeDateFocus] = useState<FocusedInputShape | null>('startDate');
    const [startDate, setStartDate] = useState<Moment | null>(startDateProps);
    const [endDate, setEndDate] = useState<Moment | null>(endDateProps);
    const { bookingMaximumDuration } = useRemoteConfig();

    function onFocusChange(focusedInput: any) {
        if (!focusedInput) {
            return;
        }

        setRangeDateFocus(focusedInput);
    }

    function onDatesChange({ startDate: newStartDate, endDate: newEndDate }: DateChange) {
        const data = onChange && onChange(newStartDate, newEndDate);

        if (data) {
            setRangeDateFocus(data.focusedDate);
            setStartDate(data.startDate);
            setEndDate(data.endDate);
        }
    }

    function handleWeekDays(day: any) {
        // added white space to duplicated letters to prevent duplicated keys
        day._locale._weekdaysMin = ['S', 'M', 'T', 'W', 'T ', 'F', 'S '];
        return (
            <div className={styles.outerBackground}>
                <div className={styles.round}>{day.format('D')}</div>
            </div>
        );
    }

    useEffect(() => {
        setStartDate(startDateProps);
        setEndDate(endDateProps);

        if (startDateProps === undefined && endDateProps === undefined) {
            setRangeDateFocus('startDate');
        }
    }, [startDateProps, endDateProps]);

    /* This function and isOutsideRange is copied from DateRangePicker component, ideally this component and DateRangePicker should be merged into one someday  */
    function setBlockedDays(dateToCompare: Moment) {
        const now = moment().setTime('0');

        // Disable dates before today
        if (now.isAfter(dateToCompare)) {
            return true;
        }

        if (blockedDates) {
            const isBlocked = blockedDates.some(d => moment(d).isSame(dateToCompare, 'D'));

            if (isBlocked) {
                return true;
            }

            const before = dateToCompare.clone().subtract(1, 'd');
            const after = dateToCompare.clone().add(1, 'd');
            const singleDate =
                blockedDates.some(d => moment(d).isSame(before, 'D')) &&
                blockedDates.some(d => moment(d).isSame(after, 'D'));

            if (singleDate) {
                return true;
            }
        }

        return false;
    }

    function isOutsideRange(date: Moment) {
        const isItOutsideRange = checkIfIsOutsideRange({
            startDate,
            endDate,
            date,
            dateRangeFocus: rangeDateFocus,
            blockedDates,
            bookingMaximumDuration,
        });

        return isItOutsideRange;
    }

    return (
        <Row className={styles.container}>
            <DayPickerRangeController
                isDayBlocked={setBlockedDays}
                isOutsideRange={isOutsideRange}
                onDatesChange={onDatesChange}
                onFocusChange={onFocusChange}
                focusedInput={rangeDateFocus || 'startDate'}
                startDate={startDate || null}
                endDate={endDate || null}
                renderCalendarInfo={renderCalendarInfo}
                numberOfMonths={10}
                orientation="verticalScrollable"
                hideKeyboardShortcutsPanel
                initialVisibleMonth={() => moment()}
                noBorder
                renderDayContents={handleWeekDays}
            />
        </Row>
    );
}

export default ScrollableDateRange;
