import Dinero, { Currency } from 'dinero.js';

const precisionNumber = 4;
const fee = 1.15;
const vat = 1.23;
const currencyCode = 'USD';

/**
 *
 */
export type PriceObject = {
    amount: number;
    currency: Currency;
    precision: number;
};

/**
 *
 */
export class PriceCalculator {
    public instance: Dinero.Dinero;
    private currency: Currency;

    constructor(amount: number | PriceObject, currency?: Currency) {
        this.currency = currency || currencyCode;

        if (typeof amount === 'object') {
            this.instance = Dinero(amount);
        } else if (typeof amount === 'number') {
            this.instance = this.toDinero(amount, this.currency);
        } else {
            this.instance = this.toDinero(0, this.currency);
        }
    }

    /**
     *
     */
    addFee(): PriceCalculator {
        this.instance = this.instance.multiply(fee);
        return this;
    }

    /**
     *
     */
    add(number: number | PriceObject | PriceCalculator): PriceCalculator {
        if (typeof number === 'number')
            this.instance = this.instance.add(this.toDinero(number, this.instance.toObject().currency));
        else if (number instanceof PriceCalculator) this.instance = this.instance.add(number.instance);
        else this.instance = this.instance.add(Dinero(number));
        return this;
    }

    /**
     *
     * @param number
     */
    divide(number: number): PriceCalculator {
        this.instance = this.instance.divide(number);
        return this;
    }

    /**
     *
     * @param number
     */
    multiply(number: number): PriceCalculator {
        this.instance = this.instance.multiply(number);
        return this;
    }

    withoutTax(): PriceCalculator {
        this.instance = this.instance.divide(vat);
        return this;
    }

    /**
     *
     */
    asObject(): PriceObject {
        return {
            amount: this.instance.getAmount(),
            currency: this.instance.getCurrency(),
            precision: this.instance.getPrecision(),
        } as PriceObject;
    }

    /**
     *
     * @param decimals
     */
    asValue(decimals = 2): number {
        const value = this.instance.toRoundedUnit(this.instance.getPrecision());
        const exp = Math.pow(10, decimals);

        return Math.ceil(value * exp) / exp;
    }

    /**
     *
     * @param amount
     */
    private toDinero(amount: number, currency: Currency): Dinero.Dinero {
        return Dinero({
            amount: Math.round(amount * 10 ** precisionNumber),
            currency: currency || this.currency,
            precision: precisionNumber,
        });
    }

    /**
     *
     * @param precision
     */
    convertPrecision(precision: number): PriceCalculator {
        const value = this.asValue(precision);
        const exp = Math.pow(10, precision);

        const assertedValue = Math.round((value * exp * exp) / exp);
        const curr: Currency = this.instance.getCurrency() as Currency;
        this.instance = Dinero({
            amount: assertedValue,
            precision,
            currency: curr,
        });
        return this;
    }

    /**
     *
     * @param price
     */
    getPriceAmount(): number {
        return this.instance.getAmount();
    }

    /**
     *
     */
    removeFee(): PriceCalculator {
        this.instance = this.instance.divide(fee);
        return this;
    }

    /**
     *
     * @param number
     */
    subtract(number: number | PriceObject | PriceCalculator): PriceCalculator {
        if (typeof number === 'number')
            this.instance = this.instance.subtract(this.toDinero(number, this.instance.toObject().currency));
        else if (number instanceof PriceCalculator) this.instance = this.instance.subtract(number.instance);
        else this.instance = this.instance.subtract(Dinero(number));
        return this;
    }

    format(precision?: number): string {
        this.convertPrecision(precision || 2);
        return this.instance.toFormat('$0,0.00', 'HALF_AWAY_FROM_ZERO');
    }

    clone(): PriceCalculator {
        return new PriceCalculator(this.asObject(), this.instance.toObject().currency);
    }

    getPercentage(percentage: number): PriceCalculator {
        this.instance = this.instance.percentage(100 - percentage);
        return this;
    }

    total(prices: PriceCalculator[]) {
        for (const price of prices) this.add(price);
        return this;
    }

    // Temporary function to change currency. This does not convert prices!
    toUSD(): PriceCalculator {
        return new PriceCalculator({ ...this.asObject(), currency: 'USD' });
    }

    lessThanOrEqual(value: PriceObject): boolean {
        return this.instance.lessThanOrEqual(Dinero(value));
    }

    greaterThanOrEqual(value: PriceObject): boolean {
        return this.instance.greaterThanOrEqual(Dinero(value));
    }

    greaterThan(value: PriceObject): boolean {
        return this.instance.greaterThan(Dinero(value));
    }
}
