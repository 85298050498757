import { ChakraProps, extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import ButtonTheme from './components/button.theme';
import InputTheme from './components/input.theme';
import LinkTheme from './components/link.theme';
import TextTheme from './components/text.theme';

export type ChakraStyles<T = ChakraProps & any> = {
    [key: string]: T & ChakraProps;
};

const breakpoints = createBreakpoints({
    sm: '492px',
    md: '692px',
    lg: '928px',
    xl: '1360px',
    '2xl': '1680px',
});

export const theme = extendTheme({
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    breakpoints,
    fonts: {
        body: 'Poppins, Open Sans, sans-serif',
        heading: 'Poppins, Open Sans, sans-serif',
    },
    colors: {
        brand: {
            primary: {
                200: 'rgba(225, 68, 54, 0.25)',
                500: 'rgba(225, 68, 54, 0.5)',
                700: 'rgba(225, 68, 54, 0.75)',
                900: 'rgba(225, 68, 54, 1)',
            },
            secondary: {
                200: 'rgba(11, 11, 13, 0.25);',
                500: 'rgba(11, 11, 13, 0.5);',
                700: 'rgba(11, 11, 13, 0.75);',
                900: 'rgba(11, 11, 13, 1)',
            },
            500: '#E14436',
            600: '#AC362C',
        },
        black: {
            500: '#0B0B0D',
            600: 'rgba(11, 11, 13, 0.6)',
        },
        gray: {
            50: '#F9F9F9',
            100: '#F2F2F4',
            300: '#BEC1CA',
            500: '#9DA0AA',
            700: '#0B0B0D',
            600: '#F9F9F9',
            border: '#E2E3E7',
        },
    },
    fontSizes: {
        xxsmall: '12px',
        xsmall: '14px',
        small: '15px',
        default: '16px',
        medium: '18px',
        large: '20px',
        xlarge: '22px',
        xxlarge: '24px',
        xxxlarge: '26px',
    },

    components: {
        Text: TextTheme,
        Link: LinkTheme,
        Input: InputTheme,
        Button: ButtonTheme,
        Modal: {
            baseStyle: {
                dialogContainer: {
                    '@supports(height: -webkit-fill-available)': {},
                    height: '100%',
                },
            },
        },
    },
    global: () => ({
        // Fix missing modal footer on iOS 15.
        '.chakra-modal__content': {
            '@supports(height: -webkit-fill-available)': {
                minHeight: '-webkit-fill-available !important',
            },
        },
    }),
});
