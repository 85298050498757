import React, { ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

import { Button } from '@chakra-ui/react';
import Margin from '../../../../base/margin';
import Row from '../../../../base/row';
import Column from '../../../../base/column';
import Text from '../../../../base/text';
import { BottomDrawer } from '../../../../base/bottomDrawer';

import styles from './index.module.css';

interface FilterMobileModalProps {
    children: ReactNode;
    show: boolean;
    title: string;
    footer?: boolean;
    onClose: () => void;
    onSubmit: () => void;
    onSortChange?: (option: string) => void;
    onReset?: () => void;
}

export function FilterMobileModal(props: FilterMobileModalProps) {
    const { children, show, title, footer, onClose, onSubmit, onReset } = props;

    return (
        <BottomDrawer show={show} onClose={onClose}>
            <Row className={styles.header}>
                <FiX className={styles.closeIcon} fontSize={28} onClick={onClose} />
                <Text className={styles.headerTitle} noMargin>
                    {title}
                </Text>
            </Row>

            <Column className={styles.content}>
                {children}
                <Margin />
            </Column>
            {footer ? (
                <Row className={styles.actionRow}>
                    <div className={styles.clearButton} onClick={onReset}>
                        Reset all
                    </div>
                    <Button variant="solid" colorScheme="black" size="md" onClick={onSubmit}>
                        Show Results
                    </Button>
                </Row>
            ) : null}
        </BottomDrawer>
    );
}
