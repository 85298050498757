export default {
    getCurrentLocation(): Promise<GeolocationPosition | undefined> {
        return new Promise((resolve, reject) => {
            const geolocation = window.navigator && window.navigator.geolocation;

            if (geolocation) {
                return geolocation.getCurrentPosition(
                    position => resolve(position),
                    error => reject(error),
                );
            }

            return reject(undefined);
        });
    },
};
