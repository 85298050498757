import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Box, Button, HStack, Text, useBreakpointValue, Link, useToast } from '@chakra-ui/react';
import moment from 'moment';
import _ from 'lodash';

import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';
import Loading from 'components/base/loading';
import Margin from 'components/base/margin';
import Splitter from 'components/base/splitter';
import DriverInfo from 'components/sections/asset-checkout/driver-info';
import AssetHero from 'components/sections/_layout/asset-hero';
import AssetPanel from 'components/sections/_layout/asset-panel';
import DeliveryReturnModal from 'components/sections/_layout/delivery-return-modal/index';
import LocationItem from 'components/sections/_layout/location-item';
import BookingStatusLabel from 'components/sections/bookings/booking-status';
import ModalCancelation from 'components/base/modalCancelation';

import { useAssetDetails, useAuth, useRemoteConfig, useOperatingAreas } from 'hooks';
import useRequest from 'hooks/useRequest';
import { useAssetOccupiedDates } from 'hooks/useAssetOccupiedDates';
import i18n from 'language/i18n';
import { Booking } from 'models/booking/Booking';
import { SearchFilters } from 'models/sharedData/SearchFilters';
import { createSearchObject, SearchObject } from 'models/sharedData/SearchObject';
import { BookingStatus } from 'models/booking/BookingStatus';
import BookingService from 'services/booking.service';
import CompanyService, { Company } from 'services/company.service';
import { BookingModificationsService } from 'services/bookingModifications.service';
import { Insurance } from 'services/insurances.service';
import paymentMethodsService from 'services/paymentMethods.service';
import OperatingCitiesService from 'services/operatingCities.service';

import { OccupiedAssetsAction } from 'models/asset/getOccupiedDatesAction';
import { TimeSlots } from 'models/sharedData/TimeSlots';
import styles from './styles';

export default function BookingDetailsPage() {
    const history = useHistory();
    const { id: bookId } = useParams<{ id: string }>();
    const [booking, setBooking] = useState<Booking>();
    const [getBooking, bookingLoading] = useRequest(BookingService.get, { bookId }, setBooking, () =>
        history.replace('/bookings'),
    );
    const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
    const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);
    const [searchFilters, setSearchFilters] = useState<SearchFilters>();
    const [loadingModal, setLoadingModal] = useState(false);
    const [company, setCompany] = useState<Company>();
    const [paymentMethod, setPaymentMethod] = useState<any>();
    const [loadingCancelModal, setLoadingCancelModal] = useState<boolean>(false);
    const { bookingCancellationConfig, modificationsTime } = useRemoteConfig();
    const [withinCancelTime, setWithinCancelTime] = useState<boolean>(false);
    const [withinModificationsTime, setWithinModificationsTime] = useState<boolean>(false);
    const [assetTimezone, setAssetTimezone] = useState<string>();
    const toast = useToast();
    const isBookingEditable =
        booking?.status === BookingStatus.PENDING ||
        booking?.status === BookingStatus.SECURITY_DEPOSIT_PENDING ||
        booking?.status === BookingStatus.BOOKED ||
        booking?.status === BookingStatus.SECURITY_DEPOSIT_RETRY;

    const { asset, loading: assetloading, setAssetId } = useAssetDetails();
    const {
        getOccupiedDates,
        occupiedDates,
        loading: loadingOccupiedDates,
    } = useAssetOccupiedDates({
        assetId: booking?.assetDetails.id,
        booking,
        lazyLoad: true,
        action: OccupiedAssetsAction.EXCLUDE_BOOKING_DATES,
    });
    const { auth, user, getUser } = useAuth();
    const { storeSelectedOperatingCity } = useOperatingAreas();
    const isMobile = useBreakpointValue({ base: true, md: false });

    useEffect(useCallback(getBooking, [bookId]), []);

    function onMount() {
        getUser();
    }

    function onClose() {
        setShowEditDeliveryModal(false);
        setShowCancelBookingModal(false);
        setLoadingCancelModal(false);
    }

    const getPaymentMethod = useCallback(async () => {
        if (booking && booking.paymentMethodRef) {
            const result = await paymentMethodsService.get(booking.paymentMethodRef.id);
            if (result === undefined || null) {
                return;
            }
            setPaymentMethod(result);
        }
    }, [booking]);

    const isBookingModificationsAllowed = useCallback(() => {
        const now = moment();
        if (booking && booking.startDate) {
            const startDate = moment(booking.startDate.toDate());

            if (moment.duration(startDate.diff(now)).asHours() > modificationsTime) {
                setWithinModificationsTime(true);
            }
        }
    }, [booking, modificationsTime]);

    const disableCancelBooking = useMemo(() => {
        if (!booking) {
            return false;
        }

        return !isBookingEditable;
    }, [booking]);

    useEffect(onMount, []);

    useEffect(() => {
        getPaymentMethod();
    }, [getPaymentMethod]);

    useEffect(() => {
        isBookingModificationsAllowed();
    }, [booking, isBookingModificationsAllowed]);

    useEffect(() => {
        async function getCompany() {
            if (user && user.companyRef) {
                const companyId = user.companyRef.id;
                const result = await CompanyService.get(companyId);
                setCompany(result!);
            }
        }

        getCompany();
    }, [user]);

    useEffect(() => {
        async function getOperatingCity(id: string) {
            const result = await OperatingCitiesService.get(id);
            storeSelectedOperatingCity(result!);
            setAssetTimezone(result?.timezone);
        }

        if (booking && booking.assetDetails) {
            setAssetId(booking.assetDetails.id);
            getOccupiedDates();
            getOperatingCity(booking.assetDetails.operatingCityRef.id);
        }
    }, [booking, setAssetId]);

    useEffect(() => {
        if (!booking || !booking.returnLocation || !booking.deliveryLocation) {
            return;
        }

        // shared data update

        const start = booking.startDate ? moment(booking.startDate?.toDate()) : null;
        const end = booking.endDate ? moment(booking.endDate?.toDate()) : null;

        const [deliveryLongitude, deliveryLatitude] = booking.deliveryLocation;
        const deliveryCoordObj = {
            longitude: deliveryLongitude,
            latitude: deliveryLatitude,
        };
        const deliveryObject = createSearchObject(
            start,
            start?.format('HH:mm') as TimeSlots,
            deliveryCoordObj,
            booking.deliveryLocationName,
        );
        const deliveryLocationObject = deliveryObject;

        const [returnLongitude, returnLatitude] = booking.returnLocation;
        const returnCoordObj = {
            longitude: returnLongitude,
            latitude: returnLatitude,
        };

        const returnObject = createSearchObject(
            end,
            end?.format('HH:mm') as TimeSlots,
            returnCoordObj,
            booking.returnLocationName,
        );
        const returnLocationObject = returnObject;

        const newLocation = returnLocationObject.location ? returnLocationObject.location : undefined;

        const newValue = `custom_location_${newLocation?.longitude},${newLocation?.latitude}`;

        const newCurrCustomLocations = [];

        if (returnLocationObject.query && returnLocationObject.query !== deliveryLocationObject.query) {
            newCurrCustomLocations.push({
                label: returnLocationObject.query,
                value: newValue,
            });
        }
        setSearchFilters({
            start: deliveryLocationObject,
            end: returnLocationObject,
            returnLocationHistory: newCurrCustomLocations,
        });
    }, [booking]);

    if (assetloading || bookingLoading) return <Loading spinnerSize={40} halfScreen />;

    const mappedBookingInsurance = booking?.insurances?.map(insuranceObj => {
        if (insuranceObj.multipleOptions === false) {
            return insuranceObj.insuranceSnapshot;
        }

        insuranceObj.insuranceSnapshot.options = insuranceObj.insuranceSnapshot.options?.map((option: any) => {
            if (option.optionId === insuranceObj.selectedOption) {
                option.selected = true;
            } else {
                option.selected = false;
            }
            return option;
        });

        return insuranceObj.insuranceSnapshot;
    });

    function checkLocationsChanged(deliveryObject: SearchObject, returnObject: SearchObject) {
        const deliveryLocation = [deliveryObject.location?.longitude, deliveryObject.location?.latitude];
        const returnLocation = [returnObject.location?.longitude, returnObject.location?.latitude];

        const sameDeliveryLocationName = _.isEqual(deliveryObject.query, booking?.deliveryLocationName);
        const sameDeliveryLocation = _.isEqual(deliveryLocation, booking?.deliveryLocation);
        const sameReturnLocationName = _.isEqual(returnObject.query, booking?.returnLocationName);
        const sameReturnLocation = _.isEqual(returnLocation, booking?.returnLocation);

        const sameDelivery = sameDeliveryLocation && sameDeliveryLocationName;
        const sameReturn = sameReturnLocation && sameReturnLocationName;

        return !sameDelivery || !sameReturn;
    }

    async function onEditReturnDeliverySave(deliveryObject: SearchObject, returnObject: SearchObject) {
        setLoadingModal(true);

        try {
            const startDate = moment(deliveryObject!.date?.toDate());
            const endDate = moment(returnObject!.date?.toDate());

            // throw error
            if (!deliveryObject.location || !deliveryObject.query) {
                throw new Error('Delivery Coordinates or Query does not exist.');
            }

            if (!returnObject.location || !returnObject.query) {
                throw new Error('Return Coordinates or Query does not exist.');
            }

            const startDateChanged = !startDate.isSame(booking?.startDate?.toDate());
            const endDateChanged = !endDate.isSame(booking?.endDate?.toDate());
            const datesChanged = startDateChanged || endDateChanged;

            const dates = datesChanged && {
                startDate,
                endDate,
            };
            const locationsChanged = checkLocationsChanged(deliveryObject, returnObject);

            const locations = locationsChanged && {
                deliveryLocation: [deliveryObject?.location?.latitude, deliveryObject?.location?.longitude],
                deliveryLocationName: deliveryObject?.query,
                returnLocation: [returnObject?.location?.latitude, returnObject?.location?.longitude],
                returnLocationName: returnObject?.query,
            };

            if (!dates && !locations) {
                return;
            }

            const requestPayload = {
                ...dates,
                ...locations,
            };

            if (auth && bookId) {
                await BookingModificationsService.create(auth.uid, bookId, requestPayload);
            }
            setLoadingModal(false);
            getBooking();
            onClose();
            toast({
                title: i18n.t('pages.profile.payments.toast.success.creation.title'),
                description: i18n.t('pages.modification.toast.success.creation.description'),
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
        } catch (error) {
            console.error('Error while creating booking modifications', error);
            setLoadingModal(false);
            toast({
                title: i18n.t('pages.profile.documents.toast.error.creation.title'),
                description: i18n.t('pages.modification.toast.error.creation.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
        }
    }

    // sorting the insurances to display the main one on top
    const sortedInsurance = mappedBookingInsurance?.sort((a: any, b: any) => b.multipleOptions - a.multipleOptions);

    const selectedExtras = booking && booking.extras ? booking.extras.map(extra => extra.extraRef.id) : [];

    const dates = {
        from: moment(booking?.startDate?.toDate()).tz(assetTimezone!),
        to: moment(booking?.endDate?.toDate()).tz(assetTimezone!),
    };
    const bookedOn = moment(booking?.creationDate.toDate()).tz(assetTimezone!);

    const userInfo: any = {
        name: auth?.displayName,
        email: auth?.email,
        phone: auth?.phoneNumber,
        license: '',
    };

    async function onCancelBooking() {
        setLoadingCancelModal(true);
        try {
            // create doc in request_booking collection
            await BookingService.requestBookingAction(auth!.uid, 'user-cancel', booking);
            getBooking();
            onClose();
            toast({
                title: i18n.t('pages.profile.payments.toast.success.creation.title'),
                description: i18n.t('pages.bookingDetails.toast.cancelled.booking.description'),
                status: 'success',
                duration: 6000,
                isClosable: true,
                position: 'top-right',
            });
        } catch (error) {
            toast({
                title: i18n.t('pages.bookingDetails.toast.error.cancelled.booking.title'),
                description: i18n.t('pages.bookingDetails.toast.error.cancelled.booking.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
            console.log('there was an error', error);

            setLoadingCancelModal(false);
        }
    }

    function calculateCancellationTime() {
        setWithinCancelTime(true);
        if (booking?.startDate && booking?.endDate) {
            // remote config cancellation time value
            const cancellationTime = bookingCancellationConfig.cancellation_time;

            // formatting the current time and start date time
            const convertedStartDate = Number(booking.startDate.seconds);
            const convertedEndDate = Number(booking.endDate.seconds);
            const currentTime = moment();

            const startDate = moment.unix(convertedStartDate);
            const endDate = moment.unix(convertedEndDate);

            // verify if the booking dates are in the past or future
            if (startDate.isBefore(currentTime) || endDate.isBefore(currentTime)) {
                setWithinCancelTime(false);
                return;
            }

            // Time difference calculations turning the negative difference into positive
            const timeDifference = Math.abs(Number(moment.duration(currentTime.diff(startDate)).asHours().toFixed(2)));

            // comparing duration difference with cancellation time
            if (timeDifference > cancellationTime) {
                setWithinCancelTime(true);
            } else if (timeDifference <= cancellationTime) {
                setWithinCancelTime(false);
            } else if (timeDifference < 0) {
                setWithinCancelTime(false);
            }
        }
    }

    const renderPolicy = (isItMobile: boolean) => (
        <Box hidden={isItMobile}>
            <Text {...styles.title}>{i18n.t('pages.assetDetails.booking.cancelationPolicy.title')}</Text>
            <Box {...styles.cancelationBody}>
                <Text {...styles.cancelationBodyBold}>
                    {i18n.t('pages.bookingDetails.cancelationPolicy.first', {
                        cancellationTime: bookingCancellationConfig.cancellation_time,
                    })}{' '}
                </Text>
                {i18n.t('pages.bookingDetails.cancelationPolicy.second')}
            </Box>
            <Margin />
            <Link to="/faq" as={RouterLink}>
                {i18n.t('pages.bookingDetails.cancelationPolicy.action')}
            </Link>
            <Margin />
            <Button
                size="md"
                variant="solid"
                colorScheme="brand"
                disabled={disableCancelBooking}
                onClick={() => {
                    setShowCancelBookingModal(true);
                    calculateCancellationTime();
                }}
            >
                {i18n.t('pages.bookingDetails.cancelationPolicy.cancelButton')}
            </Button>
        </Box>
    );

    function determineEndQueryText() {
        if (!booking?.returnLocationName) {
            return 'N/A';
        }

        if (booking.returnLocationName === booking.deliveryLocationName) {
            return i18n.t('sections.assetDetails.booking.sameAddressAsDelivery');
        }

        return booking.returnLocationName;
    }

    function displayDate(date?: moment.Moment | null) {
        if (!date) {
            return undefined;
        }

        return date?.format('dddd, MMM DD, hh:mma');
    }

    return (
        <Box {...styles.container}>
            <Box>
                <Button as={RouterLink} to="/bookings" variant="unstyled" d="inline-block">
                    <ArrowLeftIcon />
                </Button>
                <Text {...styles.headerTitle}>
                    {i18n.t('pages.bookingDetails.header.title')} #{booking?.refNumber}
                </Text>
                <Box {...styles.headerDescription}>
                    <Text {...styles.headerDescriptionBold}>{i18n.t('pages.bookingDetails.header.description')}: </Text>
                    {bookedOn.format('LLL')}
                </Box>

                {booking!.status && (
                    <BookingStatusLabel isMobile={!isMobile} status={booking!.status}>
                        {i18n.t(`pages.booking.confirmationStatus.${booking!.status}`)}
                    </BookingStatusLabel>
                )}
            </Box>

            <HStack {...styles.grid}>
                <Box flex="1">
                    <Box {...styles.leftColWrapper}>
                        <Text {...styles.title}>{i18n.t('pages.bookingDetails.bookingInfo.title')}</Text>
                        <AssetHero asset={asset} />

                        <Box my="6">
                            <LocationItem
                                inverted
                                title={`${i18n.t('pages.assetDetails.booking.deliveryInfoSuccess')}:`}
                                location={booking?.deliveryLocationName || 'N/A'}
                                date={displayDate(dates.from)}
                            />
                            <LocationItem
                                inverted
                                title={`${i18n.t('pages.assetDetails.booking.returnInfoSuccess')}:`}
                                location={determineEndQueryText() || 'N/A'}
                                date={displayDate(dates.to)}
                            />
                        </Box>
                        {withinModificationsTime && isBookingEditable ? (
                            <Button
                                variant="link"
                                colorScheme="brand"
                                marginBottom="var(--margin-medium)"
                                onClick={() => {
                                    setShowEditDeliveryModal(true);
                                }}
                            >
                                {i18n.t('sections.assetDetails.booking.changeDatesLabel')}
                            </Button>
                        ) : null}

                        <Splitter spacing="xlarge" />

                        <Text {...styles.title}>{i18n.t('pages.bookingDetails.userInfo')}</Text>
                        <DriverInfo hideLicense user={userInfo} mb="8" />

                        <Splitter spacing="xlarge" />

                        {renderPolicy(!!isMobile)}
                    </Box>
                </Box>

                <Box {...styles.rightCol}>
                    <AssetPanel
                        startDate={moment(booking?.startDate?.toDate())}
                        endDate={moment(booking?.endDate?.toDate())}
                        relative={isMobile}
                        mode="booked"
                        asset={{ ...asset, pricePerDay: booking?.pricePerDay }}
                        insurances={sortedInsurance as Insurance[]}
                        extras={selectedExtras}
                        booking={booking}
                        company={company}
                        paymentMethod={paymentMethod}
                        bookingCancellationConfig={bookingCancellationConfig}
                    />
                </Box>
            </HStack>

            {renderPolicy(!isMobile)}

            <DeliveryReturnModal
                isOpen={showEditDeliveryModal}
                onSuccess={onEditReturnDeliverySave}
                onClose={onClose}
                initFilters={searchFilters!}
                loading={loadingModal || loadingOccupiedDates}
                blockedDates={occupiedDates}
                resetOnSubmit
            />

            <ModalCancelation
                isOpen={showCancelBookingModal}
                onClose={onClose}
                title={i18n.t('pages.bookingDetails.cancelModalTitle')}
                sureMessage={i18n.t('pages.bookingDetails.cancelText.sureMessage')}
                dontCancelButtonText={i18n.t('pages.bookingDetails.dontCancelbutton')}
                cancelButtonText={i18n.t('pages.bookingDetails.cancelBookingButton')}
                cancelSmallButtonText={i18n.t('pages.bookingDetails.cancelBookingSmallButton')}
                onClick={onCancelBooking}
                loading={loadingCancelModal}
                cancelText={
                    withinCancelTime
                        ? i18n.t(`pages.bookingDetails.cancelText.message`, {
                              cancellationTime: bookingCancellationConfig.cancellation_time,
                          })
                        : i18n.t('pages.bookingDetails.cancelText.outsideCancellationPeriod')
                }
            />
        </Box>
    );
}
