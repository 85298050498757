import FirestoreService from './firestore.service';
import { firestore } from './persistence';

export type Coordinate = {
    longitude: number;
    latitude: number;
};

export type OperatingArea = {
    name: string;
    coordinates: [Coordinate, Coordinate];
};

const OperatingAreasCollection = 'operating_areas';
const OperatingCitiesCollection = 'operating_cities';

const OperatingAreasService = {
    async getAll<T>() {
        const response: T = await FirestoreService.getAll(OperatingAreasCollection);
        return response;
    },

    async getOperatingAreasFromCity(operatingCityId: string): Promise<OperatingArea[] | null> {
        const result = await FirestoreService.getAll(OperatingAreasCollection, {
            field: 'operatingCityRef',
            operator: '==',
            value: firestore.collection(OperatingCitiesCollection).doc(operatingCityId),
        });

        if (!result) return null;
        return result;
    },
};

export default OperatingAreasService;
