import React from 'react';
import { ReactComponent as Icon } from '../../../../assets/icons/rectangle-sm.svg';

export default function RectangleSm(props: any) {
    const { color } = props;

    switch (color) {
        default:
            return <Icon {...props} />;
    }
}
