import { ButtonProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<ButtonProps> = {
    businessButton: {
        fontSize: 'var(--font)',
        fontWeight: 'var(--font-weight-regulars)',
        lineHeight: 'var(--line)',
        marginTop: 'var(--margin-medium)',
        boxShadow: '0 14px 80px #00000014',
        padding: 'var(--margin-small) var(--margin-medium)',
        backgroundColor: 'black',
        borderRadius: '50px',
        height: '54px',
        border: '#fff solid 1px',
        color: '#fff',
    },
};
export default styles;
