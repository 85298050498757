import { OccupiedAssetsAction } from 'models/asset/getOccupiedDatesAction';
import { Booking } from 'models/booking/Booking';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import AssetService from 'services/asset.service';
import useRequest from './useRequest';
import { useRemoteConfig } from './remoteConfig';

interface UseAssetOccupiedDatesProps {
    assetId: string;
    startRange?: Moment;
    endRange?: Moment;
    booking?: Booking;
    lazyLoad?: boolean;
    action?: OccupiedAssetsAction;
}

export function useAssetOccupiedDates(props: UseAssetOccupiedDatesProps) {
    const { assetId, startRange, endRange, booking, lazyLoad = false, action } = props;
    const { bookingMaximumDuration } = useRemoteConfig();

    const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);
    const [occupiedDates, setOccupiedDates] = useState<string[]>();

    function _setOccupiedDates({ dates }: { dates: string[] }) {
        if (booking) {
            const endDate = booking.endDate && booking?.endDate?.toDate();
            const startDate = booking.startDate && booking.startDate.toDate();
            const bookingDates: string[] = [];

            while (startDate! <= endDate!) {
                bookingDates.push(startDate!.toISOString().slice(0, 10));

                startDate!.setDate(startDate!.getDate() + 1);
            }

            for (let i = 0; i < bookingDates.length; ++i) {
                for (let j = i + 1; j < dates.length; ++j) {
                    if (bookingDates[i] === dates[j]) {
                        dates.splice(j--, 1);
                    }
                }
            }
        }
        setOccupiedDates(dates);
    }

    const [getOccupiedDates, loading] = useRequest(
        AssetService.getOccupiedDates,
        {
            uid: assetId,
            from: startRange || moment().subtract(bookingMaximumDuration, 'days'),
            to: endRange || moment().add(1, 'year'),
            action,
            bookingId: booking?.id,
        },
        _setOccupiedDates,
    );

    if (!lazyLoad && alreadyLoaded === false) {
        setAlreadyLoaded(true);
        getOccupiedDates();
    }

    return { getOccupiedDates, loading, occupiedDates };
}
