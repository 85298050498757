import React from 'react';

import { ReactComponent as Icon } from '../../../../assets/icons/plus-circle-v2.svg';

import { IconProps } from '../types';

export const PlusCircleIcon2 = (props: IconProps) => {
    const { className, color } = props;
    return <Icon {...props} className={className} stroke={color} />;
};
