import { BreadcrumbItem } from 'components/base/breadcrumb';
import Card from 'components/base/card';
import Column from 'components/base/column';
import Loading from 'components/base/loading';
import Margin from 'components/base/margin';
import PageTemplate from 'components/base/pageTemplate';
import Row from 'components/base/row';
import Splitter from 'components/base/splitter';
import { DocumentsInfo } from 'components/sections/profile/documentsCard';
import { MyWalletInfo } from 'components/sections/profile/myWalletCard';
import { ProfileInfo } from 'components/sections/profile/profileInfoCard';
import { joinClasses } from 'helpers/joinClasses';
import { useAuth } from 'hooks/auth';
import React, { useEffect, useState } from 'react';

import i18n from '../../language/i18n';
import styles from './index.module.css';

export default function ProfilePage() {
    const [loading, setLoading] = useState(true);
    const { getUser, user } = useAuth();
    const breadcrumbItems: BreadcrumbItem[] = [{ label: 'MY ACCOUNT' }, { label: 'PROFILE' }];

    useEffect(() => {
        async function loadUser() {
            await getUser();
            setLoading(false);
        }
        loadUser();
    }, [getUser]);

    if (loading) {
        return (
            <Column>
                <Loading spinnerSize={40} fullScreen />
            </Column>
        );
    }

    return (
        <PageTemplate
            title={`${i18n.t('pages.profile.helloLabel')}, ${user!.name}`}
            breadcrumbs={breadcrumbItems}
            templateClassName={styles.page}
            titleClassName={styles.pageTitle}
        >
            <Row className={styles.cardsRow}>
                <Card className={styles.card}>
                    <ProfileInfo />
                </Card>

                <Margin display={{ base: 'flex', lg: 'none' }} size="xxlarge" />
                <Splitter display={{ base: 'flex', lg: 'none' }} spacing="xlarge" />

                <Card className={joinClasses(styles.card, styles.minHeight)}>
                    <DocumentsInfo />
                </Card>

                <Margin display={{ base: 'flex', lg: 'none' }} size="xxlarge" />
                <Splitter display={{ base: 'flex', lg: 'none' }} spacing="xlarge" />
            </Row>
            <Row className={styles.walletContainer}>
                <Card className={styles.card}>
                    <MyWalletInfo />
                </Card>
            </Row>
        </PageTemplate>
    );
}
