import React, { useEffect, useReducer } from 'react';
import i18n from '../../../../../language/i18n';
import Row from '../../../../base/row';
import AssetFilterOrder from '../../filter-order';
import AssetFilterSortBy from '../../filter-sort/desktop';

import { AssetFilterProps } from '../types';
import { seatTypes, transmissionTypes, propulsionTypes } from '../data';

import styles from './index.module.css';

export function DesktopAssetFilter(props: AssetFilterProps) {
    const filterFields = [
        {
            label: i18n.t('sections.assetList.filters.type.placeholder'),
            value: 'propulsionType',
            options: propulsionTypes,
        },
        {
            label: i18n.t('sections.assetList.filters.seats.placeholder'),
            value: 'seats',
            options: seatTypes,
        },
        {
            label: i18n.t('sections.assetList.filters.transmission.placeholder'),
            value: 'transmission',
            options: transmissionTypes,
        },
    ];

    const initialState = {
        fields: filterFields,
        sort: 'asc',
        transmission: [],
        category: [],
        seats: [],
        brand: [],
        feature: [],
        yearRange: [],
        greenVehicle: [],
        propulsionType: [],
    };

    function reducer(state: any, filter: any) {
        switch (filter.key) {
            case 'sort':
                return { ...state, sort: filter.value };
            case 'propulsionType':
                return { ...state, propulsionType: filter.value };
            case 'transmission':
                return { ...state, transmission: filter.value };
            case 'brand':
                return { ...state, brand: filter.value };
            case 'category':
                return { ...state, category: filter.value };
            case 'seats':
                return { ...state, seats: filter.value };
            case 'feature':
                return { ...state, features: filter.value };
            case 'yearRange':
                return { ...state, yearsRange: filter.value };

            default:
                return initialState;
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    // by destructuring these properties we can better fine tune the usage of
    // useEffect
    const { onFilterStateChanged } = props;
    const { category, sort, seats, transmission, brand, feature, yearRange, propulsionType } = state;

    /**
     * sends the updated state of the filters if any have changed
     */
    useEffect(() => {
        onFilterStateChanged &&
            onFilterStateChanged({
                total:
                    category.length +
                    seats.length +
                    transmission.length +
                    brand.length +
                    feature.length +
                    propulsionType.length,
                ...(category ? { category } : {}),
                ...(seats ? { seats } : {}),
                ...(transmission ? { transmission } : {}),
                ...(propulsionType ? { propulsionType } : {}),

                ...(brand ? { brand } : {}),
                ...(feature ? { feature } : {}),
                ...(yearRange ? { yearRange } : {}),

                ...(sort ? { sort } : {}),
            });
    }, [
        category,
        category.length,
        transmission,
        transmission.length,
        seats,
        seats.length,
        sort,
        onFilterStateChanged,
        brand,
        brand.length,
        feature,
        feature.length,
        yearRange,
        yearRange.length,
        propulsionType,
        propulsionType.length,
    ]);

    /**
     *
     * @param filter
     */
    function onFilterChange(filter: { key: string; value: any }) {
        const item: any[] = state[filter.key] ? state[filter.key] : [];
        if (!item.includes(filter.value)) {
            item.push(filter.value);
        } else {
            item.splice(item.indexOf(filter.value), 1);
        }
        dispatch({ key: filter.key, value: item });
    }

    /**
     *
     * @param value
     */
    function onSortChange(value: string) {
        dispatch({ key: 'sort', value });
    }

    /**
     *
     *
     */
    function clearFilters() {
        dispatch(initialState);
    }

    function canClearFilters() {
        const isFull = (data: any[]) => data.length > 0;
        return (
            isFull(propulsionType) ||
            isFull(category) ||
            isFull(seats) ||
            isFull(transmission) ||
            isFull(brand) ||
            isFull(feature) ||
            isFull(yearRange)
        );
    }

    return (
        <>
            <AssetFilterOrder fields={state.fields.slice(0, 4)} filters={state} onChange={onFilterChange} />

            <Row className={styles.filterRow}>
                <AssetFilterSortBy value={state.sort || 'asc'} onChange={onSortChange} />
                {canClearFilters() ? (
                    <div className={styles.clearButton} onClick={clearFilters}>
                        {i18n.t('sections.assetList.clearFilters')}
                    </div>
                ) : null}
            </Row>
        </>
    );
}
