import { Location } from 'pages/assets/types';
import { SharedDataActionType } from '../SharedDataActions';

export enum ChangeLocationActionLocationType {
    RETURN_LOCATION = 'returnLocation',
    DELIVERY_LOCATION = 'deliveryLocation',
}

export type ChangeLocationActionPayload = {
    location: Location | null | undefined;
    query: string | null | undefined;
    locationType: ChangeLocationActionLocationType;
};

export type ChangeLocationAction = {
    type: SharedDataActionType.CHANGE_LOCATION;
    payload: ChangeLocationActionPayload;
};
