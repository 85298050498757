import React from 'react';
import NoPicture from '../../../assets/images/Logo_Circle.svg';

import styles from './index.module.css';

interface AvatarProps {
    source?: string | null;
    className?: string;
}

export function Avatar(props: AvatarProps) {
    const { className, source } = props;

    return <img src={source || NoPicture} className={[styles.avatar, className].join(' ')} alt="" />;
}
