export enum ValidationStatus {
    PROCESSING = 'processing',
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export enum DocumentValidationRequestStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    PROCESSING = 'processing',
    REJECTED = 'rejected',
    ACCEPTED = 'accepted',
    FAILURE = 'failure',
}
