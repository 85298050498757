import Column from 'components/base/column';
import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';
import Row from 'components/base/row';
import { Spinner } from 'components/base/spinner';
import { Text } from 'components/base/textV2';
import { AddressAutocompleteValue } from 'components/sections/home/addressComplete/types';
import AddressAutocomplete from 'components/sections/standalone/header/search/addressComplete';
import i18n from 'language/i18n';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { WizardStepProps } from 'pages/search-mobile';
import { useOperatingAreas } from 'hooks';
import { Trans } from 'react-i18next';
import styles from './index.module.css';

export type MobileSearchSectionProps = {
    onBack: (stepNumber?: number) => void;
    stepNumber?: number;
    onAddressClick: (address: AddressAutocompleteValue) => void;
    initialAddress?: string;
    placeholder?: string;
    className?: any;
    selectedItem?: any;
};

function MobileSearchSection(props: MobileSearchSectionProps & Partial<WizardStepProps>) {
    const [addressSuggestions, setAddressSuggestions] = useState<AddressAutocompleteValue[]>([]);
    const [userInput, setUserInput] = useState(props.initialAddress || '');
    const [loading, setLoading] = useState(false);
    const { checkLocationInBoundingBox, openWarningModal, operatingCity } = useOperatingAreas();

    function onNewSuggestions(addresses: AddressAutocompleteValue[], currentUserInput: string) {
        setAddressSuggestions(addresses);
    }

    function onLoadingChange(isLoading: boolean) {
        setLoading(isLoading);
    }

    function onAddressClick(address: AddressAutocompleteValue) {
        const coordinate = {
            longitude: address.value[0],
            latitude: address.value[1],
        };
        if (checkLocationInBoundingBox(coordinate) === false) {
            openWarningModal();
            return;
        }

        props.onAddressClick(address);
        props.jumpToStep && props.jumpToStep(1); // Explicitly jump to middle step if in wizard
    }

    function onClickBack() {
        props.onBack(props.stepNumber);
    }

    function handleChange(term: string) {
        setUserInput(term);
    }

    function renderAddressSuggestions() {
        const renderShortQueryMessage = userInput.length < 3;
        const renderNoResultsMesage =
            addressSuggestions.length === 0 && !loading && userInput !== '' && userInput.length >= 3;
        const renderSpinner = addressSuggestions.length === 0 && loading && userInput !== '' && userInput.length >= 3;
        const renderResults = addressSuggestions.length > 0;

        return (
            <Column>
                {renderShortQueryMessage && (
                    <Text className={styles.noResultsText}>
                        <Trans
                            i18nKey={
                                props.stepNumber === 1
                                    ? 'mobileSearchSection.returnResults.empty'
                                    : 'mobileSearchSection.deliveryResults.empty'
                            }
                            values={{ operatingCity: operatingCity?.name }}
                        />
                    </Text>
                )}
                {renderNoResultsMesage && (
                    <Text className={styles.noResultsText}>
                        {i18n.t('mobileSearchSection.deliveryResults.noResults')}
                    </Text>
                )}
                {renderSpinner && (
                    <div className={styles.centerSpinner}>
                        <Spinner color="red" />
                    </div>
                )}
                {renderResults &&
                    addressSuggestions.map(address => (
                        <Highlighter
                            key={address.value[0]}
                            className={styles.resultItem}
                            highlightClassName={styles.searchResultTitleHighlight}
                            searchWords={[userInput]}
                            onClick={() => onAddressClick(address)}
                            autoEscape
                            textToHighlight={address.label!}
                        />
                    ))}
            </Column>
        );
    }

    return (
        <div className={props.className}>
            <Row className={styles.searchHeader}>
                <ArrowLeftIcon className={styles.arrowBack} onClick={onClickBack} />
                <div className={styles.searchInput}>
                    <AddressAutocomplete
                        onNewSuggestions={onNewSuggestions}
                        onLoading={onLoadingChange}
                        disabledSuggestions
                        placeholder={props.placeholder || i18n.t('mobileSearchSection.searchbar.placeholder')}
                        value={userInput}
                        onChange={handleChange}
                        className={styles.addressComplete}
                        searchOnInitialRender
                    />
                </div>
            </Row>
            {renderAddressSuggestions()}
        </div>
    );
}

export default MobileSearchSection;
