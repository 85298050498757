import React from 'react';
import StarIcon from '../icon/star';
import styles from './index.module.css';

type StarsRatingProps = {
    rating: number;
};

export default function StarsRating(props: StarsRatingProps) {
    const roundedRating = Math.round(props.rating);

    return (
        <div className={styles.component}>
            {[...Array(roundedRating)].map(() => (
                <StarIcon className={styles.filled} />
            ))}
            {[...Array(5 - roundedRating)].map(() => (
                <StarIcon className={styles.unfilled} />
            ))}
        </div>
    );
}
