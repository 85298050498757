import i18n from '../../language/i18n';
import am from '../../assets/images/team/am.png';
import fg from '../../assets/images/team/fg.png';
import dp from '../../assets/images/team/dp.png';
import ms from '../../assets/images/team/ms.png';
import it from '../../assets/images/team/it.png';
import mc from '../../assets/images/team/mc.png';
import jv from '../../assets/images/team/jv.png';

export interface TeamMember {
    name: string;
    jobTitle: string;
    photo: string;
}

export const teamMembers: TeamMember[] = [
    { name: 'João Viegas', jobTitle: i18n.t('page.team.teamLead'), photo: jv },
    {
        name: 'Margarida Valadão',
        jobTitle: i18n.t('page.team.ssdm'),
        photo: ms,
    },

    {
        name: 'Diogo Pascoal',
        jobTitle: i18n.t('page.team.softwareDev'),
        photo: dp,
    },

    {
        name: 'Alice Moniz',
        jobTitle: i18n.t('page.team.productDesigner'),
        photo: am,
    },
    {
        name: 'Fábio Guerreiro',
        jobTitle: i18n.t('page.team.softwareDev'),
        photo: fg,
    },
    {
        name: 'Matilde Costa',
        jobTitle: i18n.t('page.team.softwareDev'),
        photo: mc,
    },
    {
        name: 'Inês Tavares',
        jobTitle: i18n.t('page.team.qualityAssurance'),
        photo: it,
    },
];
