import React, { useState } from 'react';
import { ChakraProps, FormControl, Input, InputGroup, InputRightElement, Text, useDisclosure } from '@chakra-ui/react';
import i18n from 'language/i18n';
import styles from './styles';
import LocationSearchModal from '../location-search-modal';

interface LocationDeliveryPickerProps {
    title?: string;
    isOpen?: boolean;
    defaultValue?: string;
    onChange?: (loc: string, value: number[]) => void;
}

function LocationDeliveryPicker({
    title,
    defaultValue,
    isOpen,
    onChange,
    ...rest
}: LocationDeliveryPickerProps & ChakraProps) {
    const [value, setValue] = useState<string>(defaultValue || '');
    const modal = useDisclosure({ isOpen });

    function _onChange(label: string, valueInput: number[]) {
        setValue(label);
        onChange?.(label, valueInput);
        modal.onClose();
    }

    return (
        <FormControl {...rest}>
            <Text {...styles.title}>{title}</Text>

            <InputGroup>
                <Input
                    size="lg"
                    value={value}
                    readOnly={!modal.isOpen}
                    placeholder={i18n.t('location.search.modal.input.placeholder') || 'Enter location'}
                    onClick={modal.onToggle}
                />
                <InputRightElement hidden={value === ''} {...styles.inputIcon} onClick={modal.onToggle} />
            </InputGroup>

            {modal.isOpen && <LocationSearchModal isOpen title={title} onClose={modal.onClose} onChange={_onChange} />}
        </FormControl>
    );
}

export default LocationDeliveryPicker;
