import React from 'react';

import ReactPaginate from 'react-paginate';

import styles from './index.module.css';
import ChevronLeftIcon from '../icon/chevron-left';
import ChevronRightIcon from '../icon/chevron-right';

export type PaginationConfig = {
    totalPages: number;
    onPageChange: any;
    className?: string;
    currentPage?: number;
    disableInitialCallback?: boolean;
};

export default class Pagination extends React.Component<PaginationConfig, any> {
    render() {
        const { totalPages, onPageChange, className, currentPage, disableInitialCallback } = this.props;

        if (!totalPages) {
            return <div className={styles.container} />;
        }

        const total = Number(totalPages.toFixed(0));

        return total > 1 ? (
            <div className={[styles.container, className].join(' ')}>
                <ReactPaginate
                    previousLabel={<ChevronLeftIcon className={styles.imgSize} />}
                    nextLabel={<ChevronRightIcon className={styles.imgSize} />}
                    breakLabel="..."
                    initialPage={currentPage}
                    pageCount={total}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={onPageChange}
                    disableInitialCallback={disableInitialCallback}
                    activeLinkClassName={styles.active}
                    disabledClassName={styles.disabled}
                    activeClassName={styles.active}
                    containerClassName={styles.list}
                    pageLinkClassName={styles.pageLink}
                />
            </div>
        ) : null;
    }
}
