import useUrl from 'hooks/useUrl';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validations } from 'validations';

import { Modal } from '../../../../base/modal';
import ForgotPasswordSection from '../forgotPassword';
import LoginSection from '../login';
import RegisterSection from '../register';
import styles from './index.module.css';

export type AuthenticationModalProps = {
    isOpen: boolean;
    onModalClosed?: () => void;
    history: any;
    section?: 'login' | 'register' | 'forgotPassword';
};

export default function AuthenticationModal(props: AuthenticationModalProps) {
    const history = useHistory();
    const { onModalClosed, isOpen, section } = props;
    const [selectedSection, setSelectedSection] = useState<string | undefined>('register');
    const [action] = useUrl('action');
    const [email] = useUrl('email');

    useEffect(() => {
        if (action && (!email || validations.isEmail(decodeURIComponent(email)) !== null)) {
            history.replace({});
            history.go(0);
        }
    }, [action, email, history]);

    const onRequestClose = () => {
        setSelectedSection('login');
        onModalClosed && onModalClosed();
    };

    const onRequestBack = () => {
        onToggle('login');
    };

    const onToggle = (sectionType: string) => {
        setSelectedSection(sectionType);
    };

    const renderPasswordSection = () => {
        return <ForgotPasswordSection />;
    };

    const renderRegisterSection = () => {
        return (
            <RegisterSection
                onRegisterSuccess={onRequestClose}
                onShowLogin={() => onToggle('login')}
                onClose={onRequestClose}
            />
        );
    };

    const renderLoginSection = () => {
        return (
            <LoginSection
                onLoginSuccess={onRequestClose}
                onShowRegistration={() => onToggle('register')}
                onShowForgotPassword={() => onToggle('forgotPassword')}
                onClose={onRequestClose}
            />
        );
    };

    const renderSection = () => {
        if (selectedSection) {
            switch (selectedSection) {
                case 'forgotPassword':
                    return renderPasswordSection();
                case 'register':
                    return renderRegisterSection();
                case 'login':
                default:
                    return renderLoginSection();
            }
        }
    };

    useEffect(() => {
        if (section) {
            setSelectedSection(section);
        }
    }, [section]);

    if (action && (!email || validations.isEmail(decodeURIComponent(email)) !== null)) {
        return null;
    }

    return (
        <Modal
            hideClose={action === 'register'}
            showReturnButton={selectedSection === 'forgotPassword'}
            shouldCloseOnOverlayClick={action !== 'register'}
            className={styles.modal}
            show={!!isOpen}
            onClose={onRequestClose}
            onBack={onRequestBack}
            size="fit"
        >
            {renderSection()}
        </Modal>
    );
}
