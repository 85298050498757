import React, { useState, KeyboardEvent } from 'react';
import {
    Button,
    Box,
    Text,
    Flex,
    ScaleFade,
    Icon,
    VStack,
    Link as LinkButton,
    useBreakpointValue,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { Input } from 'components/base/form';

import { validations } from 'validations';
import authService from 'services/auth.service';
import i18n from 'language/i18n';

import { colors } from 'styles/colors';
import { styles } from './styles';

export default function ForgotPasswordSection() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const responsiveTitle = useBreakpointValue({ base: 'h3', md: 'h4' });

    const formValid = (value: string) => {
        const { isNotEmpty, isEmail } = validations;
        return isEmail(value) === null && isNotEmpty(value) === null;
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setError('');

        if (value && !formValid(value)) setError(i18n.t('errors.invalid.email'));

        setEmail(event.target.value);
    };

    const submit = async () => {
        setLoading(true);
        try {
            await authService.sendResetPasswordEmail(email);

            setSuccess(true);
        } catch (err) {
            let { message } = err;

            switch (err.code) {
                case 'not-found':
                    setSuccess(true);
                    break;
                case 'permission-denied':
                    message = i18n.t('sections.forgotPassword.errors.disabledUser');
                    break;
                case 'unknown':
                    message = i18n.t('errors.unknown');
                    setError(message);
                    break;
                default:
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTryAgain = () => {
        setSuccess(false);
    };

    const handleSubmitOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            submit();
        }
    };

    return (
        <Box
            flexDir="column"
            mt="var(--margin-medium)"
            w={{ base: 'full', sm: '422px', md: '400px' }}
            textAlign={{ base: 'start', sm: 'center' }}
            alignSelf="center"
            {...styles.responsiveCenterContent}
        >
            <Text variant={responsiveTitle} fontWeight={{ base: 'semibold', md: 'medium' }}>
                {i18n.t('sections.forgotPassword.title')}
            </Text>
            <Box mt="var(--margin-xlarge)">
                {success ? (
                    <VStack gap="var(--margin-medium)" {...styles.responsiveCenterContent}>
                        <ScaleFade initialScale={0.5} in={success}>
                            <Flex align="center">
                                <Icon as={CheckCircleIcon} color={colors.red} alignSelf="center" w="28px" h="28px" />
                                <Text variant="md" ml="2">
                                    {i18n.t('sections.forgotPassword.successLabel')}
                                </Text>
                            </Flex>
                        </ScaleFade>

                        <Text variant="sm" fontWeight="light">
                            {i18n.t('sections.forgotPassword.infoLabel')}
                        </Text>

                        <Text variant="sm" fontWeight="light" color={colors.grayish}>
                            {i18n.t('sections.forgotPassword.retry.mainLabel')}
                            <LinkButton
                                variant="sm"
                                fontWeight="light"
                                textDecoration="none"
                                ml="var(--xs_space)"
                                onClick={handleTryAgain}
                            >
                                {i18n.t('sections.forgotPassword.retry.linkLabel')}
                            </LinkButton>
                        </Text>
                    </VStack>
                ) : (
                    <>
                        <Box my="6">
                            <Input
                                name="email"
                                label={i18n.t('sections.forgotPassword.input.email')}
                                type="email"
                                value={email}
                                onChange={onInputChange}
                                errorMessage={error}
                                onKeyPress={handleSubmitOnEnter}
                            />
                        </Box>

                        <Button
                            variant="solid"
                            colorScheme="brand"
                            size="md"
                            w="full"
                            isLoading={loading}
                            isDisabled={!email || !!error}
                            onClick={submit}
                        >
                            {i18n.t('sections.forgotPassword.submitBtn')}
                        </Button>

                        <Text variant="sm" color={colors.grayDarker} fontWeight="light" mt="4">
                            {i18n.t('sections.forgotPassword.label')}
                        </Text>
                    </>
                )}
            </Box>
        </Box>
    );
}
