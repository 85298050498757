import { TextProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<TextProps> & any = {
    noPrice: (breakpoint: string) => {
        let variant;
        let width;
        let textDecoration;

        switch (breakpoint) {
            case 'base':
            case 'sm':
                variant = 'md';
                width = '100px';
                textDecoration = 'underline';
                break;
            default:
                variant = 'h5';
                width = '100%';
                textDecoration = 'none';
                break;
        }

        return {
            variant,
            width,
            textDecoration,
            fontWeight: 'var(--font-weight-medium)',
            textAlign: 'start',
        };
    },
};

export default styles;
