import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Accordion } from 'components/base/roundedAccordion';
import { useRemoteConfig } from 'hooks';
import { Button } from '@chakra-ui/react';
import styles from './index.module.css';
import Row from '../../../base/row';
import { H1 } from '../../../base/text/h1';
import i18n from '../../../../language/i18n';
import PageContainer from '../../../base/pageContainer';
import RectangleLgIcon from '../../../base/icon/rectangle-lg';
import RectangleSm from '../../../base/icon/rectangle-sm';
import { questionsAndAnswers } from './questionsAndAnswers';
import ChakraStyles from './styles';

export default function FAQ() {
    const history = useHistory();
    const [expanded, setExpanded] = useState<false | number>(false);

    const { bookingCancellationConfig } = useRemoteConfig();

    const onlyFirstFourQuestions = questionsAndAnswers(bookingCancellationConfig)[0].body.slice(0, 4);

    return (
        <div className={styles.section}>
            <PageContainer>
                <RectangleSm className={styles.rectangleSm} />
                <Row className={styles.titleContainer}>
                    <H1>{i18n.t('sections.faq.title')}</H1>
                </Row>
                <div className={styles.content}>
                    {onlyFirstFourQuestions.map(({ title, content }, index) => (
                        <Accordion
                            key={index}
                            title={title}
                            content={content}
                            i={index}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            className={styles.faqAccordion}
                        />
                    ))}
                    <Button
                        _hover={{ background: 'black', color: 'white', boxShadow: 'none' }}
                        {...ChakraStyles.faqButton}
                        onClick={() => history.push('/faq')}
                    >
                        {i18n.t('sections.faq.allQuestionsButton')}
                    </Button>
                </div>
                <RectangleLgIcon className={styles.rectangleLg} />
            </PageContainer>
        </div>
    );
}
