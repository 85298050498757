import React, { useState, useEffect } from 'react';
import { Box, Flex, useDisclosure, useToast, Button } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import moment, { Moment } from 'moment';

import MobilePriceDrawer from 'components/sections/_layout/mobile-price-drawer';
import AssetDetailsCharacteristics from 'components/sections/asset-details/characteristics';
import AssetDetailsHeader from 'components/sections/asset-details/header';
import AssetDetailsTitle from 'components/sections/asset-details/title';
import Splitter from 'components/base/splitter';
import AssetDetailsExtras from 'components/sections/asset-details/extras';
import Margin from 'components/base/margin';
import { provision_booking_reviews } from 'components/sections/asset-details/booking-reviews/booking-review-card';
import BookingReviewPanel from 'components/sections/asset-details/booking-reviews';
import Explanation from 'components/sections/asset-details/explanation';
import AssetPanel from 'components/sections/_layout/asset-panel';
import LocationItem from 'components/sections/_layout/location-item';
import Loading from 'components/base/loading';

import { TimeFormat } from 'models/timeFormat';
import { useAssetOccupiedDates } from 'hooks/useAssetOccupiedDates';
import { useRemoteConfig, useSharedData, useOperatingAreas, useWindowSize, useAssetDetails, useAuth } from 'hooks';
import i18n from 'language/i18n';

import PageContainer from 'components/base/pageContainer';
import SharedDataDeliveryReturnModal from 'components/sections/_layout/sharedData-Delivery-Return-Modal';
import AssetService from 'services/asset.service';
import { OccupiedAssetsAction } from 'models/asset/getOccupiedDatesAction';
import { SharedData } from 'models/sharedData/SharedData';
import { SearchObject } from 'models/sharedData/SearchObject';
import CSSStyles from './index.module.css';
import styles from './styles';

function AssetsDetailsPage() {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const toast = useToast();

    const { shared, sharedDataService, setAssetDetailsBookingDataContext, assetDetailsBookingDataContext } =
        useSharedData();
    const windowSize = useWindowSize();
    const deliveryReturnModalProps = useDisclosure();
    const { pagesConfig } = useRemoteConfig();
    const { checkIfHavePendingVerifications } = useAuth();

    const { bookingCancellationConfig } = useRemoteConfig();

    const { locationIsAvailable, openWarningModal } = useOperatingAreas();
    const { asset, loading: assetLoading } = useAssetDetails(params.id);
    const { occupiedDates, loading: occupiedDatesLoading } = useAssetOccupiedDates({
        assetId: params.id,
        action: OccupiedAssetsAction.ALL_OCCUPIED_DATES,
    });

    const [selectedExtras, setSelectedExtras] = useState<string[]>(shared.extras);
    const [isPriceDrawerOpen, setIsPriceDrawerOpen] = useState<boolean>(false);
    const filterDates = {
        from: assetDetailsBookingDataContext?.start?.date || undefined,
        to: assetDetailsBookingDataContext?.end?.date || undefined,
    };

    useEffect(() => {
        async function checkIfAssetExists(id: string) {
            const assetToCheck = await AssetService.get(id);
            if (!assetToCheck) {
                toast({
                    title: i18n.t('pages.assetDetails.error.assetNotExists.title'),
                    description: i18n.t('pages.assetDetails.error.assetNotExists.description'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',
                });
                history.push('/');
            }
        }
        checkIfAssetExists(params.id);
    }, [params.id, toast, history]);

    function onAssetDetailsDatesChange(changes: Partial<SharedData>) {
        setAssetDetailsBookingDataContext && setAssetDetailsBookingDataContext(changes);
    }

    function onExtrasChange(items: string[]) {
        // DO NOT CHANGE THE WAY THIS ARRAY IS BEING PUSHED ([...array]) OR RENDERING WILL NOT BE TRIGGERED FOR EXTRAS
        // EXPLANATION: REACT DOESNT DETECT A CHANGE IN AN ARRAY CAUSE IT IS NOT FOCUSED ON ARRAY-LENGTH ONLY IF THE ARRAY IS THE SAME
        // BUT FOR REACT IT WILL ALWAYS BE THE SAME CAUSE THE ARRAY IS ALWAYS THE SAME ONLY THE LENGTH DIFFERS. Ty
        setSelectedExtras([...items]);
    }

    function onDateChangeClick() {
        deliveryReturnModalProps.onToggle();
    }

    function submit() {
        const havePendingVerifications = checkIfHavePendingVerifications();

        if (havePendingVerifications) {
            return;
        }

        if (!locationIsAvailable) {
            openWarningModal();
            return;
        }

        sharedDataService.updateSelectedExtras(selectedExtras);

        history.push(`/assets/${params.id}/book`, {
            startDate: filterDates!.from!.toDate(),
            endDate: filterDates!.to!.toDate(),
            paymentOption: 'pay-per-day',
        });
    }

    function toggleEditDrawer() {
        deliveryReturnModalProps.onToggle();
    }

    function openPriceDrawer() {
        setIsPriceDrawerOpen(true);
    }

    function closePriceDrawer() {
        setIsPriceDrawerOpen(false);
    }

    function determineEndQueryText() {
        const { start, end } = assetDetailsBookingDataContext;

        if (!end.query || !end.location) {
            return undefined;
        }

        if (start.query === end.query) {
            return i18n.t('sections.assetDetails.booking.sameAddressAsDelivery');
        }

        return end.query;
    }

    function displayDate(date?: Moment | null, time?: string | null, timeFormat?: TimeFormat) {
        if (!date || !time) {
            return undefined;
        }

        return `${date?.format('dddd, MMM DD')}, ${moment(time, 'HH:mm').format(timeFormat)}`;
    }

    if (assetLoading && !asset) {
        return (
            <Flex>
                <Loading spinnerSize={40} halfScreen />;
            </Flex>
        );
    }

    function renderSplitterBeforeInformationSection() {
        if (windowSize.width < 692) {
            return <Splitter spacing="xlarge" />;
        }
        if (windowSize.width >= 928) {
            return <Splitter spacing="xxxlarge" />;
        }
        if (windowSize.width >= 692) {
            return <Splitter spacing="xl-space" />;
        }
    }

    const extrasExist = asset.extras && asset.extras.length > 0;

    return (
        <>
            <AssetDetailsHeader asset={asset} />
            <PageContainer>
                {isBrowser && <Margin />}
                <Box {...styles.container}>
                    <Flex {...styles.content}>
                        <Box {...styles.contentDetails}>
                            <AssetDetailsTitle asset={asset} />
                            <Splitter spacing="xlarge" />
                            <AssetDetailsCharacteristics asset={asset} />

                            {windowSize.width < 692 && (
                                <>
                                    <Splitter spacing="xlarge" />
                                    <LocationItem
                                        title={i18n.t('sections.assetDetails.booking.deliveryInfo')}
                                        location={assetDetailsBookingDataContext?.start.query}
                                        date={displayDate(
                                            assetDetailsBookingDataContext?.start?.date,
                                            assetDetailsBookingDataContext?.start?.time,
                                            'hh:mma',
                                        )}
                                        className={CSSStyles.locationItem}
                                    />
                                    <LocationItem
                                        title={i18n.t('sections.assetDetails.booking.returnInfo')}
                                        location={determineEndQueryText()}
                                        date={displayDate(
                                            assetDetailsBookingDataContext?.end?.date,
                                            assetDetailsBookingDataContext?.end?.time,
                                            'hh:mma',
                                        )}
                                        className={CSSStyles.locationItem}
                                    />
                                    <Button
                                        variant="link"
                                        colorScheme="brand"
                                        mb="40px"
                                        onClick={toggleEditDrawer}
                                        textAlign="start"
                                    >
                                        {i18n.t('sections.assetDetails.booking.changeDatesLabel')}
                                    </Button>
                                </>
                            )}

                            {extrasExist && (
                                <>
                                    <Splitter spacing="xlarge" />
                                    <AssetDetailsExtras
                                        extras={asset.extras}
                                        selectedExtras={selectedExtras}
                                        onChange={onExtrasChange}
                                    />
                                </>
                            )}
                        </Box>
                        <Box flex="0" alignItems="end" {...styles.panelContainer}>
                            <AssetPanel
                                asset={asset}
                                extras={selectedExtras}
                                insurances={[]}
                                mode="details"
                                onSubmit={submit}
                                onDates={onDateChangeClick}
                                bookingCancellationConfig={bookingCancellationConfig}
                            />
                        </Box>
                    </Flex>
                    {renderSplitterBeforeInformationSection()}
                    <Explanation />
                    {pagesConfig.asset_page_config.reviews && (
                        <BookingReviewPanel asset={asset} reviews={provision_booking_reviews} />
                    )}
                    {windowSize.width < 692 && (
                        <MobilePriceDrawer
                            asset={asset}
                            extras={selectedExtras}
                            insurances={[]}
                            mode="details"
                            onSubmit={submit}
                            onDates={onDateChangeClick}
                            isOpen={isPriceDrawerOpen}
                            onToggle={openPriceDrawer}
                            onClose={closePriceDrawer}
                            bookingCancellationConfig={bookingCancellationConfig}
                            onClickOpenEditModal={toggleEditDrawer}
                        />
                    )}
                    <SharedDataDeliveryReturnModal
                        loading={occupiedDatesLoading}
                        asset={asset}
                        {...deliveryReturnModalProps}
                        blockedDates={occupiedDates}
                        onAssetDetailsDatesChange={onAssetDetailsDatesChange}
                    />
                </Box>
            </PageContainer>
        </>
    );
}

export default AssetsDetailsPage;
