import { Extra } from 'services/extras.service';
import { PriceCalculator } from 'services/price.formatter';

export function extrasReceiptFormat(allExtras: Extra[], selectedExtras: string[], duration: number) {
    // map selected extras
    return allExtras
        ? allExtras
              .filter(extra => selectedExtras.includes(extra.id))
              .map(({ id, price, label }: Extra) => {
                  const itemPrice = new PriceCalculator(price);
                  const isZero = itemPrice.getPriceAmount() <= 0;
                  const isMultipleDays = duration > 1;
                  const info =
                      isMultipleDays && !isZero ? `(${duration} x ${itemPrice.format(2).slice(1)})` : undefined;

                  return {
                      key: id,
                      label,
                      info,
                      value: itemPrice.clone().multiply(duration),
                  };
              })
        : [];
}
