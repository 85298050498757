export enum BookingStatus {
    PENDING = 'pending',
    CANCELLED = 'cancelled',
    CANCELLED_BY_USER = 'cancelled-by-user',
    BOOKED = 'booked',
    SECURITY_DEPOSIT_PENDING = 'security-deposit-pending',
    SECURITY_DEPOSIT_RETRY = 'security-deposit-retry',
    SECURITY_DEPOSIT_FAILED = 'security-deposit-failed',
    PAYMENT_FAILED = 'payment-failed',
    ACTIVE = 'active',
    COMPLETED = 'completed',
    REVIEW = 'review',
    FINISHED = 'finished',
    PROCESSED = 'processed',
}
