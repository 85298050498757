import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { Modal } from '../../base/modal';
import styles from './index.module.css';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import Text from '../../base/text';
import Row from '../../base/row';
import i18n from '../../../language/i18n';

export type DriverVerificationsModalProps = {
    onConfirm: () => void;
    isOpen: boolean;
    onClose: () => void;
};

export default function DriverVerificationsModal(props: DriverVerificationsModalProps) {
    const { isOpen, onConfirm, onClose } = props;
    const { pathname } = useLocation();

    if (pathname === '/profile') {
        return null;
    }

    return (
        <Modal show={isOpen} onClose={onClose} size="fit" className={styles.container}>
            <WarningIcon className={styles.icon} />
            <Text className={styles.title}>{i18n.t('driverVerifications.title')}</Text>
            <Text className={styles.description}>{i18n.t('driverVerifications.message')}</Text>
            <Row className={styles.buttonsRow}>
                <Button variant="solid" colorScheme="black" size="md" onClick={onConfirm}>
                    {i18n.t('sidebar.link.profile')}
                </Button>
                <Button variant="outline" colorScheme="black" size="md" onClick={onClose}>
                    {i18n.t('driverVerifications.skip')}
                </Button>
            </Row>
        </Modal>
    );
}
