import React from 'react';
import Caption from '../../../base/text/caption';
import styles from './index.module.css';

function AssetDetailsCharacteristicsItem(props: AssetDetailsCharacteristicsItemProps) {
    return (
        <div className={[props.row ? styles.itemContainerRow : styles.itemContainer, props.className].join(' ')}>
            <props.icon className={styles.icon} />
            <Caption>{props.label}</Caption>
        </div>
    );
}

export default AssetDetailsCharacteristicsItem;

export type AssetDetailsCharacteristicsItemProps = {
    icon: any;
    label?: any;
    row?: boolean;
    className?: string;
};
