import React from 'react';

import { FaSpinner } from 'react-icons/fa';
import styles from './index.module.css';

export type SpinnerProps = {
    className?: string;
    full?: boolean;
    size?: string | number;
    color?: string;
};

export function Spinner(props: SpinnerProps) {
    const { full, className, size, color } = props;

    let colorClassName;

    switch (color) {
        case 'red':
            colorClassName = styles.spinRed;
            break;
        case 'white':
            colorClassName = styles.spinWhite;
            break;
        default:
        case 'black':
            colorClassName = styles.spinBlack;
            break;
    }

    return (
        <FaSpinner
            size={size}
            className={[styles.spin, full && styles.fullHeight, className, colorClassName].join(' ')}
        />
    );
}
