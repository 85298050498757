import React from 'react';
import Card from '../../../../base/card';
import Text from '../../../../base/text';
import styles from './index.module.css';
import BodyText from '../../../../base/text/body';

export type BusinessIntroCardProps = {
    title?: string;
    description?: string;
    image?: string;
};

export default function BusinessIntroCard({ title, description, image }: BusinessIntroCardProps) {
    return (
        <div className={styles.container}>
            <Card className={styles.card}>
                <Text className={styles.title}>{title}</Text>
                <BodyText className={styles.description}>{description}</BodyText>
                {image && <img alt={title} width="100%" height="100%" src={image} />}
            </Card>
        </div>
    );
}
