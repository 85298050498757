import React from 'react';
import { Box, Image, Text, Button, useMediaQuery, useBreakpointValue } from '@chakra-ui/react';

import BookingStatusIcon from 'components/base/icon/status-icon';
import BookingStatus from 'components/sections/bookings/booking-status';

import { BookingStatus as BookingStatuses } from 'models/booking/BookingStatus';
import { colors } from 'styles/colors';
import Photo from '../../../base/photo';
import i18n from '../../../../language/i18n';
import CSSModuleStyles from './index.module.css';

import { styles, dynamicStyles } from './styles';

type BookingCardProps = {
    booking: any;
    past?: boolean;
    onItemClick?: any;
};

export default function BookingCard(props: BookingCardProps) {
    const { booking, past, onItemClick } = props;

    const [isLargerThanTablet] = useMediaQuery('(min-width: 928px)');
    const isMobile = useBreakpointValue({ base: true, md: false });

    const year = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const getLabelStyle = (status: string) => {
        switch (status) {
            case BookingStatuses.PENDING:
            case BookingStatuses.SECURITY_DEPOSIT_PENDING:
            case BookingStatuses.SECURITY_DEPOSIT_RETRY:
            case BookingStatuses.REVIEW:
            case BookingStatuses.SECURITY_DEPOSIT_FAILED:
            case BookingStatuses.PAYMENT_FAILED:
                return colors.grayish;
            case BookingStatuses.COMPLETED:
            case BookingStatuses.CANCELLED:
            case BookingStatuses.CANCELLED_BY_USER:
            case BookingStatuses.PROCESSED:
            case BookingStatuses.FINISHED:
                return colors.black;
            case BookingStatuses.BOOKED:
            case BookingStatuses.ACTIVE:
                return colors.red;
            default:
                return colors.grayish;
        }
    };

    function redirectToDetailsOnMobile() {
        if (!isMobile) {
            return;
        }

        onItemClick();
    }

    function renderAssetPhoto() {
        if (!booking.assetDetails.photos || booking.assetDetails.photos.length < 0) {
            return <Photo className={CSSModuleStyles.photoDefault} size="relative" wide alt="default-image" />;
        }

        return (
            <Image
                width="100%"
                height={{ base: '59.75px', md: '113.16px', lg: '124.48px' }}
                marginBottom={{ base: '10px', md: '0px' }}
                {...(past ? { ...styles.pastImg } : '')}
                alt="car"
                src={booking.assetDetails.photos[0]}
            />
        );
    }

    return (
        <Box {...styles.container} onClick={redirectToDetailsOnMobile}>
            <Box {...styles.card}>
                <Box {...styles.cardContainer}>
                    <Box {...styles.imageContainer}>{renderAssetPhoto()}</Box>
                    <Text {...dynamicStyles.refLabel(getLabelStyle(booking.status))}>
                        #{booking.refNumber.toUpperCase()}
                    </Text>
                </Box>
                <Box {...styles.detailsContainer}>
                    <Text {...styles.bookingRef} {...(past ? { ...styles.pastText } : '')}>
                        {i18n.t('sections.bookingCard.bookingId')}
                        {booking.refNumber.toUpperCase()}
                    </Text>
                    <Text {...styles.model}>
                        {booking.assetDetails.brand} {booking.assetDetails.model}
                    </Text>
                    <Text {...styles.renting}>{i18n.t('sections.bookingCard.address')} New York City</Text>
                    <Text {...styles.date}>
                        {booking.startDate.slice(8, 10)} {year[+(booking.startDate.slice(5, 7) - 1)]} {' - '}{' '}
                        {booking.endDate.slice(8, 10)} {year[+(booking.endDate.slice(5, 7) - 1)]} {', '}{' '}
                        {booking.endDate.slice(0, 4)}
                    </Text>
                    <Box {...styles.status}>
                        <BookingStatusIcon status={booking.status} />
                    </Box>
                </Box>
            </Box>
            <Box {...styles.linksContainer}>
                {booking.status && (
                    <BookingStatus status={booking.status}>
                        {i18n.t(`sections.bookingList.status_${booking.status}`)}
                    </BookingStatus>
                )}

                <Button {...styles.detailsButton} onClick={onItemClick}>
                    {isLargerThanTablet
                        ? i18n.t('sections.bookingCard.bookingDetails')
                        : i18n.t('sections.bookingCard.seeBooking')}
                </Button>
            </Box>
        </Box>
    );
}
