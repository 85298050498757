import { Flex } from '@chakra-ui/react';
import CloseIconThinn from 'components/base/icon/closeThin';
import { AddressAutocompleteValue } from 'components/sections/home/addressComplete/types';
import React, { forwardRef, RefObject, useEffect, useState } from 'react';
import i18n from '../../../../language/i18n';
import { Input } from '../input';
import styles from './index.module.css';

export type AutocompleteProps = {
    suggestions: AddressAutocompleteValue[];
    onChange: (e: string) => void;
    onInputSelected: (e: any) => void;
    onBlur?: (e: any) => void;
    value?: string;
    focusOnRender?: boolean;
    disabled?: boolean;
    placeholder?: string;
    withClear?: boolean;
    isHovering?: boolean;
    endHovering?: () => void;
    setSearchBtn?: (value: boolean) => void;
    onClear?: () => void;
    onFocus?: (e: FocusEvent) => void;
    className?: string;
    disabledSuggestions?: boolean;
    searchOnInitialRender?: boolean;
    id?: string;
};

const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>((props: AutocompleteProps, ref) => {
    const { suggestions, focusOnRender, disabled, placeholder, value, searchOnInitialRender } = props;
    const [activeSuggestion, setActiveSuggestion] = useState<number>(0);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [showClear, setShowClear] = useState<boolean>(false);

    useEffect(() => {
        if (suggestions.length > 1) {
            setShowSuggestions(true);
        }
    }, [suggestions]);

    useEffect(() => {
        // on first render, if a value is provided, do a initial search to fill suggestions
        if (searchOnInitialRender && value !== '' && value !== undefined) {
            props.onChange(value);
        }
        // eslint-disable-next-line
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;

        setActiveSuggestion(0);
        props.onChange(inputValue);
    };

    const onClick = (label: string, valueArray: Array<number>) => {
        setActiveSuggestion(0);
        setShowSuggestions(false);

        props.onInputSelected && props.onInputSelected({ label, value: valueArray });
    };

    const onFocus = (event: any) => {
        if (showSuggestions === false) {
            setShowSuggestions(true);
        }

        props.onFocus?.(event);

        setShowClear(true);
    };

    const onBlur = (event: any) => {
        // prevents bluring if the close icon is clicked
        if (props.isHovering) return;

        if (showSuggestions === true) {
            setShowSuggestions(false);
        }

        setShowClear(false);
        props.setSearchBtn?.(false);
        props.onBlur && props.onBlur(event);
    };

    const onKeyDown = (e: any) => {
        switch (e.keyCode) {
            case 13: {
                setActiveSuggestion(0);
                setShowSuggestions(false);
                const input = suggestions.find((s: any) => s.label === activeSuggestion);
                if (input?.label !== undefined) {
                    props.onInputSelected && props.onInputSelected(input);
                }
                break;
            }
            case 38: {
                activeSuggestion !== 0 && setActiveSuggestion(activeSuggestion - 1);
                break;
            }
            case 40: {
                if (activeSuggestion - 1 === suggestions.length) {
                    break;
                }

                setActiveSuggestion(activeSuggestion + 1);
                break;
            }
            default:
        }
    };

    function handleClear(e: any) {
        props.onClear?.();
    }

    const getSuggestions = () => {
        if (suggestions.length > 0) {
            return suggestions.map(suggestion => {
                return (
                    <li
                        key={suggestion.label}
                        onKeyDown={onKeyDown}
                        onMouseDown={e => e.preventDefault()}
                        onClick={e => {
                            // this blurs the search input when choosing the suggestion
                            e.stopPropagation();

                            const reference = ref as RefObject<HTMLInputElement>;

                            reference?.current?.blur();

                            onClick(suggestion.label!, suggestion.value);
                        }}
                    >
                        {suggestion.label}
                    </li>
                );
            });
        }

        return <li key="search-not-found">{i18n.t('sections.searchBar.noResults')}</li>;
    };

    const getSuggestionBox = () => {
        if (props.disabledSuggestions === true) {
            return null;
        }
        if (showSuggestions && value) {
            return (
                <ul className={styles.results} onMouseEnter={() => props.endHovering?.()}>
                    {getSuggestions()}
                </ul>
            );
        }

        return <></>;
    };

    return (
        <>
            <Flex alignItems="center" className={props.className}>
                <Input
                    placeholder={placeholder || i18n.t('sections.searchHeader.searchLabel')}
                    className={styles.input}
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                    focusOnRender={focusOnRender}
                    disabled={disabled}
                    ref={ref}
                    id={props.id}
                />
                {props.withClear && showClear && value && value.length > 0 && (
                    <CloseIconThinn className={styles.closeIcon} onClick={handleClear} />
                )}
            </Flex>
            {getSuggestionBox()}
        </>
    );
});

export default Autocomplete;
