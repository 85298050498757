import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import { Insurance } from 'services/insurances.service';
import { PriceCalculator } from 'services/price.formatter';

import styles from './index.module.css';

interface InsuranceCardProps {
    insurance: Insurance;
    isSelected: boolean;
    onInsurancesChange: (item: Insurance) => void;
}

function InsuranceCard({ isSelected, insurance, onInsurancesChange }: InsuranceCardProps) {
    function renderHeaderMain() {
        const price = new PriceCalculator(insurance.price!);
        return (
            <Flex justify="space-between" color={isSelected ? 'brand.primary.900' : undefined}>
                <Text fontWeight="medium" boxSizing="border-box" pr="4px">
                    {insurance.title}
                </Text>
                <Flex justify="flex-end">
                    <Text fontWeight="medium">{price.format()}</Text>
                    <Text fontWeight="light">/day</Text>
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex
            direction="column"
            p="20px"
            border="1px solid "
            borderColor={isSelected ? colors.red : colors.grayLightBorder}
            color={isSelected ? 'brand.primary.900' : undefined}
            borderRadius="4px"
            mb="28px"
            onClick={() => onInsurancesChange(insurance)}
            cursor="pointer"
        >
            {renderHeaderMain()}
            <Flex
                direction="column"
                mt="12px"
                color={colors.grayish}
                className={styles.details}
                dangerouslySetInnerHTML={{
                    __html: insurance.description || '',
                }}
            />
        </Flex>
    );
}

export default InsuranceCard;
