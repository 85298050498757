import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input as ChakraInput,
    InputProps as ChakraInputProps,
    FormLabelProps,
} from '@chakra-ui/react';

interface InputProps extends ChakraInputProps {
    name: string;
    label?: string;
    labelProps?: FormLabelProps;
    errorMessage?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (props, ref) => {
    const { name, label, labelProps, errorMessage = null, ...rest } = props;

    return (
        <FormControl isInvalid={!!errorMessage}>
            {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}

            <ChakraInput
                id={name}
                name={name}
                focusBorderColor="brand.900"
                borderRadius="4px"
                variant="outline"
                _hover={{
                    bgColor: 'none',
                }}
                size="lg"
                fontSize="md"
                ref={ref}
                {...rest}
            />

            {!!errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </FormControl>
    );
};

export const Input = forwardRef(InputBase);
