import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './index.module.css';

function Link(props: LinkProps) {
    return (
        <RouterLink
            className={[styles.link, props.className].join(' ')}
            id={props.id}
            to={props.to || '/'}
            onClick={props.onClick}
        >
            {props.children}
        </RouterLink>
    );
}

export type LinkProps = {
    children: any;
    id?: string;
    to?: any;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
};

export default Link;
