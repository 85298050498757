import React from 'react';
import Column from '../column';
import BreadcrumbLink from '../link';
import Row from '../row';
import BodyText from '../text/body';

import styles from './index.module.css';

export interface BreadcrumbItem {
    label: string;
    to?: string;
}

export interface BreadcrumbProps {
    items: BreadcrumbItem[];
    className?: string;
}

function Breadcrumb({ items, className }: BreadcrumbProps) {
    function renderLink(item: BreadcrumbItem) {
        if (item.to) {
            return <BreadcrumbLink className={styles.link}>{item.label}</BreadcrumbLink>;
        }

        return <BodyText>{item.label}</BodyText>;
    }

    return (
        <Row className={[className, styles.breadcrumb].join(' ')}>
            {items.map((item, index) => (
                <Column key={item.label}>
                    {index !== 0 && <BodyText className={styles.slash}>/</BodyText>}
                    {renderLink(item)}
                </Column>
            ))}
        </Row>
    );
}

export default Breadcrumb;
