import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';

import Column from 'components/base/column';
import Text from 'components/base/text';
import { Avatar } from 'components/base/avatar';
import Row from 'components/base/row';

import { useAuth } from 'hooks';
import i18n from 'language/i18n';

import styles from './index.module.css';

export function ProfileInfoDetails() {
    const { claims, user } = useAuth();

    const rawPhoneNumber = () => {
        if (!user.phoneObject) {
            return 'N/A';
        }

        if (user.phoneObject.number && user.phoneObject.code) {
            return `${user.phoneObject.code}  ${user.phoneObject.number}`;
        }

        return 'N/A';
    };

    return (
        <Row>
            <Avatar className={styles.avatar} />
            <Column className={styles.container}>
                <Column>
                    <strong>{i18n.t('pages.profile.info.nameLabel')}</strong>
                    <Text noMargin>{user.name}</Text>
                </Column>

                <Column>
                    <strong>{i18n.t('pages.profile.info.emailLabel')}</strong>
                    <Row align="center">
                        <Text noMargin>{user.email}</Text>
                        {claims.email_verified && <FiCheckCircle className={styles.checkIcon} />}
                    </Row>
                </Column>

                <Column>
                    <strong>{i18n.t('pages.profile.info.phoneLabel')}</strong>
                    <Row align="center">
                        <Text noMargin>{rawPhoneNumber()}</Text>
                        {user.phoneObject && <FiCheckCircle className={styles.checkIcon} />}
                    </Row>
                </Column>
            </Column>
        </Row>
    );
}
