import React from 'react';
import { BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import { AppProvider } from 'hooks/app';
import AssetsCheckoutSuccessPage from 'pages/assets-checkout-success';
import BookingDetailsPage from 'pages/booking-details';
import SearchMobilePage from 'pages/search-mobile';
import ScrollToTop from 'components/base/scrollToTop';
import ProtectedRoute from '../components/auth/protectedRoute';
import Route from '../components/auth/route';
import Column from '../components/base/column';
import Footer from '../components/sections/standalone/footer';
import Header from '../components/sections/standalone/header';
import AboutPage from '../pages/about';
import AssetsPage from '../pages/assets';
import AssetsDetailsPage from '../pages/assets-details';
import AssetsExtrasAndInsurancePage from '../pages/assets-extras-insurance';
import BlogPage from '../pages/blog';
import BookingsPage from '../pages/bookings';
import BusinessPage from '../pages/business';
import CarSharingPage from '../pages/car-sharing';
import CareerPage from '../pages/career';
import CompanyPage from '../pages/company';
import CorporateProfilePage from '../pages/corporate-profile';
import CustomSearchPage from '../pages/custom-search';
import DriverPage from '../pages/driver';
import FaqFeedbackPage from '../pages/faq-feedback';
import GammaPage from '../pages/gamma';
import HelpPage from '../pages/help';
import HomePage from '../pages/home';
import ImprintPage from '../pages/imprint';
import InsurancePage from '../pages/insurance';
import NotFoundPage from '../pages/not-found';
import OperationsPage from '../pages/operations';
import PartnerPage from '../pages/partner';
import PolicyPage from '../pages/policy';
import ProfilePage from '../pages/profile';
import RentCarPage from '../pages/rent-a-car';
import SubscribePage from '../pages/subscribe';
import TeamPage from '../pages/team';
import TermsPage from '../pages/terms';
import VehiclesPage from '../pages/vehicles';

import AssetsCheckoutPage from '../pages/assets-checkout';

import '../styles/global.css';
import '../typings/moment-utils';
import styles from './index.module.css';

function App() {
    return (
        <Router>
            <AppProvider>
                <ScrollToTop>
                    <Column className={styles.contentWrapper}>
                        <Header />
                        <Switch>
                            <Route exact path="/" wrap component={HomePage} />
                            <Route exact path="/assets/:id" wrap component={AssetsDetailsPage} />
                            <Route exact path="/assets" wrap component={AssetsPage} />
                            <Route exact path="/about" wrap component={AboutPage} />
                            <Route exact path="/company" wrap component={CompanyPage} />
                            <Route exact path="/corporate-profile" wrap component={CorporateProfilePage} />
                            <Route exact path="/custom-search" wrap component={CustomSearchPage} />
                            <Route exact path="/faq" wrap component={FaqFeedbackPage} />
                            <Route exact path="/rent-a-car" wrap component={RentCarPage} />
                            <Route exact path="/car-sharing" wrap component={CarSharingPage} />
                            <Route exact path="/subscribe" wrap component={SubscribePage} />
                            <Route exact path="/business" wrap component={BusinessPage} />
                            <Route exact path="/gamma" wrap component={GammaPage} />
                            <Route exact path="/help" wrap component={HelpPage} />
                            <Route exact path="/insurance" wrap component={InsurancePage} />
                            <Route exact path="/operations" wrap component={OperationsPage} />
                            <Route exact path="/policy" wrap component={PolicyPage} />
                            <Route exact path="/terms" wrap component={TermsPage} />
                            <Route exact path="/vehicles" wrap component={VehiclesPage} />
                            <Route exact path="/imprint" wrap component={ImprintPage} />
                            <Route exact path="/team" wrap component={TeamPage} />
                            <Route exact path="/blog" wrap component={BlogPage} />
                            <Route exact path="/partner" wrap component={PartnerPage} />
                            <Route exact path="/driver" wrap component={DriverPage} />
                            <Route exact path="/career" wrap component={CareerPage} />
                            <Route exact path="/search" component={SearchMobilePage} />
                            <Route exact path="/assets/:id/checkout" wrap pageWrap component={AssetsCheckoutPage} />
                            <Route
                                exact
                                path="/assets/:id/checkout/:bookId/success"
                                wrap
                                pageWrap
                                component={AssetsCheckoutSuccessPage}
                            />
                            <Route
                                exact
                                path="/assets/:id/book"
                                wrap
                                pageWrap
                                component={AssetsExtrasAndInsurancePage}
                            />

                            <ProtectedRoute exact path="/profile/" wrap pageWrap component={ProfilePage} />
                            <ProtectedRoute exact path="/bookings" wrap pageWrap component={BookingsPage} />
                            <ProtectedRoute exact path="/booking/:id" wrap pageWrap component={BookingDetailsPage} />
                            <Route path="/" wrap pageWrap component={NotFoundPage} />
                        </Switch>
                        <Footer />
                    </Column>
                </ScrollToTop>
            </AppProvider>
        </Router>
    );
}

export default withRouter(App);
