import {
    Box,
    Divider,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Text,
    useDisclosure,
    Link as LinkButton,
} from '@chakra-ui/react';
import React from 'react';
import { colors } from 'styles/colors';
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward.svg';
import MobileDateTimeSelectSection from 'components/sections/mobile-search/date-time';
import i18n from 'language/i18n';
import { Moment } from 'moment';
import styles from './index.module.css';
import chakraStyles from './styles';

interface DatesDisplayProps {
    startDate?: Moment | null;
    endDate?: Moment | null;
    asset?: any;
    onConfirm?: (start: Moment, end: Moment) => void;
    blockedDates?: string[];
}

function DatesDisplay({ startDate, endDate, asset, onConfirm, blockedDates }: DatesDisplayProps) {
    const drawer = useDisclosure();

    function handleConfirmClick(start: Moment, end: Moment) {
        onConfirm?.(start, end);

        drawer.onClose();
    }

    return (
        <Box mt="40px">
            <Text {...chakraStyles.title}>{i18n.t('delivery.return.modal.rentalDates')}</Text>

            <Flex
                h="56px"
                justifyContent="space-evenly"
                alignItems="center"
                border="1px solid var(--color-gray-light-border)"
                borderRadius="56px"
                onClick={() => drawer.onOpen()}
            >
                <Text fontWeight="light">{`${startDate?.format('MMM DD hh:mma') || 'Choose date'}`}</Text>
                <Divider orientation="vertical" zIndex="-1" h="20px" borderColor={colors.grayLight} />
                <Text fontWeight="light">{`${endDate?.format('MMM DD hh:mma') || 'Choose date'}`}</Text>
            </Flex>
            <Flex mt="16px" alignItems="center" onClick={() => drawer.onOpen()}>
                <LinkButton as="button" mr="8px">
                    {i18n.t('delivery.return.modal.editDates')}
                </LinkButton>
                <ArrowForward className={styles.arrowForward} />
            </Flex>
            {drawer.isOpen && (
                <Drawer isOpen onClose={drawer.onClose} placement="bottom">
                    <DrawerOverlay />
                    <DrawerContent {...chakraStyles.drawerContent} paddingX="0">
                        <MobileDateTimeSelectSection
                            onConfirmClick={handleConfirmClick}
                            clearButtonLocation="header"
                            onBack={() => drawer.onClose()}
                            initialStartDate={startDate || null}
                            initialEndDate={endDate || null}
                            className={styles.mobileDatesSelection}
                            selectedDatesComponentVariant="variant2"
                            asset={asset}
                            blockedDates={blockedDates}
                        />
                    </DrawerContent>
                </Drawer>
            )}
        </Box>
    );
}

export default DatesDisplay;
