import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import Lottie from 'react-lottie';
import { useRandomLoadingMessage } from 'hooks/useRandomLoadingMessage';
import carAnimation from '../../../assets/Lotties/loading-car-animation.json';
import styles from './styles';

import backgroundImage from '../../../assets/Lotties/loadingCar-background.svg';

/**
 *
 * @returns a loading animation made by lotties.
 */
export default function LoadingAnimation() {
    const randomLoadingMessage = useRandomLoadingMessage();
    // lottie config
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: carAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Box {...styles.lottieContainer}>
            <Box {...styles.lottieWrapper} backgroundImage={backgroundImage}>
                <Lottie options={lottieOptions} height={263} width={335} />
            </Box>
            <Text {...styles.loadingMessage}>{randomLoadingMessage || 'Checking your car preferences...'}</Text>
        </Box>
    );
}
