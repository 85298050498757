import { WarningLocationModal } from 'components/sections/standalone/warningLocationModal';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import OperatingAreasService, { Coordinate } from 'services/operatingAreas.service';
import { OperatingCity } from 'services/operatingCities.service';

export interface OperatingAreasContextType {
    locationIsAvailable: boolean;
    checkLocationInBoundingBox: (coord: Coordinate) => boolean;
    openWarningModal: () => void;
    storeSelectedOperatingCity: (selectedCity: OperatingCity | undefined) => void;
    operatingCity?: OperatingCity | undefined;
    loadBoundingBoxes: (operatingCityId: string) => void;
}

interface OperatingAreasProviderProps {
    children: ReactNode;
}

interface SessionStorageCoords {
    _ne: { lng: number; lat: number };
    _sw: { lng: number; lat: number };
}

const OperatingAreasContext = React.createContext<OperatingAreasContextType>({} as OperatingAreasContextType);

function OperatingAreasProvider({ children }: OperatingAreasProviderProps) {
    const [boundingBoxes, setBoundingBoxes] = useState<LngLatBounds[]>([]);
    const [locationIsAvailable, setLocationIsAvailable] = useState(true);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [operatingCity, setOperatingCity] = useState<OperatingCity | undefined>();

    useEffect(() => {
        if (operatingCity) {
            setOperatingCity(operatingCity);
            sessionStorage.setItem('operatingCity', JSON.stringify(operatingCity));
        }

        if (operatingCity === null || operatingCity === undefined) {
            const getData = sessionStorage.getItem('operatingCity');

            if (getData) {
                setOperatingCity(JSON.parse(getData));
            }
        }
    }, [operatingCity]);

    async function loadBoundingBoxes(operatingCityId: string) {
        const response = await OperatingAreasService.getOperatingAreasFromCity(operatingCityId);

        if (!response) throw new Error('Operating area not found for the selected city!');

        const boundingBoxInstances = response!.map(operatingArea => {
            const [sw, ne] = operatingArea.coordinates;

            const southWest = new mapboxgl.LngLat(sw.longitude, sw.latitude);
            const northEast = new mapboxgl.LngLat(ne.longitude, ne.latitude);

            const boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
            return boundingBox;
        });

        setBoundingBoxes(boundingBoxInstances);
    }

    useEffect(() => {
        if (boundingBoxes === undefined || boundingBoxes === null || boundingBoxes.length === 0) {
            const getBoundingBoxes = sessionStorage.getItem('boundingBox');

            if (getBoundingBoxes) {
                const parsed = JSON.parse(getBoundingBoxes!);

                const newBoundingBoxesInstance = parsed.map((boundingBoxCoords: SessionStorageCoords) => {
                    const southWestLat: number = boundingBoxCoords._sw.lat;
                    const southWestLng: number = boundingBoxCoords._sw.lng;
                    const northEastLat: number = boundingBoxCoords._ne.lat;
                    const northEastLng: number = boundingBoxCoords._ne.lng;

                    const southWest = new mapboxgl.LngLat(southWestLng, southWestLat);
                    const northEast = new mapboxgl.LngLat(northEastLng, northEastLat);

                    const boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
                    return boundingBox;
                });

                setBoundingBoxes(newBoundingBoxesInstance);
            }
        }

        if (boundingBoxes.length > 0) {
            sessionStorage.setItem('boundingBox', JSON.stringify(boundingBoxes));

            setBoundingBoxes(boundingBoxes);
        }
    }, [boundingBoxes]);

    function checkLocationInBoundingBox({ longitude, latitude }: Coordinate): boolean {
        const location = new mapboxgl.LngLat(longitude, latitude);
        const isAvailable = boundingBoxes.some(bb => bb.contains(location));

        if (!isAvailable) {
            setShowWarningModal(true);
        }

        setLocationIsAvailable(isAvailable);

        return isAvailable;
    }

    function closeWarningModal() {
        setShowWarningModal(false);
    }

    function openWarningModal() {
        setShowWarningModal(true);
    }

    function storeSelectedOperatingCity(operatingCityToStore: OperatingCity | undefined) {
        sessionStorage.setItem('operatingCity', JSON.stringify(operatingCityToStore));
        if (operatingCityToStore === undefined) {
            sessionStorage.removeItem('operatingCity');
        }

        setOperatingCity(operatingCityToStore);
        if (operatingCityToStore && operatingCityToStore.id) {
            loadBoundingBoxes(operatingCityToStore.id);
        }
    }

    return (
        <OperatingAreasContext.Provider
            value={{
                locationIsAvailable,
                checkLocationInBoundingBox,
                openWarningModal,
                storeSelectedOperatingCity,
                operatingCity,
                loadBoundingBoxes,
            }}
        >
            {children}
            <WarningLocationModal isOpen={showWarningModal} onClose={closeWarningModal} />
        </OperatingAreasContext.Provider>
    );
}

function useOperatingAreas() {
    const context = useContext(OperatingAreasContext);

    if (!context) {
        throw new Error('useBoundingBoxes must be used within an BoundingBox Provider');
    }

    return context;
}

export { OperatingAreasProvider, useOperatingAreas };
