/* eslint-disable prettier/prettier */
import firebase from 'firebase';
import { SelectedInsurance } from 'models/insurance/SelectedInsurance';
import { Booking } from 'models/booking/Booking';
import { SearchObject } from 'models/sharedData/SearchObject';
import { BookingStatus } from 'models/booking/BookingStatus';
import FirestoreService from './firestore.service';
import { DocumentReference, firestore, functions } from './persistence';
import { convertDateToTimezone } from './moment.service';

const BookingCollection = 'booking';
const RequestBookingCollection = 'request_booking';
const UsersCollection = 'users';
const ExtrasCollection = 'extras';
const AssetsCollection = 'assets';
const InsurancesCollection = 'insurances';

const BookingService = {
    /**
     * GET all bookings
     */
    async getAll(criteria?: any) {
        return functions.httpsCallable('searchBookings')(criteria || {});
    },

    // Get booking by id
    async get({ bookId }: { bookId: string }): Promise<Booking | null> {
        const result = await firestore.collection(BookingCollection).doc(bookId).get();
        if (!result.exists) return null;
        return { id: bookId, ...result.data() } as Booking;
    },

    getBookingSnapshot: (
        id: string,
        onNext: (snapshot: firebase.firestore.DocumentSnapshot) => void,
        onError?: (error: Error) => void,
    ) => {
        return firestore.collection(BookingCollection).doc(id).onSnapshot(onNext, onError);
    },

    /* Create booking
     *
     * */
    async create(
        uid: string,
        assetId: string,
        start?: SearchObject,
        end?: SearchObject,
        paymentOption?: string,
        extras?: string[],
        insurances?: SelectedInsurance[],
        paymentSource?: { profile: string; methodRef?: DocumentReference },
        operatingCityRef?: DocumentReference,
    ): Promise<any> {
        const usersRef = firestore.collection(UsersCollection).doc(uid);
        const assetsRef = firestore.collection(AssetsCollection).doc(assetId);
        const bookingCollection = firestore.collection(BookingCollection);
        const bookingRequestCollection = firestore.collection(RequestBookingCollection);
        const extrasRefs =
            extras && extras.length > 0 ? extras.map(extra => firestore.collection(ExtrasCollection).doc(extra)) : null;
        const insurancesRefs =
            insurances &&
            insurances.map(insurance => {
                const insuranceRef = firestore.collection(InsurancesCollection).doc(insurance.id);

                return insurance.selectedOption
                    ? {
                          ref: insuranceRef,
                          selectedOption: insurance.selectedOption,
                      }
                    : { ref: insuranceRef };
            });

        const operatingCityData = (await operatingCityRef?.get())!.data();
        const operatingCityTimezone = operatingCityData?.timezone;

        const convertedStartDate = convertDateToTimezone(start!.date!, operatingCityTimezone);
        const convertedEndDate = convertDateToTimezone(end!.date!, operatingCityTimezone);

        const parseSearchObject = {
            startDate: {
                ...(start!.time ? { time: start!.time } : {}),
                ...(start!.query ? { query: start!.query } : {}),
                ...(start!.location ? { location: start!.location } : {}),
                date: convertedStartDate.toISOString(true),
                location: [start!.location?.latitude || 0, start!.location?.longitude || 0],
            },
            endDate: {
                ...(end!.time ? { time: end!.time } : {}),
                ...(end!.query ? { query: end!.query } : start!.query ? { query: start!.query } : {}),
                ...(end!.location ? { location: end!.location } : {}),
                date: convertedEndDate.toISOString(true),
                location: [end!.location?.latitude || 0, end!.location?.longitude || 0],
            },
        };

        const payments = {
            ...(paymentSource?.profile ? { paymentProfile: paymentSource?.profile } : {}),
            ...(paymentSource?.methodRef ? { paymentMethodRef: paymentSource?.methodRef } : {}),
        };

        const request = {
            action: 'create',
            status: 'pending',
            usersRef,
            assetsRef,
            creationDate: new Date(),
            ...(paymentOption ? { paymentOption } : {}),
            ...(extrasRefs ? { extras: extrasRefs } : {}),
            ...(insurancesRefs ? { insurances: insurancesRefs } : {}),
            ...parseSearchObject,
            ...payments,
        };

        const expected = {
            success: [BookingStatus.BOOKED, BookingStatus.SECURITY_DEPOSIT_PENDING],
            failure: [BookingStatus.PAYMENT_FAILED, BookingStatus.CANCELLED],
        };

        const response: any = await FirestoreService.createSnapshotRequest(bookingRequestCollection, request);

        const doc = bookingCollection.doc(response.data.bookingRef.id);
        return FirestoreService.createParentRequestSnapshot(response, doc, expected);
    },

    checkActiveBooking(id: string, callback: any) {
        return firestore
            .collection(BookingCollection)
            .where('userDetails.id', '==', id)
            .where('status', '==', 'active')
            .limit(1)
            .onSnapshot((snap: any) => {
                const idSnapshot = snap.docs.length > 0 ? snap.docs[0].idSnapshot : null;
                callback(id);
            }, console.error);
    },

    /** Create a doc on request_booking collection
     * @param uid
     * @param action
     * @param booking
     * @returns document in request_booking collection
     * */
    requestBookingAction: (uid: any, action: string, booking: any) => {
        try {
            const { assetDetails } = booking;
            const usersRef = firestore.collection(UsersCollection).doc(uid);
            const assetsRef = firestore.collection(AssetsCollection).doc(assetDetails.id);
            const bookingRef = firestore.collection(BookingCollection).doc(booking.id);
            const bookingRequestCollection = firestore.collection(RequestBookingCollection);

            const request = {
                bookingRef,
                usersRef,
                assetsRef,
                action,
                status: 'pending',
            };

            return FirestoreService.createSnapshotRequest(bookingRequestCollection, request);
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    requestBookingPrice: async (data: any, uid?: string) => {
        const { assetId, startDate, endDate, extras, insurances } = data;
        const assetRef = firestore.collection('assets').doc(assetId);
        const userRef = firestore.collection('users').doc(uid);

        const bookingPriceRequestRef = await firestore.collection('request_booking_price').add({
            status: 'pending',
            issuedBy: userRef,
            createdDate: new Date(),
            updatedDate: new Date(),
            data: {
                assetRef,
                startDate: startDate.toISOString(true),
                endDate: endDate.toISOString(true),
                extras: extras || [],
                insurances: insurances || [],
            },
        });

        return new Promise((resolve, reject) => {
            // wait for the snapshot to be resolved and the value to change
            let timeout: any;

            const unsubscribe = bookingPriceRequestRef.onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
                const dataSnapshot = snapshot.data();
                switch (dataSnapshot && dataSnapshot.status) {
                    case 'accepted':
                        unsubscribe();
                        resolve(dataSnapshot);
                        clearTimeout(timeout);
                        break;
                    case 'failure':
                        unsubscribe();
                        reject({});
                        clearTimeout(timeout);
                        break;
                    default:
                }
            });

            timeout = setTimeout(() => {
                unsubscribe();
                reject({});
                clearTimeout();
            }, 10000);
        });
    },
};

export default BookingService;
