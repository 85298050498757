import React, { useEffect, useState, useMemo } from 'react';
import { joinClasses } from 'helpers/joinClasses';
import styles from './index.module.css';
import Column from '../column';
import { Input } from '../input';
import Row from '../row';
import Dropdown from '../dropdown';
import ErrorText from '../text/error';

const phoneCodes = require('./phoneCodes.json');

type Option = {
    label: string;
    value: string;
};

export type PhoneObject = {
    countryCode: string;
    formatted: string;
    phone: string;
    phoneCode: string;
};

export type PhoneInputProps = {
    value?: string;
    includedCountries?: string[];
    excludedCountries?: string[];
    country?: any;
    label?: string;
    onChange?: (data: PhoneObject) => void;
    placeholder?: string;
    errorMessage?: string;
    onBlur?: () => void;
    disabled?: boolean;
    required?: boolean;
    className?: string;
};

export function PhoneInput(props: PhoneInputProps) {
    const { className, country, value } = props;

    const [activeStyle, setActiveStyle] = useState(styles.inputBlur);
    const [platformStyle] = useState(styles.web);
    const [option, setOption] = useState<Option>({} as Option);
    const [phone, setPhone] = useState('');

    const options = useMemo(handleCountryList, []);

    useEffect(() => {
        let selectedCountry = options.find((item: any) =>
            country ? item.value.trim().toLowerCase() === country.trim().toLowerCase() : item.value === 'US',
        );

        if (!selectedCountry) {
            selectedCountry = {
                value: 'US',
                label: `US (1)`,
            };
        }
        const defaultPhone = value ? formatPhone(value, phoneCodes[selectedCountry.value]) : '';

        setOption(selectedCountry);
        setPhone(defaultPhone);
    }, [options, country, value]);

    function isNumber(input: any) {
        return new RegExp('^[0-9]*$').test(input);
    }

    function sortByKey(a: any, b: any, key: string) {
        if (a[key] > b[key]) {
            return 1;
        }
        if (a[key] < b[key]) {
            return -1;
        }
        return 0;
    }

    function handleCountryList() {
        return Object.keys(phoneCodes)
            .filter(
                (key: any) =>
                    key &&
                    phoneCodes[key] &&
                    phoneCodes[key].trim() !== '' &&
                    !(phoneCodes[key].indexOf('and') > -1) &&
                    !(phoneCodes[key].indexOf('-') > -1) &&
                    (props.includedCountries ? props.includedCountries.includes(key) : true) &&
                    (props.excludedCountries ? !props.excludedCountries.includes(key) : true),
            )
            .map((key: any) => ({
                value: key,
                label: `${key} (${phoneCodes[key]})`,
            }))
            .sort((a: any, b: any) => sortByKey(a, b, 'label'));
    }

    function formatPhone(phoneNumber: string, codeToRemove?: string) {
        if (!phoneNumber) {
            return '';
        }

        const hasSymbol = phoneNumber.indexOf('+') > -1;

        if (codeToRemove) {
            return hasSymbol ? phoneNumber.replace(`+${codeToRemove}`, '') : phoneNumber;
        }

        return (hasSymbol ? '' : '+') + phoneNumber;
    }

    function _onChange(event: any) {
        const newNumber = event.target.value || '';
        if (isNumber(newNumber)) {
            setPhone(newNumber);
            if (props.onChange) {
                props.onChange({
                    countryCode: option.value,
                    phoneCode: formatPhone(phoneCodes[option.value]),
                    phone: newNumber,
                    formatted: formatPhone(phoneCodes[option.value] + newNumber),
                });
            }
        }
    }

    function _onCountryChange(countrySet: any) {
        setOption(countrySet);
        if (props.onChange) {
            props.onChange({
                countryCode: countrySet.value,
                phoneCode: formatPhone(phoneCodes[countrySet.value]),
                phone,
                formatted: formatPhone(phoneCodes[countrySet.value] + phone),
            });
        }
    }

    function onFocus() {
        setActiveStyle(styles.inputFocus);
    }

    function onBlur() {
        setActiveStyle(styles.inputBlur);
    }

    function onMouseEnter() {
        setActiveStyle(styles.mouseIn);
    }

    function onMouseLeave() {
        setActiveStyle(styles.mouseOut);
    }

    return (
        <Column>
            <Row className={joinClasses(styles.phoneInputContainer, activeStyle)}>
                <Dropdown
                    value={option}
                    className={joinClasses(styles.phoneInputSelect, platformStyle, className || '')}
                    options={options}
                    onChange={_onCountryChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />

                <Input
                    name="phone"
                    className={styles.phoneInputText}
                    placeholder={props.placeholder}
                    onChange={_onChange}
                    value={phone}
                    disabled={props.disabled}
                    required={props.required}
                    hideError
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            </Row>
            <ErrorText>{props.errorMessage}</ErrorText>
        </Column>
    );
}
