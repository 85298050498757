import { Box, BoxProps, ResponsiveValue } from '@chakra-ui/react';
import React from 'react';
import styles from './index.module.css';

function Splitter({ spacing, style, noMargin, className, ...rest }: SplitterProps) {
    function getSize() {
        switch (spacing) {
            case 'small':
                return styles.small;
            case 'normal':
                return styles.normal;
            case 'medium':
                return styles.medium;
            case 'large':
                return styles.large;
            case 'xlarge':
                return styles.xlarge;
            case 'xxlarge':
                return styles.xxlarge;
            case 'xxxlarge':
                return styles.xxxlarge;
            case 'xl-space':
                return styles.xlspace;
            default:
                return styles.medium;
        }
    }

    const componentStyle = [styles.splitter, style, noMargin ? styles.noMargin : '', className, getSize()];

    return <Box className={componentStyle.join(' ')} {...rest} />;
}

export default Splitter;

export interface SplitterProps extends BoxProps {
    style?: any;
    spacing?: 'small' | 'normal' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge' | 'xl-space';
    noMargin?: boolean;
    className?: any;
    display?: ResponsiveValue<any>;
}
