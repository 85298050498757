import React from 'react';
import { ReactComponent as Icon } from '../../../../assets/icons/calendar.svg';

export default class CalendarIcon extends React.Component<any> {
    render() {
        const { color } = this.props;

        switch (color) {
            default:
                return <Icon {...this.props} />;
        }
    }
}
