import { useOperatingAreas } from 'hooks';
import React, { useState } from 'react';
import MapService from '../../../../services/map.service';
import Autocomplete from '../../../base/v1/autocomplete';
import { AddressAutocompleteProps, MapServiceResult, PlaceOption } from './types';

function AddressAutocomplete(props: AddressAutocompleteProps) {
    const { onInputSelected, onInputBlur, disabled, placeholder } = props;

    const { operatingCity } = useOperatingAreas();

    const [apiResult, setApiResult] = useState<PlaceOption[]>([]);

    const handleChange = (term: string) => {
        props.onChange?.(term);

        if (term === '') {
            setApiResult([]);
            return;
        }

        if (term.length < 3) {
            return;
        }

        MapService.search(term, operatingCity!.id!).then((result: MapServiceResult) => {
            const features = result.features.map((f: any) => {
                return { label: f.place_name, value: f.center };
            });
            setApiResult(features);
        });
    };

    return (
        <Autocomplete
            suggestions={apiResult}
            onChange={handleChange}
            onInputSelected={onInputSelected}
            onBlur={onInputBlur}
            value={props.value}
            focusOnRender={props.focusOnRender}
            disabled={disabled}
            placeholder={placeholder}
        />
    );
}

export default AddressAutocomplete;
