import { FlexProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

interface PopoverProps extends FlexProps {
    maximumWidth?: boolean;
}

export const styles: ChakraStyles<PopoverProps> & any = {
    container: (maximumWidth?: boolean) => ({
        height: '100%',
        ...(maximumWidth && { width: '100%' }),
    }),
};
