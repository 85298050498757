import { TextProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<TextProps> = {
    title: {
        fontWeight: 'medium',
        fontSize: { base: '20px', md: '24px' },
    },
};

export default styles;
