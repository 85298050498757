import { BookingCancellationConfig } from 'hooks/remoteConfig';
import { howDoesRummoWorkSection } from './howDoesRummoWorkSection';
import { makingABookingSection } from './makingABookingSection';
import { cancellingOrChangingSection } from './cancellingOrChangingSection';
import { duringRummoTripSection } from './duringRummoTripSection';
import { afterRummoTripSection } from './afterRummoTripSection';
import { citationsAndTollsSection } from './citationsAndTollsSection';

interface QABody {
    title: string;
    content: JSX.Element | string;
}

interface QuestionsAndAnswers {
    key: string;
    title: string;
    description?: string;
    body: QABody[];
    cancellationTime?: BookingCancellationConfig;
}

enum FAQSections {
    HOW_DOES_RUMMO_WORK = 'how_does_rummo_work',
    MAKING_A_BOOKING = 'making_a_booking',
    CANCELLING_OR_CHANGING_RESERVATION = 'cancelling_or_changing_reservation',
    DURING_RUMMO_TRIP = 'during_rummo_trip',
    AFTER_RUMMO_TRIP = 'after_rummo_trip',
    CITATIONS_AND_TOLLS = 'citations_and_tolls',
}

export function questionsAndAnswers(bookingCancellationConfig: BookingCancellationConfig) {
    const questionsAndAnswersArray: QuestionsAndAnswers[] = [
        { key: FAQSections.HOW_DOES_RUMMO_WORK, ...howDoesRummoWorkSection },
        { key: FAQSections.MAKING_A_BOOKING, ...makingABookingSection },
        {
            key: FAQSections.CANCELLING_OR_CHANGING_RESERVATION,
            ...cancellingOrChangingSection(bookingCancellationConfig),
        },
        { key: FAQSections.DURING_RUMMO_TRIP, ...duringRummoTripSection },
        { key: FAQSections.AFTER_RUMMO_TRIP, ...afterRummoTripSection },
        { key: FAQSections.CITATIONS_AND_TOLLS, ...citationsAndTollsSection },
    ];

    return questionsAndAnswersArray;
}
