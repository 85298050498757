export enum RemoteConfigKeys {
    LOGIN_CONFIG = 'login_config',
    SIGNUP_CONFIG = 'signup_config',
    SEARCH_CONFIG = 'search_config',
    ASSET_PAGE_CONFIG = 'asset_page_config',
    DOCUMENT_VALIDATION_CONFIG = 'document_validation_config',
    PASSWORD_RECOVERY_CONFIG = 'password_recovery_config',
    PAYMENTS_CONFIG = 'payments_config',
    BOOKING_CANCELLATION_CONFIG = 'booking_cancellation_config',
    MODIFICATIONS_TIME_CONFIG = 'modifications_time_config',
}
