import { BoxProps, TextProps, ImageProps, ButtonProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

interface DynamicStylesProps {
    refLabel: (color: string) => TextProps;
}

export const styles: ChakraStyles<BoxProps & ImageProps & ButtonProps & TextProps> = {
    container: {
        display: 'flex',
        borderBottom: '1px solid var(--color-gray-lighter)',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: '2rem',
        paddingBottom: '2rem',
        marginRight: '1rem',
    },

    card: {
        display: 'flex',
        alignItems: 'center',
        flex: 'content',
    },

    linksContainer: {
        display: { base: 'none', md: 'flex', lg: 'flex' },
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },

    detailsContainer: {
        display: 'flex',
        flex: 'auto',
        paddingLeft: '1rem',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '215px',
        height: '96px',
    },

    cardContainer: {
        width: { base: '104px', md: '160px', lg: '224px' },
    },

    imageContainer: {
        height: { base: '59.75px', md: '113.16px', lg: '124.48px' },
    },

    pastImg: {
        filter: 'grayscale(100%)',
    },

    pastText: {
        color: 'var(--color-grayish)',
    },

    bookingRef: {
        display: { base: 'none', lg: 'inline' },
        margin: '0',
        fontWeight: 'var(--font-weight-light)',
        color: 'var(--color-red)',
        fontSize: 'var(--font-xsmall)',
        lineHeight: 'var(--line-xsmall)',
        marginBottom: 'var(--xs_space)',
    },

    model: {
        marginBottom: { base: 'var(--xs_space)', md: 'var(--margin-internal)' },
        lineHeight: {
            base: 'var(--line)',
            sm: 'var(--line-medium)',
            lg: 'var(--line-large)',
            '2xl': 'var(--line-xxlarge)',
        },
        fontSize: {
            base: 'var(--font)',
            sm: 'var(--font-medium)',
            lg: 'var(--font-large)',
            '2xl': 'var(--font-xxlarge)',
        },
        fontWeight: 'var(--font-weight-semibold)',
        margin: '0',
    },

    renting: {
        fontWeight: 'var(--font-weight-light)',
        fontSize: 'var(--font-xsmall)',
        margin: '0',
        lineHeight: 'var(--line-xsmall)',
        marginBottom: 'var(--xs_space)',
    },

    date: {
        fontWeight: 'var(--font-weight-light)',
        fontSize: 'var(--font-xsmall)',
        margin: '0',
        lineHeight: 'var(--line-xsmall)',
        marginBottom: 'var(--xs_space)',
    },

    refNumber: {
        fontSize: 'var(--font-xsmall)',
    },

    detailsButton: {
        colorScheme: 'none',
        fontWeight: 'normal',
        height: 'auto',
        lineHeight: 'var(--line)',
        display: { base: 'none', md: 'inline-block' },
        padding: '10px 24px',
        backgroundColor: 'var(--color-red)',
        borderRadius: '48px',
        color: 'var(--color-white)',
        fontSize: 'var(--font)',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        width: { md: '146px', lg: '204px' },
    },
};

export const dynamicStyles: DynamicStylesProps = {
    refLabel: (color: string) => ({
        fontSize: {
            base: 'var(--font-xxsmall)',
            sm: '10px',
            md: 'var(--font-xxsmall)',
        },
        color: 'var(--color-white)',
        textAlign: 'center',
        lineHeight: { base: 'var(--line)', md: 'var(--line-xsmall)' },
        fontWeight: 'var(--font-weight-regular)',
        padding: '5px 5px',
        borderRadius: '0px 0px 5px 5px',
        backgroundColor: `${color}`,
    }),
};
