import React from 'react';
import { useAuth } from 'hooks';
import { HeaderMenu } from './menu';
import { SignButtonsSection } from './signButtonsSection';

export function HeaderAuth() {
    const { loading, auth } = useAuth();

    if (loading) {
        return null;
    }

    if (auth) {
        return <HeaderMenu />;
    }
    return <SignButtonsSection />;
}
