import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Column from 'components/base/column';
import Splitter from 'components/base/splitter';
import { H3 } from 'components/base/text/h3';

import { useOperatingAreas, useSharedData } from 'hooks';
import i18n from 'language/i18n';
import { Asset } from 'services/asset.service';

import Loading from 'components/base/loading';
import AssetListItem from '../list-item';
import styles from './index.module.css';

export type AssetListProps = {
    assets: Asset[];
    onAssetClick?: (asset: Asset) => void;
    loading?: boolean;
};

export default function AssetList(props: AssetListProps) {
    const { onAssetClick } = props;
    const { locationIsAvailable, openWarningModal } = useOperatingAreas();
    const history = useHistory();
    const { sharedDataService } = useSharedData();

    useEffect(() => {
        sharedDataService.unsetExtras();
        sharedDataService.unsetInsurances();
    }, []);

    function reserveRummo(asset: Asset) {
        if (locationIsAvailable) {
            onAssetClick ? onAssetClick(asset) : history.push(`/assets/${asset.id}`);
            return;
        }

        openWarningModal();
    }

    function renderAsset(asset: Asset, index: number) {
        return (
            <React.Fragment key={index}>
                <AssetListItem onClick={reserveRummo} asset={asset} loading={props.loading} />
                <Splitter className={styles.splitter} />
            </React.Fragment>
        );
    }

    function renderAssets() {
        const { assets } = props;
        return <div>{assets.map(renderAsset)}</div>;
    }

    function renderNoResults() {
        return (
            <Column className={styles.noResultsContainer}>
                <H3>{i18n.t('sections.assetList.noResults')}</H3>
            </Column>
        );
    }

    if (props.loading) {
        return (
            <Column className={styles.containerSpinner}>
                <Loading spinnerSize={40} halfScreen />
                {/* to be determined */}
                {/* <Text className={styles.spinnerText}>Awesome content is being loaded</Text> */}
            </Column>
        );
    }

    return props.assets && props.assets.length > 0 ? renderAssets() : renderNoResults();
}
