import { TimeSlots } from 'models/sharedData/TimeSlots';
import { Moment } from 'moment';
import { FocusedInputShape } from 'react-dates';

/**
 * Calculates the duration between the two provided dates
 *
 *  Code used in different components to handle the calendar behaviour related to the booking dates
 *
 */

export type DateChange = { startDate: Moment | null; endDate: Moment | null };

export class BookingCalendarDatesChangesInformation {
    newStartDate: Moment | null;
    newEndDate: Moment | null;
    previousStartDate: Moment | null;
    previousEndDate: Moment | null;
    startTime: TimeSlots;
    endTime: TimeSlots;
    dateRangeFocus: FocusedInputShape | null;
    bookingMaximumDuration: number;
    updateStartLimit: (startDate?: moment.Moment | null | undefined) => TimeSlots;
    isFocusedDateVisible?: boolean;

    constructor(props: BookingCalendarDatesChangesInformation) {
        const {
            newStartDate,
            newEndDate,
            previousStartDate,
            startTime,
            previousEndDate,
            endTime,
            dateRangeFocus,
            bookingMaximumDuration,
            updateStartLimit,
            isFocusedDateVisible,
        } = props;
        this.newStartDate = newStartDate;
        this.newEndDate = newEndDate;
        this.previousStartDate = previousStartDate;
        this.startTime = startTime;
        this.previousEndDate = previousEndDate;
        this.endTime = endTime;
        this.dateRangeFocus = dateRangeFocus;
        this.bookingMaximumDuration = bookingMaximumDuration;
        this.updateStartLimit = updateStartLimit;
        this.isFocusedDateVisible = isFocusedDateVisible;
    }
}

export type ReturnBookingCalendarDatesChangesInformation = {
    startTime: TimeSlots;
    endTime: TimeSlots;
    startDate: Moment | null;
    endDate: Moment | null;
    focusedDate: FocusedInputShape | null;
};

export function handleBookingCalendarDatesChanges(params: BookingCalendarDatesChangesInformation) {
    const {
        newStartDate,
        newEndDate,
        previousStartDate,
        startTime,
        previousEndDate,
        endTime,
        dateRangeFocus,
        bookingMaximumDuration,
        updateStartLimit,
        isFocusedDateVisible,
    } = params;

    const returnObj: ReturnBookingCalendarDatesChangesInformation = {
        startTime,
        endTime,
        startDate: previousStartDate,
        endDate: previousEndDate,
        focusedDate: dateRangeFocus,
    };

    if (dateRangeFocus === 'startDate') {
        returnObj.focusedDate = 'endDate';
    }

    if (
        (dateRangeFocus === 'endDate' && newStartDate === null) ||
        (dateRangeFocus === 'endDate' && isFocusedDateVisible === false)
    ) {
        returnObj.focusedDate = 'startDate';
    }

    if (dateRangeFocus === 'endDate') {
        returnObj.focusedDate = 'startDate';
    }

    if (!newStartDate?.isSame(returnObj.startDate)) {
        const outOfRange = newStartDate?.isBefore(
            returnObj.endDate?.clone().subtract(bookingMaximumDuration - 1, 'days'),
        );

        if (outOfRange || newStartDate?.isAfter(returnObj.endDate)) {
            returnObj.endDate = null;
            returnObj.endTime = '09:00';
            returnObj.focusedDate = 'endDate';
        }

        const updatedStartTime = updateStartLimit(newStartDate);
        returnObj.startDate = newStartDate;
        returnObj.startTime = updatedStartTime;

        return returnObj;
    }

    if (!newEndDate?.isSame(returnObj.endDate)) {
        returnObj.endTime = '09:00';
        returnObj.endDate = newEndDate;

        return returnObj;
    }

    return returnObj;
}
