import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useHistory } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../base/icon/arrowLeft';
import ChevronLeftIcon from '../../../base/icon/chevron-left';
import ChevronRightIcon from '../../../base/icon/chevron-right';
import { ShareIcon } from '../../../base/icon/share';
import Photo from '../../../base/photo';
import Row from '../../../base/row';
import styles from './index.module.css';
import { Asset } from '../../../../services/asset.service';

function AssetDetailsHeader(props: AssetDetailsHeaderProps) {
    const { photos, displayPhoto } = props.asset;
    const carouselPhotos: string[] = [];

    const history = useHistory();

    /**
     *
     */
    function renderSlide() {
        if (photos && photos.length > 1) {
            for (const photo of photos) carouselPhotos.push(photo);
            if (displayPhoto) carouselPhotos.unshift(displayPhoto);

            const renderCarouselPhotos = carouselPhotos.map((photo: string, index: number) => {
                return <Photo key={index} className={styles.headerPhoto} size="relative" wide src={photo} />;
            });

            return renderCarouselPhotos;
        }

        if (!carouselPhotos || carouselPhotos.length === 0) {
            return (
                <Photo
                    size="relative"
                    wide
                    alt={displayPhoto ? 'display-photo' : 'default-photo'}
                    src={displayPhoto || ''}
                />
            );
        }
    }

    function renderArrowPrev(clickHandler: () => void, hasPrev: boolean, label: string) {
        if (carouselPhotos && carouselPhotos.length > 1) {
            return (
                <BrowserView viewClassName={[styles.arrow, styles.arrowPrev].join(' ')}>
                    <div className={styles.arrowWrapper} onClick={clickHandler}>
                        <ChevronLeftIcon className={styles.icon} />
                    </div>
                </BrowserView>
            );
        }
    }

    function renderArrowNext(clickHandler: () => void, hasPrev: boolean, label: string) {
        if (carouselPhotos && carouselPhotos.length > 1) {
            return (
                <BrowserView viewClassName={[styles.arrow, styles.arrowNext].join(' ')}>
                    <div className={styles.arrowWrapper} onClick={clickHandler}>
                        <ChevronRightIcon className={styles.icon} />
                    </div>
                </BrowserView>
            );
        }
    }

    async function onShareButtonClick() {
        const nav: any = navigator;
        const { asset } = props;

        if (nav.share) {
            // this only works on mobile devices (phone, tablets)
            // if on desktop you should use internal components to create an hardcoded share capability
            try {
                await nav.share({
                    title: `Rummo | ${asset.brand} - ${asset.model}`,
                    url: `https://rummo-development-001.web.app/assets/${asset.id}`,
                });
            } catch (err) {
                console.log(err);
            }
        } else {
            // fallback to show share options on desktop
            console.log('Device not capable of using Share API...');
        }
    }

    return (
        <div className={styles.container}>
            <MobileView viewClassName={styles.actionOverlay}>
                <Row className={styles.overlayIconContainer}>
                    <div>
                        <ArrowLeftIcon className={styles.arrowBack} onClick={history.goBack} />
                    </div>
                    <div>
                        {/* <HeartIcon className={styles.like}/> */}
                        <ShareIcon className={styles.share} onClick={onShareButtonClick} />
                    </div>
                </Row>
            </MobileView>
            <Carousel
                className={styles.headerCarousel}
                infiniteLoop={carouselPhotos?.length > 1}
                showArrows={carouselPhotos?.length > 1}
                showThumbs={false}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
                showStatus={false}
            >
                {renderSlide()}
            </Carousel>
        </div>
    );
}

export type AssetDetailsHeaderProps = {
    asset: Asset;
};

export default AssetDetailsHeader;
