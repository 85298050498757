import { firestore as firebaseFirestore } from 'firebase';
import { statusCodes } from '../configuration/statusCodes';
import { CollectionReference, DocumentReference, DocumentSnapshot, firestore } from './persistence';

export interface FirebaseFilter {
    field: string | firebaseFirestore.FieldPath;
    operator: firebaseFirestore.WhereFilterOp;
    value: any;
    orderBy?: {
        order: 'asc' | 'desc';
        field: string;
    };
}

const FirestoreService = {
    async getAll(collection: string, filter?: FirebaseFilter) {
        try {
            let query = firestore.collection(collection) as firebaseFirestore.Query<firebaseFirestore.DocumentData>;

            if (filter) {
                const { field, operator, value, orderBy } = filter;
                query = query.where(field, operator, value);
                if (orderBy) {
                    query = query.orderBy(orderBy.field, orderBy.order);
                }
            }

            const result = await query.get();

            if (result.empty) {
                return null;
            }

            return [
                ...result.docs.map(d => {
                    const { id } = d;
                    const dataObj = d.data();
                    return { ...dataObj, id };
                }),
            ] as any;
        } catch (err) {
            console.log(err);
            return null;
        }
    },

    async get(collection: string | CollectionReference, uid: string) {
        try {
            const col = typeof collection === 'string' ? firestore.collection(collection) : collection;
            const result = await col.doc(uid).get();
            if (!result.exists) return null;

            const res: any = result.data();
            res.id = result.id;

            return res;
        } catch (err) {
            console.log(err);
            return null;
        }
    },

    createParentRequestSnapshot(
        request: any,
        document: DocumentReference,
        expected?: { success: string[]; failure: string[] },
    ) {
        let timeout: any;
        let subscribe: any;

        const clean = () => {
            subscribe && subscribe();
            clearTimeout(timeout);
        };

        return new Promise(async (resolve, reject) => {
            try {
                subscribe = document.onSnapshot(
                    res => {
                        const data = res.data();
                        if (data && expected) {
                            if (expected?.success.includes(data.status)) {
                                resolve(request);
                                clean();
                            }
                            if (expected?.failure.includes(data.status)) {
                                reject({
                                    status: 'failure',
                                    code: 'snap-failed',
                                    data,
                                });
                                clean();
                            }
                        }
                        timeout = setTimeout(() => {
                            reject({ status: 'failure', code: 'snap-timeout' });
                            clean();
                        }, 40000);
                    },
                    err => {
                        reject(err);
                        clean();
                    },
                );
            } catch (err) {
                reject({ status: 'failure', code: 'snap-failed', err });
                clean();
            }
        });
    },

    createSnapshotRequest: (
        collection: CollectionReference,
        requestData: any,
        successStatus?: string,
        errorStatus?: string,
        timeoutValue?: number,
    ) => {
        return new Promise(async (resolve, reject) => {
            let timeout: any;
            let subscribe: any;
            const STATUS_SUCCESS = successStatus || statusCodes.ACCEPTED;
            const STATUS_ERROR = errorStatus || statusCodes.FAILURE;

            const clean = () => {
                subscribe && subscribe();
                clearTimeout(timeout);
            };

            try {
                const reference = await collection.add({
                    status: 'pending',
                    creationDate: new Date(),
                    ...requestData,
                });

                subscribe = reference.onSnapshot(
                    (snap: DocumentSnapshot) => {
                        const data: any = { ...snap.data(), id: snap.id };

                        if (!snap.exists) {
                            reject({
                                status: STATUS_ERROR,
                                code: 'snap-deleted',
                            });
                            clean();
                        }
                        if (data.status === STATUS_SUCCESS) {
                            resolve({
                                status: STATUS_SUCCESS,
                                code: 'snap-success',
                                data,
                            });
                            clean();
                        }
                        if (data.status === STATUS_ERROR) {
                            reject({
                                status: STATUS_ERROR,
                                code: 'snap-failed',
                                data,
                            });
                            clean();
                        }
                        timeout = setTimeout(() => {
                            reject({
                                status: STATUS_ERROR,
                                code: 'snap-timeout',
                            });
                            clean();
                        }, timeoutValue || 25000);
                    },
                    function (err) {
                        reject({ status: 'failure', code: 'internal' });
                        console.log(err);
                    },
                );
            } catch (err) {
                console.log(err);
                reject({ status: 'failure', code: 'internal' });
                clean();
            }
        });
    },

    getAllSnapshot(
        collection: string | CollectionReference,
        onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
        filter?: FirebaseFilter,
        onError?: (error: Error) => void,
    ) {
        try {
            const col = typeof collection === 'string' ? firestore.collection(collection) : collection;
            let query = col as firebaseFirestore.Query<firebaseFirestore.DocumentData>;

            if (filter) {
                const { field, operator, value, orderBy } = filter;
                query = query.where(field, operator, value);
                if (orderBy) {
                    query = query.orderBy(orderBy.field, orderBy.order);
                }
            }

            return query.onSnapshot(onNext, onError);
        } catch (err) {
            console.log(err);
            return null;
        }
    },
};

export default FirestoreService;
