import { InputProps, ButtonProps, TextProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

interface DynamicStylesProps {
    title: (centerTitle?: boolean, fontSize?: string) => TextProps;
}

const dynamicStyles: DynamicStylesProps = {
    title: (centerTitle?: boolean, fontSize?: string) => ({
        p: 0,
        flex: 1,
        fontSize: fontSize || {
            base: 'var(--font-large)',
            lg: 'var(--font-xxlarge)',
        },
        lineHeight: 'var(--line)',
        fontWeight: 'var(--font-weight-medium)',
        bottom: 0,
        textAlign: centerTitle ? 'center' : 'start',
    }),
};

const styles: ChakraStyles<InputProps & ButtonProps & TextProps> = {
    content: {
        p: 7,
        borderRadius: 'xl',
    },
    body: {
        p: 6,
        pb: 0,
        mx: -6,
        display: 'flex',
        flex: 1,
        flexDir: 'column',
    },

    close: {
        position: 'relative',
        top: 0,
        right: 0,
        bottom: 0,
        width: '16px',
    },
};

export default { ...styles, ...dynamicStyles } as ChakraStyles & DynamicStylesProps;
