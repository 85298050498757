import React, { useEffect, useState } from 'react';
import { useAuth, useRemoteConfig } from 'hooks';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar.svg';
import { ReactComponent as HelpIcon } from '../../../../../assets/icons/help-circle.svg';
import { ReactComponent as PowerIcon } from '../../../../../assets/icons/power.svg';
import i18n from '../../../../../language/i18n';
import authService from '../../../../../services/auth.service';
import Column from '../../../../base/column';
import Margin from '../../../../base/margin';
import DrawerMenuItem from '../item';
import { ProfileReference } from '../profileRef';

import { SignModalTypes } from '../../header/auth/signButtonsSection';

import CSSStyles from './index.module.css';
import { SignButtons } from '../../header/auth/signButtonsSection/signButtons';

export type DrawerMenuProps = {
    open?: boolean;
    onClose?: () => void;
    onClick?: (modalType: SignModalTypes) => void;
};

function DrawerMenu(props: DrawerMenuProps) {
    const [open, setOpen] = useState(false);
    const { auth, loading } = useAuth();
    const { loginEnabled, signupEnabled } = useRemoteConfig();

    const { onClick } = props;

    useEffect(() => {
        setOpen(!!props.open);
        return () => setOpen(false);
    }, [props.open]);

    function onAuthButtonClick(signType: SignModalTypes) {
        onClick && onClick(signType as SignModalTypes);
    }

    function onClose() {
        props.onClose && props.onClose();
    }

    function renderWhenAuthenticated() {
        if (loading || !auth) {
            return null;
        }

        return (
            <>
                <ProfileReference onProfileLinkClick={onClose} />
                <Margin />
                <DrawerMenuItem to="/booking" icon={<CalendarIcon />} onClick={onClose}>
                    {i18n.t('sidebar.option.booking')}
                </DrawerMenuItem>
                <DrawerMenuItem to="/help" icon={<HelpIcon />} onClick={onClose}>
                    {i18n.t('sidebar.option.help')}
                </DrawerMenuItem>
                <Margin />
                <DrawerMenuItem icon={<PowerIcon />} onClick={authService.logout}>
                    {i18n.t('pages.profile.logoutBtn')}
                </DrawerMenuItem>
            </>
        );
    }

    function renderWhenAnonymous() {
        if (loading || auth) {
            return null;
        }

        const displayButton = loginEnabled || signupEnabled;
        return (
            <>
                {displayButton && <SignButtons onClick={onAuthButtonClick} />}
                <DrawerMenuItem to="/" onClick={onClose} isMobile>
                    {i18n.t('sidebar.option.rentARummo')}
                </DrawerMenuItem>
                <DrawerMenuItem to="/business" onClick={onClose} isMobile>
                    {i18n.t('sidebar.option.forBusinessSection')}
                </DrawerMenuItem>
                <DrawerMenuItem to="/faq" onClick={onClose} isMobile>
                    {i18n.t('sidebar.option.FAQ')}
                </DrawerMenuItem>
            </>
        );
    }

    return (
        <>
            <div className={[CSSStyles.overlay, open ? CSSStyles.open : {}].join(' ')} onClick={onClose} />
            <Column className={[CSSStyles.container, open ? CSSStyles.open : {}].join(' ')}>
                {renderWhenAnonymous()}
                {renderWhenAuthenticated()}
            </Column>
        </>
    );
}

export default DrawerMenu;
