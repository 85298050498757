import React from 'react';
import styles from './index.module.css';

function Checkbox(props: CheckboxProps) {
    const { className, checked, labelClassName, label, position, onClick } = props;
    const positionStyle = position === 'right' ? styles.checkmarkRight : styles.checkmarkLeft;

    return (
        <div
            className={[styles.container, positionStyle, className, checked ? styles.checked : ''].join(' ')}
            onClick={onClick}
        >
            <span className={[styles.checkmark, positionStyle, className].join(' ')} />
            <span className={[styles.label, labelClassName].join(' ')}>{label}</span>
        </div>
    );
}

export type CheckboxProps = {
    label?: string;
    checked?: boolean;
    className?: string;
    labelClassName?: string;
    onClick?: () => void;
    position?: 'left' | 'right';
};

export default Checkbox;
