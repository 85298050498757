import React from 'react';
import { Text, ModalBody, DrawerFooter, Button } from '@chakra-ui/react';

import ModalNew from '../modalNew';
import Margin from '../margin';
import styles from './styles';

export interface ModalCancelationProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string | undefined;
    loading?: boolean;
    errorMessage?: boolean;
    sureMessage?: string;
    dontCancelButtonText?: string;
    dontCancelSmallButtonText?: string;
    cancelButtonText?: string;
    cancelSmallButtonText?: string;
    cancelText?: string;
    onClick?: () => void;
}

function ModalCancelation({
    title,
    onClose,
    errorMessage,
    sureMessage,
    cancelText,
    dontCancelButtonText,
    dontCancelSmallButtonText,
    cancelButtonText,
    cancelSmallButtonText,
    loading,
    isOpen,
    onClick,
}: ModalCancelationProps) {
    return (
        <ModalNew
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            size="2xl"
            bodyStyles={styles.cancelModal}
            loading={loading}
        >
            <ModalBody {...styles.modalCancelBody}>
                <Text {...styles.sureMessage}>{sureMessage}</Text>
                <Text>{cancelText}</Text>
                <Margin />
                {errorMessage && <Text>There was an error.</Text>}
            </ModalBody>
            <DrawerFooter {...styles.modalFooter}>
                <Button
                    onClick={onClose}
                    variant="link"
                    colorScheme="black"
                    marginRight="var(--margin-large)"
                    alignSelf="center"
                >
                    <>
                        <Text {...styles.dontCancelTextButton}>{dontCancelButtonText}</Text>
                        <Text {...styles.dontCancelSmallTextButton}>{dontCancelSmallButtonText}</Text>
                    </>
                </Button>
                <Button
                    onClick={onClick}
                    variant="solid"
                    colorScheme="black"
                    size="md"
                    alignSelf="center"
                    disabled={loading}
                >
                    <>
                        <Text {...styles.cancelTextButton}>{cancelButtonText}</Text>
                        <Text {...styles.cancelTextSmallButton}>{cancelSmallButtonText}</Text>
                    </>
                </Button>
            </DrawerFooter>
        </ModalNew>
    );
}

export default ModalCancelation;
