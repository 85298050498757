import React, { useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import PageTemplate from '../../components/base/pageTemplate';
import styles from './index.module.css';
import background from '../../assets/images/backgrounds/business-banner.png';
import { BreadcrumbItem } from '../../components/base/breadcrumb';
import i18n from '../../language/i18n';
import BusinessIntro from '../../components/sections/business/intro';
import Column from '../../components/base/column';
import SectionTitle from '../../components/base/text/sectionTitle';
import BusinessFleets from '../../components/sections/business/fleets';
import Margin from '../../components/base/margin';
import { useWindowSize } from '../../hooks/useWindowSize';

export default function BusinessPage() {
    const { width } = useWindowSize();
    const backgroundResponsiveMaxH = useBreakpointValue({ base: 500, md: 550, xl: 532 });

    const breadcrumbItems: BreadcrumbItem[] = [{ label: 'RUMMO', to: '/' }, { label: 'BUSINESS' }];

    useEffect(() => {
        document.getElementById('root')?.classList.add('overflowXHidden');
        return () => document.getElementById('root')?.classList.remove('overflowXHidden');
    }, []);

    return (
        <PageTemplate
            title="Rummo Business"
            subtitle={i18n.t('page.business.header.subtitle')}
            breadcrumbs={breadcrumbItems}
            templateClassName={styles.page}
            titleClassName={styles.pageTitle}
            breadcrumbsClassName={styles.breadcrumbs}
            background={background}
            backgroundStyles={{ maxH: backgroundResponsiveMaxH }}
        >
            <BusinessIntro />
            {width > 1360 && (
                <a
                    href="https://mqqe7k3fksx.typeform.com/rummobusiness"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.buttonBlack}
                >
                    {i18n.t('page.business.intro.button')}
                </a>
            )}

            <Margin value="56px" />

            <BusinessFleets />

            <Margin value="5rem" />

            <Column className={styles.hero}>
                <SectionTitle className={styles.heroTitle}>{i18n.t('page.business.requestInfo.title')}</SectionTitle>
                <Margin value="32px" />
                <a
                    href="https://mqqe7k3fksx.typeform.com/rummobusiness"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.buttonBlack}
                >
                    {i18n.t('page.business.requestInfo.button')}
                </a>
            </Column>
            <Margin value="7rem" />
        </PageTemplate>
    );
}
