import React from 'react';
import i18n from 'language/i18n';

function ticketsAndTrafficViolationsQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.citationsAndTolls.ticketsAndTrafficViolations.stepOne')}</p>
            <p>{i18n.t('sections.faq.citationsAndTolls.ticketsAndTrafficViolations.stepTwo')}</p>
        </>
    );
}

const body = [
    {
        title: i18n.t('sections.faq.citationsAndTolls.tollsPayment.title'),
        content: i18n.t('sections.faq.citationsAndTolls.tollsPayment.description'),
    },
    {
        title: i18n.t('sections.faq.citationsAndTolls.ticketsAndTrafficViolations.title'),
        content: ticketsAndTrafficViolationsQuestionBody(),
    },
];

export const citationsAndTollsSection = {
    title: i18n.t('sections.faq.citationsAndTolls.title'),
    description: '',
    body,
};
