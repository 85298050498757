import { ButtonProps, InputProps, StackProps, TextProps } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<InputProps & ButtonProps & StackProps & TextProps> = {
    container: {
        mb: 32,
        pos: 'relative',
    },

    grid: {
        alignItems: 'flex-start',
        flexDir: { base: 'column', md: 'row' },
        spacing: 0,
    },

    leftColWrapper: {
        maxW: '800px',
        pr: 8,
        mb: 10,
    },

    rightCol: {
        w: { base: '100%', md: '302px', lg: '400px' },
        pb: { base: 10, md: 0 },
        position: 'sticky',
        top: 'var(--margin-xlarge)',
    },

    tag: {
        border: `1px solid ${colors.grayLight}`,
        color: colors.grayLight,
        padding: '8px 16px',
        borderRadius: '8px',
        height: 'min-content',
        width: 'min-content',
        mb: 10,
    },
    headerTitle: {
        my: 2,
        fontWeight: {
            base: 'var(--font-weight-semibold)',
            xl: 'var(--font-weight-medium)',
        },
        fontSize: { base: 'var(--font-h4)', xl: 'var(--font-h3)' },
        lineHeight: { base: 'var(--line-h4)', xl: 'var(--line-h3)' },
        color: colors.red,
    },
    headerDescription: {
        mb: 4,
        fontWeight: 'var(--font-weight-light)',
        fontSize: 'var(--font)',
        lineHeight: 'var(--line)',
        color: colors.grayLight,
    },
    headerDescriptionBold: {
        mb: 10,
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font)',
        lineHeight: 'var(--line)',
        color: colors.grayLight,
        display: 'initial',
    },
    title: {
        mb: 6,
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font-xlarge)',
        lineHeight: 'var(--line-xlarge)',
    },
    cancelationBodyBold: {
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font)',
        lineHeight: 'var(--line)',
        display: 'initial',
    },
    cancelationBody: {
        fontWeight: 'var(--font-weight-light)',
        fontSize: 'var(--font)',
        lineHeight: 'var(--line)',
        mb: 4,
    },
    cancelationAction: {
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font-small)',
        lineHeight: 'var(--line-small)',
        display: 'initial',
        color: colors.red,
    },
};

export default styles;
