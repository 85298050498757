import React from 'react';
import styles from './index.module.css';
import Row from '../../../base/row';
import { H1 } from '../../../base/text/h1';
import Card from '../../../base/card';
import i18n from '../../../../language/i18n';
import PageContainer from '../../../base/pageContainer';
import Image1 from '../../../../assets/images/design/how-it-works_image1.png';
import Image2 from '../../../../assets/images/design/how-it-works_image2.png';
import Image3 from '../../../../assets/images/design/how-it-works_image3.png';

export default function HowItWorks(props: any) {
    const { reference } = props;

    function renderCard(title: string, image: string, text: string) {
        return (
            <Card className={styles.card}>
                <img alt={title} src={image} />
                <div className={styles.cardBody}>
                    <div className={styles.cardTitle}>
                        <h2>{title}</h2>
                    </div>
                    <div className={styles.cardText}>
                        <h3>{text}</h3>
                    </div>
                </div>
            </Card>
        );
    }

    return (
        <PageContainer>
            <div className={styles.section} ref={reference}>
                <H1 className={styles.header}>{i18n.t('sections.process.title')}</H1>
                <div className={styles.container}>
                    <Row className={styles.row}>
                        {renderCard(
                            i18n.t('sections.process.firstStep.title'),
                            Image1,
                            i18n.t('sections.process.firstStep.description'),
                        )}
                        {renderCard(
                            i18n.t('sections.process.secondStep.title'),
                            Image2,
                            i18n.t('sections.process.secondStep.description'),
                        )}
                        {renderCard(
                            i18n.t('sections.process.thirdStep.title'),
                            Image3,
                            i18n.t('sections.process.thirdStep.description'),
                        )}
                    </Row>
                </div>
            </div>
        </PageContainer>
    );
}
