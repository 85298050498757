import React, { ChangeEvent, useState } from 'react';
import {
    Box,
    Button,
    Center,
    ChakraProps,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
} from '@chakra-ui/react';
import ModalNew from 'components/base/modalNew';
import { CloseIcon } from '@chakra-ui/icons';
import i18n from 'language/i18n';
import { useOperatingAreas } from 'hooks';
import styles from './styles';
import MapService from '../../../../services/map.service';

interface LocationPickerModalProps {
    title?: string;
    isOpen: boolean;
    onClose: () => void;
    onChange?: (label: string, value: number[]) => void;
}

function LocationSearchModal({ title, isOpen, onClose, onChange }: LocationPickerModalProps & ChakraProps) {
    const [apiResult, setApiResult] = useState<{ label: string; value: number[] }[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<{
        label: string;
        value: number[];
    }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<boolean>(false);
    const initialFocusRef: any = React.useRef();
    const { operatingCity } = useOperatingAreas();

    const search = (query: string) => {
        if (query === '' || query.length < 3) {
            setApiResult([]);
            return;
        }
        setLoading(true);

        MapService.search(query, operatingCity!.id!).then(({ features }: any) => {
            if (Array.isArray(features)) {
                const map = features.map(feature => ({
                    label: feature.place_name,
                    value: feature.center,
                }));
                setApiResult(map);
                setLoading(false);
            }
        });
    };

    function save() {
        if (!selectedOption) {
            return;
        }

        setApiResult([]);
        onChange?.(selectedOption.label, selectedOption.value);
    }

    function onInputChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        setSelected(false);
        setInputValue(value);
    }

    function onKeyUp() {
        search(inputValue);
    }

    function onInputClear() {
        setSelected(false);
        setInputValue('');
        setApiResult([]);
    }

    function onItemClick(location: { label: string; value: number[] }) {
        setSelected(true);
        setInputValue(location.label);
        setSelectedOption(location);
    }

    return (
        <ModalNew
            titleFontSize="var(--font-medium)"
            title={title}
            isOpen={isOpen}
            onClose={onClose}
            size="xl"
            initialFocusRef={initialFocusRef}
        >
            <HStack spacing="4" mb="4">
                <InputGroup>
                    <Input
                        size="lg"
                        ref={initialFocusRef}
                        onKeyUp={onKeyUp}
                        value={inputValue}
                        readOnly={!isOpen}
                        onChange={onInputChange}
                        placeholder={i18n.t('location.search.modal.input.placeholder') || 'Enter location'}
                    />
                    <InputRightElement hidden={inputValue === ''} {...styles.inputIcon} onClick={onInputClear}>
                        {loading ? (
                            <Center>
                                <Spinner size="sm" />
                            </Center>
                        ) : (
                            <CloseIcon />
                        )}
                    </InputRightElement>
                </InputGroup>
                <Button size="sm" variant="solid" colorScheme="black" disabled={!selected} onClick={save}>
                    {i18n.t('default.save')}
                </Button>
            </HStack>

            {apiResult!.map(res => (
                <Box {...styles.item} onClick={() => onItemClick(res)}>
                    {res.label}
                </Box>
            ))}
        </ModalNew>
    );
}

export default LocationSearchModal;
