import React, { forwardRef, useState } from 'react';
import { AddressAutocompleteValue, MapServiceResult } from 'components/sections/home/addressComplete/types';
import { useOperatingAreas } from 'hooks';
import Autocomplete from '../../../../../base/v1/autocomplete';
import MapService from '../../../../../../services/map.service';

export type AddressAutocompleteProps = {
    onLoading?: (loading: boolean) => void;
    onNewSuggestions?: (addresses: AddressAutocompleteValue[], currentUserInput: string) => void;
    disabledSuggestions?: boolean;
    onInputSelected?: (event: any) => void;
    onInputBlur?: (event: any) => void;
    focusOnRender?: boolean;
    placeholder?: string;

    onClear?: () => void;
    withClear?: boolean;
    isHovering?: boolean;
    endHovering?: () => void;
    setSearchBtn?: (value: boolean) => void;
    value?: string;
    setUserInput?: (value: string) => void;
    onChange?: (term: string) => void;
    onFocus?: (e: FocusEvent) => void;
    className?: string;
    searchOnInitialRender?: boolean;
    id?: string;
};

const AddressAutocomplete = forwardRef<HTMLInputElement, AddressAutocompleteProps>(
    (props: AddressAutocompleteProps, ref) => {
        const { operatingCity } = useOperatingAreas();
        const [apiResult, setApiResult] = useState<AddressAutocompleteValue[]>([]);
        const handleChange = (term: string) => {
            props.onLoading && props.onLoading(true);
            props.onChange?.(term);
            if (term === '') {
                props.onInputSelected && props.onInputSelected({ label: '', value: [] });
                props.onNewSuggestions && props.onNewSuggestions([], term);

                return;
            }

            if (term.length < 3) {
                props.onNewSuggestions && props.onNewSuggestions([], term);
                return;
            }

            MapService.search(term, operatingCity!.id!).then((result: MapServiceResult) => {
                const features =
                    Array.isArray(result.features) &&
                    result.features.map(f => {
                        return { label: f.place_name, value: f.center };
                    });

                if (features === false) {
                    setApiResult([]);
                    props.onNewSuggestions && props.onNewSuggestions([], '');
                    return;
                }

                setApiResult(features);
                props.onNewSuggestions && props.onNewSuggestions(features, term);
                props.onLoading && props.onLoading(false);
            });
        };

        const onInputSelected = (event: any) => {
            props.onInputSelected && props.onInputSelected(event);
        };

        const onBlur = (event: any) => {
            props.onInputBlur && props.onInputBlur(event);
        };

        return (
            <Autocomplete
                placeholder={props.placeholder}
                disabledSuggestions={props.disabledSuggestions}
                suggestions={apiResult}
                onChange={handleChange}
                onInputSelected={onInputSelected}
                onBlur={onBlur}
                focusOnRender={props.focusOnRender}
                ref={ref}
                withClear={props.withClear}
                isHovering={props.isHovering}
                endHovering={props.endHovering}
                setSearchBtn={props.setSearchBtn}
                value={props.value}
                onClear={props.onClear}
                onFocus={props.onFocus}
                className={props.className}
                searchOnInitialRender={props.searchOnInitialRender}
                id={props.id}
            />
        );
    },
);

export default AddressAutocomplete;
