import React from 'react';

import { ReactComponent as Icon } from '../../../../assets/icons/arrow-down.svg';

interface IconProps {
    className?: string;
    fill?: string;
}

export const ArrowDownIcon = (props: IconProps) => {
    return <Icon {...props} />;
};
