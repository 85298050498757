import React, { ReactNode } from 'react';
import {
    Popover as ChakraPopover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    PopoverProps as ChakraPopoverProps,
    useOutsideClick,
    Box,
} from '@chakra-ui/react';
import { styles } from './styles';

interface PopoverProps extends ChakraPopoverProps {
    children: ReactNode;
    renderTrigger: ReactNode;
    isOpen?: boolean;
    hideArrow?: boolean;
    ousideClick?: () => void;
    maximumWidth?: boolean;
}

function Popover(props: PopoverProps) {
    const { maximumWidth, children, renderTrigger, isOpen, hideArrow, ousideClick, ...rest } = props;
    const ref: any = React.useRef();
    useOutsideClick({
        ref,
        handler: () => ousideClick && ousideClick(),
    });

    return (
        <Box ref={ref} {...styles.container(maximumWidth)}>
            <ChakraPopover isOpen={isOpen} placement="bottom-start" {...rest}>
                <PopoverTrigger>{renderTrigger}</PopoverTrigger>
                <PopoverContent minH={100} w="max-content">
                    {!hideArrow && <PopoverArrow />}
                    <PopoverBody>{children}</PopoverBody>
                </PopoverContent>
            </ChakraPopover>
        </Box>
    );
}

export default Popover;
