import React from 'react';
import Toggle, { ToggleProps } from 'react-toggle';
import './switcher.css';
import styles from './index.module.css';

function Switcher(props: ToggleProps) {
    return (
        <Toggle
            {...props}
            className={[styles.switcher, props.className].join(' ')}
            icons={{
                checked: null,
                unchecked: null,
            }}
        />
    );
}

export default Switcher;
