import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type ScrollToTopProps = {
    children?: React.ReactNode;
};

export default function ScrollToTop(props: ScrollToTopProps) {
    const locationPath = useLocation();
    const history = useHistory();
    const { children } = props;

    function toTop() {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        toTop();
        // Handling page previous page action to scroll to top instead of having user scroll restoration
        if (history.action === 'POP') {
            window.history.scrollRestoration = 'manual';
        }
    }, [locationPath, history]);

    return <>{children}</>;
}
