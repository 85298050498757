import React from 'react';
import i18n from 'language/i18n';
import { BookingCancellationConfig } from 'hooks/remoteConfig';

function cancellationQuestionBody(bookingCancellationConfig: BookingCancellationConfig) {
    return (
        <>
            <ul>
                <li>
                    {i18n.t('sections.faq.cancellation.stepOne', {
                        cancellationTime: bookingCancellationConfig.cancellation_time,
                    })}
                </li>
                <li>
                    {i18n.t('sections.faq.cancellation.stepTwo', {
                        cancellationTime: bookingCancellationConfig.cancellation_time,
                    })}
                </li>
                <li>{i18n.t('sections.faq.cancellation.stepThree')}</li>
                <li>{i18n.t('sections.faq.cancellation.stepFour')}</li>
            </ul>
        </>
    );
}

function bookingChangeQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.bookingChange.stepOne')}</p>
            <p>{i18n.t('sections.faq.bookingChange.stepTwo')}</p>
            <p>{i18n.t('sections.faq.bookingChange.stepThree')}</p>
            <p>{i18n.t('sections.faq.bookingChange.stepFour')}</p>
        </>
    );
}

function rentalModificationQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.rentalModification.description')}</p>
            <strong>{i18n.t('sections.faq.rentalModification.note')}</strong>
        </>
    );
}

export function cancellingOrChangingSection(bookingCancellationConfig: BookingCancellationConfig) {
    const body = [
        {
            title: i18n.t('sections.faq.cancellation.title'),
            content: cancellationQuestionBody(bookingCancellationConfig),
        },
        {
            title: i18n.t('sections.faq.bookingChange.title'),
            content: bookingChangeQuestionBody(),
        },
        {
            title: i18n.t('sections.faq.rentalModification.title'),
            content: rentalModificationQuestionBody(),
        },
    ];
    const cancellingOrChangingSectionObj = {
        title: i18n.t('sections.faq.cancellingOrChangingReservation.title'),
        description: i18n.t('sections.faq.cancellingOrChangingReservation.description'),
        body,
    };

    return cancellingOrChangingSectionObj;
}
