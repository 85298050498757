import { Box, Flex, Link as ChakraLink, Link, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import background from '../../assets/images/backgrounds/team-big.jpg';
import gf from '../../assets/images/team/gf.png';
import pb from '../../assets/images/team/pb.png';
import { BreadcrumbItem } from '../../components/base/breadcrumb';
import { ArrowRightIcon } from '../../components/base/icon/arrowRight';
import RectangleSm from '../../components/base/icon/rectangle-sm';
import PageTemplate from '../../components/base/pageTemplate';
import BodyText from '../../components/base/text/body';
import SectionTitle from '../../components/base/text/sectionTitle';
import LeaderCard from '../../components/sections/team/leaderCard';
import TeamCard from '../../components/sections/team/teamCard';
import { joinClasses } from '../../helpers/joinClasses';
import { useWindowSize } from '../../hooks/useWindowSize';
import i18n from '../../language/i18n';
import styles from './index.module.css';
import { leaders } from './leadership';
import { TeamMember, teamMembers } from './teamMembers';

// carousel breakpoints
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1100 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1099, min: 692 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 691, min: 0 },
        items: 1,
    },
};

export default function TeamPage() {
    const breadcrumbItems: BreadcrumbItem[] = [{ label: 'COMPANY', to: '/' }, { label: 'TEAM' }];
    const { width } = useWindowSize();

    function renderTeamCards() {
        const sortCb = (a: TeamMember, b: TeamMember) => (a.name < b.name ? -1 : 1);
        // sorts TLs by name
        const leads = teamMembers.filter(item => item.jobTitle === 'Team Lead');
        // sorts others by name
        const members = teamMembers.filter(item => item.jobTitle !== 'Team Lead').sort(sortCb);
        // joins whole team
        const team = [...leads, ...members];

        // on mount it shows only the amount of cards as per the mockups
        return team.map(item => (
            <TeamCard className={styles.teamCard} name={item.name} jobTitle={item.jobTitle} photo={item.photo} />
        ));
    }

    useEffect(() => {
        document.getElementById('root')?.classList.add('overflowXHidden');
        return () => document.getElementById('root')?.classList.remove('overflowXHidden');
    }, []);

    return (
        <PageTemplate
            title="Rummo Team"
            breadcrumbs={breadcrumbItems}
            templateClassName={styles.page}
            titleClassName={styles.pageTitle}
            breadcrumbsClassName={styles.breadcrumbs}
            background={background}
        >
            <Flex>
                <Box flexDirection="column" marginTop="1rem" width="100%">
                    <SectionTitle className={styles.sectionTitle}>{i18n.t('page.team.meetLeaders')}</SectionTitle>
                    <Carousel
                        responsive={responsive}
                        itemClass={styles.carouselItem}
                        customRightArrow={<ArrowRightIcon className={joinClasses(styles.arrow, styles.arrowRight)} />}
                        customLeftArrow={<ArrowRightIcon className={joinClasses(styles.arrow, styles.arrowLeft)} />}
                    >
                        <LeaderCard
                            name={leaders.gf.name}
                            mission={leaders.gf.currentMission}
                            picture={gf}
                            jobTitle={leaders.gf.jobTitle}
                            exp={leaders.gf.exp}
                        />
                        <LeaderCard
                            name={leaders.pb.name}
                            mission={leaders.pb.currentMission}
                            picture={pb}
                            jobTitle={leaders.pb.jobTitle}
                            exp={leaders.pb.exp}
                        />
                    </Carousel>
                    <RectangleSm className={joinClasses(styles.rect, styles.leaderRectangle)} />
                </Box>
            </Flex>
            <Flex mb="80px" mt="120px" position="relative" flexDirection="column">
                <SectionTitle className={styles.sectionTitle}>{i18n.t('page.team.meetTeam')}</SectionTitle>
                <SimpleGrid minChildWidth="240px" spacing="32px" justifyItems="center">
                    {renderTeamCards()}
                </SimpleGrid>
                <RectangleSm className={joinClasses(styles.rect, styles.teamRectangle)} />
            </Flex>
            <Flex className={joinClasses(styles.sectionRow, styles.hiringSection)}>
                <Box className={joinClasses(styles.col, styles.hiringCol)}>
                    <SectionTitle className={styles.hiringTitle}>
                        {i18n.t('page.team.hiring')} {width >= 928 && <br />} {i18n.t('page.team.acrossBoard')}
                    </SectionTitle>
                    <BodyText className={styles.hiringBody}>
                        {i18n.t('page.team.joinIntro')}
                        <span className={styles.joinTeam}> {i18n.t('page.team.joinMsg')}</span>
                    </BodyText>
                    <ChakraLink
                        as={Link}
                        w="13.5em"
                        fontSize="var--(--font-medium)"
                        fontWeight="var(--font-weight-medium)"
                        backgroundColor="var(--color-black)"
                        color="var(--color-white)"
                        textDecor="none"
                        href="https://rummo.factorialhr.com/"
                        isExternal
                        height="48px"
                        borderRadius="88px"
                        display="flex"
                        justifyContent="center"
                        mt="40px"
                        alignItems=" center"
                        variant="noStyle"
                    >
                        <Text textDecor="none">{i18n.t('page.team.viewAllJobs')}</Text>
                    </ChakraLink>
                </Box>
                <RectangleSm className={joinClasses(styles.rect, styles.hiringRectangle)} />
            </Flex>
        </PageTemplate>
    );
}
