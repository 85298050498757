import Column from 'components/base/column';
import Margin from 'components/base/margin';
import Row from 'components/base/row';
import React from 'react';
import styles from './index.module.css';

type PriceTotalProps = {
    title: string;
    price: string;
    info: string;
    message?: string;
    light?: boolean;
};

function PriceTotal({ title, price, info, message, light }: PriceTotalProps) {
    return (
        <Column className={light ? styles.light : ''}>
            <Row align="center">
                <div className={styles.title}>{title}</div>
                <div className={styles.price}>{price}</div>
            </Row>
            <div className={styles.info}>({info})</div>
            <Margin size="medium" />
            {message && (
                <span className={styles.message}>
                    <span className={styles.messageIcon}>! </span>
                    {message}
                </span>
            )}
        </Column>
    );
}

export default PriceTotal;
