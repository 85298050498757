import React from 'react';
import { Icon } from '@chakra-ui/react';
import { Modal } from '../../../base/modal';
import styles from './index.module.css';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import Text from '../../../base/text';
import i18n from '../../../../language/i18n';

export type ModalErrorProps = {
    error: string;
    onClose?: () => void;
};

function ModalError(props: ModalErrorProps) {
    const { error, onClose } = props;

    return (
        <Modal show onClose={onClose} size="large" className={styles.container}>
            <Icon as={WarningIcon} w="42px" h="42px" my="5" className={styles.icon} />
            <Text className={styles.title}>
                {i18n.t(`sections.checkout.modal.error.title.${error.toLowerCase().replace(/-/g, '_')}`)}
            </Text>
            <Text className={styles.description}>
                {i18n.t(`sections.checkout.modal.error.message.${error.toLowerCase().replace(/-/g, '_')}`)}
            </Text>
        </Modal>
    );
}

export default ModalError;
