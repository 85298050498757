import React from 'react';
import { joinClasses } from '../../../../helpers/joinClasses';
import Card from '../../../base/card';
import Text from '../../../base/text';
import Label from '../../../base/text/label';

import styles from './index.module.css';

export interface TeamCardProps {
    className?: string;
    name: string;
    jobTitle: string;
    photo: string;
}

function TeamCard({ className, name, jobTitle, photo }: TeamCardProps) {
    return (
        <Card className={joinClasses(styles.teamCard, className || '')}>
            <img src={photo} className={styles.imgPlaceholder} alt="team member" />
            <Text className={styles.nameText}>{name}</Text>
            <Label className={styles.label}>{jobTitle}</Label>
        </Card>
    );
}

export default TeamCard;
