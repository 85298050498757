import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment';

import Select from 'components/base/select';

import i18n from 'language/i18n';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SelectComponents } from 'react-select/src/components';
import 'moment/locale/en-gb';
import { TimeSlots } from 'models/sharedData/TimeSlots';

moment.updateLocale('en', {});

interface Option {
    value: string;
    label: string;
}

function TimePicker(props: TimePickerProps) {
    const { onChange, time, label, startLimit, endLimit, step, customOptions, format } = props;

    const [options, setOptions] = useState<Option[]>([]);

    function onTimeChange(item: Option) {
        onChange?.(item.value as any);
    }

    function isInteger(n: number) {
        return n % 1 === 0;
    }

    function zeroIt(value: number) {
        if (value > 9) return value;

        return (new Array(2).join('0') + value).slice(2 * -1);
    }

    function timeToDecimal(timeDecimal: moment.Moment) {
        return timeDecimal.hours() + timeDecimal.minutes() / 60;
    }

    // This function allows only fixed steps => 15 or 30 minutes
    const buildTimeFrames = useCallback(
        (start = props.start || '09:00', end = props.end || '23:00', stepMinutes = 30) => {
            const arr = [];
            const startTime = moment(start, 'HH:mm');
            const endTime = moment(end, 'HH:mm');
            const startTimeDecimal = timeToDecimal(startTime); // transform start time to decimal
            const endTimeDecimal = timeToDecimal(endTime); // transform end time to decimal

            function formatTime(formattedTime: string) {
                return moment(formattedTime, 'HH:mm').format(format || 'HH:mm');
            }

            const decimalStep = stepMinutes / 60; // transform minutes to decimal
            for (let i = startTimeDecimal; i <= endTimeDecimal; i += decimalStep) {
                const hours = zeroIt(Math.floor(i));
                const minutes = isInteger(i) ? '00' : (i % 1) * 60;
                const a = `${hours}:${minutes}`;

                arr.push({ value: a, label: formatTime(a) });
            }

            return arr.length > 0
                ? arr
                : [
                      {
                          value: '',
                          label: i18n.t('pages.assetDetails.checkout.noHour'),
                      },
                  ];
        },
        [format, props.end, props.start],
    );

    useEffect(() => {
        function loadOptions() {
            const timeFrames = buildTimeFrames(startLimit, endLimit, step);
            setOptions(customOptions || timeFrames);
        }

        loadOptions();
    }, [startLimit, endLimit, buildTimeFrames, customOptions, step]);

    return (
        <Flex flex="1" flexDir="column">
            {props.label && (
                <Text fontSize="var(--font)" lineHeight="var(--line)" fontWeight="medium">
                    {label}
                </Text>
            )}
            <Select
                soft
                options={options}
                selected={options.find(item => item.value === time)}
                onChange={onTimeChange}
            />
        </Flex>
    );
}

export default TimePicker;

export type TimePickerProps = {
    time?: string;
    startLimit?: string;
    endLimit?: string;
    step?: number;
    label?: string;
    containerStyle?: any;
    style?: any;
    onChange?: (time: TimeSlots) => void;
    customOptions?: any[]; // setting as array of any in order to keep it flexible
    format?: string;
    start?: string; // examples of how it should be formatted 09:00 - 11:00 - 13:30 - 15:30
    end?: string; // examples of how it should be formatted 09:00 - 11:00 - 13:30 - 15:30
    components?: Partial<SelectComponents<any, any>>;
};
