import React, { useState } from 'react';

import TransmissionAutomaticIcon from 'components/base/icon/transmission-automatic';
import { joinClasses } from 'helpers/joinClasses';
import { AssetTransmission } from 'models/asset/AssetTransmission';
import { isMobile } from 'react-device-detect';
import i18n from '../../../../language/i18n';
import { Asset } from '../../../../services/asset.service';
import Grid from '../../../base/grid';
import DieselIcon from '../../../base/icon/diesel';
import DoorsIcon from '../../../base/icon/doors';
import SeatsIcon from '../../../base/icon/seats';
import TransmissionIcon from '../../../base/icon/transmission';
import { H3 } from '../../../base/text/h3';
import AssetDetailsModal from '../../assets/asset-details-modal';
import AssetDetailsCharacteristicsItem from '../characteristics-item';
import styles from './index.module.css';

export type AssetDetailsCharacteristicsProps = {
    asset: Asset;
};

function AssetDetailsCharacteristics(props: AssetDetailsCharacteristicsProps) {
    const { asset } = props;
    const transmission = asset.transmission ? i18n.t(`asset.transmission.${asset.transmission.toUpperCase()}`) : 'N/A';
    const transmissionIcon =
        asset.transmission === AssetTransmission.AUTOMATIC ? TransmissionAutomaticIcon : TransmissionIcon;
    const propulsionType = asset.propulsionType
        ? i18n.t(`propulsionTypes.${asset.propulsionType.toUpperCase()}`)
        : 'N/A';
    const seats = asset.seats || 0;
    const seeMore = isMobile ? i18n.t('sections.assetDetails.characteristics.showMore') : i18n.t('default.showMore');
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    function onCloseDetailsModal() {
        setShowDetailsModal(false);
    }

    return (
        <Grid className={styles.grid}>
            <div className={styles.container}>
                <H3 className={styles.title}>{i18n.t('sections.assetDetails.title')}</H3>
                <div className={styles.characteristicsGrid}>
                    <AssetDetailsCharacteristicsItem
                        icon={SeatsIcon}
                        label={`${seats} ${i18n.t('pages.assetDetails.seats')}`}
                        className={styles.iconLabel}
                    />
                    <AssetDetailsCharacteristicsItem
                        icon={DoorsIcon}
                        label={`5 ${i18n.t('pages.assetDetails.doors')}`}
                        className={styles.iconLabel}
                    />
                    <AssetDetailsCharacteristicsItem
                        className={joinClasses(styles.iconTransmission, styles.iconLabel)}
                        icon={transmissionIcon}
                        label={transmission}
                    />
                    <AssetDetailsCharacteristicsItem
                        icon={DieselIcon}
                        label={propulsionType}
                        className={styles.iconLabel}
                    />
                </div>
            </div>
            <div className={styles.seeMore}>
                <span onClick={() => setShowDetailsModal(true)}>{seeMore}</span>
            </div>
            <AssetDetailsModal asset={asset} isOpen={showDetailsModal} onClose={onCloseDetailsModal} />
        </Grid>
    );
}

export default AssetDetailsCharacteristics;
