import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@chakra-ui/react';
import Column from '../../../base/column';
import Card from '../../../base/card';
import Row from '../../../base/row';
import { H1 } from '../../../base/text/h1';
import Text from '../../../base/text';
import PageContainer from '../../../base/pageContainer';
import BusinessImg01 from '../../../../assets/images/design/rummo-for-business_1.svg';
import BusinessImg02 from '../../../../assets/images/design/rummo-for-business_2.svg';
import ChakraStyles from './styles';

import i18n from '../../../../language/i18n';

import styles from './index.module.css';

function Business() {
    const history = useHistory();
    function route() {
        history.push('/business');
    }

    function renderCardSection(boldText: string, lightText: string, image: string) {
        return (
            <Column className={styles.cardSection}>
                <img className={styles.cardImage} src={image} alt="Rummo for Business" />
                <span className={styles.cardText}>
                    <Text className={styles.boldText}>{boldText} </Text>
                    <Text className={styles.lightText}>{lightText}</Text>
                </span>
            </Column>
        );
    }

    return (
        <div className={styles.businessContainer}>
            <PageContainer className={styles.pageContainer}>
                <Column className={styles.descriptionContainer}>
                    <Row>
                        <H1 className={styles.title}>{i18n.t('sections.business.title')}</H1>
                    </Row>
                    <Row>
                        <Text className={styles.description}>{i18n.t('sections.business.description')}</Text>
                    </Row>
                    <Row>
                        <Button
                            _hover={{ background: 'white', color: 'black', boxShadow: 'none' }}
                            {...ChakraStyles.businessButton}
                            onClick={route}
                        >
                            {i18n.t('sections.business.button')}
                        </Button>
                    </Row>
                </Column>
                <Column className={styles.cardContainer}>
                    <Card className={styles.card}>
                        {renderCardSection(
                            i18n.t('sections.business.card.prices.bold'),
                            i18n.t('sections.business.card.prices.normal'),
                            BusinessImg01,
                        )}
                        {renderCardSection(
                            i18n.t('sections.business.card.vehicles.bold'),
                            i18n.t('sections.business.card.vehicles.normal'),
                            BusinessImg02,
                        )}
                    </Card>
                </Column>
            </PageContainer>
        </div>
    );
}

export default Business;
