import { ButtonProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<ButtonProps> = {
    faqButton: {
        fontSize: 'var(--font)',
        fontWeight: 'var(--font-weight-regulars)',
        lineHeight: 'var(--line)',
        marginTop: 'var(--margin-medium)',
        border: 'none',
        color: '#0b0b0d',
        boxShadow: '0 14px 80px #00000014',
        padding: 'var(--margin-small) var(--margin-medium)',
        backgroundColor: 'white',
        borderRadius: '50px',
        height: '54px',
    },
};
export default styles;
