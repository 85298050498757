import { ChakraStyles } from 'theme';

const styles: ChakraStyles = {
    text: {
        padding: 'var(--margin-small) 0 var(--margin)',
        fontSize: ' var(--font)',
        lineHeight: 'var(--line)',
        fontWeight: 'var(--font-weight-light)',
        alignItems: 'center',
        border: 'none',
    },
    span: {
        ml: '5px',
        fontSize: ' var(--font-xsmall)',
        lineHeight: 'var(--line-xsmall)',
    },
};

export default styles;
