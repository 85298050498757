import React from 'react';
import { Box } from '@chakra-ui/react';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import { BookingStatus } from 'models/booking/BookingStatus';

import { colors } from 'styles/colors';
import styles from './styles';

type BookingStatusProps = {
    status: string;
    children: any;
    className?: string;
    isMobile?: boolean;
};

function BookingStatusComponent(props: BookingStatusProps) {
    const { status, isMobile, children } = props;

    const getStatus = () => {
        switch (status) {
            case BookingStatus.ACTIVE:
            case BookingStatus.BOOKED:
                return colors.red;
            case BookingStatus.PENDING:
            case BookingStatus.SECURITY_DEPOSIT_PENDING:
            case BookingStatus.SECURITY_DEPOSIT_RETRY:
            case BookingStatus.REVIEW:
                return colors.grayish;
            case BookingStatus.COMPLETED:
            case BookingStatus.FINISHED:
            case BookingStatus.PROCESSED:
            case BookingStatus.CANCELLED:
            case BookingStatus.CANCELLED_BY_USER:
                return colors.black;
                break;
            default:
        }
    };

    const failedStyle = () => {
        if (status === BookingStatus.SECURITY_DEPOSIT_FAILED || status === BookingStatus.PAYMENT_FAILED) {
            return { ...styles.failed() };
        }
    };

    const mobileStyle = () => {
        if (isMobile === false) return { ...styles.mobile() };
    };

    return (
        <Box hidden={isMobile} {...styles.tag(getStatus())} {...failedStyle()} {...mobileStyle()}>
            {(status === BookingStatus.SECURITY_DEPOSIT_FAILED || status === BookingStatus.PAYMENT_FAILED) && (
                <WarningIcon {...styles.icon()} />
            )}
            {children}
        </Box>
    );
}

export default BookingStatusComponent;
