import { Button } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import i18n from '../../../../../language/i18n';
import Column from '../../../../base/column';
import Text from '../../../../base/text';
import { H3 } from '../../../../base/text/h3';
import styles from './index.module.css';

function ExplanationItem(props: ExplanationItemProps) {
    const history = useHistory();
    const { image, title, description } = props;

    function onItemButtonClick() {
        history.push('/faq');
    }

    return (
        <Column className={styles.itemContainer}>
            <img className={styles.image} src={image} alt={image} />
            <H3 className={styles.title}>{title}</H3>
            <Text className={styles.description}>{description}</Text>
            <Button variant="outline" colorScheme="black" size="md" onClick={onItemButtonClick}>
                {i18n.t('sections.assetDetails.explanation.moreInfoButton')}
            </Button>
        </Column>
    );
}

export default ExplanationItem;

export type ExplanationItemProps = {
    image: string;
    title: string;
    description?: string;
};
