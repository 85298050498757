import moment, { MomentInput, MomentFormatSpecification, Moment } from 'moment';
import 'moment/locale/pt';
import 'moment/locale/en-gb';
import 'moment/locale/cs';
import momentTimezone from 'moment-timezone';
import { shortLocale } from '../language/i18n';

export function momentWithLocale(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean) {
    return moment(inp, format, strict).locale(shortLocale);
}

export function convertDateToTimezone(date: Moment, timezone: string) {
    const userOffset = momentTimezone.tz(date, timezone).utcOffset();
    const convertedTime = date?.clone().utcOffset(userOffset, true);

    return convertedTime;
}
