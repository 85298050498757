import { PhoneObject } from 'components/base/phoneInput';
import { DocumentReference, firestore, functions } from './persistence';
import { GetAllResponse } from './types';

const CompanyCollection = 'companies';

export enum CountryCodeOptions {
    PT = 'PT',
    US = 'US',
}

export type UserEmbedded = {
    id: string;
    name: string;
    email: string;
};

export type Company = {
    name: string | undefined;
    publicName: string | undefined;
    vat: string | undefined;
    sentInvites: string[] | undefined;
    phone: PhoneObject | undefined;
    email: string;
    countryCode: CountryCodeOptions;
    address: string;
    invitedManagers?: string[];
    invitedEmployees?: string[];
    managersRef?: UserEmbedded[];
    employeesRef?: UserEmbedded[];
    disabled: boolean | undefined;
    disabledAt?: moment.Moment;
    disabledBy?: DocumentReference;
    programs?: string[];
    companyRef?: string;
};

const CompanyService = {
    async getAll(criteria?: any): Promise<GetAllResponse<Company>> {
        const response = await functions.httpsCallable(CompanyCollection)(criteria || {});
        return response.data;
    },

    async get(uid: string): Promise<Company | undefined | null> {
        try {
            const result = await firestore.collection(CompanyCollection).doc(uid).get();
            if (!result.exists) return null;

            return result.data() as Company;
        } catch (err) {
            console.log(err);
            return null;
        }
    },
};

export default CompanyService;
