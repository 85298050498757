import React from 'react';
import i18n from 'language/i18n';

function securityHoldQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.afterRummoTrip.securityHold.creditCard.title')}</p>
            <ul>
                <li>{i18n.t('sections.faq.afterRummoTrip.securityHold.creditCard.stepOne')}</li>
                <li>{i18n.t('sections.faq.afterRummoTrip.securityHold.creditCard.stepTwo')}</li>
            </ul>
            <p>{i18n.t('sections.faq.afterRummoTrip.securityHold.debitCard.title')}</p>
            <ul>
                <li>{i18n.t('sections.faq.afterRummoTrip.securityHold.debitCard.stepOne')}</li>
                <li>{i18n.t('sections.faq.afterRummoTrip.securityHold.debitCard.stepTwo')}</li>
            </ul>
        </>
    );
}

function securityHoldReturnQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.afterRummoTrip.securityHoldReturn.stepOne')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.securityHoldReturn.stepTwo')}</p>
        </>
    );
}

function complaintOrDisputeQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.description')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.stepOne')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.stepTwo')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.stepThree')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.stepFour')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.stepFive')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.stepSix')}</p>
            <p>{i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.note')}</p>
        </>
    );
}

const body = [
    {
        title: i18n.t('sections.faq.afterRummoTrip.securityHold.title'),
        content: securityHoldQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.afterRummoTrip.securityHoldReturn.title'),
        content: securityHoldReturnQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.afterRummoTrip.complaintOrDispute.title'),
        content: complaintOrDisputeQuestionBody(),
    },
];

export const afterRummoTripSection = {
    title: i18n.t('sections.faq.afterRummoTrip.title'),
    description: '',
    body,
};
