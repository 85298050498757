import React, { useEffect, useState } from 'react';
import { MinusCircleIcon } from 'components/base/icon/minus-circle';
import { PlusCircleIcon2 } from 'components/base/icon/plusCircle-v2';
import { Text, Flex, Icon } from '@chakra-ui/react';
import { styles } from './styles';

type NumberPickerProps = {
    label: string;
    negativeAllowed?: boolean;
    filterKey: string;
    value?: number;
    onChange?: (filter: { key: string; value: any }) => void;
    resetCounter?: boolean;
};

export const NumberPicker = (props: NumberPickerProps) => {
    const { label, negativeAllowed, onChange, value, filterKey, resetCounter } = props;
    const [counter, setCounter] = useState<number>(value || 2);

    useEffect(() => {
        // handling the counter reset order coming from parent component
        if (resetCounter === true) {
            setCounter(2);
        }
    }, [counter, resetCounter]);

    if (!negativeAllowed && counter < 2) {
        setCounter(2);
    }

    function decrement() {
        const updatedValue = Number(counter) - 1;
        setCounter(updatedValue);
        onChange && onChange({ key: filterKey, value: updatedValue });
    }

    function increment() {
        const updatedValue = Number(counter) + 1;
        setCounter(updatedValue);
        onChange && onChange({ key: filterKey, value: updatedValue });
    }

    return (
        <Flex {...styles.container}>
            <Text {...styles.label}>{label}</Text>
            <Flex {...styles.seatsComponent}>
                <Icon as={MinusCircleIcon} onClick={decrement} {...styles.icon} />
                <Text {...styles.seatsCount}>{counter}</Text>
                <Icon as={PlusCircleIcon2} onClick={increment} {...styles.icon} />
            </Flex>
        </Flex>
    );
};
