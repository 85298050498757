import React from 'react';
import Select, { components, IndicatorProps } from 'react-select';

import Label from 'components/base/text/label';
import { ChevDownIcon } from '../icon/chev-down';

import { DropdownProps } from './types';

import styles from './index.module.css';

const DropdownIndicator = (props: IndicatorProps<any, any>) => {
    const { selectProps } = props;
    const dropdownIconStyles = [
        styles.chevDownIcon,
        selectProps.menuIsOpen ? styles.rotateChevUp : styles.rotateChevDown,
    ].join(' ');

    return (
        <components.DropdownIndicator {...props}>
            <ChevDownIcon className={dropdownIconStyles} color="black" />
        </components.DropdownIndicator>
    );
};

export function Dropdown(props: DropdownProps) {
    const {
        isSearchable,
        value,
        className,
        classNamePrefix,
        options,
        onChange,
        getOptionLabel,
        getOptionValue,
        placeholder,
        onFocus,
        onBlur,
        label,
        noOptionsMessage,
        onTextChange,
        onMouseEnter,
        onMouseLeave,
        round,
    } = props;

    const componentStyle = [styles.select, className];

    if (round) {
        componentStyle.push(styles.round);
    }

    return (
        <>
            {label ? <Label className={styles.label}>{label}</Label> : null}
            <Select
                placeholder={placeholder}
                components={{ ...components, DropdownIndicator }}
                isSearchable={isSearchable}
                value={value}
                className={componentStyle.join(' ')}
                classNamePrefix={classNamePrefix || 'select'}
                options={options}
                noOptionsMessage={() => noOptionsMessage || null}
                onChange={onChange}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onFocus={onFocus}
                onInputChange={onTextChange}
                onBlur={onBlur}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                maxMenuHeight={500}
            />
        </>
    );
}
