import React from 'react';
import { ReactComponent as Icon } from '../../../../assets/icons/rectangle-lg.svg';
import { ReactComponent as IconRed } from '../../../../assets/icons/rectangle-lg-red.svg';

export default function RectangleLgIcon(props: any) {
    const { color } = props;

    switch (color) {
        case 'red':
            return <IconRed {...props} />;
        default:
            return <Icon {...props} />;
    }
}
