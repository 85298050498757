// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import { initialize } from '@procimo/feature-flag-web';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

import firebaseConfig from '../configuration/firebase/firebase-config.json';

export type DocumentReference = firebase.firestore.DocumentReference;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export type CollectionReference = firebase.firestore.CollectionReference;
export type QuerySnapshot = firebase.firestore.QuerySnapshot;

// Initialize Firebase
firebase.initializeApp(firebaseConfig.result.sdkConfig);
initialize(firebaseConfig.result.sdkConfig, 0);

export const auth = firebase.auth();
export const functions = firebase.app().functions('europe-west1');
export const firestore = firebase.firestore();
