import React from 'react';
import { Moment } from 'moment';
import { Box, HStack } from '@chakra-ui/react';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import MobileHeaderSearch from './mobile';
import styles from './styles';
import DesktopHeaderSearch from './desktop';

export type HeaderSearchProps = {
    onSelectAddress?: (address: any) => void;
    onSubmit?: (dates?: { start: Moment | null; end: Moment | null }) => void;
    withoutSearch?: boolean;
    isMobile?: boolean;
};

function HeaderSearch(props: HeaderSearchProps) {
    const windowSize = useWindowSize();
    const { isMobile } = props;
    const getHeader = () => {
        if (windowSize.width > 928) {
            return <DesktopHeaderSearch {...props} />;
        }

        return <MobileHeaderSearch {...props} />;
    };

    return (
        <Box {...styles.container(isMobile)}>
            <HStack {...styles.stack}>{getHeader()}</HStack>
        </Box>
    );
}

export default HeaderSearch;
