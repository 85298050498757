import React from 'react';

import { ReactComponent as Icon } from '../../../../assets/icons/info.svg';

export default class InfoIcon extends React.Component<any> {
    render() {
        const { color } = this.props;

        switch (color) {
            default:
                return <Icon {...this.props} />;
        }
    }
}
