import Margin from 'components/base/margin';
import BodyText from 'components/base/text/body';
import { H3 } from 'components/base/text/h3';
import LoadingAnimation from 'components/Lottie/loadingAnimation';
import DriverInfo from 'components/sections/asset-checkout/driver-info';
import AssetHero from 'components/sections/_layout/asset-hero';
import AssetPanel from 'components/sections/_layout/asset-panel';
import BookingSuccessHero from 'components/sections/_layout/booking-success-hero';
import LocationItem from 'components/sections/_layout/location-item';
import { useAssetDetails, useAuth, useInsurances, useRemoteConfig, useSharedData, useWindowSize } from 'hooks';
import { Booking } from 'models/booking/Booking';
import { PaymentMethod } from 'models/paymentMethods';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BookingService from 'services/booking.service';
import CompanyService, { Company } from 'services/company.service';
import paymentMethodsService from 'services/paymentMethods.service';
import Column from '../../components/base/column';
import Row from '../../components/base/row';
import Splitter from '../../components/base/splitter';
import i18n from '../../language/i18n';
import styles from './index.module.css';

function AssetsCheckoutSuccessPage() {
    const history = useHistory();
    const windowSize = useWindowSize();
    const params = useParams<{ id: string; bookId: string }>();
    const { asset, loading } = useAssetDetails(params.id);
    const { shared } = useSharedData();
    const location = useLocation();
    const { state } = location;
    const { getUser, user } = useAuth();
    const [company, setCompany] = useState<Company | null | undefined>();
    const [booking, setBooking] = useState<Booking | null | undefined>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();

    const { bookingCancellationConfig } = useRemoteConfig();

    const noContext = !shared.start || !shared.end;
    const noDates = !shared.start.date || !shared.end.date;
    const noLocation = !shared.start.location || !shared.end.location;

    if (!state || noContext || noDates || noLocation) {
        history.replace(`/`, state);
    }

    function onMount() {
        getUser();
        getCompany();
        getBooking();
    }

    const getCompany = useCallback(async () => {
        if (user && user.companyRef) {
            const companyId = user.companyRef.id;
            const result = await CompanyService.get(companyId);
            setCompany(result);
        }
    }, [user]);

    const getBooking = useCallback(async () => {
        const result = await BookingService.get({ bookId: params.bookId });
        setBooking(result);
    }, [params.bookId]);

    const getPaymentMethod = useCallback(async () => {
        if (booking && booking.paymentMethodRef) {
            const result = await paymentMethodsService.get(booking.paymentMethodRef.id);
            if (result === undefined || result === null) {
                return;
            }
            setPaymentMethod(result);
        }
    }, [booking]);

    useEffect(onMount, []);

    useEffect(() => {
        getPaymentMethod();
    }, [getPaymentMethod]);

    function displayDate(date?: Moment | null, time?: string | null) {
        if (!date || !time) {
            return undefined;
        }

        const formattedTime = moment(time, 'HH:mm').format('hh:mma');

        return `${date?.format('dddd, MMM DD')}, ${formattedTime}`;
    }

    function determineEndQueryText() {
        const { start, end } = shared;

        if (!end.query || !end.location) {
            return undefined;
        }

        if (start.query === end.query) {
            return i18n.t('sections.assetDetails.booking.sameAddressAsDelivery');
        }

        return end.query;
    }

    const { insurances: insuranceProp, creationDate }: any = state || {};
    const insurances = useInsurances(insuranceProp);
    const [selectedExtras] = useState<string[]>(shared.extras || []);

    if ((loading && !asset) || insurances.insurancesLoading || !booking) {
        return <LoadingAnimation />;
    }

    asset.id = params.id;

    return (
        <>
            <Column className={styles.container}>
                <BookingSuccessHero
                    className={styles.bookingDetails}
                    id={booking.refNumber}
                    creationDate={moment(creationDate).format('LLL')}
                />
                <Margin size="large" />

                <div className={styles.separator} />
                <Margin size="large" />
                <Row className={styles.content}>
                    <Column className={styles.contentDetails}>
                        <H3 className={styles.sectionTitle}>{i18n.t('pages.assetDetails.booking.bookingInfo')}</H3>

                        <AssetHero asset={asset} />
                        <Margin size="large" />

                        <LocationItem
                            inverted
                            title={`${i18n.t('pages.assetDetails.booking.deliveryInfoSuccess')}:`}
                            location={shared.start.query}
                            date={displayDate(shared.start.date, shared.start.time)}
                        />
                        <Margin size="internal" />
                        <LocationItem
                            inverted
                            title={`${i18n.t('pages.assetDetails.booking.returnInfoSuccess')}:`}
                            location={determineEndQueryText()}
                            date={displayDate(shared.end.date, shared.end.time)}
                        />

                        <Splitter spacing="xlarge" />

                        <H3 className={styles.sectionTitle}>
                            {i18n.t('pages.assetDetails.booking.driverDetails.title')}
                        </H3>
                        <DriverInfo hideLicense user={user} />

                        <Margin size="xlarge" />
                        <Splitter spacing="xlarge" />

                        {windowSize.width < 692 && (
                            <>
                                <AssetPanel
                                    relative
                                    company={company}
                                    className={styles.assetPanel}
                                    mode="checkout-3"
                                    asset={asset}
                                    insurances={insurances.data}
                                    extras={selectedExtras}
                                    paymentMethod={paymentMethod}
                                    bookingCancellationConfig={bookingCancellationConfig}
                                />
                                <Margin size="xlarge" />
                                <Splitter spacing="xlarge" />
                            </>
                        )}

                        <H3 className={styles.sectionTitle}>
                            {i18n.t('pages.assetDetails.booking.cancelationPolicy.title')}
                        </H3>
                        <BodyText className={styles.cancelationFirst}>
                            {i18n.t('pages.assetDetails.booking.cancelationPolicy.first', {
                                cancellationTime: bookingCancellationConfig.cancellation_time,
                            })}
                        </BodyText>

                        <Margin size="medium" />
                    </Column>
                    {windowSize.width >= 692 && (
                        <Column flex="0" align="end" className={styles.panelContainer}>
                            <AssetPanel
                                company={company}
                                className={styles.assetPanel}
                                mode="checkout-3"
                                asset={asset}
                                insurances={insurances.data}
                                extras={selectedExtras}
                                booking={booking}
                                paymentMethod={paymentMethod}
                                bookingCancellationConfig={bookingCancellationConfig}
                            />
                        </Column>
                    )}
                </Row>
            </Column>
        </>
    );
}

export default AssetsCheckoutSuccessPage;
