import React, { useState, useRef, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { FocusedInputShape } from 'react-dates';
import { Box, Button, Divider, Flex, HStack, Text, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import DateRangePicker from 'components/base/dateRangePicker';
import Popover from 'components/base/popover';
import SearchIcon from 'components/base/icon/search';
import TimePicker from 'components/base/timePicker';
import Column from 'components/base/column';
import CloseIconThinn from 'components/base/icon/closeThin';
import { HeaderMenu } from 'components/sections/standalone/header/auth/menu';
import { Logo } from 'components/logo';

import { useSharedData, useOperatingAreas, useRemoteConfig, useAuth, useCalendarLimits } from 'hooks';
import i18n from 'language/i18n';

import { colors } from 'styles/colors';
import { createDateSearchObject } from 'models/sharedData/SearchObject';
import {
    BookingCalendarDatesChangesInformation,
    handleBookingCalendarDatesChanges,
} from 'helpers/handleBookingCalendarDatesChanges';
import checkIfIsOutsideRange from 'helpers/isOutsideRange';
import styles from './index.module.css';
import { HeaderSearchProps } from '..';
import { HeaderAuth } from '../../auth';
import AddressAutocomplete from '../addressComplete';

function DesktopHeaderSearch(props: HeaderSearchProps) {
    const { bookingMaximumDuration } = useRemoteConfig();
    const { checkLocationInBoundingBox } = useOperatingAreas();
    const { shared, sharedDataService } = useSharedData();

    const [startTime, setStartTime] = useState(shared.start?.time || '09:00');
    const [endTime, setEndTime] = useState(shared.end?.time || '09:00');
    const [dateRangeFocus, setRangeDateFocus] = useState<FocusedInputShape | null>(null);
    const [hoverSearch, setHoverSearch] = useState<boolean>(false);
    const [deliveryQuery, setDeliveryQuery] = useState<string>(shared.start!.query || '');
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchFocused, setSearchFocused] = useState<boolean>(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [startDate, setStartDate] = useState<Moment | null>(shared.start?.date || null);
    const [endDate, setEndDate] = useState<Moment | null>(shared.end?.date || null);
    const { auth } = useAuth();

    const { startLimit, updateStartLimit, endLimit } = useCalendarLimits({
        startDate,
        startTime,
        endDate,
        endTime,
        setEndTime,
    });

    const inputRef = useRef<HTMLInputElement>(null);

    function setContextDates(dates: { start: null | Moment; end: null | Moment }) {
        const startDateWithTime = moment(dates.start).setTime(startTime);
        const endDateWithTime = moment(dates.end).setTime(endTime);

        const deliveryObject = createDateSearchObject(startDateWithTime!, startTime);
        const returnObject = createDateSearchObject(endDateWithTime!, endTime);

        sharedDataService.updateDates(deliveryObject, returnObject);
    }

    function onInputDateRange(newStartDate: Moment | null, newEndDate: Moment | null) {
        const bookingCalendarInformation = new BookingCalendarDatesChangesInformation({
            newStartDate,
            newEndDate,
            previousStartDate: startDate,
            previousEndDate: endDate,
            startTime,
            endTime,
            dateRangeFocus,
            bookingMaximumDuration,
            updateStartLimit,
        });

        const data = handleBookingCalendarDatesChanges(bookingCalendarInformation);

        setRangeDateFocus(data.focusedDate);
        setStartDate(data.startDate);
        setEndDate(data.endDate);
        setStartTime(data.startTime);
        setEndTime(data.endTime);
    }

    function onInputAddressChange(address: { label: string; value: Array<number> }) {
        setDeliveryQuery(address.label);

        if (address.label === '') {
            return;
        }

        const [longitude, latitude] = address.value;

        if (longitude && latitude) {
            const isAvailable = checkLocationInBoundingBox({
                latitude,
                longitude,
            });

            if (isAvailable === false) {
                return;
            }
        }

        props.onSelectAddress && props.onSelectAddress(address);
        setIsCalendarOpen(true);
        setRangeDateFocus('startDate');
    }

    function onClickSearch() {
        setContextDates({ start: startDate, end: endDate });
        setShowSearch(false);
        props.onSubmit && props.onSubmit({ start: startDate, end: endDate });
    }

    function handleClearDate(e: any) {
        e.stopPropagation();

        setRangeDateFocus('startDate');
        setEndDate(null);
        setStartDate(null);
        setStartTime('09:00');
        setEndTime('09:00');
        updateStartLimit();
    }

    function handleSearchClick() {
        inputRef.current?.focus();
        setSearchFocused(true);
    }

    function handleOpenCalendar() {
        setShowSearch(true);
    }

    function handleSearchChange(term: string) {
        setDeliveryQuery(term);
    }

    function handleSearchFocus() {
        setShowSearch(true);
    }

    function handleClear() {
        inputRef.current?.focus();
        setDeliveryQuery('');
        props.onSelectAddress && props.onSelectAddress({ label: undefined });
    }

    function setBlockedDays(day: Moment) {
        if (moment().setTime('0').isAfter(day)) {
            return true;
        }
        return false;
    }

    function isOutsideRange(date: Moment) {
        const isItoutsideRange = checkIfIsOutsideRange({
            startDate,
            endDate,
            date,
            dateRangeFocus,
            blockedDates: undefined,
            bookingMaximumDuration,
        });
        return isItoutsideRange;
    }

    useEffect(() => {
        setDeliveryQuery(shared.start.query || '');
        setEndDate(shared.end.date || null);
        setStartDate(shared.start.date || null);
    }, [shared.start, shared.end.date]);

    const renderDivider = () => <Divider orientation="vertical" height="16px" borderLeftColor={colors.grayLight} />;

    function formatTimeToAmPm(time: string | undefined) {
        if (!time) return '';

        return moment(time, 'HH:mm').format('hh:mma');
    }

    function renderTriggerBody() {
        const setText = (value: string, shouldFocus: FocusedInputShape) => (
            <Text
                variant="sm"
                fontWeight="light"
                w="100%"
                h="100%"
                display="flex"
                alignItems="center"
                p="0 16px"
                backgroundColor={dateRangeFocus === shouldFocus ? '#fff' : '#ffffff00'}
                borderRadius="44px"
                border={dateRangeFocus === shouldFocus ? '1px solid #F2F2F4' : undefined}
                whiteSpace="nowrap"
                onClick={() => {
                    setRangeDateFocus(shouldFocus);
                    setIsCalendarOpen(true);
                }}
            >
                {value}
                {value.toLowerCase() !== 'choose date' && dateRangeFocus === shouldFocus && (
                    <CloseIconThinn className={styles.closeIcon} onClick={handleClearDate} />
                )}
            </Text>
        );

        return (
            <Flex h="100%" alignItems="center">
                {setText(
                    startDate
                        ? `${moment(startDate).format('MMM DD')} ${formatTimeToAmPm(startTime)}`
                        : i18n.t('dateRangeDisplay.noDateText'),
                    'startDate',
                )}
                {!dateRangeFocus && renderDivider()}
                {setText(
                    endDate
                        ? `${moment(endDate).format('MMM DD')} ${formatTimeToAmPm(endTime)}`
                        : i18n.t('dateRangeDisplay.noDateText'),
                    'endDate',
                )}
            </Flex>
        );
    }

    function getCalendarInfo() {
        return (
            <>
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onInputDateRange}
                    focusedDate={dateRangeFocus}
                    blockedDates={setBlockedDays}
                    isOutsideRange={isOutsideRange}
                />

                <Flex p="4">
                    <Divider />
                </Flex>

                <HStack spacing="8" p="4" mb="6">
                    <TimePicker
                        time={startTime}
                        startLimit={startLimit}
                        endLimit="23:30"
                        containerStyle={styles.pickerContainer}
                        style={styles.picker}
                        onChange={time => setStartTime(time)}
                        label={i18n.t('delivery.return.modal.deliveryTime')}
                        format="hh:mma"
                    />

                    <TimePicker
                        time={endTime}
                        startLimit="00:00"
                        endLimit={endLimit}
                        containerStyle={styles.pickerContainer}
                        style={styles.picker}
                        onChange={time => setEndTime(time)}
                        label={i18n.t('delivery.return.modal.returnTime')}
                        format="hh:mma"
                    />
                </HStack>

                <Column className={styles.calHeader} />
            </>
        );
    }

    return (
        <HStack justifyContent="space-between" flex="1">
            <div className={styles.logoContainer}>
                <Link as={RouterLink} to="/" className={styles.logo} variant="noStyle">
                    <Logo size="large" />
                </Link>
            </div>

            {!props.withoutSearch && (
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="44px"
                    m="0"
                    marginInlineStart="0 !important"
                    position="relative"
                    height="48px"
                    _hover={{ bgColor: '#f9f9f9' }}
                    bgColor={dateRangeFocus ? '#f9f9f9' : ''}
                >
                    <Flex
                        minW="250px"
                        p="0 16px"
                        height="100%"
                        borderRadius="44px"
                        _focusWithin={{
                            bgColor: colors.white,
                            border: '1px solid #F2F2F4',
                        }}
                        onClick={handleSearchClick}
                        onMouseEnter={() => setHoverSearch(true)}
                        onMouseLeave={() => setHoverSearch(false)}
                        alignItems="center"
                    >
                        <SearchIcon svg className={styles.icon} />
                        <Box>
                            <AddressAutocomplete
                                onInputSelected={onInputAddressChange}
                                ref={inputRef}
                                withClear
                                isHovering={hoverSearch}
                                endHovering={() => setHoverSearch(false)}
                                value={deliveryQuery}
                                onChange={handleSearchChange}
                                onClear={handleClear}
                                onFocus={handleSearchFocus}
                                className={styles.addressComplete}
                                onInputBlur={() => setSearchFocused(false)}
                                searchOnInitialRender={false}
                            />
                        </Box>
                    </Flex>

                    {!searchFocused && dateRangeFocus !== 'startDate' && renderDivider()}

                    <Popover
                        renderTrigger={renderTriggerBody()}
                        placement="bottom"
                        hideArrow
                        closeOnBlur
                        onOpen={handleOpenCalendar}
                        isOpen={isCalendarOpen}
                        ousideClick={() => {
                            setIsCalendarOpen(false);
                            setRangeDateFocus(null);
                        }}
                    >
                        {getCalendarInfo()}
                    </Popover>

                    {showSearch && (
                        <Button
                            backgroundColor={colors.red}
                            borderRadius="24px"
                            w={82}
                            color={colors.white}
                            fontWeight="normal"
                            fontSize="xsmall"
                            h="28px"
                            mr="16px"
                            ml="20px"
                            onClick={onClickSearch}
                            _hover={{ bgColor: colors.red }}
                        >
                            {i18n.t('sections.searchHeader.searchLabel')}
                        </Button>
                    )}
                </Flex>
            )}

            {auth ? <HeaderMenu /> : <HeaderAuth />}
        </HStack>
    );
}

export default DesktopHeaderSearch;
