import React from 'react';

import styles from './index.module.css';

export type TextProps = {
    children: React.ReactNode;
    className?: string;
    center?: boolean;
    ellipsis?: boolean;
    onClick?: (...args: any[]) => void;
    fontSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'md' | 'sm';
    color?: string;
    fontWeight?: 'bold' | 'semibold' | 'medium' | 'regular' | 'light';
};

/**
 * @default
 * h1 - { font-size: 48px; line-height: 58px }.
 * gary-dark - #3E424D.
 * regular - 400.
 *
 * @param fontSize {string}
 * h1 - { font-size: 48px; line-height: 58px }.
 * h2 - { font-size: var(--font-h4); line-height: 40px }.
 * h3 - { font-size: var(--font-h5); line-height: 36px }.
 * h4 - { font-size: 24px; line-height: var(--line-xxlarge) }.
 * h5 - { font-size: 20px; line-height: var(--line-large) }.
 * md - { font-size: 16px; line-height: 24px }.
 * sm - { font-size: 14px; line-height: var(--line-xsmall) }.
 *
 * @param color {string}
 * gary-dark - #3E424D;
 * grayish - #9DA0AA.
 * gray-light - #B1B5C0.
 *
 * @param fontWeight {string}
 * Bold - 700.
 * Semibold - 600.
 * Medium - 500.
 * Regular - 400.
 * Light - 300.
 */

export function Text(props: TextProps) {
    const { children, className, color, ellipsis, onClick, fontSize, fontWeight } = props;

    const classNames = [styles.text, className];

    if (fontSize) {
        classNames.push(styles[fontSize]);
    }

    if (color) {
        classNames.push(styles[color]);
    }

    if (fontWeight) {
        classNames.push(styles[fontWeight]);
    }

    if (ellipsis) {
        return (
            <div className={classNames.join(' ')} onClick={onClick}>
                {children}
            </div>
        );
    }

    return (
        <span className={classNames.join(' ')} onClick={onClick}>
            {children}
        </span>
    );
}
