import i18n from '../language/i18n';

export const validations = {
    /**
     * validate email
     */
    isEmail: (value: any) => {
        if (!value) return i18n.t('errors.requiredInput');

        const regex = RegExp(
            /([a-z0-9][-a-z0-9_+.]*[a-z0-9])@([a-z0-9][-a-z0-9.]*[a-z0-9]\.(app|arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|([0-9]{1,3}\.{3}[0-9]{1,3}))/,
        );
        if (regex.test(value.toLowerCase()) === false) {
            return i18n.t('errors.invalid.email');
        }

        return null;
    },

    /**
     * validate empty values
     */
    isNotEmpty: (value: string) => {
        if (value && value.trim() === '') {
            return i18n.t('errors.requiredInput');
        }

        return null;
    },

    /**
     * validate name
     */
    validateName: (name: string) => {
        if (!name || name.length === 0) {
            return i18n.t('errors.requiredInput');
        }

        if (name.length <= 2) {
            return i18n.t('errors.invalidName');
        }

        return null;
    },

    /**
     * check passwords equal
     * password validator
     */

    arePasswordsEqual: (password: string, confirmPassword: string) => {
        if (!password || password.length === 0) {
            return i18n.t('errors.requiredInput');
        }

        if (!confirmPassword || confirmPassword.length === 0) {
            return i18n.t('errors.requiredInput');
        }

        if (password !== confirmPassword) {
            return i18n.t('errors.passwordsNotEqual');
        }

        return null;
    },

    isPasswordInvalid: (password: string) => {
        if (password.length < 6) {
            return i18n.t('errors.shortPassword');
        }

        return null;
    },
};
