import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Icon,
    Box,
    useBreakpointValue,
    Button,
} from '@chakra-ui/react';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import i18n from 'language/i18n';

interface WarningLocationModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export function WarningLocationModal({ isOpen, onClose }: WarningLocationModalProps) {
    const titleFontSize = useBreakpointValue({ base: '20px', lg: '24px' });
    const descriptionFontSize = useBreakpointValue({
        base: '15px',
        lg: '16px',
    });
    const modalSize = useBreakpointValue({ base: 'sm', md: 'md' });

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered trapFocus={false} size={modalSize}>
            <ModalOverlay />
            <ModalContent p={[2, 4]}>
                <ModalHeader />
                <ModalCloseButton size="lg" />

                <ModalBody>
                    <Flex flexDir="column" alignItems="center">
                        <Icon as={WarningIcon} w="42px" h="42px" my="5" />

                        <Text fontSize={titleFontSize} fontWeight="medium" mb="4">
                            {i18n.t('sections.warningLocationModal.title')}
                        </Text>
                        <Text fontSize={descriptionFontSize} textAlign="center" fontWeight="light">
                            {i18n.t('sections.warningLocationModal.description')}
                        </Text>

                        <Box mt="8" textAlign="center">
                            <Button size="md" variant="solid" colorScheme="black">
                                {i18n.t('sections.warningLocationModal.checkServiceAreas')}
                            </Button>
                        </Box>
                        <Text
                            as="button"
                            color="gray.500"
                            mt="6"
                            fontSize="lg"
                            textDecoration="underline"
                            onClick={onClose}
                        >
                            {i18n.t('default.tryAgain')}
                        </Text>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
