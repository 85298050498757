import React, { ReactNode, useEffect, useState } from 'react';
import Column from '../column';
import BodyText from '../text/body';
import styles from './index.module.css';

/**
 *
 * @param props
 */
function Radio(props: RadioProps) {
    const [checkedValue, setCheckedValue] = useState(props.value);

    const { value, options, onChange, className } = props;

    /**
     * If the value passed as prop changed, we update the checkedValue
     */
    useEffect(() => {
        setCheckedValue(value);
    }, [value]);

    /**
     * if the options change, we should reset the chosen option
     */
    useEffect(() => {
        if (!checkedValue) {
            const radioValue = options.find(option => !option.disabled);
            radioValue && setCheckedValue(radioValue.value);
        }
    }, [options, checkedValue]);

    /**
     * if the checked value changes in the component's internal state, then issue a call to the
     * underlying callback
     */
    useEffect(() => {
        if (checkedValue && checkedValue !== value) {
            onChange && onChange(checkedValue);
        }
    }, [checkedValue, value, onChange]);

    function onOptionClick(radioValueOption: RadioOption) {
        !radioValueOption.disabled && setCheckedValue(radioValueOption.value);
    }

    function renderOption(option: RadioOption) {
        return (
            <div
                key={option.value}
                className={[styles.option, option.disabled ? styles.disabled : ''].join(' ')}
                onClick={() => onOptionClick(option)}
            >
                <input
                    type="radio"
                    value={option.value}
                    checked={option.value === checkedValue}
                    onChange={() => onOptionClick(option)}
                    disabled={option.disabled}
                />
                <BodyText>
                    {option.label} {option.labelSpan}
                </BodyText>
            </div>
        );
    }

    return <Column className={[styles.container, className].join(' ')}>{props.options.map(renderOption)}</Column>;
}

export default Radio;

export type RadioProps = {
    options: Array<RadioOption>;
    value: string;
    onChange?: (value: string) => void;
    className?: string;
};

export type RadioOption = {
    label: string;
    labelSpan?: ReactNode;
    value: string;
    disabled?: boolean;
};
