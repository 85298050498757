import React, { useState, useEffect, useCallback } from 'react';
import RNModal from 'react-modal';
import { Icon } from '@chakra-ui/react';

import CloseIcon from 'components/base/icon/close';
import Column from 'components/base/column';
import Row from 'components/base/row';
import Text from 'components/base/text';
import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';

import styles from './index.module.css';
import { styles as ChakraStyles } from './styles';

export type ModalProps = {
    show: boolean;
    children: React.ReactNode;
    onClose?: () => void;
    onBack?: () => void;
    size?: 'small' | 'medium' | 'large' | 'fit' | 'bottom-fit' | '50%';
    hideClose?: boolean;
    showReturnButton?: boolean;
    className?: string;
    containerClassName?: string;
    title?: string;
    shouldCloseOnOverlayClick?: boolean;
    headerClassName?: string;
};

export function Modal(props: ModalProps) {
    const {
        show,
        onClose,
        onBack,
        showReturnButton,
        children,
        size,
        hideClose,
        title,
        shouldCloseOnOverlayClick,
        headerClassName,
        containerClassName,
    } = props;

    const [instancesNum, setInstancesNum] = useState(0);

    function handleSize() {
        switch (size) {
            case 'small':
                return styles.modalSmall;
            case 'medium':
                return styles.modalMedium;
            case 'large':
                return styles.modalLarge;
            case 'fit':
                return styles.modalFit;
            case '50%':
                return styles.modal50;
            case 'bottom-fit':
                return styles.bottomFit;
            default:
                return styles.modalSmall;
        }
    }

    function scrollLock() {
        setInstancesNum(prevState => prevState + 1);
        document.body.classList.add(styles.scrollLock);
    }

    const scrollUnlock = useCallback(() => {
        if (instancesNum >= 1) {
            setInstancesNum(prevState => prevState - 1);
        }

        if (instancesNum === 0) {
            document.body.classList.remove(styles.scrollLock);
        }
    }, [instancesNum]);

    useEffect(() => {
        if (show === true) {
            scrollUnlock();
        }
    }, [show, scrollUnlock]);

    return (
        <RNModal
            isOpen={!!show}
            className={[styles.modal, handleSize(), props.className].join(' ')}
            overlayClassName={styles.overlay}
            htmlOpenClassName={styles.htmlOpen}
            bodyOpenClassName={styles.bodyOpen}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick !== null ? shouldCloseOnOverlayClick : true}
            shouldCloseOnEsc={shouldCloseOnOverlayClick !== null ? shouldCloseOnOverlayClick : true}
            onAfterOpen={scrollLock}
            onRequestClose={onClose || undefined}
            ariaHideApp={false}
        >
            <Column className={[styles.childrenContainer, containerClassName].join(' ')}>
                {!hideClose && (
                    <Row className={[styles.headerAligner, headerClassName].join(' ')}>
                        {showReturnButton && <Icon {...ChakraStyles.icon} as={ArrowLeftIcon} onClick={onBack} />}
                        <Text noMargin>{title}</Text>
                        <Icon as={CloseIcon} {...ChakraStyles.icon} onClick={onClose} />
                    </Row>
                )}
                {children}
            </Column>
        </RNModal>
    );
}
