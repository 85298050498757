import { TextProps } from 'components/base/textV2';
import React from 'react';
import styles from './index.module.css';

export default function ErrorText(props: TextProps) {
    const { children } = props;
    const getStyle = (): string => {
        return styles.error;
    };

    function render() {
        return <div className={getStyle()}>{children}</div>;
    }

    return render();
}
