import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { debugContextDevtool } from 'react-context-devtool';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { theme } from 'theme';
import App from './app';

import * as serviceWorker from './serviceWorker';

import 'mapbox-gl/dist/mapbox-gl.css';

import './language/i18n';
import './index.css';

moment.locale('en');

const container = document.getElementById('root');

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ChakraProvider>,
    container,
);

debugContextDevtool(container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
