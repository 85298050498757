import { ChakraStyles } from 'theme';

const styles: ChakraStyles = {
    container: (isMobile?: boolean) => ({
        position: 'sticky',
        top: 0,
        backgroundColor: 'var(--color-white)',
        zIndex: 3,
        boxShadow: isMobile ? '0 2px 16px rgba(0,0,0,.04)' : null,
        marginBottom: 'var(--margin-xlarge)',
    }),

    stack: {
        maxW: 'var(--page-max-width)',
        w: 'var(--page-width)',
        margin: '0 auto',
        p: '10px 0',
        flex: '1',
        justifyContent: 'space-between',
    },
};

export default styles;
