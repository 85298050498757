import { ChakraStyles } from 'theme';

const styles: ChakraStyles = {
    buttonLight: {
        height: '48px',
        borderRadius: '88px',
        fontSize: { base: 'var(--font-xsmall)', lg: 'var(--font)' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--color-red)',
        color: 'white',
        margin: '0 2rem 40px 2rem !important',
        fontWeight: '400',
        lineHeight: { base: '26px', md: '24px', lg: '26px' },
        _hover: {
            textDecoration: 'underline',
            fontWeight: 'var(--font-weight-medium)',
        },
        _active: {
            backgroundColor: 'transparent',
        },
    },
};

export default styles;
