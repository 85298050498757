import React, { useState } from 'react';
import Select, { SelectOption } from 'components/base/select';
import { PaymentMethod } from 'models/paymentMethods';
import { capitalize } from 'typings/string-utils';
import { PaymentMethodModal } from 'components/sections/profile/paymentMethodModal';
import { useToast } from '@chakra-ui/react';
import i18n from 'language/i18n';
import { ReactComponent as IconEmpty } from '../../../../assets/icons/emptycard.svg';
import { ReactComponent as IconAmex } from '../../../../assets/icons/amex.svg';
import { ReactComponent as IconMasterCard } from '../../../../assets/icons/mastercard.svg';
import { ReactComponent as IconVisa } from '../../../../assets/icons/visa.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

const size = { minWidth: 32, minHeight: 32 };
const providers = [
    { name: 'visa', icon: <IconVisa style={size} /> },
    { name: 'mastercard', icon: <IconMasterCard style={size} /> },
    { name: 'amex', icon: <IconAmex style={size} /> },
];

export type PaymentPickerProps = {
    sources?: PaymentMethod[] | null;
    onChange?: (option: string) => void;
    selected?: string;
};

export default function PaymentPicker({ sources, onChange, selected }: PaymentPickerProps) {
    const [formOpen, setFormOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    function renderIcon(brand: string) {
        const item = providers.find(prov => prov.name === brand);
        return item ? item.icon : <IconEmpty style={size} />;
    }

    function onInputClick() {
        if (!sources || sources.length <= 0) {
            setFormOpen(true);
        }
    }

    function onNewCardAdded() {
        toast({
            title: i18n.t('pages.profile.payments.toast.success.creation.title'),
            description: i18n.t('pages.profile.payments.toast.success.creation.description'),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top-right',
        });
    }

    function _onClose() {
        setFormOpen(false);
        setTimeout(() => setLoading(false), 3000);
    }

    function onInputChange(opt: SelectOption) {
        if (opt.value === 'NEW_METHOD') {
            setFormOpen(true);
        } else onChange?.(opt.value);
    }

    const defaultOption: SelectOption = {
        value: 'NEW_METHOD',
        label: i18n.t('pages.profile.myWallet.addPaymentMethod'),
        icon: <PlusIcon />,
    };

    const mappedSources = sources?.map((source: PaymentMethod) => ({
        label: capitalize(`${source.card.brand} *${source.card.last4}`),
        value: source.paymentMethodId,
        icon: renderIcon(source.card.brand),
        data: source,
    }));

    const options = mappedSources ? [defaultOption, ...mappedSources!] : [defaultOption];

    const active = options ? options.find(opt => opt.value === selected) || options![0] : undefined;

    return (
        <>
            <Select
                loading={loading}
                selected={active}
                options={options}
                onChange={onInputChange}
                title="Payment Methods"
                placeholder="Add payment method"
                onClick={onInputClick}
            />
            {formOpen && <PaymentMethodModal isOpen={formOpen} onClose={_onClose} onComplete={onNewCardAdded} />}
        </>
    );
}
