import Column from 'components/base/column';
import i18n from 'language/i18n';
import { Booking } from 'models/booking/Booking';
import React from 'react';
import BookingStatus from '../../bookings/booking-status/index';
import styles from './index.module.css';

type BookingHeroProps = {
    booking: Booking;
};

function BookingHero(props: BookingHeroProps) {
    const { booking } = props;
    return (
        <Column className={styles.container}>
            <div className={styles.title}>{i18n.t('sections.assetDetails.booking.bookingStatus')}</div>
            {booking.status && (
                <BookingStatus status={booking.status}>
                    {i18n.t(`pages.booking.status_${booking.status}`)}
                </BookingStatus>
            )}
        </Column>
    );
}

export default BookingHero;
