import React from 'react';
import Illustration1 from '../../../../assets/images/assets/illustration1.svg';
import Illustration2 from '../../../../assets/images/assets/illustration2.svg';
import Illustration3 from '../../../../assets/images/assets/illustration3.svg';
import i18n from '../../../../language/i18n';
import Column from '../../../base/column';
import styles from './index.module.css';
import ExplanationItem from './item';

function Explanation() {
    return (
        <Column className={styles.container}>
            <ExplanationItem
                image={Illustration1}
                title={i18n.t('sections.assetDetails.explanation.yourRummo.title')}
                description={i18n.t('sections.assetDetails.explanation.yourRummo.description')}
            />
            <ExplanationItem
                image={Illustration2}
                title={i18n.t('sections.assetDetails.explanation.returnCar.title')}
                description={i18n.t('sections.assetDetails.explanation.returnCar.description')}
            />
            <ExplanationItem
                image={Illustration3}
                title={i18n.t('sections.assetDetails.explanation.ourCars.title')}
                description={i18n.t('sections.assetDetails.explanation.ourCars.description')}
            />
        </Column>
    );
}

export default Explanation;
