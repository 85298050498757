import { IconProps } from '@chakra-ui/react';
import { colors } from 'styles/colors';

interface ModalStyleProps {
    icon: IconProps;
}

export const styles: ModalStyleProps = {
    icon: {
        w: '24px',
        h: '24px',
        color: colors.black,
        cursor: 'pointer',
    },
};
