import React, { useState } from 'react';

import Grid from '../../../../base/grid';
import Label from '../../../../base/text/label';
import Radio from '../../../../base/radio';

import i18n from '../../../../../language/i18n';

import styles from './index.module.css';
import { FilterMobileModal } from '../../filter-mobile-modal/mobile';

interface MobileSortFilterProps {
    showMobileSort: boolean;
    onCloseSort: () => void;
    onSortSubmit: (newSort: string) => void;
    sort?: string;
}

export function MobileSortFilter(props: MobileSortFilterProps) {
    const { showMobileSort, sort, onCloseSort, onSortSubmit } = props;
    const [sortOption, setSortOption] = useState(sort || 'asc');

    const options = [
        { label: 'Price: Lowest to Highest', value: 'asc' },
        { label: 'Price: Highest to Lowest', value: 'desc' },
    ];

    // This is handling the submission of each option and closing the modal since the design does not have a submission button in the flow.
    function onSortChange(option: string) {
        setSortOption(option);
        onSortSubmit(option);
        onCloseSort();
    }

    function handleSubmitSort() {
        onSortSubmit(sortOption);
        onCloseSort();
    }

    return (
        <FilterMobileModal
            title={i18n.t('sections.assetList.filterModal.title.sortBy')}
            show={showMobileSort}
            onClose={onCloseSort}
            onSortChange={onSortChange}
            onSubmit={handleSubmitSort}
            footer={false}
        >
            <Label className={styles.filterTitle}>Sort by:</Label>
            <Grid>
                <Radio className={styles.radio} options={options} value={sortOption} onChange={onSortChange} />
            </Grid>
        </FilterMobileModal>
    );
}
