import { Button, Text } from '@chakra-ui/react';
import Card from 'components/base/card';
import CreditCardIcon from 'components/base/icon/credit-card';
import Margin from 'components/base/margin';
import Row from 'components/base/row';
import Splitter from 'components/base/splitter';
import AuthenticationModal from 'components/sections/standalone/authentication/modal';
import { calculateDuration } from 'helpers/calculateDuration';
import { extrasReceiptFormat } from 'helpers/extrasReceiptFormat';
import { insuranceReceiptFormat } from 'helpers/insuranceReceiptFormat';
import { joinClasses } from 'helpers/joinClasses';
import { useAuth, useSharedData } from 'hooks';
import { useWindowSize } from 'hooks/useWindowSize';
import i18n from 'language/i18n';
import { Booking } from 'models/booking/Booking';
import { PaymentMethod } from 'models/paymentMethods';
import { SharedData } from 'models/sharedData/SharedData';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Asset } from 'services/asset.service';
import { Company } from 'services/company.service';
import { Insurance } from 'services/insurances.service';
import { PriceCalculator } from 'services/price.formatter';
import { BookingCancellationConfig } from '../../../../hooks/remoteConfig';
import AssetHero from '../asset-hero';
import BillingInfo from '../billing-info';
import BookingHero from '../booking-hero';
import LocationItem from '../location-item';
import PriceBreakdown from '../price-breakdown';
import PriceDay from '../price-day';
import PriceTotal from '../price-total';
import styles from './index.module.css';

type AssetPanelProps = {
    company?: Company | null;
    asset: Asset;
    extras: string[];
    insurances: Insurance[];
    mode: 'details' | 'checkout-1' | 'checkout-2' | 'checkout-3' | 'booked';
    onSubmit?: () => void;
    onDates?: () => void;
    className?: string;
    showAddInsurances?: boolean;
    disabled?: boolean;
    relative?: boolean;
    startDate?: Moment;
    endDate?: Moment;
    booking?: Booking;
    paymentMethod?: PaymentMethod;
    bookingCancellationConfig: BookingCancellationConfig;
};

type PriceInfo = {
    key?: string;
    label: string;
    value: PriceCalculator;
    info?: string;
};

function AssetPanel({
    asset,
    insurances,
    extras,
    company,
    mode,
    onSubmit,
    onDates,
    className,
    showAddInsurances,
    relative,
    disabled,
    startDate,
    endDate,
    booking,
    paymentMethod,
    bookingCancellationConfig,
}: AssetPanelProps) {
    const context = useAuth();
    const history = useHistory();
    const [showAuth, setShowAuth] = useState(false);
    const { width } = useWindowSize();
    const { shared, assetDetailsBookingDataContext } = useSharedData();

    function displayDate(date?: Moment | null, time?: string | null) {
        if (!date || !time) {
            return undefined;
        }

        const formattedTime = moment(time, 'HH:mm').format('hh:mma');

        return `${date?.format('dddd, MMM DD')}, ${formattedTime}`;
    }

    const auxSharedData = (assetDetailsBookingDataContext || shared) as SharedData;

    function isContinueDisabled() {
        const { start, end } = auxSharedData;

        return !start.date || !start.query || !start.location || !end.date || !end.query || !end.location || disabled;
    }

    function renderPaymentInfo() {
        if (paymentMethod) {
            return `${paymentMethod.card.brand} *${paymentMethod.card.last4}.`;
        }
        return 'N/A';
    }

    function determineEndQueryText() {
        const { start, end } = auxSharedData;

        if (!end.query || !end.location) {
            return undefined;
        }

        if (start.query === end.query) {
            return i18n.t('sections.assetDetails.booking.sameAddressAsDelivery');
        }

        return end.query;
    }

    function _submit() {
        if (context.auth) {
            onSubmit?.();
        } else {
            setShowAuth(true);
        }
    }

    function renderPriceSection(prices: PriceInfo[], total: string) {
        return (
            <>
                <PriceBreakdown
                    open={mode !== 'details'}
                    title={i18n.t('sections.assetDetails.booking.priceBreakdown')}
                    options={prices}
                    showAddInsurances={showAddInsurances}
                    mode={mode}
                />
                <PriceTotal
                    price={total}
                    light={mode === 'checkout-3' || mode === 'booked'}
                    info={i18n.t('pages.assetDetails.taxIncluded')}
                    title={
                        mode === 'checkout-3' || mode === 'booked'
                            ? i18n.t('sections.assetDetails.booking.totalPricePaid')
                            : i18n.t('sections.assetDetails.booking.totalPrice')
                    }
                />
            </>
        );
    }

    function renderDetailsReceipt(price: PriceCalculator, priceList: PriceInfo[], total?: string) {
        const canRenderPriceSection = total !== undefined;
        return (
            <>
                <PriceDay price={price.format(2)} total={total} />
                <Splitter spacing="medium" />
                <LocationItem
                    title={i18n.t('sections.assetDetails.booking.deliveryInfo')}
                    location={auxSharedData.start.query}
                    date={displayDate(auxSharedData.start?.date, auxSharedData.start?.time)}
                />
                <LocationItem
                    title={i18n.t('sections.assetDetails.booking.returnInfo')}
                    location={determineEndQueryText()}
                    date={displayDate(auxSharedData.end?.date, auxSharedData.end?.time)}
                />
                <Button marginBottom="var(--margin-small)" onClick={onDates} variant="link" colorScheme="brand">
                    {i18n.t('sections.assetDetails.booking.changeDatesLabel')}
                </Button>
                {!relative && <Splitter spacing={width < 691 ? 'xlarge' : 'medium'} />}
                {canRenderPriceSection && renderPriceSection(priceList, total!)}
                <span className={styles.message}>
                    <span className={styles.messageIcon}>! </span>
                    {i18n.t('sections.assetDetails.booking.cancelationInfo', {
                        cancellationTime: bookingCancellationConfig.cancellation_time,
                    })}
                </span>
                <Button
                    disabled={isContinueDisabled()}
                    size="xl"
                    w="full"
                    variant="solid"
                    colorScheme="black"
                    onClick={_submit}
                >
                    {i18n.t('sections.assetDetails.booking.reserve')}
                </Button>
                <Margin value="--normal-extra_space" />
                <Text variant="sm" textAlign="center" color="gray.700" fontWeight="light">
                    {i18n.t('sections.assetPanel.noChargeYet')}
                </Text>
            </>
        );
    }

    function renderCheckoutStep1Receipt(priceList: PriceInfo[], total: string) {
        return (
            <>
                {width > 692 && (
                    <>
                        <AssetHero asset={asset} />
                        <Margin className={styles.marginToPriceBreakdown} size="medium" />
                        {!relative && <Splitter spacing={width < 691 ? 'xlarge' : 'medium'} />}
                    </>
                )}
                {renderPriceSection(priceList, total)}
                <span className={styles.message}>
                    <span className={styles.messageIcon}>! </span>
                    {i18n.t('sections.assetDetails.booking.cancelationInfo', {
                        cancellationTime: bookingCancellationConfig.cancellation_time,
                    })}
                </span>
                <Button
                    disabled={isContinueDisabled()}
                    size="xl"
                    w="full"
                    variant="solid"
                    colorScheme="black"
                    onClick={_submit}
                >
                    {i18n.t('default.continue')}
                </Button>
                <Margin value="--normal-extra_space" />
                <Text variant="sm" textAlign="center" color="gray.700" fontWeight="light">
                    {i18n.t('sections.assetPanel.noChargeYet')}
                </Text>
            </>
        );
    }

    function renderCheckoutStep2Receipt(priceList: PriceInfo[], total: string) {
        return (
            <>
                {width > 692 && (
                    <>
                        <AssetHero asset={asset} />
                        <Margin className={styles.marginToPriceBreakdown} size="medium" />
                        {!relative && <Splitter spacing={width < 691 ? 'xlarge' : 'medium'} />}
                    </>
                )}
                {renderPriceSection(priceList, total)}
            </>
        );
    }

    function renderCheckoutStep3Receipt(priceList: PriceInfo[], total: string) {
        return (
            <>
                {relative ? null : booking && <BookingHero booking={booking as Booking} />}
                <Margin className={styles.marginToPriceBreakdown} size="medium" />
                {!relative && <Splitter spacing={width < 691 ? 'xlarge' : 'medium'} />}
                {renderPriceSection(priceList, total)}
                <Splitter spacing="medium" />
                <Text variant="h5" fontWeight="500" textAlign="start">
                    Payment details
                </Text>

                {booking && (
                    <Text
                        fontWeight="500"
                        textAlign="start"
                        className={styles.payProfile}
                    >{`${booking.paymentProfile} Profile`}</Text>
                )}
                <Row className={styles.cardInfo}>
                    <CreditCardIcon />
                    <Text className={styles.cardInfoText}>
                        Paid with <span>{renderPaymentInfo()}</span>
                    </Text>
                </Row>

                {company && (
                    <>
                        <Margin />
                        <BillingInfo
                            company={company}
                            simple
                            title={i18n.t('pages.assetDetails.booking.billingInfo.title')}
                        />
                    </>
                )}
            </>
        );
    }

    function renderBookedStepReceipt(priceList: PriceInfo[], total: string) {
        return (
            <>
                {relative ? null : booking && <BookingHero booking={booking as Booking} />}
                <Margin className={styles.marginToPriceBreakdown} size="medium" />
                {!relative && <Splitter spacing={width < 691 ? 'xlarge' : 'medium'} />}
                {renderPriceSection(priceList, total)}
                {company && (
                    <>
                        <Splitter spacing="medium" />
                        <Text variant="h5" fontWeight="500">
                            Payment details
                        </Text>
                        {booking && (
                            <Text
                                fontWeight="500"
                                className={styles.payProfile}
                            >{`${booking.paymentProfile} Profile`}</Text>
                        )}
                        <Row className={styles.cardInfo}>
                            <CreditCardIcon />
                            <Text className={styles.cardInfoText}>
                                Paid with <span>{renderPaymentInfo()}</span>
                            </Text>
                        </Row>
                        <Margin />
                        <BillingInfo
                            company={company}
                            simple
                            title={i18n.t('pages.assetDetails.booking.billingInfo.title')}
                        />
                    </>
                )}
            </>
        );
    }

    function renderReceipt() {
        const start = auxSharedData.start.date || startDate;
        const end = auxSharedData.end.date || endDate;
        const assetPrice = new PriceCalculator(asset.pricePerDay);
        let prices: PriceInfo[] = [];
        let total: any;
        const { currency } = asset.pricePerDay;

        if (start && end) {
            const duration = calculateDuration(start, end)!;

            const mappedInsurances = insuranceReceiptFormat(insurances, duration);
            const mappedExtras = extrasReceiptFormat(asset.extras, extras, duration);

            prices = [
                {
                    label: `${duration}-day rental`,
                    value: assetPrice.clone().multiply(duration),
                    info: `(${duration} x ${assetPrice.format(2).slice(1)})`,
                },
                ...mappedInsurances,
                ...mappedExtras,
            ];

            total = new PriceCalculator({ amount: 0, currency, precision: 4 })
                .total(prices.map(price => price.value))
                .format(2);
        }

        switch (mode) {
            case 'details':
                return renderDetailsReceipt(assetPrice, prices, total);
            case 'checkout-1':
                return renderCheckoutStep1Receipt(prices, total!);
            case 'checkout-2':
                return renderCheckoutStep2Receipt(prices, total!);
            case 'checkout-3':
                return renderCheckoutStep3Receipt(prices, total!);
            case 'booked':
                return renderBookedStepReceipt(prices, total!);
            default:
                break;
        }
    }

    return (
        <>
            <Card className={joinClasses(styles.card, relative ? styles.relative : '', className || '')}>
                {renderReceipt()}
            </Card>
            <AuthenticationModal
                history={history}
                isOpen={showAuth}
                onModalClosed={() => setShowAuth(false)}
                section="login"
            />
        </>
    );
}

export default AssetPanel;
