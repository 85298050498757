import { PaymentMethod } from 'models/paymentMethods';
import { auth, firestore } from './persistence';
import { AuthenticatedService } from './core/authenticated.service';
import FirestoreService from './firestore.service';

const UsersCollection = 'users';
const PaymentMethodsCollection = 'payment_methods';
const SourcesCollection = 'sources';
const RequestCollection = 'request';
const stripe = 'stripe';

class PaymentMethodsService extends AuthenticatedService {
    /**
     * GET all payment methods
     * @returns payment methods
     */
    async getAll() {
        try {
            const result = await firestore
                .collection(UsersCollection)
                .doc(this.userId)
                .collection(PaymentMethodsCollection)
                .doc(stripe)
                .collection(SourcesCollection)
                .get();
            return result.docs.map(doc => ({ ...doc.data(), ref: doc.ref } as PaymentMethod));
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    async get(id: string) {
        try {
            const result = await firestore
                .collection(UsersCollection)
                .doc(this.userId)
                .collection(PaymentMethodsCollection)
                .doc(stripe)
                .collection(SourcesCollection)
                .doc(id)
                .get();
            return result.data() as PaymentMethod;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    getListener(onNext: (sources: PaymentMethod[]) => void, onError?: (error: Error) => void) {
        return firestore
            .collection(UsersCollection)
            .doc(auth.currentUser?.uid)
            .collection(PaymentMethodsCollection)
            .doc(stripe)
            .collection(SourcesCollection)
            .onSnapshot(snap => {
                const data = snap.docs.map(doc => ({ ...doc.data(), ref: doc.ref } as PaymentMethod));
                onNext(data);
            }, onError);
    }

    async create(): Promise<any> {
        const userRef = firestore.collection(UsersCollection).doc(auth.currentUser?.uid);

        const createPaymentMethodRequestRef = await firestore.collection(RequestCollection).add({
            createdByRef: userRef,
            action: 'createSetupIntent',
            kind: 'paymentMethod',
            status: 'pending',
            payload: { userRef },
            creationDate: new Date(),
        });

        let unsubscribeSnapshot: () => void | undefined;
        let timeoutId: NodeJS.Timeout | undefined;
        return new Promise((resolve, reject) => {
            unsubscribeSnapshot = createPaymentMethodRequestRef.onSnapshot(
                (snapshot: firebase.firestore.DocumentSnapshot) => {
                    const data = snapshot.data();

                    switch (data && data.status) {
                        case 'success':
                            resolve(data);
                            break;
                        case 'failure':
                            reject({});
                            break;
                        default:
                    }
                },
            );

            timeoutId = setTimeout(() => {
                reject({ message: 'Request timed out' });
            }, 10000);
        }).finally(() => {
            // Clear listener and timeout on promise reject/resolve
            unsubscribeSnapshot && unsubscribeSnapshot();

            if (timeoutId) clearTimeout(timeoutId);
        });
    }

    async delete(request: any) {
        try {
            const userRef = firestore.collection(UsersCollection).doc(auth.currentUser?.uid);
            const requestCollection = firestore.collection(RequestCollection);

            const { action, payload } = request;

            const { paymentMethodRef } = payload;

            const deletePaymentMethodRequest = {
                createdByRef: userRef,
                action,
                kind: 'paymentMethod',
                status: 'pending',
                payload: { paymentMethodRef },
                creationDate: new Date(),
                updatedDate: new Date(),
            };

            return FirestoreService.createSnapshotRequest(requestCollection, deletePaymentMethodRequest, 'success');
        } catch (error) {
            console.log('Error with payment method deletion:::', error);
            return false;
        }
    }
}

export default new PaymentMethodsService();
