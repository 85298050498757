import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Link, textDecoration } from '@chakra-ui/react';
import styles from './index.module.css';
import Column from '../../../base/column';
import { Logo } from '../../../logo';
import Text from '../../../base/text';
import Row from '../../../base/row';
import i18n from '../../../../language/i18n';
import PageContainer from '../../../base/pageContainer';

function Footer() {
    const { pathname } = useLocation();

    if (pathname === '/search') {
        return null;
    }

    return (
        <div id="footer" className={styles.footer}>
            <PageContainer className={styles.container}>
                <div className={styles.mainSectionsContainer}>
                    <Column className={styles.footerHeaderContainer}>
                        <Row>
                            <Link as={RouterLink} to="/" className={styles.logo}>
                                <Logo />
                            </Link>
                        </Row>
                        <Row>
                            <Text className={styles.footerDescription}>{i18n.t('sections.footer.description')}</Text>
                        </Row>
                    </Column>
                    <div className={styles.allLinksSectionsContainer}>
                        <div className={styles.linksSectionsContainer}>
                            <Column className={[styles.companyLinksContainer, styles.linkContainer].join(' ')}>
                                <Text className={styles.subtitle}>{i18n.t('sections.footer.company')}</Text>
                                <Link as={RouterLink} to="/#">
                                    <Text className={styles.text}>{i18n.t('sections.footer.blog')}</Text>
                                </Link>
                                <Link as={RouterLink} to="/team">
                                    <Text className={styles.text}>{i18n.t('sections.footer.team')}</Text>
                                </Link>

                                <Link as={RouterLink} to="/business">
                                    <Text className={styles.text}>{i18n.t('sections.footer.business')}</Text>
                                </Link>
                                <Link href="https://rummo.factorialhr.com/" isExternal>
                                    <Text className={styles.text}>{i18n.t('sections.footer.careers')}</Text>
                                </Link>
                            </Column>
                            <Column className={[styles.rummoLinksContainer, styles.linkContainer].join(' ')}>
                                <Text className={styles.subtitle}>{i18n.t('sections.footer.rummo')}</Text>
                                <Link as={RouterLink} to="/faq">
                                    {' '}
                                    <Text className={styles.text}>{i18n.t('sections.footer.faq')}</Text>
                                </Link>
                                <Link as={RouterLink} to="/#" isExternal>
                                    <Text className={styles.text}>{i18n.t('sections.footer.terms')}</Text>
                                </Link>
                                <Link as={RouterLink} to="/#" isExternal>
                                    <Text className={styles.text}>{i18n.t('sections.footer.privacy')}</Text>
                                </Link>
                            </Column>
                        </div>
                        <Column className={styles.contactsContainer}>
                            <Row>
                                <Text className={styles.subtitle}>{i18n.t('sections.footer.contacts')}</Text>
                            </Row>
                            <Row>
                                <Text className={styles.text}>{i18n.t('sections.footer.doubt.contact')}</Text>
                            </Row>
                            <Row className={styles.socialSubtitle}>
                                <Link
                                    href="tel: +351 215 891 580"
                                    className={styles.contactLink}
                                    fontSize="var(--font-large)"
                                    color="black"
                                    textDecor="none"
                                    fontWeight="var(--font-weight-medium)"
                                    lineHeight="var(--line-large)"
                                    marginBottom="var(--margin-small)"
                                    _hover={{ textDecoration: 'none' }}
                                >
                                    {i18n.t('sections.footer.contactNumber')}
                                </Link>
                            </Row>
                            <Row>
                                <Text className={styles.text}>{i18n.t('sections.footer.doubt.email')}</Text>
                            </Row>
                            <Row>
                                <Link
                                    href="mailto:rummo@rummo.com"
                                    className={styles.contactLink}
                                    fontSize="var(--font-large)"
                                    color="black"
                                    textDecor="none"
                                    fontWeight="var(--font-weight-medium)"
                                    lineHeight="var(--line-large)"
                                    marginBottom="var(--margin-small)"
                                    _hover={{ textDecoration: 'none' }}
                                >
                                    {i18n.t('sections.footer.email')}
                                </Link>
                            </Row>
                        </Column>
                    </div>
                </div>
            </PageContainer>
            <Row className={styles.copyrights}>
                &copy; {moment().format('YYYY')} {i18n.t('sections.footer.allRights')}
            </Row>
        </div>
    );
}

export default Footer;
