import React from 'react';

import imgWhite from '../../../../assets/icons/search-white.svg';
import imgBlack, { ReactComponent as IconSvg } from '../../../../assets/icons/search.svg';
import Icon from '..';

export default class SearchIcon extends React.Component<any> {
    render() {
        const { color, svg, className } = this.props;

        if (svg) return <IconSvg className={className} />;

        const alt = 'search';
        switch (color) {
            case 'white':
                return <Icon {...this.props} img={imgWhite} alt={alt} />;
            default:
                return <Icon {...this.props} img={imgBlack} alt={alt} />;
        }
    }
}
