import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash2, FiBriefcase } from 'react-icons/fi';

import Row from 'components/base/row';
import Column from 'components/base/column';

import { Text } from 'components/base/textV2';
import { Accordion } from 'components/base/accordion';

import i18n from 'language/i18n';

import CompanyService, { Company } from 'services/company.service';
import { DocumentReference } from 'services/persistence';
import { H3 } from 'components/base/text/h3';
import { Button } from '@chakra-ui/react';
import styles from './index.module.css';

const wallets: any[] = [];

interface BusinessWalletProps {
    companyRef: DocumentReference;
    className?: string;
}
export default function BusinessWallet(props: BusinessWalletProps) {
    const { companyRef } = props;
    const [expandedPaymentMethods, setExpandedPaymentMethods] = useState(false);
    const [expandedMainBilling, setExpandedMainBilling] = useState(false);
    const [company, setCompany] = useState<Company>();

    function getCompany() {
        CompanyService.get(companyRef.id).then(data => {
            if (data) {
                setCompany(data);
            }
        });
    }

    function onMount() {
        getCompany();
    }

    useEffect(onMount, []);

    return (
        <Column>
            <H3 className={styles.paymentProfile}>{i18n.t('pages.profile.myWallet.paymentProfile')}</H3>
            <Row>
                <Button _hover={{ outline: 'unset' }} cursor="default" variant="outline" colorScheme="black" size="sm">
                    <FiBriefcase className={styles.briefcaseIcon} />
                    {i18n.t('pages.profile.myWallet.businessProfileBtn')}
                </Button>
            </Row>

            <Accordion
                className={styles.accordionContainer}
                headerClassName={styles.accordionHeader}
                i={0}
                title={i18n.t('pages.profile.myWallet.paymentMethods')}
                expanded={expandedPaymentMethods}
                setExpanded={setExpandedPaymentMethods}
            >
                {wallets.length > 0 ? (
                    wallets.map(wallet => (
                        <Row key={wallet.title} className={styles.accountInfo}>
                            <Column>
                                <Text fontSize="md" fontWeight="medium">
                                    {wallet.title}
                                </Text>
                                <Text font-size="sm" fontWeight="light" color="gray-light">
                                    {wallet.subtitle}
                                </Text>
                                <Text font-size="sm" fontWeight="light">
                                    {wallet.moneyLeft}
                                </Text>
                            </Column>
                            <Row className={styles.iconsContainer}>
                                <FiEdit className={styles.icon} />
                                <FiTrash2 className={styles.icon} />
                            </Row>
                        </Row>
                    ))
                ) : (
                    <Row className={styles.noCards}>
                        <Text font-size="sm" fontWeight="light" color="gray-light">
                            No cards added yet
                        </Text>
                    </Row>
                )}
            </Accordion>
            <Accordion
                headerClassName={styles.accordionHeader}
                i={1}
                title={i18n.t('pages.profile.myWallet.mainBillingInfo')}
                expanded={expandedMainBilling}
                setExpanded={setExpandedMainBilling}
                transition={{ enter: { duration: 15 } }}
            >
                <Column className={styles.mainBilling}>
                    <Row>
                        <Text fontSize="sm" fontWeight="medium">
                            {i18n.t('pages.profile.myWallet.companyNameLabel')}:
                        </Text>
                        <Text fontSize="sm">{company?.name}</Text>
                    </Row>
                    <Row>
                        <Text fontSize="sm" fontWeight="medium">
                            {i18n.t('pages.profile.myWallet.companyAddressLabel')}:
                        </Text>
                        <Text fontSize="sm">{company?.address}</Text>
                    </Row>
                    <Row>
                        <Text fontSize="sm" fontWeight="medium">
                            {i18n.t('pages.profile.myWallet.companyVatLabel')}:
                        </Text>
                        <Text fontSize="sm">{company?.vat}</Text>
                    </Row>
                </Column>
            </Accordion>
        </Column>
    );
}
