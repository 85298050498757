import { ButtonProps, FlexProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<ButtonProps & FlexProps> & any = {
    buttonsFlex: {
        display: 'flex',
        flexDirection: { base: 'column', lg: 'row-reverse' },
        alignItems: { base: 'flex-start', lg: 'center' },
        justifyContent: { lg: 'center' },
        justifySelf: { lg: 'end' },
        zIndex: { lg: '3' },
        gap: '16px',
        marginBottom: { base: 'var(--margin-large)', lg: 'unset' },
    },
};

export default styles;
