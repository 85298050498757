import {
    Box,
    Button,
    Center,
    Divider,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Text,
} from '@chakra-ui/react';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';

import DateRangePicker, { DateRangeFocus } from 'components/base/dateRangePicker';
import CloseIconThinn from 'components/base/icon/closeThin';
import ModalNew from 'components/base/modalNew';
import TimePicker from 'components/base/timePicker';

import { useOperatingAreas, useRemoteConfig, useWindowSize, useCalendarLimits } from 'hooks';
import i18n from 'language/i18n';
import { Coordinate } from 'models/Coordinate';

import Loading from 'components/base/loading';
import { calculateDuration } from 'helpers/calculateDuration';
import {
    BookingCalendarDatesChangesInformation,
    handleBookingCalendarDatesChanges,
} from 'helpers/handleBookingCalendarDatesChanges';
import checkIfIsOutsideRange from 'helpers/isOutsideRange';
import { LocationItem } from 'models/sharedData/LocationItem';
import { SearchFilters } from 'models/sharedData/SearchFilters';
import { createSearchObject, SearchObject } from 'models/sharedData/SearchObject';
import { TimeSlots } from 'models/sharedData/TimeSlots';
import { colors } from 'styles/colors';
import DatesDisplay from '../dates-display';
import LocationDeliveryDisplay from '../location-delivery-display';
import LocationDeliveryPicker from '../location-delivery-picker';
import LocationReturnPicker from '../location-return-picker';
import styles from './styles';

export interface DeliveryReturnModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: (
        deliveryObject: SearchObject,
        returnObject: SearchObject,
        returnLocationHistory?: LocationItem[],
    ) => void;
    asset?: any;
    blockedDates?: string[];
    loading?: boolean;
    initFilters: SearchFilters;
    resetOnSubmit?: boolean;
}

export interface ReturnLocationHistory {
    label: string;
    value: string;
}

function DeliveryReturnModal({
    isOpen,
    onClose,
    onSuccess,
    asset,
    blockedDates,
    loading,
    initFilters,
    resetOnSubmit,
}: DeliveryReturnModalProps) {
    const { bookingMaximumDuration } = useRemoteConfig();
    const { width } = useWindowSize();

    function determineInitialSelectedReturnOption(deliveryObject?: SearchObject, returnObject?: SearchObject) {
        const sameCoordinates =
            returnObject?.location?.latitude === deliveryObject?.location?.latitude &&
            returnObject?.location?.longitude === deliveryObject?.location?.longitude;

        if (sameCoordinates || !returnObject || !returnObject.location || !returnObject.query) {
            return 'same_address';
        }

        return `custom_location_${returnObject.location.longitude},${returnObject.location.latitude}`;
    }

    const [dateRangeFocus, setDateRangeFocus] = useState<DateRangeFocus>('startDate');
    const [startDate, setStartDate] = useState<Moment | null>(initFilters?.start?.date?.clone() || null);
    const [endDate, setEndDate] = useState<Moment | null>(initFilters?.end?.date?.clone() || null);

    const [startTime, setStartTime] = useState(initFilters?.start?.time || '09:00');
    const [endTime, setEndTime] = useState(initFilters?.end?.time || '09:00');
    const [returnIsValid, setReturnIsValid] = useState(true);
    const [deliveryIsValid, setDeliveryIsValid] = useState(true);
    const [dataHasChanged, setDataHasChanged] = useState(false);

    const [deliveryLocationQuery, setDeliveryLocationQuery] = useState<string>(initFilters?.start?.query || '');
    const [deliveryLocationCoordinate, setDeliveryLocationCoordinate] = useState<Coordinate | undefined | null>(
        initFilters?.start?.location,
    );

    const [returnLocationQuery, setReturnLocationQuery] = useState<string>(initFilters?.end.query || '');

    const [returnLocationCoordinate, setReturnLocationCoordinate] = useState<Coordinate | undefined | null>(
        initFilters?.end?.location,
    );
    const [selectedReturnOption, setSelectedReturnOption] = useState<string>(
        determineInitialSelectedReturnOption(initFilters?.start, initFilters?.end),
    );

    const [returnLocationHistory, setReturnLocationHistory] = useState<ReturnLocationHistory[]>(
        initFilters.returnLocationHistory!,
    );

    const { startLimit, updateStartLimit, endLimit } = useCalendarLimits({
        startDate,
        startTime,
        endDate,
        endTime,
        setEndTime,
    });

    const { checkLocationInBoundingBox, openWarningModal } = useOperatingAreas();

    function onInputDateRange(newStartDate: Moment | null, newEndDate: Moment | null) {
        const bookingCalendarInformation = new BookingCalendarDatesChangesInformation({
            newStartDate,
            newEndDate,
            previousStartDate: startDate,
            previousEndDate: endDate,
            startTime,
            endTime,
            dateRangeFocus,
            bookingMaximumDuration,
            updateStartLimit,
            isFocusedDateVisible: dataHasChanged,
        });

        const data = handleBookingCalendarDatesChanges(bookingCalendarInformation);

        setDateRangeFocus(data.focusedDate);
        setStartDate(data.startDate);
        setStartTime(data.startTime);
        setEndDate(data.endDate);
        setEndTime(data.endTime);
        setDataHasChanged(true);
    }

    function onNewReturnLocation(location: { label: string; value: string }) {
        const stringCoordinate = location.value.split('_')[2];
        const [stringLongitude, stringLatitude] = stringCoordinate.split(',');
        const longitude = Number(stringLongitude);
        const latitude = Number(stringLatitude);
        let isAvailable = true;

        // Check Operating Areas in Return location
        if (longitude && latitude) {
            isAvailable = checkLocationInBoundingBox({ latitude, longitude });
            setReturnIsValid(isAvailable);
        }

        const existentOption = returnLocationHistory.find(currOpt => currOpt.value === location.value);
        if (existentOption) {
            setReturnLocationQuery(location.label);
            setReturnLocationCoordinate({ latitude, longitude });
            return;
        }
        setDataHasChanged(true);
        setReturnLocationHistory([location, ...(returnLocationHistory || [])]);
        setReturnLocationQuery(location.label);
        setReturnLocationCoordinate({ latitude, longitude });
        setSelectedReturnOption(location.value);
    }

    function onNewDeliveryLocation(label: string, value: number[]) {
        let isAvailable = true;
        const [longitude, latitude] = value;
        // Check Operating Areas in Delivery location
        if (longitude && latitude) {
            isAvailable = checkLocationInBoundingBox({ latitude, longitude });
            setDeliveryIsValid(isAvailable);
            if (selectedReturnOption === 'same_address') {
                setReturnIsValid(isAvailable);
            }
        }
        setDataHasChanged(true);
        setDeliveryLocationQuery(label);
        setDeliveryLocationCoordinate({ latitude, longitude });
    }

    function onClearDates() {
        setStartDate(null);
        setEndDate(null);
        setStartTime('09:00');
        setEndTime('09:00');
        setDateRangeFocus('startDate');
        updateStartLimit(null);
    }

    function reset() {
        setSelectedReturnOption(determineInitialSelectedReturnOption(initFilters?.end));
        setDeliveryLocationQuery(initFilters.start.query!);
        setDeliveryLocationCoordinate(initFilters.start.location!);
        setReturnLocationQuery(initFilters.end.query!);
        setReturnLocationCoordinate(initFilters.end.location!);
        setStartDate(initFilters.start!.date!);
        setEndDate(initFilters.end!.date!);
        setStartTime(initFilters?.start?.time || '09:00');
        setEndTime(initFilters?.end?.time || '09:00');
        setReturnLocationHistory(initFilters.returnLocationHistory!);
        setReturnIsValid(true);
        setDeliveryIsValid(true);
    }

    function _onClose() {
        reset();
        onClose?.();
    }

    function onSave() {
        const startDateWithTime = startDate?.clone().setTime(startTime) || null;
        const endDateWithTime = endDate?.clone().setTime(endTime) || null;
        let returnQuery;
        let returnCoordinate;

        if (selectedReturnOption === 'same_address' && deliveryIsValid) {
            returnQuery = deliveryLocationQuery;
            returnCoordinate = deliveryLocationCoordinate;
        } else {
            returnQuery = returnLocationQuery;
            returnCoordinate = returnLocationCoordinate;
        }

        const deliveryObject = createSearchObject(
            startDateWithTime!,
            startTime,
            deliveryLocationCoordinate!,
            deliveryLocationQuery,
        );
        const returnObject = createSearchObject(endDateWithTime!, endTime, returnCoordinate!, returnQuery);

        if (!returnIsValid || !deliveryIsValid) {
            openWarningModal();
            return;
        }

        onSuccess?.(deliveryObject!, returnObject!, returnLocationHistory);
        resetOnSubmit && reset();
    }

    function selectReturnLocation(value: string) {
        setReturnLocationQuery(value);
    }

    function confirmDates(start: Moment, end: Moment) {
        setStartDate(start);
        setEndDate(end);
        setStartTime(start.format('HH:mm') as TimeSlots);
        setEndTime(end.format('HH:mm') as TimeSlots);
        setDataHasChanged(true);
    }

    function setBlockedDays(dateToCompare: Moment) {
        const now = moment().setTime('0');

        if (now.isAfter(dateToCompare)) {
            return true;
        }

        if (blockedDates) {
            const isBlocked = blockedDates.some(d => moment(d).isSame(dateToCompare, 'D'));

            if (isBlocked) {
                return true;
            }

            const before = dateToCompare.clone().subtract(1, 'd');
            const after = dateToCompare.clone().add(1, 'd');
            const singleDate =
                blockedDates.some(d => moment(d).isSame(before, 'D')) &&
                blockedDates.some(d => moment(d).isSame(after, 'D'));

            if (singleDate) {
                return true;
            }
        }

        return false;
    }

    function isOutsideRange(date: Moment) {
        const isItOutsideRange = checkIfIsOutsideRange({
            startDate,
            endDate,
            date,
            dateRangeFocus,
            blockedDates,
            bookingMaximumDuration,
        });
        return isItOutsideRange;
    }

    const changeReturnOption = (val: string) => {
        setDataHasChanged(true);
        setSelectedReturnOption(val);
        if (val === 'same_address') {
            setReturnIsValid(deliveryIsValid);
            setReturnLocationQuery(deliveryLocationQuery);
        }
    };

    function headerText() {
        if (startDate && endDate) {
            const fullStartDate = startDate.clone().setTime(startTime);
            const fullEndDate = endDate.clone().setTime(endTime);
            const duration = calculateDuration(fullStartDate, fullEndDate);
            return `${duration}-${i18n.t('delivery.return.modal.title')} New York City`;
        }

        return 'Rental Dates';
    }

    function subtitleText() {
        if (!startDate && !endDate) {
            return 'Choose dates to check availability';
        }

        const startDateText = startDate ? startDate.format('MMM D, YYYY') : 'Choose a delivery date';
        const endDateText = endDate ? endDate.format('MMM D, YYYY') : 'Choose a return date';

        return `${startDateText} - ${endDateText}`;
    }

    function renderMobileDeliveryPicker() {
        return (
            <LocationDeliveryDisplay
                title={i18n.t('delivery.return.modal.deliveryLocation')}
                defaultValue={deliveryLocationQuery}
                mt="32px"
                onChange={onNewDeliveryLocation}
            />
        );
    }

    function renderMobileReturnPicker() {
        return (
            <LocationReturnPicker
                options={returnLocationHistory}
                title={i18n.t('delivery.return.modal.returnLocation')}
                selectedOption={selectedReturnOption}
                onLocationPush={onNewReturnLocation}
                onChange={changeReturnOption}
                mt="32px"
                selectReturnLocation={selectReturnLocation}
                withDrawer
                deliveryLocation={deliveryLocationQuery}
            />
        );
    }

    function renderDateDisplay() {
        return (
            <DatesDisplay
                asset={asset}
                startDate={startDate}
                endDate={endDate}
                onConfirm={confirmDates}
                blockedDates={blockedDates}
            />
        );
    }

    // disable button variables for comparison
    const areDatesNotSet = startDate === null || endDate === null || startDate === undefined || endDate === undefined;
    const isDeliveryLocationSame = deliveryLocationQuery === initFilters.start.query;
    const isReturnLocationSame = returnLocationQuery === initFilters.end.query;
    const areDatesSameAsInitial =
        startDate?.format('MM/DD/YYYY') === initFilters.start.date?.format('MM/DD/YYYY') &&
        endDate?.format('MM/DD/YYYY') === initFilters.end.date?.format('MM/DD/YYYY');
    const areTimesSameAsInitial = startTime === initFilters.start.time && endTime === initFilters.end.time;

    /**
     * @info This was turned into a variable in order to get the updated values and prevent re-renders every time the data changes.
     */
    const disableVerification =
        (isDeliveryLocationSame && isReturnLocationSame && areDatesSameAsInitial && areTimesSameAsInitial) ||
        dataHasChanged === false ||
        areDatesNotSet;

    function renderSaveButton() {
        return (
            <Button disabled={disableVerification} variant="solid" colorScheme="black" size="md" onClick={onSave}>
                {i18n.t('delivery.return.modal.footer.saveChanges')}
            </Button>
        );
    }

    function renderCancelButton() {
        return (
            <Button variant="link" colorScheme="black" onClick={onClose}>
                {i18n.t('default.cancel')}
            </Button>
        );
    }

    const renderDesktopModal = useCallback(() => {
        const footer = (
            <Center {...styles.footer} justifyContent="space-between" flex="1">
                <Button variant="link" colorScheme="black" onClick={onClearDates}>
                    {i18n.t('delivery.return.modal.footer.clearDates')}
                </Button>
                {renderSaveButton()}
            </Center>
        );

        return (
            <ModalNew
                bodyStyles={styles.desktopModalBody}
                isOpen={isOpen}
                onClose={_onClose}
                title={headerText()}
                description={subtitleText()}
                size="3xl"
                minH="3xl"
                footer={footer}
                loading={loading}
                modalContent={styles.content}
                modalHeader={styles.modalHeader}
            >
                <HStack spacing="8" alignItems="center">
                    <LocationDeliveryPicker
                        title={i18n.t('delivery.return.modal.deliveryLocation')}
                        defaultValue={deliveryLocationQuery}
                        onChange={onNewDeliveryLocation}
                    />
                    <LocationReturnPicker
                        options={returnLocationHistory}
                        title={i18n.t('delivery.return.modal.returnLocation')}
                        selectedOption={selectedReturnOption}
                        onLocationPush={onNewReturnLocation}
                        onChange={changeReturnOption}
                        deliveryLocation={deliveryLocationQuery}
                    />
                </HStack>

                <Divider mb="4" mt="8" />

                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onInputDateRange}
                    blockedDates={setBlockedDays}
                    isOutsideRange={isOutsideRange}
                    focusedDate={dateRangeFocus}
                />

                <Divider mb="6" mt="6" />

                <HStack spacing="8" p="4" mb="6">
                    <TimePicker
                        time={startTime}
                        startLimit={startLimit}
                        endLimit="23:30"
                        containerStyle={styles.pickerContainer}
                        style={styles.picker}
                        onChange={time => {
                            setStartTime(time);
                            setDataHasChanged(true);
                        }}
                        label={i18n.t('delivery.return.modal.deliveryTime')}
                        format="hh:mma"
                    />

                    <TimePicker
                        time={endTime}
                        startLimit="00:00"
                        endLimit={endLimit}
                        containerStyle={styles.pickerContainer}
                        style={styles.picker}
                        onChange={time => {
                            setEndTime(time);
                            setDataHasChanged(true);
                        }}
                        label={i18n.t('delivery.return.modal.returnTime')}
                        format="hh:mma"
                    />
                </HStack>
            </ModalNew>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isOpen,
        startLimit,
        startDate,
        endDate,
        dateRangeFocus,
        startTime,
        endTime,
        returnLocationHistory,
        deliveryLocationQuery,
        selectedReturnOption,
        disableVerification,
        loading,
    ]);

    function renderTabletModal() {
        return (
            <ModalNew
                bodyStyles={styles.tabletModalBody}
                isOpen={isOpen}
                onClose={onClose}
                title={headerText()}
                loading={loading}
            >
                <Text variant="sm" fontWeight="light" color={colors.grayLight}>
                    {subtitleText()}
                </Text>
                {renderMobileDeliveryPicker()}
                {renderMobileReturnPicker()}
                {renderDateDisplay()}
                <Flex justifyContent="space-between" alignItems="center" marginTop="5">
                    {renderCancelButton()}
                    {renderSaveButton()}
                </Flex>
            </ModalNew>
        );
    }

    if (width >= 928) {
        return renderDesktopModal();
    }

    if (width >= 692) {
        return renderTabletModal();
    }

    return (
        <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
            <DrawerOverlay />
            <DrawerContent borderRadius="8px 8px 0px 0px" overflowY="auto">
                {loading ? (
                    <Loading spinnerSize={40} fullScreen />
                ) : (
                    <>
                        <Box {...styles.mobileDrawer}>
                            <Box paddingBottom="40px">
                                <Flex p="32px 0 22px 0">
                                    <CloseIconThinn onClick={onClose} />
                                </Flex>
                                <DrawerHeader p="0" fontWeight="500" fontSize="20px" lineHeight="28px">
                                    {headerText()}
                                </DrawerHeader>
                                <DrawerBody p="0">
                                    <Text variant="sm" fontWeight="light" color={colors.grayLight}>
                                        {subtitleText()}
                                    </Text>

                                    {renderMobileDeliveryPicker()}
                                    {renderMobileReturnPicker()}
                                    {renderDateDisplay()}
                                </DrawerBody>
                            </Box>

                            <DrawerFooter justifyContent="space-between" paddingX="0" paddingTop="0">
                                {renderCancelButton()}
                                {renderSaveButton()}
                            </DrawerFooter>
                        </Box>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
}

export default DeliveryReturnModal;
