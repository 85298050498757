import { TextProps, FlexProps } from '@chakra-ui/react';

import { ChakraStyles } from 'theme';
import { colors } from 'styles/colors';

export const styles: ChakraStyles<TextProps & FlexProps> & any = {
    container: {
        maxW: '100%',
        align: 'center',
        justify: 'space-between',
    },
    icon: {
        w: '26.67px',
        h: '26.67px',
    },

    seatsCount: {
        color: colors.grayish,
        mr: '1rem',
        ml: '1rem',
        fontWeight: 300,
    },

    label: {
        color: colors.black,
        fontWeight: 300,
    },

    seatsComponent: {
        align: 'center',
    },
};
