import React, { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { RemoteConfigProvider } from './remoteConfig';
import { SharedDataProvider } from './sharedData';
import { OperatingAreasProvider } from './operatingAreas';
import { PersonaProvider } from './persona';

interface AppProviderProps {
    children?: ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
    return (
        <RemoteConfigProvider>
            <SharedDataProvider>
                <OperatingAreasProvider>
                    <PersonaProvider>
                        <AuthProvider>{children}</AuthProvider>
                    </PersonaProvider>
                </OperatingAreasProvider>
            </SharedDataProvider>
        </RemoteConfigProvider>
    );
}
