import React from 'react';
import styles from './index.module.css';
import Row from '../row';
import Margin from '../margin';

function FabController(props: FabControllerProps) {
    const { options, fixed, selectedFilters } = props;

    const enabledOptions = options.map(option => (option.disable ? [] : option)).flat();

    function renderItem(option: FabControllerOptions, index: number) {
        const isLastItem = !(index < enabledOptions.length - 1);
        const IsMultipleActions = enabledOptions.length > 1;
        const showSeparator = !isLastItem && IsMultipleActions;

        return option.disable ? null : (
            <React.Fragment key={index}>
                <div className={styles.bottomOptionsButton} onClick={option.action}>
                    {option.icon ? <option.icon className={styles.bottomOptionsIcon} /> : null}
                    {option.label}
                    {option.label === 'Filters' && selectedFilters !== 0 && selectedFilters !== undefined ? (
                        <div className={styles.indicatorBox}>
                            <p className={styles.indicator}>{selectedFilters > 9 ? `+${9}` : selectedFilters}</p>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {showSeparator ? <div className={styles.bottomOptionsSeparator} /> : null}
            </React.Fragment>
        );
    }

    function renderItems(fabOptions: FabControllerOptions[]) {
        return fabOptions.map(renderItem);
    }

    return (
        <>
            <div className={[styles.optionsContainer, fixed ? styles.fixed : ''].join(' ')}>
                <Row className={styles.bottomOptionsWrapper}>{renderItems(enabledOptions)}</Row>
            </div>
            {fixed ? null : (
                <>
                    <Margin />
                    <Margin />
                    <Margin />
                </>
            )}
        </>
    );
}

export type FabControllerProps = {
    options: FabControllerOptions[];
    fixed?: boolean;
    selectedFilters?: number;
};

export type FabControllerOptions = {
    label: string;
    action?: () => void;
    icon?: any;
    disable?: boolean;
};

export default FabController;
