import { InputProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const inputBorder: ChakraStyles<InputProps> & any = {
    base: (isOpen?: boolean) => ({
        rounded: isOpen ? '3xl' : 'full',
        borderColor: isOpen ? 'gray.300' : 'inherit',
        borderBottomRadius: !isOpen ? 'full' : '0',
    }),
    soft: (isOpen?: boolean) => ({
        borderWidth: isOpen ? 1 : 0,
        borderColor: isOpen ? 'gray.300' : 'inherit',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: isOpen ? 'gray.500' : 'gray.200',
        rounded: 0,
    }),
};

const styles: ChakraStyles<InputProps> & any = {
    inputGroup: (isOpen?: boolean) => ({
        shadow: isOpen && 'lg',
    }),
    input: (isOpen?: boolean, soft?: boolean, withIconLeft?: boolean) => ({
        size: 'lg',
        cursor: 'pointer',
        ...(soft ? inputBorder.soft(isOpen) : inputBorder.base(isOpen)),
        fontWeight: 'light',
        textOverflow: 'ellipsis',
        height: '48px',
        paddingLeft: 'var(--margin-medium)',
        ...(withIconLeft ? { pl: 20 } : {}),
        gap: 10,
    }),
    inputIcon: (withIconLeft?: boolean) => ({
        pr: 4,
        color: 'gray.300',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        h: 'full',
        _hover: {
            color: 'gray.500',
        },
        ...(withIconLeft
            ? {
                  pl: 8,
                  minWidth: 20,
              }
            : {}),
    }),
    inputMenu: (soft?: boolean) => ({
        position: 'absolute',
        top: 12,
        left: 0,
        right: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderColor: 'gray.300',
        borderBottomRadius: soft ? 0 : '3xl',
        bg: 'white',
        zIndex: 20,
        overflow: 'hidden',
        shadow: 'lg',
        overflowY: 'scroll',
        overscrollBehavior: 'contain',
        maxHeight: { base: '100px', lg: '150px' },
    }),
    inputMenuItem: {
        p: 4,
        cursor: 'pointer',
        _hover: {
            bg: 'gray.100',
        },
    },
    title: (large?: boolean) => ({
        mb: large ? 5 : 2,
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font-medium)',
    }),
};

export default styles;
