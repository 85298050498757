import React, { useState, KeyboardEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Grid, VStack, Link as ChakraLink, Button } from '@chakra-ui/react';

import Column from 'components/base/column';
import { Input } from 'components/base/form';
import Text from 'components/base/text';
import Margin from 'components/base/margin';
import LogoLarge from 'components/base/icon/logoLarge';

import { useRemoteConfig } from 'hooks';
import { validations } from 'validations';
import authService from 'services/auth.service';
import i18n from 'language/i18n';

import styles from './index.module.css';

export type LoginSectionProps = {
    onLoginSuccess?: (...args: any[]) => any;
    onShowRegistration?: (...args: any[]) => any;
    onShowForgotPassword?: (...args: any[]) => any;
    onClose?: (...args: any[]) => any;
};

export default function LoginSection(props: LoginSectionProps) {
    const history = useHistory();
    const { signupEnabled, passwordRecoveryEnabled } = useRemoteConfig();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<string>('');

    const { onLoginSuccess, onShowRegistration, onShowForgotPassword } = props;

    const formValid = () => {
        const { isNotEmpty, isEmail } = validations;
        return isEmail(email) === null && isNotEmpty(email) === null && isNotEmpty(password) === null;
    };

    const login = async () => {
        setLoading(true);
        try {
            if (!formValid()) throw new Error(i18n.t('errors.invalid.userPass'));

            await authService.login(email, password);

            onLoginSuccess && onLoginSuccess();
        } catch (err) {
            console.log(err);
            setLoginError(err.message);
            setLoading(false);
        }
    };

    const showRegistration = (event: any) => {
        if (onShowRegistration) {
            event.preventDefault();
            onShowRegistration();
        }
    };

    const goToTerms = () => {
        history.push('/terms');
    };

    const handleSubmitOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            login();
        }
    };

    return (
        <Column className={styles.container}>
            <Column className={styles.header}>
                <Text className={styles.title} center noMargin>
                    {i18n.t('sections.register.welcome')}
                </Text>
                <LogoLarge className={styles.logo} />
            </Column>

            <VStack w="100%" gap="5">
                <Input
                    name="email"
                    label={i18n.t('sections.login.input.email')}
                    type="email"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                />
                <Input
                    name="password"
                    label={i18n.t('sections.login.input.password')}
                    type="password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                    errorMessage={loginError}
                    onKeyPress={handleSubmitOnEnter}
                />
            </VStack>

            {passwordRecoveryEnabled && (
                <ChakraLink alignSelf="start" fontWeight="normal" mt="4" onClick={onShowForgotPassword}>
                    {i18n.t('sections.login.forgotPasswordLink')}
                </ChakraLink>
            )}

            <Button
                marginTop="24px"
                variant="solid"
                colorScheme="black"
                size="md"
                w="full"
                onClick={login}
                isLoading={loading}
            >
                {i18n.t('sections.login.submitBtn')}
            </Button>

            <Margin />

            <Grid alignItems="center" gap="4">
                <Link className={styles.registerLabel} to="/terms" onClick={goToTerms}>
                    {i18n.t('sections.register.termsAndPrivacy')}
                </Link>
                {signupEnabled && (
                    <div className={styles.register}>
                        <Text className={styles.registerLabel} center>
                            {i18n.t('sections.login.registerLabel')}{' '}
                        </Text>

                        <ChakraLink variant="sm" textDecoration="none" onClick={showRegistration}>
                            {i18n.t('sections.login.registerLink')}
                        </ChakraLink>
                    </div>
                )}
            </Grid>
        </Column>
    );
}
