import React from 'react';

import styles from './index.module.css';

export type PhotoProps = {
    width?: any;
    height?: any;
    className?: any;
    src?: any;
    size?: 'mini' | 'small' | 'medium' | 'large' | 'relative' | 'xlarge';
    wide?: boolean;
    alt?: string;
    raised?: boolean;
    onClick?: () => void;
};

export default function Photo(props: PhotoProps) {
    const { src, alt, size, wide, className, raised, onClick } = props;

    const getSize = () => {
        switch (size) {
            case 'relative':
                return styles.relative;
            case 'mini':
                return wide ? styles.miniWide : styles.mini;
            case 'small':
                return wide ? styles.smallWide : styles.small;
            case 'medium':
                return wide ? styles.mediumWide : styles.medium;
            case 'large':
                return wide ? styles.largeWide : styles.large;
            case 'xlarge':
                return wide ? styles.xlargeWide : styles.xlarge;
            default:
                return wide ? styles.smallWide : styles.small;
        }
    };

    const getStyle = () => {
        return [styles.photo, getSize(), className, raised ? styles.raised : '', !src ? styles.noImage : ''].join(' ');
    };

    return src ? (
        <img onClick={onClick} alt={alt || 'image'} src={src} className={getStyle()} />
    ) : (
        <img onClick={onClick} alt="" className={getStyle()} />
    );
}
