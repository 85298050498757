import React, { ReactNode } from 'react';

import Column from '../column';

import styles from './index.module.css';

export type PageContainerProps = {
    className?: string;
    children?: ReactNode;
};

export default function PageContainer(props: PageContainerProps) {
    const { className, children } = props;

    return <Column className={[styles.container, className].join(' ')}>{children}</Column>;
}
