import { useBoolean } from '@chakra-ui/react';

export type UseRequestService = ({ ...args }: any) => Promise<any>;
export type UseRequestCallback = (response: any) => Promise<void> | void;

export default function useRequest<T>(
    service: UseRequestService,
    args?: T,
    onSuccess?: UseRequestCallback,
    onError?: UseRequestCallback,
) {
    const [loading, { on, off }] = useBoolean();

    function callback() {
        on();
        service(args || {})
            .then(onSuccess)
            .catch(({ code, message }) =>
                onError?.({
                    code: code || 'Unknown',
                    message,
                }),
            )
            .finally(off);
    }

    return [callback, loading] as [() => void, boolean];
}
