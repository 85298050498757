import React from 'react';
import { useRemoteConfig } from 'hooks';
import i18n from '../../../../language/i18n';
import StarIcon from '../../../base/icon/star';
import Row from '../../../base/row';
import Caption from '../../../base/text/caption';
import { H3 } from '../../../base/text/h3';
import styles from './index.module.css';
import { Asset } from '../../../../services/asset.service';
import Text from '../../../base/text';
import { getCarDescription } from '../../../../helpers/getCarDescription';

function AssetDetailsTitle(props: AssetDetailsTitleProps) {
    const { pagesConfig } = useRemoteConfig();
    const { asset } = props;
    const category = asset.category ? i18n.t(`asset.category.${asset.category.toUpperCase()}`) : 'N/A';

    return (
        <div className={styles.container}>
            {pagesConfig.asset_page_config.reviews && (
                <Row className={styles.ratingContainer}>
                    <StarIcon className={styles.ratingIcon} />
                    <Caption className={styles.rating}>4.7</Caption>
                    <Caption className={styles.reviews}>(14 reviews)</Caption>
                </Row>
            )}
            <Row className={styles.categoryContainer}>
                <Caption noMargin className={styles.category}>
                    {category}
                </Caption>
            </Row>
            <Row className={styles.actions}>
                <H3 className={styles.title}>
                    {asset.brand} {asset.model}
                </H3>
            </Row>
            {asset.category && <Text className={styles.description}>{getCarDescription(asset.category)}</Text>}
        </div>
    );
}

export default AssetDetailsTitle;

export type AssetDetailsTitleProps = {
    asset: Asset;
};
