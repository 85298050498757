import React from 'react';
import styles from './index.module.css';

export type TextProps = {
    className?: string;
    style?: any;
    center?: boolean;
    noMargin?: boolean;
    ellipsis?: boolean;
    onClick?: (...args: any[]) => void;
    children?: any;
};

export default function Text(props: TextProps) {
    const { center, noMargin, className, ellipsis, onClick, style, children } = props;

    const centerText = () => {
        return center ? styles.center : '';
    };

    const getMargin = () => {
        return noMargin ? styles.noMargin : '';
    };

    const getStyle = (): string => {
        return styles.text;
    };

    function render() {
        const classNames = [getStyle(), getMargin(), centerText(), className];
        if (ellipsis) {
            return (
                <div className={classNames.join(' ')} onClick={onClick} style={style}>
                    {children}
                </div>
            );
        }
        return (
            <span className={classNames.join(' ')} onClick={onClick} style={style}>
                {children}
            </span>
        );
    }

    return render();
}
