import React from 'react';

import Column from 'components/base/column';
import ErrorText from 'components/base/text/error';
import Label from 'components/base/text/label';

import { joinClasses } from 'helpers/joinClasses';

import styles from './index.module.css';

interface Props {
    name: string;
    label?: string;
    errorMessage?: string;
    hideError?: boolean;
    inputClassName?: string;
    onEnterPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export function Input(props: InputProps) {
    const {
        name,
        label,
        type,
        onChange,
        onEnterPress,
        className,
        inputClassName,
        errorMessage,
        value,
        required,
        placeholder,
        hideError,
        onBlur,
        onFocus,
        onMouseEnter,
        onMouseLeave,
        disabled,
        id,
    } = props;

    function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 13) {
            onEnterPress && onEnterPress(event);
        }
    }

    return (
        <Column className={joinClasses(styles.inputContainer, className || '')}>
            {label && <Label className={disabled ? styles.labelDisabled : ''}>{label}</Label>}
            <input
                id={id}
                className={joinClasses(styles.input, inputClassName || '')}
                name={name}
                type={type}
                disabled={disabled}
                value={value}
                required={required}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onKeyDown={onKeyPress}
            />
            {errorMessage && !hideError && <ErrorText>{errorMessage}</ErrorText>}
        </Column>
    );
}
