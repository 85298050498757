import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button, useBreakpointValue } from '@chakra-ui/react';
import Column from 'components/base/column';
import { Input } from 'components/base/input';
import ModalNew from 'components/base/modalNew';
import Row from 'components/base/row';
import Label from 'components/base/text/label';
import { Text } from 'components/base/textV2';
import { User } from 'hooks/auth';
import i18n from 'language/i18n';
import { Moment } from 'moment';
import { Errors, Validator } from 'services/validator';

import { ErrorText } from 'components/base/errorText';
import { Spinner } from 'components/base/spinner';
import profileService from 'services/profile.service';
import { useAuth } from 'hooks';
import _ from 'lodash';
import { yupSchema } from '../schema';
import styles from './index.module.css';

export interface EditBillingInfoModalProps {
    closeEditMode: () => void;
    onSuccess?: (dates: { from: Moment; to: Moment }) => void;
    loading?: boolean;
    user: User;
}

function EditBillingInfoModal({ onSuccess, user, closeEditMode }: EditBillingInfoModalProps) {
    const responsiveSize = useBreakpointValue({ base: 'full', md: '3xl' });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [formData, setFormData] = useState({ ...user });
    const [formErrors, setFormErrors] = useState<Errors>();
    const [submitError, setSubmitError] = useState('');

    const { auth, getUser } = useAuth();

    function _onReset() {
        setFormData(user);
    }

    function onClose() {
        closeEditMode();
    }

    function onInputChange(e: ChangeEvent<HTMLInputElement>) {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        Validator.validateInput(yupSchema, inputName, inputValue, formErrors, setFormErrors);

        // regex for double space
        const doubleSpace = /\s{2,}/;
        const formattedInputValue =
            inputValue.length <= 1
                ? inputValue.replace(/ /g, '')
                : inputValue.replace(doubleSpace, '').split('.').join('');

        // Preventing the double space while maintining the ability to use 1 space between names.
        setFormData({ ...formData, [inputName]: formattedInputValue });

        setSubmitDisabled(false);
    }

    async function handleSubmit() {
        try {
            setFormErrors(undefined);
            let formattedInputValue;

            const userData = {
                ...formData,
            };

            await Validator.validateSchema(yupSchema, userData);

            setLoading(true);

            // triming the end of input name if its an empty space
            if (formData.name.slice(-1) === ' ') {
                formattedInputValue = formData.name.trimEnd();
            }

            const data = {
                ...formData,
                name: formattedInputValue || formData.name,
            };

            await profileService.edit(auth!.uid, data);

            setTimeout(async () => {
                await getUser();
                setLoading(false);
                closeEditMode();
            }, 2000);
        } catch (error) {
            setLoading(false);
            const validationErrors = Validator.getValidationErrors(error);
            if (validationErrors) {
                setFormErrors(validationErrors);
                return;
            }
            setSubmitError(error.message);
        }
    }

    const modalFooter = () => (
        <Row className={styles.actionRow}>
            <Column className={styles.cancel}>
                <Text className={styles.cancelBtn} onClick={onClose}>
                    {i18n.t('pages.profile.info.edit.cancel')}
                </Text>
                <Text className={styles.resetBtn} onClick={_onReset}>
                    {i18n.t('pages.profile.info.edit.resetAll')}
                </Text>
            </Column>
            <Column className={styles.submit}>
                <Button
                    variant="solid"
                    colorScheme="black"
                    size="md"
                    onClick={handleSubmit}
                    disabled={submitDisabled}
                    alignSelf="flex-end"
                >
                    {i18n.t('pages.profile.info.edit.save')}
                </Button>
            </Column>
        </Row>
    );

    useEffect(() => {
        const isUndefined = (currentValue: any) => currentValue === undefined;
        const isEqual = _.isEqual(formData, user);

        const shouldBeDisabled = [formErrors?.name, formErrors?.address, formErrors?.taxNumber].every(isUndefined);
        setSubmitDisabled(!shouldBeDisabled || isEqual);
    }, [formData, formErrors, user]);

    return (
        <ModalNew
            isOpen
            onClose={onClose}
            title="Edit Billing Information"
            size={responsiveSize}
            footer={modalFooter()}
        >
            <Column className={styles.mainBilling}>
                <Label font-size="sm" className={styles.billingData}>
                    {i18n.t('pages.profile.personalWallet.billing.name')}:
                </Label>
                <Input name="name" value={formData.name} onChange={onInputChange} errorMessage={formErrors?.name} />

                <Label font-size="sm" className={styles.billingData}>
                    {i18n.t('pages.profile.personalWallet.billing.address')}:
                </Label>
                <Input
                    name="address"
                    value={formData.address}
                    onChange={onInputChange}
                    errorMessage={formErrors?.address}
                />

                <Label font-size="sm" className={styles.billingData}>
                    {i18n.t('pages.profile.personalWallet.billing.VAT')}:
                </Label>
                <Input
                    name="taxNumber"
                    value={formData.taxNumber}
                    onChange={onInputChange}
                    errorMessage={formErrors?.taxNumber}
                />
                {submitError && <ErrorText>{submitError}</ErrorText>}
            </Column>
            {loading && (
                <Column className={styles.loadingContainer}>
                    <Spinner className={styles.loadingSpinner} color="red" />
                </Column>
            )}
        </ModalNew>
    );
}

export default EditBillingInfoModal;
