import React, { useReducer, useCallback, useState } from 'react';

import { CheckboxRounded } from '../../../../base/checkbox-rounded';
import Grid from '../../../../base/grid';
import Margin from '../../../../base/margin';
import Splitter from '../../../../base/splitter';
import Label from '../../../../base/text/label';
import { FilterType } from '../../filter-type';

import { AssetFilterProps } from '../types';
import { seatTypes, greenVehicles, transmissionTypes, propulsionTypes } from '../data';

import i18n from '../../../../../language/i18n';

import styles from './index.module.css';
import { FilterMobileModal } from '../../filter-mobile-modal/mobile';
import { FilterBrands } from '../../filter-brands';
import { FilterFeatures } from '../../filter-features';
import { NumberPicker } from '../../../../base/number-picker';

interface FilterOption {
    label: string;
    value: string | number;
}

interface Field {
    label: string;
    value: string | number;
    options?: FilterOption[];
    action?: () => void;
}

export function MobileAssetFilter(props: AssetFilterProps) {
    const { onFilterStateChanged, onCloseFilters, showMore } = props;
    const [resetCounter, setResetCounter] = useState<boolean>(false);

    const filterFields = [
        {
            label: i18n.t('sections.assetList.filters.type.placeholder'),
            value: 'greenVehicle',
            options: greenVehicles,
        },
        {
            label: i18n.t('sections.assetList.filters.type.placeholder'),
            value: 'propulsionType',
            options: propulsionTypes,
        },
        {
            label: i18n.t('sections.assetList.filters.seats.placeholder'),
            value: 'seats',
            options: seatTypes,
        },
        {
            label: i18n.t('sections.assetList.filters.transmission.placeholder'),
            value: 'transmission',
            options: transmissionTypes,
        },
    ];

    const initialState = {
        fields: filterFields,
        transmission: [],
        category: [],
        seats: [],
        doors: [],
        feature: [],
        greenVehicle: [],
        propulsionType: [],
    };

    function reducer(state: any, filter: { key: string; value: string[] }) {
        switch (filter.key) {
            case 'greenVehicle':
                return { ...state, greenVehicle: filter.value };
            case 'transmission':
                return { ...state, transmission: filter.value };
            case 'category':
                return { ...state, category: filter.value };
            case 'seats':
                return { ...state, seats: filter.value };
            case 'doors':
                return { ...state, doors: filter.value };
            case 'feature':
                return { ...state, feature: filter.value };
            case 'propulsionType':
                return { ...state, propulsionType: filter.value };
            case 'reset':
                return initialState;
            default:
                return initialState;
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    function onReset() {
        dispatch({ key: 'reset', value: [] });
        setResetCounter(true);
    }
    function onCloseDrawer() {
        onReset();
        onCloseFilters();
    }

    function handleSubmitFilters() {
        onCloseFilters();
        if (onFilterStateChanged) {
            const { category, seats, transmission, greenVehicle, feature, propulsionType, doors } = state;
            onFilterStateChanged({
                total:
                    category.length + seats.length + transmission.length + greenVehicle.length + propulsionType.length,
                ...(category ? { category } : {}),
                ...(seats ? { seats } : {}),
                ...(doors ? { doors } : {}),
                ...(transmission ? { transmission } : {}),
                ...(greenVehicle ? { greenVehicle } : {}),
                ...(propulsionType ? { propulsionType } : {}),
                ...(feature ? { feature } : {}),
            });
        }
    }

    function onNumberPickerChange(filter: { key: string; value: any }) {
        dispatch({ key: filter.key, value: [filter.value] });
        setResetCounter(false);
    }

    const renderCheckboxes = useCallback(
        (data: FilterOption[], key: string) => {
            const filters = state[key];

            const onClick = (item: FilterOption) => {
                if (!filters.includes(item.value)) {
                    filters.push(item.value);
                } else {
                    filters.splice(filters.indexOf(item.value), 1);
                }
                dispatch({ key, value: filters });
            };

            const onClickAll = (item: FilterOption) => {
                if (item.value === 'ALL' && !filters.includes(item.value)) {
                    const allOptions: FilterOption[] = state.fields.filter((field: Field) => field.value === key)[0]
                        .options;
                    const allOptionsValues = allOptions.map(option => option.value);
                    filters.push.apply(filters, allOptionsValues);
                } else {
                    filters.splice(0, filters.length);
                }

                dispatch({ key, value: filters });
            };

            return data.map((item, index: number) => {
                const checked = filters ? filters.includes(item.value) : false;
                return (
                    <CheckboxRounded
                        labelClassName={styles.label}
                        key={index}
                        label={item.label}
                        checked={checked}
                        onClick={() => (item.value === 'ALL' ? onClickAll(item) : onClick(item))}
                    />
                );
            });
        },
        [state],
    );

    return (
        <FilterMobileModal
            title={i18n.t('sections.assetList.filterModal.title.filters')}
            show={showMore || false}
            footer
            onClose={onCloseDrawer}
            onSubmit={handleSubmitFilters}
            onReset={onReset}
        >
            <Label className={styles.filterTitle}>{i18n.t('sections.assetList.filters.type.placeholder')}</Label>
            <Grid className={styles.modalCheckbox}>{renderCheckboxes(propulsionTypes, 'propulsionType')}</Grid>
            <Margin />
            <Splitter />

            <Label className={styles.filterTitle}>{i18n.t('sections.assetList.filters.seatsDoors.placeholder')}</Label>
            <NumberPicker
                onChange={onNumberPickerChange}
                value={state.seats}
                label="Seats"
                filterKey="seats"
                resetCounter={resetCounter}
            />
            <Margin />
            <NumberPicker
                onChange={onNumberPickerChange}
                value={state.doors}
                label="Doors"
                filterKey="doors"
                resetCounter={resetCounter}
            />
            <Margin />
            <Splitter />

            <Label className={styles.filterTitle}>
                {i18n.t('sections.assetList.filterModal.transmission.placeholder')}
            </Label>
            <Grid>{renderCheckboxes(transmissionTypes, 'transmission')}</Grid>
            <Margin />
        </FilterMobileModal>
    );
}
