import Loading from 'components/base/loading';
import { useAuth } from 'hooks';
import React from 'react';
import { Redirect, RouteProps as ReactRouteProps } from 'react-router-dom';
import Route from '../route';

export type ProtectedRouteProps = {
    /**
     *
     */
    wrap?: boolean;

    /**
     *
     */
    dark?: boolean;

    /**
     *
     */
    pageWrap?: boolean;
};

export default function ProtectedRoute(props: ProtectedRouteProps & ReactRouteProps) {
    const { path } = props;
    const context = useAuth();

    function render() {
        if (context.isProcessing) {
            return <Loading spinnerSize={40} fullScreen />;
        }

        if (!context.auth) {
            return <Redirect to={{ pathname: '/', search: `?origin=${path}` }} />;
        }
        return <Route {...props} />;
    }

    return render();
}
