import { ChakraStyles } from 'theme';

const styles: ChakraStyles = {
    stack: {
        w: '100%',
        alignItems: 'flex-start',
    },
    roundWrap: {
        border: '1px solid #E2E3E7',
        borderRadius: 'full',
        mb: '3',
    },
    icon: {
        padding: 'var(--margin-small) var(--margin-internal) 0 0',
    },
    input: {
        height: '100%',
        padding: 'var(--margin-small) var(--margin)',
        fontSize: ' var(--font)',
        lineHeight: 'var(--line)',
        fontWeight: 'var(--font-weight-light)',
        border: '1px solid #E2E3E7',
        borderRadius: 'full',
        mb: '3',
        textOverflow: 'ellipsis',
    },
};

export default styles;
