import { Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import LogoLarge from 'assets/images/Logo_Full_Red.svg';
import { useWindowSize } from 'hooks';
import { HeaderAuth } from './auth';
import HeaderLink from './link';

function Header() {
    const { pathname }: { pathname: string } = useLocation();
    const windowSize = useWindowSize();
    const darkMode = pathname === '/business' || pathname === '/team';
    const isMobileOrTablet = windowSize.width <= 928;

    const shouldHideHeader =
        (pathname === '/search' || pathname === '/assets' || (pathname.includes('/assets') && isMobileOrTablet)) &&
        (pathname.includes('book') || pathname.includes('checkout')) === false;

    if (shouldHideHeader) {
        return null;
    }

    function renderLogo() {
        if (isMobileOrTablet) {
            return <Image src={LogoLarge} w="100px" h="auto" />;
        }

        return <Image src={LogoLarge} w="32px" h="auto" />;
    }

    if (pathname.includes('/assets') && isMobileOrTablet) {
        return (
            <Flex
                width="100%"
                align="center"
                p={{ base: '8px 24px', lg: '20px' }}
                marginBottom="var(--margin-xlarge)"
                boxShadow="0 2px 16px rgba(0,0,0,.04)"
            >
                <Flex width="100%" justify="space-between" align="center" maxW="var(--page-width)" margin="0 auto">
                    {renderLogo()}
                    <HeaderAuth />
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex
            id="header"
            w="100%"
            bgImg={darkMode ? 'linear-gradient(rgba(11, 11, 13, 0.6), transparent)' : 'transparent'}
            align="center"
            boxShadow={darkMode ? 'none' : '0 2px 16px rgba(0,0,0,.04)'}
            mb={{ base: '40px', xl: 'var(--xl-extra_space)' }}
        >
            <Flex
                width="100%"
                py="10px"
                justify="space-between"
                align="center"
                maxW="var(--page-width)"
                margin="0 auto"
            >
                <HeaderLink />
                <HeaderAuth />
            </Flex>
        </Flex>
    );
}

export default Header;
