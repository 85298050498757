import { ChakraStyles } from 'theme';

const styles: ChakraStyles<any> = {
    dateSection: (exists: boolean) => ({
        textAlign: 'start',
        fontWeight: 'var(--font-weight-light)',
        ...(exists === false && { color: 'var(--color-grayish)' }),
    }),
    locationSection: (exists: boolean) => ({
        textAlign: 'start',
        fontWeight: 'var(--font-weight-light)',
        ...(exists === false && { color: 'var(--color-grayish)' }),
    }),
};

export default styles;
