import React, { useCallback, useEffect, useState } from 'react';
import {
    ChakraProps,
    Flex,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    Link as LinkButton,
} from '@chakra-ui/react';
import Select, { SelectOption } from 'components/base/select';
import i18n from 'language/i18n';
import MobileSearchSection from 'components/sections/mobile-search/location';
import { AddressAutocompleteValue } from 'components/sections/home/addressComplete/types';
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward.svg';
import { useWindowSize } from 'hooks';
import cssStyles from './index.module.css';
import LocationSearchModal from '../location-search-modal';
import chakraStyles from './styles';

interface LocationReturnPickerProps {
    title?: string;
    options?: SelectOption[];
    selectedOption?: string;
    onChange?: (val: string) => void;
    onLocationPush?: (val: SelectOption) => void;
    withDrawer?: boolean;
    selectReturnLocation?: (value: string) => void;
    deliveryLocation?: string;
}

function LocationReturnPicker({
    title,
    selectedOption,
    onChange,
    onLocationPush,
    options,
    withDrawer,
    deliveryLocation,
    ...rest
}: LocationReturnPickerProps & ChakraProps) {
    const custom_location = options
        ? options!.find(opt => (selectedOption ? opt.value === selectedOption : false))!
        : undefined;

    const returnOptions = [
        {
            label: i18n.t('delivery.return.modal.deliveryOption.sameAddress'),
            value: 'same_address',
        },
        {
            label: i18n.t('delivery.return.modal.deliveryOption.otherLocation'),
            value: 'other_location',
        },
        ...(options || []),
    ];

    const [selectOptions, setSelectOptions] = useState(returnOptions);
    const [selectedItem, setSelectedItem] = useState(
        custom_location || {
            label: i18n.t('delivery.return.modal.deliveryOption.sameAddress'),
            value: 'same_address',
        },
    );
    const [notDesktopView, setNotDesktopView] = useState<boolean>(false);
    const [notSameReturnAddress, setNotSameReturnAddress] = useState<boolean>(false);

    const modal = useDisclosure();
    const drawer = useDisclosure();
    const { width } = useWindowSize();

    function _onLocationPush(label: string, value: number[]) {
        const newLocation = {
            label,
            value: `custom_location_${value.join(',')}`,
        };

        const existentOption = returnOptions.find(currOpt => currOpt.value === newLocation.value);

        if (existentOption) {
            setSelectedItem(existentOption);
            onLocationPush?.(newLocation);
            withDrawer ? drawer.onClose() : modal.onClose();
            return;
        }

        if (newLocation.label === deliveryLocation) {
            setSelectedItem({
                label: i18n.t('delivery.return.modal.deliveryOption.sameAddress'),
                value: 'same_address',
            });
            onChange?.('same_address');
            withDrawer ? drawer.onClose() : modal.onClose();
            return;
        }

        setSelectOptions([...(selectOptions || []), newLocation]);
        setSelectedItem(newLocation);
        onLocationPush?.(newLocation);
        withDrawer ? drawer.onClose() : modal.onClose();
    }

    const checkWindowSize = useCallback(() => {
        if (width <= 928) {
            setNotDesktopView(true);
        }
    }, [width]);

    const checkReturnAddress = useCallback(() => {
        if (selectedItem.value !== 'same_address') {
            setNotSameReturnAddress(true);
        } else {
            setNotSameReturnAddress(false);
        }

        if (selectedItem.label === deliveryLocation) {
            setSelectedItem({
                label: i18n.t('delivery.return.modal.deliveryOption.sameAddress'),
                value: 'same_address',
            });
        }
    }, [selectedItem, deliveryLocation]);

    function onSelect(option: SelectOption) {
        function setValues(push?: boolean) {
            if (option.value === 'same_address') {
                setSelectedItem({ label: '', value: '' });
            }

            setSelectedItem(option);
            onChange?.(option.value);
            if (push) onLocationPush?.(option);
        }

        switch (option.value) {
            case 'other_location':
                withDrawer ? drawer.onOpen() : modal.onOpen();
                break;
            case 'same_address':
                setValues();
                break;
            default:
                setValues(true);
                break;
        }
    }

    if (drawer.isOpen && selectedItem.value === 'same_address') {
        setSelectedItem({ label: '', value: '' });
    }

    useEffect(() => {
        checkWindowSize();
        checkReturnAddress();
    }, [checkWindowSize, checkReturnAddress, drawer]);

    // used just to get around typing
    function handleAddressClick(address: AddressAutocompleteValue) {
        _onLocationPush(address.label!, address.value);
    }

    return (
        <>
            <Select title={title} options={selectOptions} selected={selectedItem} onChange={onSelect} {...rest} />
            {modal.isOpen && (
                <LocationSearchModal isOpen title={title} onClose={modal.onClose} onChange={_onLocationPush} />
            )}
            {drawer.isOpen && (
                <Drawer isOpen onClose={drawer.onClose} placement="bottom">
                    <DrawerOverlay />
                    <DrawerContent height="100%" {...chakraStyles.drawerContent}>
                        <MobileSearchSection
                            selectedItem={selectedItem}
                            className={cssStyles.searchSection}
                            onBack={() => drawer.onClose()}
                            onAddressClick={handleAddressClick}
                            initialAddress={selectedItem.label}
                        />
                    </DrawerContent>
                </Drawer>
            )}
            {notDesktopView && notSameReturnAddress && (
                <Flex mt="16px" alignItems="center" onClick={() => drawer.onOpen()}>
                    <LinkButton as="button" mr="8px">
                        {i18n.t('sections.deliveryPicker.editReturn')}
                    </LinkButton>
                    <ArrowForward className={cssStyles.arrowForward} />
                </Flex>
            )}
        </>
    );
}

export default LocationReturnPicker;
