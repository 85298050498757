import React, { useMemo, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import Row from '../../../base/row';
import Text from '../../../base/text';
import BookingReviewCard, { BookingReview } from './booking-review-card';
import styles from './index.module.css';
import i18n from '../../../../language/i18n';
import Margin from '../../../base/margin';
import StarsRating from '../../../base/stars-rating';
import BookingReviewModal from './booking-review-modal';
import { Asset } from '../../../../services/asset.service';

type BookingReviewPanelProps = {
    reviews: BookingReview[];
    asset: Asset;
};

export default function BookingReviewPanel(props: BookingReviewPanelProps) {
    const { reviews, asset } = props;
    const rating = useMemo(() => {
        const ratings = reviews.map(review => review.rating);

        return ratings.reduce((a, b) => a + b) / ratings.length;
    }, [reviews]);

    const recentReviews = reviews.slice(0, 4);

    const [reviewsModalIsOpen, setReviewsModalIsOpen] = useState(false);

    function toggleReviewsModal() {
        setReviewsModalIsOpen(!reviewsModalIsOpen);
    }

    if (reviews) {
        return (
            <Box display="flex" flexDirection="column">
                <Flex alignItems="center">
                    <Text className={styles.title}>{i18n.t('sections.bookingReviews.reviews')}</Text>
                    <StarsRating rating={rating} />
                    <Text className={styles.subtitle}>{rating.toFixed(2)}</Text>
                    <Text className={styles.totalReviews}>({reviews.length})</Text>
                </Flex>
                <Margin />
                <Flex flexWrap="wrap" gap="12px">
                    {recentReviews ? recentReviews.map(review => <BookingReviewCard review={review} />) : null}
                </Flex>
                <Button
                    margin="24px !important"
                    variant="outline"
                    colorScheme="black"
                    size="md"
                    alignSelf="center"
                    onClick={() => toggleReviewsModal()}
                >
                    {i18n.t('sections.bookingReviews.seeAll')} {reviews.length}{' '}
                    {i18n.t('sections.bookingReviews.reviews')}
                </Button>
                <BookingReviewModal
                    rating={rating}
                    asset={asset}
                    reviews={reviews}
                    isOpen={reviewsModalIsOpen}
                    onClose={toggleReviewsModal}
                    className={styles.reviewModal}
                />
            </Box>
        );
    }
    return (
        <Box>
            <Row className={styles.reviewsHeader}>
                <Text className={styles.title}>{i18n.t('sections.bookingReviews.reviews')}</Text>
                <Text className={styles.totalReviews}>{i18n.t('sections.bookingReviews.noReviews')}</Text>
            </Row>
        </Box>
    );
}
