import React from 'react';
import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './index.module.css';

function DrawerMenuItem(props: DrawerMenuProps) {
    const { isMobile } = props;
    return (
        <Link as={RouterLink} to={props.to} onClick={props.onClick} className={styles.item} variant="noStyle">
            {props.icon && <div className={styles.icon}>{props.icon}</div>}
            <div className={isMobile ? styles.childrenMobile : styles.children}>{props.children}</div>
        </Link>
    );
}

export type DrawerMenuProps = {
    to?: any;
    icon?: any;
    onClick?: (event: React.MouseEvent) => void;
    children: any;
    isMobile?: boolean;
};

export default DrawerMenuItem;
