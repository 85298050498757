import { firestore, functions } from './persistence';
import { GetAllResponse } from './types';
import { PriceObject } from './price.formatter';

export type InsurancesDataOptions = {
    optionId: string;
    key: string;
    title: string;
    price: PriceObject;
    selected?: boolean;
    description: {
        includedCoverage: string;
        details: string;
    };
};

export type Insurance = {
    id: string;
    key: string;
    description?: string;
    enabled?: boolean;
    price?: PriceObject;
    options?: InsurancesDataOptions[];
    selected?: boolean;
    multipleOptions: boolean;
    title?: string;
};

const InsurancesService = {
    async getAll(criteria?: any): Promise<GetAllResponse<Insurance>> {
        const response = await functions.httpsCallable('searchInsurance')(criteria || {});

        return response.data;
    },

    async getMain(operatingCityId: string): Promise<Insurance> {
        const operatingCityRef = firestore.collection('operating_cities').doc(operatingCityId);
        const response = await firestore
            .collection('insurances')
            .where('multipleOptions', '==', true)
            .where('operatingCityRef', '==', operatingCityRef)
            .where('enabled', '==', true)
            .get();
        const [main] = response.docs;

        return { ...main.data(), id: main.id } as Insurance;
    },
};

export default InsurancesService;
