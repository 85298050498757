import React, { useState, useEffect } from 'react';
import hash from '@emotion/hash';

import { Accordion } from 'components/base/roundedAccordion';
import Breadcrumb, { BreadcrumbItem } from 'components/base/breadcrumb';
import Column from 'components/base/column';
import Row from 'components/base/row';
import PageTitle from 'components/base/text/pageTitle';
import { Dropdown } from 'components/base/dropdownV2';

import { questionsAndAnswers } from 'components/sections/home/faq/questionsAndAnswers';

import i18n from 'language/i18n';
import { useRemoteConfig } from 'hooks';
import styles from './index.module.css';

export default function FaqFeedbackPage() {
    const { bookingCancellationConfig } = useRemoteConfig();
    const [questionsAndAnswersResult] = useState(questionsAndAnswers(bookingCancellationConfig));

    const options = [{ value: 'all_sections', label: i18n.t('sections.faq.allSections') }];
    options.push(
        ...questionsAndAnswersResult.map(qa => ({
            value: qa.key,
            label: qa.title,
        })),
    );

    const breadcrumbItems: BreadcrumbItem[] = [{ label: 'RUMMO', to: '/' }, { label: 'FAQ' }];
    const [expanded, setExpanded] = useState<false | number>(false);
    const [selectedSection, setSelectedSection] = useState(options[0]);
    const [sections, setSections] = useState<any>([]);

    function handleSelectSection(option: any) {
        setSelectedSection(option);
    }

    useEffect(() => {
        if (selectedSection.value === 'all_sections') {
            setSections(questionsAndAnswersResult);
        } else {
            const filteredSections = questionsAndAnswersResult.filter(section => section.key === selectedSection.value);
            setSections(filteredSections);
        }
    }, [selectedSection, questionsAndAnswersResult]);

    return (
        <Column className={styles.container}>
            <Breadcrumb items={breadcrumbItems} />
            <PageTitle className={styles.pageTitle}>Frequently Asked Questions</PageTitle>
            <Dropdown value={selectedSection} options={options} onChange={handleSelectSection} isSearchable={false} />
            <Row className={styles.accordionContainer}>
                {sections.map(({ title, description, body }: any) => (
                    <React.Fragment key={title}>
                        {' '}
                        {/* Only using full name for key prop */}
                        <div className={styles.sectionHeader}>
                            <h2 className={styles.sectionTitle}>{title}</h2>
                            <p className={styles.sectionDescription}>{description}</p>
                        </div>
                        {body.map(({ title: bodyTitle, content }: any, index: number) => (
                            <Accordion
                                key={index}
                                title={bodyTitle}
                                content={content}
                                i={hash(bodyTitle)}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                className={styles.faqAccordion}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </Row>
        </Column>
    );
}
