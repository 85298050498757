import { TextProps } from 'components/base/textV2';
import React from 'react';
import styles from './index.module.css';

export type CaptionProps = {
    noMargin?: any;
    className?: string;
};

export default function Caption(props: TextProps & CaptionProps) {
    const { children, className } = props;

    function render() {
        return <div className={className || styles.caption}>{children}</div>;
    }

    return render();
}
