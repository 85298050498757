import React from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import rectangleMd from '../../../../assets/images/rectangle-md.png';
import rectangleSm from '../../../../assets/images/rectangle-sm.png';
import styles from './index.module.css';
import CarBanner from '../../../../assets/images/design/intro_banner_car.png';

export type SectionCarBannerProps = {
    className?: any;
    wide?: boolean;
    relative?: boolean;
};

function SectionCarBanner(props: SectionCarBannerProps) {
    const { width } = useWindowSize();
    const { className, wide, relative } = props;

    const bannerStyle = [className, styles.banner, wide ? styles.wide : ''].join(' ');

    const content = (
        <div className={bannerStyle}>
            {width >= 928 && (
                <>
                    <img src={rectangleSm} alt="small-rectangle" className={styles.carBannerRectangleSm} />
                    <img src={rectangleMd} alt="medium-rectangle" className={styles.carBannerRectangleMd} />
                </>
            )}
            <img alt="carbanner" className={styles.car} src={CarBanner} />
        </div>
    );

    if (relative) {
        return <div className={styles.relative}>{content}</div>;
    }

    return content;
}

export default SectionCarBanner;
