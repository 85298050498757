import React, { ReactNode } from 'react';
import { H3 } from '../h3';

import styles from './index.module.css';

export interface SectionTitleProps {
    children: string | ReactNode;
    className?: string;
}

function SectionTitle({ children, className }: SectionTitleProps) {
    return <H3 className={[styles.sectionTitle, className].join(' ')}>{children}</H3>;
}

export default SectionTitle;
