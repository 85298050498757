import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type UseUrlType = [string, (value: string | number) => void];

export default function useUrl(param: string) {
    const history = useHistory();
    const { search, pathname } = useLocation();
    const url = new URLSearchParams(search);

    const urlParam = url.get(param);
    const [value, setValue] = useState(urlParam);

    function _setValue(val: string) {
        url.set(param, val);
        history.replace({ pathname, search: url.toString() });
        setValue(val);
    }

    return [value, _setValue] as UseUrlType;
}
