import PageContainer from 'components/base/pageContainer';
import { AddressAutocompleteValue } from 'components/sections/home/addressComplete/types';
import MobileAddressConfirmationSection from 'components/sections/mobile-search/address-confirmation';
import MobileDateTimeSelectSection from 'components/sections/mobile-search/date-time';
import MobileSearchSection from 'components/sections/mobile-search/location';
import { useSharedData } from 'hooks';
import useUrl from 'hooks/useUrl';
import i18n from 'language/i18n';
import { LocationItem } from 'models/sharedData/LocationItem';
import { createSearchObject } from 'models/sharedData/SearchObject';
import { TimeSlots } from 'models/sharedData/TimeSlots';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import StepZilla from 'react-stepzilla';
import styles from './index.module.css';

// Props automatically injected to IStep components found in steps variable
export type WizardStepProps = {
    jumpToStep: (step: number) => void;
};

export default function SearchMobilePage() {
    const history = useHistory();
    const [origin] = useUrl('origin');
    const [step, setStep] = useUrl('step');
    const { shared, sharedDataService } = useSharedData();
    const { start, end, returnLocationHistory } = shared;
    const initialDeliveryAddress = {
        label: start!.query!,
        value: [start!.location?.latitude || 0, start!.location?.longitude || 0],
    };
    const initialReturnAddress = {
        label: end!.query!,
        value: [end!.location?.latitude || 0, end!.location?.longitude || 0],
    };
    const [deliveryAddress, setDeliveryAddress] = useState<AddressAutocompleteValue | undefined>(
        initialDeliveryAddress,
    );
    const [returnAddress, setReturnAddress] = useState<AddressAutocompleteValue | undefined>(initialReturnAddress);
    const [currReturnLocationHistory, setCurrReturnLocationHistory] = useState<LocationItem[]>(returnLocationHistory);

    function onBack(stepNumber?: number) {
        if (stepNumber) {
            if (stepNumber === 0) {
                history.push(`/${origin}`);
            } else {
                if (origin === 'assets') {
                    return history.goBack();
                }

                const n = stepNumber - 1;
                history.push(`/search?origin=${origin}&step=${n}`);
                setStep(n);
            }
            return;
        }

        setDeliveryAddress(undefined);
        setReturnAddress(undefined);
        history.push(`/${origin}`);
    }

    function onDeliveryAddressStepEnd(selectedDeliveryAddress: AddressAutocompleteValue) {
        setStep(Number(step) + 1);
        setDeliveryAddress(selectedDeliveryAddress);

        if (!returnAddress?.label) {
            setReturnAddress(selectedDeliveryAddress);
        }
    }

    function onSubmit(selectedStartDate: Moment, selectedEndDate: Moment) {
        const currentReturnLocationHistory = returnLocationHistory ? [...returnLocationHistory] : [];
        currentReturnLocationHistory.push({
            label: returnAddress!.label!,
            value: `custom_location_${returnAddress?.value[1]},${returnAddress?.value[0]}`,
        });

        const startTime = selectedStartDate.format('HH:mm') as TimeSlots;
        const endTime = selectedEndDate.format('HH:mm') as TimeSlots;
        const startCoordinate = {
            latitude: deliveryAddress?.value[0] || 0,
            longitude: deliveryAddress?.value[1] || 0,
        };
        const endCoordinate = {
            latitude: returnAddress?.value[0] || 0,
            longitude: returnAddress?.value[1] || 0,
        };
        const deliveryObject = createSearchObject(
            selectedStartDate.clone(),
            startTime,
            startCoordinate,
            deliveryAddress!.label!,
        );
        const returnObject = createSearchObject(selectedEndDate.clone(), endTime, endCoordinate, returnAddress!.label!);

        sharedDataService.updateSharedData(deliveryObject, returnObject, currentReturnLocationHistory);
        history.push(`/assets`);
    }

    function onReturnAddressStepEnd(selectedReturnAddress: AddressAutocompleteValue) {
        setStep(Number(step) + 1);
        setReturnAddress(selectedReturnAddress);
    }

    function onReturnAddressStepConfirmClick(selectedReturnAddress: AddressAutocompleteValue) {
        switch (origin) {
            case 'assets':
                return onSubmit(start.date!, end.date!);
            default:
                return onReturnAddressStepEnd(selectedReturnAddress);
        }
    }

    function onNewReturnAddress(selectedReturnAddress: AddressAutocompleteValue) {
        setReturnAddress(selectedReturnAddress);

        const newValue = `custom_location_${selectedReturnAddress.value[1]},${selectedReturnAddress.value[0]}`;
        if (
            currReturnLocationHistory.every(loc => loc.value !== newValue) &&
            selectedReturnAddress.label !== deliveryAddress?.label
        ) {
            setCurrReturnLocationHistory([
                ...currReturnLocationHistory,
                { label: selectedReturnAddress.label!, value: newValue },
            ]);
        }
    }

    const steps = [
        {
            name: 'Delivery Address',
            component: (
                <MobileSearchSection
                    onBack={onBack}
                    stepNumber={0}
                    onAddressClick={onDeliveryAddressStepEnd}
                    placeholder={i18n.t('searchWizard.step1.searchPlaceholder')}
                    initialAddress={deliveryAddress?.label || ''}
                />
            ),
        },
        {
            name: 'Return Address',
            component: (
                <MobileAddressConfirmationSection
                    stepNumber={1}
                    onBack={onBack}
                    initialReturnAddress={returnAddress!}
                    currentDeliveryAddress={deliveryAddress!}
                    onConfirmClick={onReturnAddressStepConfirmClick}
                    onNewReturnAddress={onNewReturnAddress}
                    searchPlaceholder={i18n.t('searchWizard.step2.searchPlaceholder')}
                />
            ),
        },
        {
            name: 'Date and Time Selection',
            component: (
                <MobileDateTimeSelectSection
                    initialStartDate={start!.date!}
                    initialEndDate={end!.date!}
                    onBack={onBack}
                    clearButtonLocation="footer"
                    stepNumber={2}
                    onConfirmClick={onSubmit}
                    headerTitle={i18n.t('searchWizard.step3.header')}
                />
            ),
        },
    ];

    const startingStep = () => {
        switch (step) {
            case '0':
            case '2':
                return Number(step);
            default:
                return 0;
        }
    };

    return (
        <PageContainer className={styles.pageContainer}>
            <StepZilla
                startAtStep={startingStep()}
                showNavigation={false}
                showSteps={false}
                stepsNavigation={false}
                preventEnterSubmission
                steps={steps}
            />
        </PageContainer>
    );
}
