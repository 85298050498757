import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

interface ErrorTextProps extends TextProps {
    children: string;
}

export function ErrorText({ children, ...rest }: ErrorTextProps) {
    return (
        <Text color="brand.primary.900" fontSize="var(--font-xsmall)" lineHeight="var(--line-xsmall)" mt="1" {...rest}>
            {children}
        </Text>
    );
}
