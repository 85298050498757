import { useState, useEffect } from 'react';
import InsuranceService, { Insurance } from '../services/insurances.service';
import { useOperatingAreas } from './operatingAreas';

export function useInsurances(initial?: Insurance[]) {
    const [doc, setDoc] = useState<Insurance[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [mainInsurance, setMainInsurance] = useState<Insurance | null>(null);
    const { operatingCity } = useOperatingAreas();

    function update(insurances: Insurance[]) {
        setDoc(insurances);
    }

    useEffect(() => {
        if (initial) {
            setDoc(initial);
            setLoading(false);
        } else if (operatingCity?.id) {
            InsuranceService.getAll({
                filters: { operatingCityId: operatingCity.id },
            }).then(data => {
                if (data.result?.length) {
                    const insurances = data.result
                        .filter(item => !item.multipleOptions)
                        .map(item => ({ ...item, selected: !!item.options }));
                    setDoc(insurances);
                }
                setLoading(false);
            });
        }
    }, [operatingCity?.id]);

    useEffect(() => {
        if (operatingCity?.id) {
            InsuranceService.getMain(operatingCity.id).then(data => {
                const insurance = {
                    multipleOptions: data.multipleOptions,
                    options: data.options,
                    enabled: data.enabled,
                    key: data.key,
                    id: data.id,
                } as Insurance;

                setMainInsurance(insurance);
            });
        }
    }, [operatingCity]);

    return { data: doc, update, insurancesLoading: loading, mainInsurance };
}
