import Column from 'components/base/column';
import Row from 'components/base/row';
import BodyText from 'components/base/text/body';
import { User } from 'hooks/auth';
import i18n from 'language/i18n';
import React from 'react';
import { Company } from '../../../../services/company.service';
import styles from './index.module.css';

type BillingInfoProps = {
    title?: string;
    company?: Company | null;
    user?: User;
    simple?: boolean;
    paymentProfile?: string;
};

function BillingInfo({ title, simple, company, user, paymentProfile }: BillingInfoProps) {
    return (
        <Column className={simple ? styles.simple : ''}>
            {title && <div className={styles.title}>{title}</div>}
            <Row>
                <BodyText className={styles.labelText}>
                    {i18n.t('sections.assetDetails.booking.billingInfo.name')}:
                </BodyText>
                <BodyText className={styles.valueText}>
                    {paymentProfile === 'personal' ? user?.name : company?.name}
                </BodyText>
            </Row>
            <Row>
                <BodyText className={styles.labelText}>
                    {i18n.t('sections.assetDetails.booking.billingInfo.address')}:
                </BodyText>
                <BodyText className={styles.valueText}>
                    {paymentProfile === 'personal' ? user?.address : company?.address}
                </BodyText>
            </Row>
            <Row>
                <BodyText className={styles.labelText}>
                    {i18n.t('sections.assetDetails.booking.billingInfo.vat')}:
                </BodyText>
                <BodyText className={styles.valueText}>
                    {paymentProfile === 'personal' ? user?.taxNumber : company?.vat}
                </BodyText>
            </Row>
        </Column>
    );
}

export default BillingInfo;
