import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { DayPickerRangeController, FocusedInputShape, ScrollableOrientationShape } from 'react-dates';
import { useBreakpointValue, Flex, Text } from '@chakra-ui/react';
import { DateChange } from 'helpers/handleBookingCalendarDatesChanges';
import ArrowCalendar from '../icon/arrowCalendar';
import styles from './index.module.css';
import Row from '../row';

export type DateRangeFocus = 'startDate' | 'endDate' | null;

export interface DateRangePickerProps {
    label?: string;
    startDate?: Moment | null;
    endDate?: Moment | null;
    onChange?: (start: Moment | null, end: Moment | null) => void;
    blockedDates?: (day: Moment) => boolean;
    isOutsideRange?: (day: Moment) => boolean;
    months?: number;
    onFocusChange?: (arg: FocusedInputShape | null) => void;
    focusedDate?: FocusedInputShape | null;
    disabled?: boolean;
}

function DateRangePicker(props: DateRangePickerProps) {
    const { onChange, startDate, endDate, months, onFocusChange, focusedDate, disabled } = props;
    const [focused, setFocused] = useState<'startDate' | 'endDate' | null>(null);
    const responsiveOrientation = useBreakpointValue<ScrollableOrientationShape>({
        base: 'vertical',
        md: 'horizontal',
    });

    useEffect(() => {
        // This useEffect makes it so that the popover won't close on date selection
        // Nor when changing the month
        const calendarD = window.document.getElementsByClassName('CalendarDay');
        const calendarNav = window.document.getElementsByClassName('DayPickerNavigation_button');
        const calendarFocusRegion = window.document.getElementsByClassName('DayPicker_focusRegion');
        for (let i = 0; i < calendarNav.length; i++) {
            calendarNav[i].setAttribute('tabindex', '');
        }

        for (let i = 0; i < calendarD.length; i++) {
            calendarD[i].setAttribute('tabindex', '');
        }

        for (let i = 0; i < calendarFocusRegion.length; i++) {
            calendarFocusRegion[i]?.setAttribute('tabindex', '');
        }
    }, []);

    function onDatesChange({ startDate: newStartDate, endDate: newEndDate }: DateChange) {
        if (onChange) {
            onChange?.(newStartDate!, newEndDate!);
        }
    }

    function isDayBlocked(date: Moment): boolean {
        if (props.blockedDates === undefined) {
            return false;
        }

        return props.blockedDates(date);
    }

    function onNav() {
        const calendarD = window.document.getElementsByClassName('CalendarDay');
        for (let i = 0; i < calendarD.length; i++) {
            calendarD[i].setAttribute('tabindex', '');
        }
    }

    const today = moment();

    return (
        <Row className={styles.container}>
            <DayPickerRangeController
                noBorder
                disabled={disabled}
                hideKeyboardShortcutsPanel
                keepOpenOnDateSelect
                isFocused={false}
                numberOfMonths={months || 2}
                isDayBlocked={isDayBlocked}
                isOutsideRange={props.isOutsideRange}
                startDate={startDate!}
                endDate={endDate!}
                onDatesChange={!disabled ? onDatesChange : () => {}}
                focusedInput={focusedDate || focused || 'startDate'}
                onFocusChange={onFocusChange || setFocused}
                initialVisibleMonth={() => startDate || today}
                onPrevMonthClick={onNav}
                onNextMonthClick={onNav}
                navNext={
                    <Flex className={styles.next}>
                        <ArrowCalendar />
                    </Flex>
                }
                navPrev={
                    <Flex className={styles.prev}>
                        <ArrowCalendar />
                    </Flex>
                }
                orientation={responsiveOrientation}
                renderDayContents={day => (
                    <>
                        <Text variant="sm" as="span" fontWeight="light">
                            {day.format('D')}
                        </Text>
                        <div className={styles.layer} />
                    </>
                )}
            />
        </Row>
    );
}

export default DateRangePicker;
