import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Flex, Text, useBreakpointValue, Fade, useToast } from '@chakra-ui/react';

import Loading from 'components/base/loading';

import i18n from 'language/i18n';

interface PaymentMethodFormProps {
    onClose: () => void;
    onComplete?: () => void;
}

export function PaymentMethodForm({ onClose, onComplete }: PaymentMethodFormProps) {
    const stripe = useStripe();
    const elements = useElements();
    const toast = useToast();
    const responsiveTitle = useBreakpointValue({ base: 'h3', md: 'h4' });

    const [stripeLoading, setStripeLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    async function handleSubmit(event: any) {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }
        setLoading(true);

        const { error } = await stripe.confirmSetup({
            // Type is forced as 'any' because the stripe typing is complaining about some properties missing on its own code
            elements: elements as any,
            redirect: 'if_required',
        });

        if (error) {
            error.type === 'validation_error'
                ? setLoading(false)
                : toast({
                      title: i18n.t('pages.profile.documents.toast.error.creation.title'),
                      description: i18n.t('errors.paymentError'),
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                      position: 'top-right',
                  });

            setLoading(false);
        } else {
            onClose();
            onComplete?.();
        }
    }

    return (
        <>
            <Flex align="center" justify="center">
                {stripeLoading && <Loading spinnerSize={40} />}
            </Flex>
            {/* The 'hidden' prop is used because the Fade was appearing before the loading was finished, pushing the loading to the side */}
            <Fade
                in={!stripeLoading}
                hidden={stripeLoading}
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
                delay={0.05}
            >
                <Text variant={responsiveTitle} mb="10" fontWeight={{ base: 'semibold', md: 'medium' }}>
                    {i18n.t('pages.profile.personalWallet.addPaymentMethodModal.title')}
                </Text>

                <Flex as="form" flex={1} flexDir="column" justify="space-between" onSubmit={handleSubmit}>
                    <PaymentElement onReady={() => setStripeLoading(false)} />
                    <Flex
                        mt={{ base: 0, md: '10' }}
                        px={{ base: '24px', md: '0' }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Button variant="link" p="0" onClick={onClose}>
                            {i18n.t('pages.profile.personalWallet.addPaymentMethodModal.cancelButton')}
                        </Button>

                        <Button type="submit" variant="solid" size="md" colorScheme="black" isLoading={loading}>
                            {i18n.t('pages.profile.personalWallet.addPaymentMethodModal.submitButton')}
                        </Button>
                    </Flex>
                </Flex>
            </Fade>
        </>
    );
}
