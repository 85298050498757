import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from 'hooks/auth';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import Loading from 'components/base/loading';
import { BookingStatus } from 'models/booking/BookingStatus';
import Column from '../../components/base/column';
import Row from '../../components/base/row';
import i18n from '../../language/i18n';
import BookingService from '../../services/booking.service';
import styles from './index.module.css';
import Margin from '../../components/base/margin';
import BookingCard from '../../components/sections/bookings/booking-card';

export default function BookingsPage() {
    const [loading, setLoading] = useState(true);
    const [currentBookings, setCurrentBookings] = useState<any[] | null>(null);
    const [pastBookings, setPastBookings] = useState<any[] | null>(null);
    const context = useAuth();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState<'past' | 'current'>('current');

    useEffect(() => {
        // Gets all user bookings
        async function getUserBookings() {
            if (context.auth) {
                try {
                    const { data } = await BookingService.getAll({
                        userDetails: { id: context.auth?.uid },
                        sort: [{ order: 'desc', field: 'creationDate' }],
                    });
                    if (data) {
                        const currentBookingStatuses = [
                            BookingStatus.ACTIVE,
                            BookingStatus.PENDING,
                            BookingStatus.SECURITY_DEPOSIT_PENDING,
                            BookingStatus.BOOKED,
                            BookingStatus.SECURITY_DEPOSIT_RETRY,
                        ];
                        const pastBookingStatuses = [
                            BookingStatus.COMPLETED,
                            BookingStatus.FINISHED,
                            BookingStatus.PROCESSED,
                            BookingStatus.REVIEW,
                            BookingStatus.PAYMENT_FAILED,
                            BookingStatus.SECURITY_DEPOSIT_FAILED,
                            BookingStatus.CANCELLED,
                            BookingStatus.CANCELLED_BY_USER,
                        ];
                        const isCurrentBooking = (status: string) =>
                            currentBookingStatuses.includes(status as BookingStatus);
                        const isPastBooking = (status: string) => pastBookingStatuses.includes(status as BookingStatus);

                        const currentBookingsFilter = data.result.filter((booking: any) => {
                            return isCurrentBooking(booking.status);
                        });

                        const pastBookingsFilter = data.result.filter((booking: any) => {
                            return isPastBooking(booking.status);
                        });
                        setCurrentBookings(currentBookingsFilter);
                        setPastBookings(pastBookingsFilter);
                    } else {
                        console.log('No bookings');
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    setLoading(false);
                }
            }
        }
        getUserBookings();
    }, [context]);

    const selectTab = (tab: 'past' | 'current') => {
        setSelectedTab(tab);
    };

    const renderBookingList = useCallback(() => {
        const onClickItem = (id: string) => {
            history.push(`/booking/${id}`);
        };

        if (selectedTab === 'current') {
            return currentBookings!.map(booking => (
                <BookingCard key={booking.id} booking={booking} onItemClick={() => onClickItem(booking.id)} />
            ));
        }
        return pastBookings!.map(booking => (
            <BookingCard past key={booking.id} booking={booking} onItemClick={() => onClickItem(booking.id)} />
        ));
    }, [currentBookings, pastBookings, selectedTab, history]);

    if (loading) {
        return <Loading spinnerSize={40} halfScreen />;
    }

    return (
        <Column className={styles.container}>
            <Row className={styles.navLinks}>
                <Link className={styles.link} to="/profile" as={RouterLink} variant="termsOfService">
                    {i18n.t('pages.booking.myAccountLink').toUpperCase()}
                </Link>
                /{' '}
                <Link className={styles.link} to="/bookings" as={RouterLink} variant="termsOfService">
                    {i18n.t('pages.bookingList.header').toUpperCase()}
                </Link>
            </Row>
            <div className={styles.headerRow}>
                <span className={styles.title}>{i18n.t('pages.bookingList.header')}</span>
            </div>
            <Margin />
            <Row className={styles.tabs}>
                <div
                    onClick={() => selectTab('current')}
                    className={[styles.tab, selectedTab === 'current' ? styles.active : ''].join(' ')}
                >
                    <span>{i18n.t('pages.booking.currentAndUpcoming')}</span>{' '}
                    <span className={styles.bookingSpan}>{i18n.t('sidebar.option.booking')}</span>
                    <div className={[styles.border, selectedTab === 'current' ? styles.activeBorder : ''].join(' ')} />
                </div>

                <div
                    onClick={() => selectTab('past')}
                    className={[styles.tab, selectedTab === 'past' ? styles.active : ''].join(' ')}
                >
                    <span>{i18n.t('pages.booking.past')}</span>
                    <div className={[styles.border, selectedTab === 'past' ? styles.activeBorder : ''].join(' ')} />
                </div>
            </Row>
            {currentBookings ? renderBookingList() : null}
        </Column>
    );
}
