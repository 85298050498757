import React from 'react';

import { DesktopAssetFilter } from './desktop';
import { MobileAssetFilter } from './mobile';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { AssetFilterProps } from './types';
import { MobileSortFilter } from '../filter-sort/mobile';

function AssetsFilters(props: AssetFilterProps) {
    const windowSize = useWindowSize();
    const { sort, showMobileSort, onCloseSort, onSortSubmit } = props;

    const getFilters = () => {
        if (windowSize.width > 692) {
            return <DesktopAssetFilter {...props} />;
        }

        return (
            <>
                <MobileAssetFilter {...props} />
                <MobileSortFilter
                    sort={sort}
                    showMobileSort={showMobileSort || false}
                    onCloseSort={onCloseSort!}
                    onSortSubmit={onSortSubmit!}
                />
            </>
        );
    };

    return <>{getFilters()}</>;
}

export default AssetsFilters;
