import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './locale/en_GB.json';

const defaultLanguage = 'en';
export const deviceLanguage = navigator.language;

export const parseLanguage = (lang: any) => {
    if (lang !== null && lang.trim() !== '') {
        if (lang.toLowerCase().startsWith('en') || lang.toLowerCase().includes('en')) {
            return 'en';
        }
    }
    return defaultLanguage;
};

export let shortLocale = parseLanguage(deviceLanguage) as any;

const initOptions: InitOptions = {
    fallbackLng: defaultLanguage,
    debug: false,
    returnNull: false,
    defaultNS: 'translation',
    resources: {
        en: { translation: EN },
    },
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
        return `_${key}`;
    },
    missingInterpolationHandler: key => {
        return `_${key}`;
    },
    parseMissingKeyHandler: key => {
        return `_${key}`;
    },
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    react: {
        wait: true,
    },
};

export class LanguageService {
    static initialize = () => {
        i18n.use(initReactI18next)
            .init({ ...initOptions, lng: shortLocale }, err => {
                // if languages loaded correctly ... do code here ...
            })
            .catch(err => {
                // internal err ...
                console.log(err);
            });
    };

    static changeLanguage = (lang: any) => {
        shortLocale = parseLanguage(lang);
        i18n.changeLanguage(shortLocale, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            localStorage.setItem('lang', shortLocale);
        });
    };
}

LanguageService.initialize();

export default i18n;
