import React, { useState, useEffect, useRef, useCallback } from 'react';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { useHistory } from 'react-router-dom';

import Column from 'components/base/column';
import SearchBar from 'components/sections/home/search-bar';
import LocationModal from 'components/sections/home/location-modal';
import SectionHomeIntroBanner from 'components/sections/home/intro-banner';
import SectionCarBanner from 'components/sections/home/car-banner';
import Business from 'components/sections/home/business';
import HowItWorks from 'components/sections/home/how-it-works';
import FAQ from 'components/sections/home/faq';

import { useAuth, useRemoteConfig, useUserLocation, useWindowSize } from 'hooks';
import i18n from 'language/i18n';

import useUrl from 'hooks/useUrl';
import styles from './index.module.css';

function HomePage() {
    const { homepageSearchEnabled } = useRemoteConfig();
    const [isOpenLocationModal, setIsOpenLocationModal] = useState<boolean>(false);
    const userLocation = useUserLocation();
    const history = useHistory();
    const auth = useAuth();
    const howItWorksRef = useRef<HTMLDivElement>();
    const [action] = useUrl('action');
    const windowSize = useWindowSize();

    const checkForActions = useCallback(() => {
        if (action === 'register') {
            auth.showAuth('register');
        }
    }, [action, auth]);

    const cities = [
        {
            label: i18n.t('city.lisbon'),
            location: {
                east: -9.093977234624418,
                north: 38.814589425561934,
                south: 38.6359370818962,
                west: -9.229933045171862,
            },
        },
        {
            label: i18n.t('city.paris'),
            location: {
                east: 2.46976,
                north: 48.902156,
                south: 48.815575,
                west: 2.224122,
            },
        },
        {
            label: i18n.t('city.newYork'),
            location: {
                east: -73.700181,
                north: 40.916178,
                south: 40.477399,
                west: -74.25909,
            },
        },
    ];

    const onContinueButton = () => {
        if (!homepageSearchEnabled) {
            return;
        }

        history.push('/assets');
    };

    const checkUserLocation = () => {
        const nyLocation = cities[2].location;
        const southWest = new LngLat(nyLocation.south, nyLocation.west);
        const northEast = new LngLat(nyLocation.north, nyLocation.east);

        const bounds = new LngLatBounds(southWest, northEast);

        if (userLocation === undefined) {
            return false;
        }

        if (userLocation && userLocation.coords) {
            const { latitude, longitude } = userLocation.coords;
            const isLocatedOnNY = bounds.contains([latitude, longitude]);
            return !isLocatedOnNY;
        }

        return true;
    };

    const onCloseModal = () => {
        setIsOpenLocationModal(false);
    };

    const executeScroll = () => {
        howItWorksRef.current!.scrollIntoView({
            behavior: 'smooth',
            block: windowSize.isPhone || windowSize.isTablet ? 'start' : 'center',
        });
    };

    useEffect(() => {
        const shouldOpenModal = checkUserLocation();

        if (shouldOpenModal !== isOpenLocationModal) {
            setIsOpenLocationModal(homepageSearchEnabled ? shouldOpenModal : false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation]);

    useEffect(checkForActions, []);

    return (
        <>
            <LocationModal isOpen={false} onClose={onCloseModal} />
            <SectionCarBanner className={styles.banner} />
            <SearchBar onSubmit={onContinueButton} />
            <Column className={styles.content}>
                <SectionHomeIntroBanner onClick={executeScroll} />
                <SectionCarBanner className={styles.bannerWide} relative wide />
                <HowItWorks reference={howItWorksRef} />
                <Business />
                <FAQ />
            </Column>
        </>
    );
}

export default HomePage;
