import { ChakraStyles } from 'theme';

const styles: ChakraStyles<any> = {
    tag: (color: string) => ({
        border: `1px solid ${color}`,
        color: `${color}`,
        padding: '8px 16px',
        borderRadius: '8px',
        height: 'max-content',
        width: 'max-content',
    }),

    icon: () => ({
        width: '16.67px',
        height: '16.67px',
    }),

    failed: () => ({
        display: 'flex',
        height: 'min-content',
        width: 'min-content',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        padding: '8px 16px',
        border: '1px solid var(--color-red)',
        borderRadius: '8px',
        color: 'var(--color-red)',
    }),

    mobile: () => ({
        mb: 3,
        padding: '6px 10px',
    }),
};

export default styles;
