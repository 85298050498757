import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Box, HStack, Input } from '@chakra-ui/react';

import DateRangeDisplay from 'components/base/dateRangeDisplay';
import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';

import { useSharedData } from 'hooks';
import i18n from 'language/i18n';
import { HeaderSearchProps } from '..';

import styles from './styles';

function MobileHeaderSearch(_: HeaderSearchProps) {
    const { shared, sharedDataService } = useSharedData();
    const { query } = shared.start;
    const { start, end } = shared;
    const loc = useLocation();
    const locName = loc.pathname.slice(1, loc.pathname.length);

    const [startDate, setStartDate] = useState(start.date);
    const [endDate, setEndDate] = useState(end.date);
    const history = useHistory();

    useEffect(() => {
        setStartDate(shared.start.date);
        setEndDate(shared.end.date);
    }, [shared]);

    function onSearchClick() {
        history.push(`/search?origin=${locName}&step=0`);
    }

    function onDatesClick() {
        history.push(`/search?origin=${locName}&step=2`);
    }

    function onClickBack() {
        sharedDataService.unsetDeliveryAddress();
        sharedDataService.unsetReturnAddress();
        sharedDataService.unsetDeliveryDate();
        sharedDataService.unsetReturnDate();

        history.push(`/`);
    }

    return (
        <HStack {...styles.stack}>
            <Box {...styles.icon}>
                <ArrowLeftIcon fill="black" onClick={onClickBack} />
            </Box>
            <Box flex="1" maxW="100%" whiteSpace="nowrap" overflow="hidden">
                <Input
                    {...styles.input}
                    onClick={onSearchClick}
                    placeholder={query || i18n.t('sections.searchHeader.searchLabel')}
                    value={query || i18n.t('sections.searchHeader.searchLabel')}
                />
                <Box {...styles.roundWrap} onClick={onDatesClick}>
                    <DateRangeDisplay noBorder startDate={startDate} endDate={endDate!} timeFormat="hh:mma" />
                </Box>
            </Box>
        </HStack>
    );
}

export default MobileHeaderSearch;
