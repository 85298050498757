import i18n from '../language/i18n';
import { CarCategory } from '../models/carCategory';

export function getCarDescription(category?: string) {
    switch (category && category.toLowerCase()) {
        case CarCategory.ECONOMY.toLowerCase():
            return i18n.t('page.assets.economy.description');
        case CarCategory.SEDAN.toLowerCase():
            return i18n.t('page.assets.sedan.description');
        case CarCategory.SUV.toLowerCase():
            return i18n.t('page.assets.suv.description');
        default:
            return i18n.t('page.assets.economy.description');
    }
}
