import i18n from '../../../../language/i18n';

export const propulsionTypes = [
    { label: 'All', value: 'ALL' },
    { label: i18n.t('propulsionTypes.GAS'), value: 'gas' },
    { label: i18n.t('propulsionTypes.DIESEL'), value: 'diesel' },
    { label: i18n.t('propulsionTypes.HYBRID'), value: 'hybrid' },
    { label: i18n.t('propulsionTypes.ELECTRIC'), value: 'electric' },
];

export const greenVehicles = [
    { label: 'All', value: 'ALL', filter: 'propulsionTypes' },
    { label: i18n.t('propulsionTypes.HYBRID'), value: 'hybrid' },
    { label: i18n.t('propulsionTypes.ELECTRIC'), value: 'electric' },
];

export const seatTypes = [
    { label: '2', value: 2 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
];

export const transmissionTypes = [
    { label: 'All', value: 'ALL', filter: 'transmissionTypes' },
    { label: 'Automatic', value: 'AUTOMATIC' },
    { label: 'Manual', value: 'MANUAL' },
];

export const categoryTypes = [
    { label: i18n.t('asset.category.A'), value: 'A' },
    { label: i18n.t('asset.category.B'), value: 'B' },
    { label: i18n.t('asset.category.C'), value: 'C' },
    { label: i18n.t('asset.category.D'), value: 'D' },
    { label: i18n.t('asset.category.E'), value: 'E' },
];

export const brands = [
    { label: 'Volkswagen', value: 'volkswagen' },
    { label: 'BMW', value: 'bmw' },
];

export const features = [
    { label: 'Wheelchair Accessible', value: 'wheelchair' },
    { label: 'All-wheel drive', value: 'allWheel' },
    { label: 'Android Auto', value: 'android' },
    { label: 'Apple Car', value: 'appleCar' },
    { label: 'Bluetooth', value: 'bluetooth' },
    { label: 'Heated seats', value: 'heatedSeats' },
    { label: 'Backup camera', value: 'camera' },
    { label: 'Blind spot warning', value: 'blindSpot' },
];

export const yearsRange = [
    { label: '2021-2020', value: '21/20' },
    { label: '2019-2017', value: '19/17' },
    { label: '2016-2012', value: '16/12' },
    { label: '2011-2005', value: '11/05' },
];
