import React from 'react';
import ModalNew from 'components/base/modalNew';
import TransmissionAutomaticIcon from 'components/base/icon/transmission-automatic';
import ElectricIcon from 'components/base/icon/electric';
import { Text, useBreakpointValue } from '@chakra-ui/react';
import Column from '../../../base/column';
import Row from '../../../base/row';
import styles from './index.module.css';
import i18n from '../../../../language/i18n';
import { Asset } from '../../../../services/asset.service';
import Splitter from '../../../base/splitter';
import AssetDetailsCharacteristicsItem from '../../asset-details/characteristics-item';
import SeatsIcon from '../../../base/icon/seats';
import DoorsIcon from '../../../base/icon/doors';
import TransmissionIcon from '../../../base/icon/transmission';
import DieselIcon from '../../../base/icon/diesel';
import AssetDetailsFeaturesItem from '../../asset-details/features-item';
import LuggagesIcon from '../../../base/icon/luggages';
import HeatingIcon from '../../../base/icon/heating';
import HotseatIcon from '../../../base/icon/hot-seat';
import BluetoothIcon from '../../../base/icon/bluetooth';
import UsbIcon from '../../../base/icon/usb';

export type AssetDetailsModalProps = {
    asset: Asset;
    isOpen: boolean;
    onClose: (...args: any[]) => void;
};

export default function AssetDetailsModal(props: AssetDetailsModalProps) {
    const { asset, isOpen, onClose } = props;
    const responsiveSize = useBreakpointValue({ base: 'full', md: '3xl' });
    const transmission = asset.transmission ? i18n.t(`asset.transmission.${asset.transmission.toUpperCase()}`) : 'N/A';
    const transmissionIcon = asset.transmission === 'AUTOMATIC' ? TransmissionAutomaticIcon : TransmissionIcon;
    const propulsionType = asset.propulsionType
        ? i18n.t(`propulsionTypes.${asset.propulsionType.toUpperCase()}`)
        : 'N/A';
    const suitcases = asset.luggages
        ? `${Number(asset.luggages)} Suitcase${Number(asset.luggages) !== 1 && 's'}`
        : 'N/A';
    const doors = asset.doors ? `${asset.doors} ${i18n.t('pages.assetDetails.doors')}` : 'N/A';
    const propulsionIcon = asset.propulsionType === 'electric' ? ElectricIcon : DieselIcon;

    const title = `${i18n.t('sections.assetDetails.detailsModal.title')}`;

    return (
        <ModalNew isOpen={isOpen} onClose={onClose} title={title} centerTitle size={responsiveSize}>
            <Column>
                <Splitter spacing="large" />
                <Column className={styles.contentContainer}>
                    <Text className={styles.subtitle}>
                        {asset.brand} {asset.model} {i18n.t('sections.assetDetails.detailsModal.mainFeatures')}:
                    </Text>
                    <Row className={styles.detailsContainer}>
                        <Column className={styles.column}>
                            <AssetDetailsCharacteristicsItem
                                row
                                icon={SeatsIcon}
                                label={`${asset.seats} ${i18n.t('pages.assetDetails.seats')}`}
                            />
                            <Splitter spacing="small" />
                            <AssetDetailsCharacteristicsItem row icon={DoorsIcon} label={doors} />
                            <Splitter spacing="small" />
                            <AssetDetailsCharacteristicsItem
                                row
                                className={styles.iconTransmission}
                                icon={transmissionIcon}
                                label={transmission}
                            />
                            <Splitter spacing="small" />
                            <AssetDetailsCharacteristicsItem row icon={propulsionIcon} label={propulsionType} />
                            <Splitter spacing="small" />
                            <AssetDetailsFeaturesItem row icon={LuggagesIcon} label={`${suitcases}`} />
                            <Splitter spacing="small" className={styles.splitter} />
                        </Column>
                        <Column>
                            <AssetDetailsFeaturesItem
                                row
                                icon={HeatingIcon}
                                label={i18n.t('pages.assetDetails.heating')}
                            />
                            <Splitter spacing="small" />
                            <AssetDetailsFeaturesItem
                                row
                                icon={HotseatIcon}
                                label={i18n.t('pages.assetDetails.seatHeater')}
                            />
                            <Splitter spacing="small" />
                            <AssetDetailsFeaturesItem
                                row
                                icon={BluetoothIcon}
                                label={i18n.t('pages.assetDetails.bluetooth')}
                            />
                            <Splitter spacing="small" />
                            <AssetDetailsFeaturesItem row icon={UsbIcon} label={i18n.t('pages.assetDetails.usb')} />
                            <Splitter spacing="small" />
                        </Column>
                    </Row>
                    <Splitter spacing="xxlarge" className={styles.largeSplitter} />
                    <Text className={[styles.subtitle, styles.moreInfoSubtitle].join(' ')}>
                        {i18n.t('sections.assetDetails.detailsModal.moreInfo')}
                    </Text>
                    <Row className={styles.marginBottom}>
                        <Text className={[styles.moreInfoText, styles.regular].join(' ')}>
                            {i18n.t('sections.assetDetails.detailsModal.tankInfo')}
                        </Text>
                    </Row>
                    <Row className={styles.marginBottom}>
                        <Text className={[styles.moreInfoText, styles.regular].join(' ')}>
                            {i18n.t('sections.assetDetails.detailsModal.mileageInfo')}
                        </Text>
                    </Row>
                    <Row className={styles.marginBottom}>
                        <Text className={[styles.moreInfoText, styles.regular].join(' ')}>
                            {i18n.t('sections.assetDetails.detailsModal.childrenSeatsInfo')}
                        </Text>
                    </Row>
                    <Row>
                        <Text className={[styles.moreInfoText, styles.regular].join(' ')}>
                            {i18n.t('sections.assetDetails.detailsModal.tollLaneInfo')}
                        </Text>
                    </Row>
                </Column>
            </Column>
        </ModalNew>
    );
}
