import React from 'react';
import styles from './index.module.css';
import i18n from '../../language/i18n';
import Column from '../../components/base/column';

export default class NotFoundPage extends React.Component<any, any> {
    render() {
        return <Column className={styles.container}>{i18n.t('default.notFound')}</Column>;
    }
}
