import React from 'react';
import { Button } from '@chakra-ui/react';
import Text from '../../../base/text';
import styles from './index.module.css';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import i18n from '../../../../language/i18n';
import { Modal } from '../../../base/modal';

function LocationModal(props: any) {
    return (
        <Modal show={props.isOpen} size="fit" onClose={props.onClose} className={styles.container}>
            <WarningIcon className={styles.icon} />
            <Text className={styles.title}>{i18n.t('sections.locationModal.title')}</Text>
            <Text className={styles.description}>{i18n.t('sections.locationModal.description')}</Text>
            <Button onClick={props.onClose} variant="outline" colorScheme="black" size="sm" alignSelf="center">
                {i18n.t('sections.locationModal.button')}
            </Button>
        </Modal>
    );
}

export default LocationModal;
