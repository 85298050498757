export class UrlService {
    static params: URLSearchParams;

    static componentProps: any;

    static destroy: any;

    static initialize = (props: any) => {
        try {
            UrlService.componentProps = props;
            UrlService.params = new URLSearchParams(props.location.search);
        } catch (err) {
            console.log(err);
            UrlService.destroy && UrlService.destroy();
        }
    };

    static onChange = (onParamsChange?: any) => {
        if (onParamsChange) {
            UrlService.destroy = UrlService.componentProps.history.listen(async (location: any, action: any) => {
                UrlService.params = new URLSearchParams(location.search);
                await onParamsChange(location, action);
            });
        }
    };

    static setParams = (newParams: any[], historyAction: 'push' | 'replace' = 'push') => {
        let areSameParams = true;
        newParams.forEach((newParam: any) => {
            if (newParam && UrlService.params.get(newParam.key) !== String(newParam.data)) {
                UrlService.params.set(newParam.key, newParam.data);
                areSameParams = false;
            }
        });

        // Don't update query string if the new parameters are equal to the current ones
        if (areSameParams) {
            return;
        }

        UrlService.componentProps.history[historyAction](
            `${UrlService.componentProps.history.location.pathname}?${UrlService.params.toString()}`,
        );
    };

    static getParams = (newParams: string[]) => {
        return newParams.map((param: any) => {
            return UrlService.params.get(param) || null;
        });
    };

    static removeParams = (newParams: string[], historyAction: 'push' | 'replace' = 'push') => {
        newParams.forEach((newParam: any) => {
            if (UrlService.params.get(newParam)) {
                UrlService.params.delete(newParam);
            }
        });

        UrlService.componentProps.history[historyAction](
            `${UrlService.componentProps.history.location.pathname}?${UrlService.params.toString()}`,
        );
    };
}

export const urlService = new UrlService();
