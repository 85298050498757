import React, { ReactNode } from 'react';
import Row from '../../row';
import { H2 } from '../h2';

import styles from './index.module.css';

export interface PageTitleProps {
    children: ReactNode;
    className?: string;
}

const PageTitle = ({ children, className }: PageTitleProps) => {
    return (
        <Row className={[styles.titleContainer, className].join(' ')}>
            <H2 className={styles.title}>{children}</H2>
        </Row>
    );
};

export default PageTitle;
