import { DrawerContentProps } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import { ChakraStyles } from 'theme';

const chakraStyles: ChakraStyles<DrawerContentProps> = {
    drawerContent: {
        height: '100%',
        p: { base: '21px', md: '32px 62px' },
    },
    drawerFooter: {
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
        bgColor: colors.white,
        p: { base: '24px 20px', md: '24px 62px' },
        boxShadow: '0px -2px 16px 0px #0B0B0D14',
    },
    title: {
        mb: 2,
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font-medium)',
    },
};

export default chakraStyles;
