import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Accordion } from 'components/base/accordion';
import i18n from 'language/i18n';
import { InsurancesDataOptions } from 'services/insurances.service';
import { joinClasses } from 'helpers/joinClasses';
import { PriceCalculator } from 'services/price.formatter';
import styles from './index.module.css';

interface InsuranceOptionProps {
    data: InsurancesDataOptions;
    onOptionChange: (item: InsurancesDataOptions) => void;
    isSelected: boolean;
}

function InsuranceOption({ data, onOptionChange, isSelected }: InsuranceOptionProps) {
    const [isOpen, setisOpen] = useState<boolean>(false);

    function toggleOption() {
        if (isOpen) {
            setisOpen(false);
        } else {
            setisOpen(true);
        }
    }

    function renderHeaderMain(insurance: InsurancesDataOptions) {
        const price = new PriceCalculator(insurance.price!);
        return (
            <Flex justify="space-between" color={isSelected ? 'brand.primary.900' : undefined}>
                <Text fontWeight="medium" boxSizing="border-box" pr="4px">
                    {insurance.title}
                </Text>
                <Flex justify="flex-end">
                    <Text fontWeight="medium">{price.format()}</Text>
                    <Text fontWeight="light">/day</Text>
                </Flex>
            </Flex>
        );
    }

    function renderHeader(item: InsurancesDataOptions) {
        return (
            <Flex direction="column">
                {renderHeaderMain(item)}
                <Flex direction="column" mt="12px">
                    <Text variant="sm" fontWeight="medium">
                        Included coverage
                    </Text>
                    <Text variant="sm" fontWeight="light" color="gray.500" mt="4px">
                        {item.description.includedCoverage}
                    </Text>
                </Flex>
            </Flex>
        );
    }

    function renderFooter() {
        return (
            <Flex>
                <Text
                    textDecor="underline"
                    textUnderlineOffset="1px"
                    textDecorationThickness=".5px"
                    fontSize="15px"
                    fontWeight="medium"
                    lineHeight="22px"
                    color="brand.primary.900"
                    mt="12px"
                    onClick={e => {
                        e.stopPropagation();
                        toggleOption();
                    }}
                    cursor="pointer"
                >
                    {isOpen
                        ? i18n.t('sections.insurances.seeLessDetails')
                        : i18n.t('sections.insurances.seeMoreDetails')}
                </Text>
            </Flex>
        );
    }

    return (
        <Accordion
            i={!!data.optionId}
            expanded={isOpen}
            title={data.title}
            onToggle={() => toggleOption()}
            variants={{
                open: { height: 'auto', overflow: 'hidden' },
                collapsed: { height: 0, overflow: 'hidden' },
            }}
            header={renderHeader(data)}
            footer={renderFooter()}
            className={joinClasses(styles.accordion, isSelected ? styles.selected : '')}
            onClick={() => onOptionChange(data)}
            transition={{
                duration: 0.3,
                ease: [0.04, 0.62, 0.23, 0.98],
            }}
        >
            <Text fontSize="14px" fontWeight="medium" lineHeight="20px" mt="12px" mb="4px">
                Details
            </Text>
            <div className={styles.details} dangerouslySetInnerHTML={{ __html: data.description.details }} />
        </Accordion>
    );
}

export default InsuranceOption;
