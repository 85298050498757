import { functions } from './persistence';
import { GetAllResponse } from './types';
import { PriceObject } from './price.formatter';

export interface Extra {
    id: string;
    key: string;
    enabled: boolean;
    price: PriceObject;
    hasFees: boolean;
    selected?: boolean;
    label: string;
}

const ExtrasService = {
    async getAll(criteria?: any): Promise<GetAllResponse<Extra>> {
        const response = await functions.httpsCallable('searchExtras')(criteria || {});

        return response.data;
    },
};

export default ExtrasService;
