import React from 'react';
import Column from 'components/base/column';
import CalendarIcon from 'components/base/icon/calendar';
import MapPinIcon from 'components/base/icon/map-pin';
import Row from 'components/base/row';

import { ChakraProps, Flex, Text } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import i18n from 'language/i18n';
import chakraStyles from './styles';
import styles from './index.module.css';

export type LocationItemProps = {
    title?: string;
    location?: string | null;
    date?: string;
    sublocation?: string;
    inverted?: boolean;
    className?: string;
};

function Date({ date }: { date?: string }) {
    return (
        <Row className={styles.infoContainer}>
            <CalendarIcon className={styles.icon} />
            <Text {...chakraStyles.dateSection(!!date)} variant="normal">
                {date || i18n.t('sections.locationItem.specifyDate')}
            </Text>
        </Row>
    );
}

function Location({ location, sublocation }: Pick<LocationItemProps, 'location' | 'sublocation'>) {
    return (
        <Row className={styles.infoContainer}>
            <MapPinIcon className={styles.icon} variant="white" />
            <Column>
                <Text {...chakraStyles.locationSection(!!location)} variant="normal">
                    {location || i18n.t('sections.locationItem.specifyLocation')}
                </Text>
                {sublocation && (
                    <Text variant="sm" color={colors.gray} fontWeight="light">
                        {sublocation}
                    </Text>
                )}
            </Column>
        </Row>
    );
}

function LocationItem(props: LocationItemProps & ChakraProps) {
    const { title, inverted, className, ...rest } = props;
    const renderDate = Date(props);
    const renderLocation = Location(props);

    return (
        <Flex className={className} flexDirection="column" mb="var(--margin-medium)" {...rest}>
            <Text
                fontSize={{
                    base: 'var(--font-large)',
                    md: 'var(--font-medium)',
                }}
                lineHeight={{
                    base: 'var(--line-large)',
                    md: 'var(--line-medium)',
                }}
                textAlign="start"
                fontWeight="medium"
                mb="var(--margin-small)"
            >
                {title}
            </Text>
            {inverted ? (
                <>
                    {renderDate}
                    {renderLocation}
                </>
            ) : (
                <>
                    {renderLocation}
                    {renderDate}
                </>
            )}
        </Flex>
    );
}

LocationItem.Date = Date;
LocationItem.Location = Location;
export default LocationItem;
