import React from 'react';
import {
    Text,
    ChakraProps,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    ModalProps,
    useBreakpointValue,
    ModalHeader,
    Center,
    ModalFooter,
    ModalBodyProps,
    ModalContentProps,
    TextProps,
    BoxProps,
    Box,
} from '@chakra-ui/react';
import CloseIcon from '../icon/close';
import styles from './styles';
import Loading from '../loading';

export interface ModalNewProps {
    title?: string;
    description?: string;
    footer?: JSX.Element;
    centerTitle?: boolean;
    titleFontSize?: string;
    bodyStyles?: ModalBodyProps;
    loading?: boolean;
    modalContent?: ModalContentProps;
    modalHeader?: BoxProps;
    modalDescription?: TextProps;
}

function ModalNew({
    isOpen,
    onClose,
    title,
    description,
    children,
    size,
    minH,
    minHeight,
    footer,
    scrollBehavior,
    centerTitle,
    titleFontSize,
    bodyStyles,
    loading,
    modalContent,
    modalHeader,
    modalDescription,
    ...rest
}: ModalNewProps & ModalProps & ChakraProps) {
    const responsiveSize = useBreakpointValue({
        base: 'full',
        md: size || 'lg',
    });
    const responsiveHeight = useBreakpointValue({
        base: 'full',
        md: minH || minHeight || 300,
    });

    const Title = <ModalHeader {...styles.title(centerTitle, titleFontSize)}> {title} </ModalHeader>;
    const Close = (
        <ModalCloseButton {...styles.close}>
            <CloseIcon />
        </ModalCloseButton>
    );

    function modalContentStyles() {
        if (modalContent) return { ...modalContent };
        return { ...styles.content };
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={responsiveSize}
            isCentered
            scrollBehavior={scrollBehavior || 'inside'}
            {...rest}
        >
            <ModalOverlay />
            <ModalContent
                {...modalContentStyles()}
                minH={responsiveHeight}
                overflowY="scroll"
                justifyContent="center"
                sx={{
                    /* Fix modal full height on iOS browsers only to mobile */
                    '@media screen and (max-width: 492px)': {
                        '&': {
                            minH: '100dvh',
                        },
                    },
                    /* Hide de scrollbar while being able to scroll */
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': {
                        display: 'none',
                    },
                }}
            >
                {loading ? (
                    <Loading spinnerSize={40} />
                ) : (
                    <>
                        <Box {...modalHeader}>
                            <Center>
                                {Title}
                                {Close}
                            </Center>
                            <Text color="var(--color-gray-light)" {...modalDescription}>
                                {description}
                            </Text>
                        </Box>
                        <ModalBody {...styles.body} {...bodyStyles}>
                            {children}
                        </ModalBody>
                        <ModalFooter m="0" p="0">
                            {footer}
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default ModalNew;
