import { ButtonProps, InputProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<InputProps & ButtonProps> = {
    inputIcon: {
        p: 6,
        color: 'gray.300',
        cursor: 'pointer',
        _hover: {
            color: 'gray.500',
        },
    },
    item: {
        py: 4,
        px: 1,
        cursor: 'pointer',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'gray.100',
        _hover: {
            opacity: 0.8,
        },
    },
};

export default styles;
