import { Insurance, InsurancesDataOptions } from 'services/insurances.service';
import { PriceCalculator, PriceObject } from 'services/price.formatter';

export function insuranceReceiptFormat(insurances: Insurance[], duration: number) {
    // find selected options inside insurances

    const findOptions = (options: InsurancesDataOptions[]) =>
        options.find((option: InsurancesDataOptions) => option.selected);

    // map selected insurances
    return insurances.map(({ id, price, options, title }: Insurance) => {
        // if options: use price from options
        const value = options ? (findOptions(options)!.price as number | PriceObject) : price!;

        const insuranceTitle = options ? findOptions(options)!.title : title!;

        const itemPrice = new PriceCalculator(value);
        const isZero = itemPrice.getPriceAmount() <= 0;
        const isMultipleDays = duration > 1;
        const info = isMultipleDays && !isZero ? `(${duration} x ${itemPrice.format(2).slice(1)})` : undefined;

        return {
            key: id,
            label: insuranceTitle,
            value: itemPrice.clone().multiply(duration),
            info,
        };
    });
}
