import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import styles from './index.module.css';
import i18n from '../../../../language/i18n';
import AssetDetailsExtrasItem from '../extras-item';
import { Extra } from '../../../../services/extras.service';
import { H3 } from '../../../base/text/h3';

export type AssetDetailsExtrasProps = {
    extras: Extra[];
    selectedExtras: string[];
    onChange?: (items: string[]) => void;
};

function AssetDetailsExtras(props: AssetDetailsExtrasProps) {
    const { extras, selectedExtras, onChange } = props;

    const seeMore = i18n.t('default.showMore');
    const seeLess = i18n.t('default.seeLess');
    const [extrasCollapsed, setExtrasCollapsed] = useState(extras.length > 6);
    const hideSection = extras.length <= 0;

    function renderItem(item: Extra) {
        const isSelected = selectedExtras.includes(item.id);

        return (
            <AssetDetailsExtrasItem
                key={item.id}
                selected={isSelected}
                extra={item}
                onClick={() => onItemClick(item.id)}
            />
        );
    }

    function renderItems(collapsed: boolean) {
        const extrasCollapsedArray = extras.length > 6 ? extras.slice(0, 6) : null;

        if (collapsed && extrasCollapsedArray) {
            return extrasCollapsedArray.map(renderItem);
        }
        return extras.map(renderItem);
    }

    function onExtrasCollapse() {
        // set the boolean as opposite value
        setExtrasCollapsed(prev => !prev);
    }

    function onItemClick(key: string) {
        let items: string[] = selectedExtras;
        const itemExists = items.includes(key);

        if (itemExists) {
            items = items.filter(x => x !== key);
            onChange && onChange(items);
        } else {
            items.push(key);
            onChange && onChange(items);
        }
    }

    if (hideSection) return null;

    return (
        <div className={isMobile ? styles.mobileContainer : styles.container}>
            <H3 className={styles.header}>{i18n.t('sections.assetDetails.extras.header')}</H3>
            <div className={styles.extrasItems}>{renderItems(extrasCollapsed)}</div>
            {extras.length > 6 && (
                <div className={styles.seeMore}>
                    {extrasCollapsed ? (
                        <span onClick={onExtrasCollapse}>{seeMore}</span>
                    ) : (
                        <span onClick={onExtrasCollapse}>{seeLess}</span>
                    )}
                </div>
            )}
        </div>
    );
}

export default AssetDetailsExtras;
