import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Link as LinkButton, useToast } from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';

import { Accordion } from 'components/base/accordion';
import Column from 'components/base/column';
import Margin from 'components/base/margin';
import Row from 'components/base/row';
import { H3 } from 'components/base/text/h3';
import { Text } from 'components/base/textV2';

import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { useAuth } from 'hooks';
import { capitalize } from 'typings/string-utils';
import { PaymentMethod } from 'models/paymentMethods';
import PaymentMethodsService from 'services/paymentMethods.service';
import i18n from 'language/i18n';

import moment from 'moment';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import DeleteCardModal from './edit/deleteCardModal';
import EditBillingInfoModal from './edit/editBillingInfoModal';
import { PaymentMethodModal } from '../paymentMethodModal';
import styles from './index.module.css';

export function PersonalWallet() {
    const { getUser, user, auth } = useAuth();
    const unsubscribeSourcesListener = useRef<undefined | (() => void)>();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null | undefined>();
    const [expandedPaymentMethods, setExpandedPaymentMethods] = useState(false);
    const [expandedMainBilling, setExpandedMainBilling] = useState(false);
    const [paymentMethodFormIsOpen, setPaymentMethodFormIsOpen] = useState(false);
    const [openDeleteCardModal, setOpenDeleteCardModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [paymentMethodRef, setPaymentMethodRef] = useState('');
    const [sortedPaymentMethods, setSortedPaymentMethods] = useState<PaymentMethod[] | undefined>();
    const toast = useToast();

    const createSourcesListener = useCallback(async () => {
        if (!user || !auth || !auth.uid) {
            return;
        }

        const unsubscribeFn = PaymentMethodsService.getListener(
            sources => setPaymentMethods(sources),
            er => console.log(er),
        );

        unsubscribeSourcesListener.current = unsubscribeFn;
    }, [user, auth]);

    function onMount() {
        getUser();
        createSourcesListener();

        return () => {
            unsubscribeSourcesListener.current && unsubscribeSourcesListener.current();
        };
    }

    useEffect(onMount, []);

    const sortPaymentMethods = useCallback(async () => {
        if (paymentMethods && paymentMethods.length > 0) {
            // sort the payment methods with milliseconds
            const sortArrayPaymentMethods = paymentMethods.sort((a, b) => b.created - a.created);

            // change milliseconds to moment date
            const formattedPaymentMethods: PaymentMethod[] = sortArrayPaymentMethods.map(card => ({
                ...card,
                created: moment.unix(card.created).format('MM/DD/YYYY h:mm:ss'),
            }));

            setSortedPaymentMethods(formattedPaymentMethods);
        }
    }, [paymentMethods]);

    useEffect(() => {
        sortPaymentMethods();
    }, [paymentMethods, sortPaymentMethods]);

    function closeEditMode() {
        setShowEditModal(false);
    }

    function getPaymentReference(ref: any) {
        setOpenDeleteCardModal(true);
        setPaymentMethodRef(ref);
    }

    function onAddedCard() {
        toast({
            title: i18n.t('pages.profile.payments.toast.success.creation.title'),
            description: i18n.t('pages.profile.payments.toast.success.creation.description'),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top-right',
        });
    }

    function onClose() {
        setOpenDeleteCardModal(false);
    }

    function renderPaymentMethodList() {
        if (!paymentMethods || paymentMethods.length <= 0 || !sortedPaymentMethods) {
            return (
                <Row className={styles.paymentMethodTitle}>
                    <Text font-size="sm" fontWeight="light" color="gray-light">
                        {i18n.t('pages.profile.myWallet.noPaymentMethod')}
                    </Text>
                </Row>
            );
        }

        return sortedPaymentMethods.map(paymentMethod => {
            const title = capitalize(`${paymentMethod.card.brand} *${paymentMethod.card.last4}`);

            return (
                <Row key={paymentMethod.paymentMethodId} className={styles.accountInfo}>
                    <Column>
                        <Text fontSize="md" fontWeight="medium" className={styles.paymentMethodTitle}>
                            {title}
                        </Text>
                        <Text font-size="sm" fontWeight="light" color="gray-light">
                            {i18n.t('pages.profile.myWallet.paymentMethodItem.expiresOn', {
                                month: paymentMethod.card.exp_month,
                                year: paymentMethod.card.exp_year,
                            })}
                        </Text>
                        {user.taxNumber && (
                            <Text font-size="sm" fontWeight="light" color="gray-light">
                                {i18n.t('pages.profile.myWallet.paymentMethodItem.vatInfo', {
                                    lastThreeVAT: user.taxNumber.slice(-3),
                                })}
                            </Text>
                        )}
                    </Column>
                    <Trash className={styles.trashIcon} onClick={() => getPaymentReference(paymentMethod.ref)} />
                </Row>
            );
        });
    }

    return (
        <>
            <H3 className={styles.paymentProfile}>{i18n.t('pages.profile.myWallet.paymentProfile')}</H3>
            <Row>
                <Button
                    _hover={{ outline: 'unset' }}
                    cursor="default"
                    variant="outline"
                    colorScheme="black"
                    size="sm"
                    className={styles.businessProfileButton}
                >
                    <FiUser className={styles.briefcaseIcon} />
                    {i18n.t('pages.profile.personalWallet.personalProfileBtn')}
                </Button>
            </Row>

            <Accordion
                className={styles.accordionContainer}
                headerClassName={styles.accordionHeader}
                i={0}
                title={i18n.t('pages.profile.myWallet.paymentMethods')}
                expanded={expandedPaymentMethods}
                setExpanded={setExpandedPaymentMethods}
            >
                <Margin />
                <LinkButton className={styles.link} onClick={() => setPaymentMethodFormIsOpen(true)}>
                    <Row align="center">
                        {' '}
                        <PlusIcon /> {i18n.t('pages.profile.myWallet.addPaymentMethod')}{' '}
                    </Row>
                </LinkButton>
                <Margin />

                {renderPaymentMethodList()}
            </Accordion>

            <Accordion
                headerClassName={styles.accordionHeader}
                i={10}
                title={i18n.t('pages.profile.myWallet.mainBillingInfo')}
                expanded={expandedMainBilling}
                setExpanded={setExpandedMainBilling}
                transition={{ enter: { duration: 15 } }}
            >
                {user && user !== undefined ? (
                    <Column className={styles.mainBilling}>
                        <Text font-size="sm" fontWeight="regular" color="black" className={styles.billingData}>
                            <span>{i18n.t('pages.profile.personalWallet.billing.name')}: </span>
                            {user.name}
                        </Text>
                        <Text font-size="sm" fontWeight="regular" color="black" className={styles.billingData}>
                            <span>{i18n.t('pages.profile.personalWallet.billing.address')}: </span>
                            {user.address}
                        </Text>
                        <Text font-size="sm" fontWeight="regular" color="black" className={styles.billingData}>
                            <span>{i18n.t('pages.profile.personalWallet.billing.VAT')}: </span>
                            {user.taxNumber}
                        </Text>
                    </Column>
                ) : (
                    <Column className={styles.mainBilling}>
                        <Text font-size="sm" fontWeight="light" color="gray-light">
                            {i18n.t('pages.profile.personalWallet.noBillingInfo')}
                        </Text>
                    </Column>
                )}

                <Button variant="link" colorScheme="brand" onClick={() => setShowEditModal(true)}>
                    {i18n.t('pages.profile.myWallet.editMainBillingInfo')}
                </Button>
                {showEditModal ? <EditBillingInfoModal closeEditMode={closeEditMode} user={user} /> : null}
            </Accordion>

            {paymentMethodFormIsOpen && (
                <PaymentMethodModal isOpen onClose={() => setPaymentMethodFormIsOpen(false)} onComplete={onAddedCard} />
            )}
            <DeleteCardModal
                paymentMethodRef={paymentMethodRef}
                isOpen={openDeleteCardModal}
                onClose={() => onClose()}
            />
        </>
    );
}
