import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LogoLarge from 'components/base/icon/logoLarge';
import { useRemoteConfig } from 'hooks';
import useUrl from 'hooks/useUrl';
import { Button } from '@chakra-ui/react';
import authService from '../../../../../services/auth.service';
import styles from './index.module.css';
import Column from '../../../../base/column';
import { Input } from '../../../../base/input';
import Margin from '../../../../base/margin';
import { validations } from '../../../../../validations';
import Text from '../../../../base/text';
import i18n from '../../../../../language/i18n';

export type RegisterSectionProps = {
    onRegisterSuccess?: (...args: any[]) => any;
    onShowLogin?: (...args: any[]) => any;
    onClose?: (...args: any[]) => any;
};

export default function RegisterSection(props: RegisterSectionProps) {
    const [queryStringEmail] = useUrl('email');
    const { onRegisterSuccess, onShowLogin } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [nameError, setNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
    const [email, setEmail] = useState<string>(queryStringEmail || '');
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const history = useHistory();
    const { loginEnabled } = useRemoteConfig();

    const formValid = () => {
        const { isEmail, arePasswordsEqual, validateName, isPasswordInvalid } = validations;

        // Validation variables
        const nameValidation = validateName(name);
        const emailValidation = isEmail(email);
        const passwordsAreEqual = arePasswordsEqual(password, passwordConfirm);
        const passwordIsInvalid = isPasswordInvalid(password);

        // set error if strings are returned from the validation variables.

        if (nameValidation) {
            setNameError(nameValidation);
        }

        if (emailValidation) {
            setEmailError(emailValidation);
        }

        if (passwordsAreEqual) {
            setConfirmPasswordError(passwordsAreEqual);
        }

        if (passwordIsInvalid) {
            setPasswordError(passwordIsInvalid);
        }

        return (
            emailValidation === null &&
            passwordsAreEqual === null &&
            nameValidation === null &&
            passwordIsInvalid === null
        );
    };

    useEffect(() => {
        if (name) {
            setNameError('');
        }

        if (email) {
            setEmailError('');
        }

        if (password) {
            setPasswordError('');
        }

        if (passwordConfirm) {
            setConfirmPasswordError('');
        }
    }, [name, email, password, passwordConfirm]);

    const register = async () => {
        setLoading(true);
        try {
            if (!formValid()) {
                throw new Error(i18n.t('errors.invalid.userPass'));
            }

            await authService.register(email, password, name);
            await authService.login(email, password);

            history.replace({});
            onRegisterSuccess && onRegisterSuccess();
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const showLogin = (event: any) => {
        if (onShowLogin) {
            event.preventDefault();
            onShowLogin();
        }
    };

    const goToTerms = () => {
        history.push('/terms');
    };

    const renderLoginLink = loginEnabled && !queryStringEmail;
    return (
        <Column className={styles.container}>
            <Column className={styles.header}>
                <Text className={styles.title} center noMargin>
                    {i18n.t('sections.register.welcome')}
                </Text>
                <LogoLarge className={styles.logo} />
            </Column>

            <Input
                className={styles.input}
                name="name"
                label={i18n.t('sections.register.input.name')}
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                errorMessage={nameError}
            />
            <Input
                className={styles.input}
                name="email"
                label={i18n.t('sections.register.input.email')}
                type="email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                }}
                errorMessage={emailError}
                value={queryStringEmail ? decodeURIComponent(queryStringEmail) : undefined}
                disabled={!!queryStringEmail}
            />
            <Input
                className={styles.input}
                name="password"
                label={i18n.t('sections.register.input.password')}
                type="password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                errorMessage={passwordError}
            />
            <Input
                className={styles.input}
                name="passwordConfirm"
                label={i18n.t('sections.register.input.passwordConfirm')}
                type="password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirm(event.target.value)}
                errorMessage={confirmPasswordError}
                onEnterPress={register}
            />
            <Button variant="solid" colorScheme="black" size="md" w="full" onClick={register} isLoading={loading}>
                {i18n.t('sections.register.submitBtn')}
            </Button>

            <Margin />

            <Column>
                <Text className={styles.registerLink} center>
                    <Link className={styles.registerLabel} to="/terms" onClick={goToTerms}>
                        {i18n.t('sections.register.termsAndPrivacy')}
                    </Link>
                </Text>
                {renderLoginLink && (
                    <div className={styles.register}>
                        <Text className={styles.registerLabel} center>
                            {i18n.t('sections.register.loginLabel')}
                        </Text>
                        <Link className={styles.registerLink} to="/register" onClick={showLogin}>
                            {i18n.t('sections.register.loginLink')}
                        </Link>
                    </div>
                )}
            </Column>
        </Column>
    );
}
