import { Moment } from 'moment';
import { Coordinate } from 'services/operatingAreas.service';
import { TimeSlots } from './TimeSlots';

export interface SearchObject {
    date: Moment | null;
    time: TimeSlots | null;
    location: Coordinate | null;
    query: string | null;
}

export type DateSearchObject = Pick<SearchObject, 'date' | 'time'>;

export type LocationSearchObject = Pick<SearchObject, 'location' | 'query'>;

export function createSearchObject(
    date?: Moment | null,
    time?: TimeSlots | null,
    location?: Coordinate | null,
    query?: string | null,
) {
    return {
        date,
        time,
        location,
        query,
    } as SearchObject;
}

export function createDateSearchObject(date: Moment | null, time: TimeSlots | null) {
    return createSearchObject(date, time, undefined, undefined) as DateSearchObject;
}

export function createLocationSearchObject(location: Coordinate | null, query: string | null) {
    return createSearchObject(undefined, undefined, location, query) as LocationSearchObject;
}
