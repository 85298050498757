import React from 'react';

import imgBlack from '../../../../assets/icons/chevron-forward.svg';
import { ReactComponent as RedIcon } from '../../../../assets/icons/chevron-right-red.svg';
import Icon from '..';

export default class ChevronRightIcon extends React.Component<any> {
    render() {
        const { color } = this.props;

        const alt = 'chevron-forward';
        switch (color) {
            case 'red':
                return <RedIcon {...this.props} />;
            default:
                return <Icon {...this.props} img={imgBlack} alt={alt} />;
        }
    }
}
