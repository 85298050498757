import React from 'react';
import { InputProps } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<InputProps> = {
    inputIcon: {
        p: 6,
        color: 'gray.300',
        cursor: 'pointer',
        children: <CloseIcon />,
        _hover: {
            color: 'gray.500',
        },
    },
    title: {
        mb: 2,
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font-medium)',
    },
};

export default styles;
