import { Moment } from 'moment';

/**
 * Calculates the duration between the two provided dates
 *
 *  Example 1
 *
 *      Delivery 26 of May at 2 PM
 *      Return 27 at May at 2 PM or BEFORE
 *      Duration: 1 day
 *
 *   Exemple 2:
 *
 *       Delivery 26 of May at 2 PM
 *       Return 27 of May at 2:30 PM or AFTER (up until the 28th at 2 PM. After 2 PM the duration is 3 days, and so on)
 *       Duration: 2 days
 *
 * @param startDate
 * @param endDate
 * @returns `undefined` if either of the provided dates is invalid, otherwise a `number` representing the duration
 */
export function calculateDuration(startDate: Moment | undefined, endDate: Moment | undefined) {
    if (!startDate || !endDate) {
        return undefined;
    }

    const diff = endDate.clone().diff(startDate.clone(), 'days', true);

    // Means startDate and endDate have the same hours and minutes (i.e. both have their time equal to 9am)
    if (diff % 1 === 0) {
        return diff;
    }

    const preciseDiff = Math.floor(diff + 1);

    return preciseDiff;
}
