import React from 'react';
import Text from 'components/base/text';
import { BookingStatus } from 'models/booking/BookingStatus';
import i18n from 'language/i18n';
import { ReactComponent as ConfirmationIcon } from '../../../../assets/icons/confirmed-status.svg';
import { ReactComponent as PendingIcon } from '../../../../assets/icons/pending-status.svg';
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/completed-status.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import { ReactComponent as CancelledIcon } from '../../../../assets/icons/x_circle.svg';

import styles from './index.module.css';

type BookingStatusIconProps = {
    status?: string;
};

export default function BookingStatusIcon(props: BookingStatusIconProps) {
    const { status } = props;

    const isPending =
        status === BookingStatus.PENDING ||
        status === BookingStatus.SECURITY_DEPOSIT_PENDING ||
        status === BookingStatus.REVIEW ||
        status === BookingStatus.SECURITY_DEPOSIT_RETRY;
    const isVerified = status === BookingStatus.ACTIVE || status === BookingStatus.BOOKED;
    const isFailed = status === BookingStatus.SECURITY_DEPOSIT_FAILED || status === BookingStatus.PAYMENT_FAILED;
    const isCancelled = status === BookingStatus.CANCELLED || status === BookingStatus.CANCELLED_BY_USER;

    if (isPending) {
        return (
            <div className={styles.container}>
                <PendingIcon className={styles.icon} />
                <Text className={[styles.label, styles.pendingLabel].join(' ')}>
                    {i18n.t(`pages.booking.confirmationStatus.${status}`)}
                </Text>
            </div>
        );
    }
    if (isVerified) {
        return (
            <div className={styles.container}>
                <ConfirmationIcon className={styles.icon} />
                <Text className={[styles.label, styles.activeLabel].join(' ')}>
                    {i18n.t(`pages.booking.confirmationStatus.${status}`)}
                </Text>
            </div>
        );
    }
    if (isFailed) {
        return (
            <div className={styles.container}>
                <WarningIcon width={20} height={20} className={styles.icon} />
                <Text className={[styles.label, styles.failedLabel].join(' ')}>
                    {i18n.t(`pages.booking.confirmationStatus.${status}`)}
                </Text>
            </div>
        );
    }
    if (isCancelled) {
        return (
            <div className={styles.container}>
                <CancelledIcon width={20} height={20} className={[styles.icon, styles.cancelledLabel].join(' ')} />
                <Text className={[styles.label, styles.cancelledLabel].join(' ')}>
                    {i18n.t(`pages.booking.confirmationStatus.${status}`)}
                </Text>
            </div>
        );
    }
    return (
        <div className={styles.container}>
            <CompletedIcon className={[styles.icon, styles.completedLabel].join(' ')} />
            <Text className={[styles.label, styles.completedLabel].join(' ')}>
                {i18n.t(`pages.booking.confirmationStatus.${status}`)}
            </Text>
        </div>
    );
}
