import React from 'react';

import { ReactComponent as Icon } from '../../../../assets/icons/minus-circle.svg';

import { IconProps } from '../types';

export const MinusCircleIcon = (props: IconProps) => {
    const { className, color } = props;
    return <Icon {...props} className={className} stroke={color} />;
};
