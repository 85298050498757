import { useEffect, useState } from 'react';

/**
 * @returns random loading messages
 */

export function useRandomLoadingMessage() {
    /**
     * @description array with loading messages
     */
    const randomLoadMessage = [
        'Adjusting the seats...',
        'Fastening the seat belts...',
        'Revying up the engine...',
        'Checking dates...',
        'We promise this will be faster than a Windows update...',
    ];
    const [loadingMessage, setLoadingMessage] = useState<string | undefined | null>('');
    const [getPreviousMessage, setGetPreviousMessage] = useState<undefined | string>();

    /**
     * @function responsible for randomizing element
     */
    const randomizeLoadingMessage = (randomizeItems: string[]) => {
        const randomElement = randomizeItems[Math.floor(Math.random() * randomizeItems.length)];
        setGetPreviousMessage(randomElement);
        return randomElement;
    };

    const previousRandomMessage = getPreviousMessage;

    useEffect(() => {
        const intervalId = setInterval(() => {
            const copyArray = [...randomLoadMessage];
            let newRandomValue = randomizeLoadingMessage(randomLoadMessage);

            if (newRandomValue === previousRandomMessage) {
                const arrayWithoutPreviousValue = copyArray.filter(item => item !== newRandomValue);
                newRandomValue = randomizeLoadingMessage(arrayWithoutPreviousValue);
                setLoadingMessage(newRandomValue);
            }

            setLoadingMessage(newRandomValue);
        }, 2500);

        return () => clearInterval(intervalId);
    }, [loadingMessage]);

    return loadingMessage;
}
