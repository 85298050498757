import React, { ReactNode } from 'react';
import { Flex, Text, Icon, FlexProps, Box } from '@chakra-ui/react';
import moment, { Moment } from 'moment';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-v2.svg';

import { TimeFormat } from 'models/timeFormat';

import { styles } from './styles';

interface DateInputProps extends FlexProps {
    date?: Moment | null;
    time?: string | null | undefined;
    timeFormat?: 'HH:mm' | 'hh:mma';
    label?: string;
    placeholder?: string;
    isFocused?: boolean;
    clearIcon?: ReactNode;
}

export function DateInput(props: DateInputProps) {
    const {
        date,
        time,
        timeFormat = 'hh:mma',
        label,
        isFocused,
        clearIcon,
        placeholder: customPlaceholder,
        ...rest
    } = props;
    const placeholder = customPlaceholder || 'Choose date';
    const isPlaceholder = !date && !!placeholder;

    function formatTime(timeAsString: string | null | undefined, format: TimeFormat) {
        if (!timeAsString) return '';

        return moment(time, 'HH:mm').format(format);
    }

    return (
        <Box flex={1}>
            {label && (
                <Text variant="md" fontWeight="medium">
                    {label}
                </Text>
            )}
            <Flex role="group" {...styles.input(isFocused)} {...styles.dateInputWidth} {...rest}>
                {!date && <Icon as={CalendarIcon} {...styles.inputIcon(isFocused)} />}
                <Text {...styles.inputText(isPlaceholder, isFocused)}>
                    {date ? `${date.format('MMM DD')} ${formatTime(time, timeFormat)}` : placeholder}
                </Text>
                <Flex position="absolute" right={{ base: '2', xl: '4' }}>
                    {date && clearIcon && clearIcon}
                </Flex>
            </Flex>
        </Box>
    );
}
