import React from 'react';
import { useHistory } from 'react-router-dom';
import Column from 'components/base/column';
import { Button, Flex } from '@chakra-ui/react';
import styles from './index.module.css';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/check-circle.svg';
import Text from '../../../base/text';
import Row from '../../../base/row';
import i18n from '../../../../language/i18n';

export type BookingSuccessHeroProps = {
    id: string;
    creationDate: string;
    className: string;
};

function BookingSuccessHero({ id, creationDate, className }: BookingSuccessHeroProps) {
    const history = useHistory();

    function goToHome() {
        history.push('/');
    }

    function goToBookings() {
        history.push(`/bookings`);
    }

    return (
        <Column className={className}>
            <Row justify="start" align="center">
                <CheckCircleIcon className={styles.icon} />
                <Text className={styles.title}>{i18n.t('sections.checkout.modal.success.title')}</Text>
            </Row>
            <Text noMargin className={styles.info}>
                <span>{i18n.t('sections.checkout.modal.success.bookingId')}: </span> {id}
            </Text>
            <Text className={styles.info}>
                <span>{i18n.t('sections.checkout.modal.success.bookedOn')}: </span> {creationDate}
            </Text>
            <Text className={styles.description}>{i18n.t('sections.checkout.modal.success.message')}</Text>
            <Flex className={styles.buttonsRow}>
                <Button variant="solid" colorScheme="black" size="md" onClick={goToBookings}>
                    {i18n.t('sections.checkout.modal.success.buttonPrimary')}
                </Button>
                <Button variant="outline" colorScheme="black" size="md" onClick={goToHome}>
                    {i18n.t('sections.checkout.modal.success.buttonSecondary')}
                </Button>
            </Flex>
        </Column>
    );
}

export default BookingSuccessHero;
