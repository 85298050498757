import { ChangeDateAction } from 'models/sharedData/actions/ChangeDateAction';
import { ChangeSharedExtrasAction } from 'models/sharedData/actions/ChangeExtrasAction';
import { ChangeLocationAction, ChangeLocationActionLocationType } from 'models/sharedData/actions/ChangeLocationAction';
import { ChangeSharedDataAction } from 'models/sharedData/actions/ChangeSharedData';
import { LocationItem } from 'models/sharedData/LocationItem';
import {
    createDateSearchObject,
    createLocationSearchObject,
    DateSearchObject,
    LocationSearchObject,
    SearchObject,
} from 'models/sharedData/SearchObject';
import { SharedDataAction, SharedDataActionType } from 'models/sharedData/SharedDataActions';
import { ChangeSharedInsurancesAction } from 'models/sharedData/actions/ChangeInsurancesAction';
import { Insurance } from './insurances.service';

export class SharedDataService {
    private _dispatcher?: React.Dispatch<SharedDataAction>;

    get dispatcher() {
        if (this._dispatcher === undefined) {
            throw new Error();
        }

        return this._dispatcher;
    }

    set dispatcher(dispatcher: React.Dispatch<SharedDataAction>) {
        this._dispatcher = dispatcher;
    }

    updateDeliveryAddress(newLocation: LocationSearchObject) {
        const changeDeliveryLocationAction: ChangeLocationAction = {
            type: SharedDataActionType.CHANGE_LOCATION,
            payload: {
                query: newLocation.query,
                location: newLocation.location,
                locationType: ChangeLocationActionLocationType.DELIVERY_LOCATION,
            },
        };

        this.dispatcher(changeDeliveryLocationAction);
    }

    updateReturnAddress(newLocation: LocationSearchObject) {
        const changeReturnLocationAction: ChangeLocationAction = {
            type: SharedDataActionType.CHANGE_LOCATION,
            payload: {
                query: newLocation.query,
                location: newLocation.location,
                locationType: ChangeLocationActionLocationType.RETURN_LOCATION,
            },
        };

        this.dispatcher(changeReturnLocationAction);
    }

    unsetReturnAddress() {
        this.updateReturnAddress(createLocationSearchObject(null, null));
    }

    unsetDeliveryAddress() {
        this.updateDeliveryAddress(createLocationSearchObject(null, null));
    }

    unsetDeliveryDate() {
        this.updateDeliveryDate(createDateSearchObject(null, '09:00'));
    }
    unsetReturnDate() {
        this.updateReturnDate(createDateSearchObject(null, '09:00'));
    }

    updateReturnDate(dateObject: DateSearchObject) {
        this.updateDates(undefined, dateObject);
    }

    updateDeliveryDate(dateObject: DateSearchObject) {
        this.updateDates(dateObject, undefined);
    }

    updateDates(deliveryDateObject?: DateSearchObject, returnDateObject?: DateSearchObject) {
        const changeDeliveryDateAction: ChangeDateAction = {
            type: SharedDataActionType.CHANGE_DATE,
            payload: {
                deliveryDateObject,
                returnDateObject,
            },
        };

        this.dispatcher(changeDeliveryDateAction);
    }

    updateSharedData(deliveryObject: SearchObject, returnObject: SearchObject, returnLocationHistory: LocationItem[]) {
        const changeDeliveryDateAction: ChangeSharedDataAction = {
            type: SharedDataActionType.CHANGE_SHARED_DATA,
            payload: {
                deliveryObject,
                returnObject,
                returnLocationHistory,
            },
        };

        this.dispatcher(changeDeliveryDateAction);
    }

    updateSelectedExtras(extras: string[] | []) {
        const changeExtrasAction: ChangeSharedExtrasAction = {
            type: SharedDataActionType.CHANGE_EXTRAS,
            payload: {
                extras,
            },
        };

        this.dispatcher(changeExtrasAction);
    }

    updateSelectedInsurances(insurances: { main: Insurance | null; extras: Insurance[] }) {
        const changeInsurancesAction: ChangeSharedInsurancesAction = {
            type: SharedDataActionType.CHANGE_INSURANCES,
            payload: {
                insurances,
            },
        };

        this.dispatcher(changeInsurancesAction);
    }

    unsetExtras() {
        this.updateSelectedExtras([]);
    }

    unsetInsurances() {
        this.updateSelectedInsurances({ main: null, extras: [] });
    }
}
