import React, { useState, ReactNode } from 'react';
import { Button, Icon, Box, Flex } from '@chakra-ui/react';

import BriefcaseIcon from 'components/base/icon/briefcase';
import UserPersonalIcon from 'components/base/icon/user-personal';

import { joinClasses } from 'helpers/joinClasses';

import { DocumentReference } from 'services/persistence';
import styles from './index.module.css';

export type SectionOption = {
    value: string;
    label: string;
    icon: ReactNode;
    disabled?: boolean;
};

export type AssetPaymentProfile = {
    title: string;
    companyRef?: DocumentReference;
    profile: string;
    onChange?: (opt: string) => void;
    disabled?: 'personal' | 'business';
};

const options = [
    { value: 'personal', label: 'Personal', icon: UserPersonalIcon },
    { value: 'business', label: 'Business', icon: BriefcaseIcon },
];

function AssetPaymentProfile({ title, companyRef, onChange, profile, disabled }: AssetPaymentProfile) {
    const [selectedSection, setSelectedSection] = useState<SectionOption>(
        options.find(opt => opt.value === profile) || options[companyRef ? 1 : 0],
    );

    function handleSelectSection(opt: SectionOption) {
        if (opt.disabled || opt.value === disabled) {
            return;
        }

        setSelectedSection(opt);
        onChange?.(opt.value);
    }

    return (
        <Box>
            {title && <div className={styles.title}>{title}</div>}
            <Flex>
                {options.map(opt => {
                    const isSelected = opt.value === selectedSection.value;
                    const invalidBusiness = opt.value === 'business' && !companyRef;
                    const isDisabled = opt.value === disabled;

                    return (
                        <Button
                            key={opt.value}
                            variant={isSelected ? 'solid' : 'outline'}
                            colorScheme={isSelected ? 'black' : 'gray'}
                            size="sm"
                            height="48px"
                            mr="12px"
                            onClick={() => handleSelectSection(opt)}
                            disabled={invalidBusiness || isDisabled}
                        >
                            <Icon
                                as={opt.icon}
                                w="21px"
                                h="21px"
                                className={joinClasses(styles.icon, isSelected ? styles.activeIcon : '')}
                            />
                            {opt.label}
                        </Button>
                    );
                })}
            </Flex>
        </Box>
    );
}

export default AssetPaymentProfile;
