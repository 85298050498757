import React from 'react';

import styles from './index.module.css';

export interface GridProps {
    children?: any;
    className?: any;
}

export default function Grid(props: GridProps) {
    function render() {
        return (
            <div {...props} className={[styles.dynamic, props.className].join(' ')}>
                {props.children}
            </div>
        );
    }

    return render();
}
