import React from 'react';
import i18n from 'language/i18n';

function paymentMethodsQuestionBody() {
    return (
        <>
            <ul>
                <li>{i18n.t('sections.faq.paymentMethods.stepOne')}</li>
                <li>{i18n.t('sections.faq.paymentMethods.stepTwo')}</li>
            </ul>
            <p>{i18n.t('sections.faq.paymentMethods.ending')}</p>
        </>
    );
}

function notAcceptedPaymentQuestionBody() {
    return (
        <>
            <ul>
                <li>{i18n.t('sections.faq.notAcceptedPayment.stepOne')}</li>
                <li>{i18n.t('sections.faq.notAcceptedPayment.stepTwo')}</li>
                <li>{i18n.t('sections.faq.notAcceptedPayment.stepThree')}</li>
                <li>{i18n.t('sections.faq.notAcceptedPayment.stepFour')}</li>
                <li>{i18n.t('sections.faq.notAcceptedPayment.stepFive')}</li>
                <li>{i18n.t('sections.faq.notAcceptedPayment.stepSix')}</li>
                <li>{i18n.t('sections.faq.notAcceptedPayment.stepSeven')}</li>
            </ul>
        </>
    );
}

function internationalDriverLicenseQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.internationalDriverLicense.stepOne')}</p>
            <p>{i18n.t('sections.faq.internationalDriverLicense.stepTwo')}</p>
        </>
    );
}

function returnLocationQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.returnLocation.description')}</p>
            <strong>{i18n.t('sections.faq.returnLocation.note')}</strong>
        </>
    );
}

function myAddressQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.myAddress.stepOne')}</p>
            <p>{i18n.t('sections.faq.myAddress.stepTwo')}</p>
        </>
    );
}
function additionalDriverQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.additionalDriver.stepOne')}</p>
            <p>{i18n.t('sections.faq.additionalDriver.stepTwo')}</p>
        </>
    );
}

const body = [
    {
        title: i18n.t('sections.faq.paymentMethods.title'),
        content: paymentMethodsQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.notAcceptedPayment.title'),
        content: notAcceptedPaymentQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.reservationPayment.title'),
        content: i18n.t('sections.faq.reservationPayment.description'),
    },
    {
        title: i18n.t('sections.faq.longTermDiscounts.title'),
        content: i18n.t('sections.faq.longTermDiscounts.description'),
    },
    {
        title: i18n.t('sections.faq.internationalDriverLicense.title'),
        content: internationalDriverLicenseQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.carDelivery.title'),
        content: i18n.t('sections.faq.carDelivery.description'),
    },
    {
        title: i18n.t('sections.faq.returnLocation.title'),
        content: returnLocationQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.myAddress.title'),
        content: myAddressQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.carCleaning.title'),
        content: i18n.t('sections.faq.carCleaning.description'),
    },
    {
        title: i18n.t('sections.faq.additionalDriver.title'),
        content: additionalDriverQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.childrenSeat.title'),
        content: i18n.t('sections.faq.childrenSeat.description'),
    },
    {
        title: i18n.t('sections.faq.pets.title'),
        content: i18n.t('sections.faq.pets.description'),
    },
];

export const makingABookingSection = {
    title: i18n.t('sections.faq.makingABooking.title'),
    description: i18n.t('sections.faq.makingABooking.description'),
    body,
};
