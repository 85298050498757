import { RefObject, useEffect, useRef } from 'react';

function useOuterClick(callback: any) {
    const callbackRef: any = useRef(); // initialize mutable callback ref
    const innerRef: any = useRef(); // returned to client, who sets the "border" element

    // update callback on each render, so second useEffect has most recent callback
    useEffect(() => {
        callbackRef.current = callback;
    });

    // console.log("callback => ", callback);

    useEffect(() => {
        function handleClick(e: any) {
            if (innerRef.current && callbackRef.current) {
                if (!innerRef.current.contains(e.target)) {
                    callbackRef.current(e);
                }
            }
        }
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []); // no dependencies -> stable click listener

    return innerRef as RefObject<any>; // convenience for client (doesn't need to init ref himself)
}

export default useOuterClick;
