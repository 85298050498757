const h1 = { fontSize: '48px', lineHeight: '58px' };
const h2 = { fontSize: '32px', lineHeight: '40px' };
const h3 = { fontSize: '28px', lineHeight: '36px' };
const h4 = { fontSize: '24px', lineHeight: '32px' };
const h5 = { fontSize: '20px', lineHeight: '28px' };
const md = { fontSize: '16px', lineHeight: '24px' };
const sm = { fontSize: '14px', lineHeight: '20px' };
const normal = { fontSize: '15px', lineHeight: '22px' };

const TextTheme = {
    variants: {
        h1,
        h2,
        h3,
        h4,
        h5,
        md,
        sm,
        normal,
    },
    defaultProps: {
        variant: 'md',
    },
};

export default TextTheme;
