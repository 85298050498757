import React, { Suspense, useCallback, useEffect } from 'react';
import { Route as ReactRoute, RouteProps as ReactRouteProps } from 'react-router-dom';
import PageContainer from '../../base/pageContainer';
import styles from './index.module.css';

export type RouteProps = {
    /**
     *
     */
    wrap?: boolean;

    /**
     *
     */
    dark?: boolean;

    /**
     *
     */
    pageWrap?: boolean;
};
export default function Route(props: RouteProps & ReactRouteProps) {
    const { pageWrap, location } = props;
    const renderContent = () => {
        let content;
        if (pageWrap) {
            content = (
                <PageContainer className={styles.container}>
                    <ReactRoute {...props} />
                </PageContainer>
            );
        } else {
            content = <ReactRoute {...props} />;
        }

        return <Suspense fallback={<div>loading</div>}>{content}</Suspense>;
    };

    const onRouteChanged = useCallback(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        // if location changes from previous location trigger scroll
        function componentDidUpdate(prevProps: any) {
            if (location !== prevProps.location) {
                onRouteChanged();
            }
        }
        componentDidUpdate(props);
    }, [onRouteChanged]);

    return renderContent();
}
