import MapboxSDK from '@mapbox/mapbox-sdk/umd/mapbox-sdk';
import axios from 'axios';
import OperatingCitiesService from './operatingCities.service';

export const MapboxAccessToken =
    'pk.eyJ1IjoiZmllbGRtYXBib3gyMDIwIiwiYSI6ImNrYzZndjZqMjBheXgyeHBoaXpxZWo3NjgifQ.Iy0GmODEQ8uHqO9kCYykJg';
export const newYorkMapUrl = `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/[-74.3832,40.4836,-73.6288,40.9412]/500x400@2x?access_token=${MapboxAccessToken}`;
const client = MapboxSDK({ accessToken: MapboxAccessToken });
/**
 *
 * @param searchTerm
 */
export default {
    async searchLocation(searchTerm: string) {
        if (searchTerm && searchTerm.trim() !== '') {
            const response = await client.geocoding
                .forwardGeocode({
                    query: searchTerm,
                    countries: ['PT'],
                    autocomplete: true,
                })
                .send();

            if (response && response.body && response.body.features && response.body.features.length) {
                return response.body.features.map((feature: any) => ({
                    label: feature.place_name,
                    value: feature.center,
                    type: feature.place_type,
                }));
            }
        }

        return [];
    },

    async search(term: string, operatingCityId: string) {
        const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${new URLSearchParams(
            term,
        ).toString()}.json`;

        const operatingCity = await OperatingCitiesService.get(operatingCityId);

        return axios
            .get(endpoint, {
                params: {
                    access_token: MapboxAccessToken,
                    bbox: `${operatingCity?.bounding_box_coordinates.southWest.longitude},${operatingCity?.bounding_box_coordinates.southWest.latitude},${operatingCity?.bounding_box_coordinates.northEast.longitude},${operatingCity?.bounding_box_coordinates.northEast.latitude}`,
                },
            })
            .then((result: any) => {
                return result.data;
            });
    },
};
