import { useEffect, useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import { TimeSlots } from 'models/sharedData/TimeSlots';
import { useRemoteConfig } from './remoteConfig';
import { useSharedData } from './sharedData';

interface UseCalendarLimitsProps {
    startDate: Moment | null;
    endDate: Moment | null;
    startTime: TimeSlots;
    endTime: TimeSlots;
    setEndTime: React.Dispatch<React.SetStateAction<TimeSlots>>;
}

export function useCalendarLimits({ startDate, endDate, startTime, endTime, setEndTime }: UseCalendarLimitsProps) {
    const { noticeTime, bookingMaximumDuration } = useRemoteConfig();
    const { shared } = useSharedData();
    const [startLimit, setStartLimit] = useState<TimeSlots>('09:00');

    const isMaximumRange = endDate?.diff(startDate, 'days') === bookingMaximumDuration;
    const endLimit = isMaximumRange ? startTime : '23:30';

    const updateStartLimit = useCallback(
        (updatedStartDate?: Moment | null): TimeSlots => {
            if (!updatedStartDate) {
                setStartLimit('00:00');
                return '09:00';
            }

            const startDateSameAsToday = updatedStartDate?.clone().startOf('day').isSame(moment().startOf('day'));

            if (startDateSameAsToday) {
                const noticeTimeNumber = Number(noticeTime);
                const nowMinutes = moment().minutes();
                const exactHour = nowMinutes === 0;
                const firstHalf = (nowMinutes >= 1 && nowMinutes <= 30) || exactHour;
                const hoursToAdd = firstHalf ? noticeTimeNumber : noticeTimeNumber + 1;
                const minutesToAdd = firstHalf && !exactHour ? 30 : 0;
                const hours = moment().add(hoursToAdd, 'hours').set('minutes', minutesToAdd);
                setStartLimit(hours.format('HH:mm') as TimeSlots);
                return hours.format('HH:mm') as TimeSlots;
            }

            setStartLimit('00:00');
            return shared.start.time as TimeSlots;
        },
        [shared, noticeTime],
    );

    const updateEndTime = useCallback(
        (newTime: TimeSlots) => {
            const newTimeMoment = moment().setTime(newTime);
            const endTimeMoment = moment().setTime(endTime);
            if (newTimeMoment.isBefore(endTimeMoment, 'minutes')) {
                setEndTime(newTime);
            }
        },
        [endTime, setEndTime],
    );

    useEffect(() => {
        if (isMaximumRange) {
            updateEndTime(startTime);
        }
    }, [isMaximumRange, startTime, updateEndTime]);

    return { startLimit, updateStartLimit, endLimit, isMaximumRange };
}
