import React, { useEffect, useState, useCallback } from 'react';
import { Moment } from 'moment';
import { Box, Text as ChText, HStack, Button } from '@chakra-ui/react';
import DineroFactory from 'dinero.js';

import Column from 'components/base/column';
import DateRangeDisplay from 'components/base/dateRangeDisplay';
import Row from 'components/base/row';
import ScrollableDateRange from 'components/base/scrollableDateRange';
import DatesLengthDisplay from 'components/base/datesLengthDisplay';
import Margin from 'components/base/margin';
import TimePicker from 'components/base/timePicker';
import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';
import { Text } from 'components/base/textV2';
import { WizardStepProps } from 'pages/search-mobile';
import PageContainer from 'components/base/pageContainer';

import { calculateDuration } from 'helpers/calculateDuration';
import { joinClasses } from 'helpers/joinClasses';
import i18n from 'language/i18n';

import { colors } from 'styles/colors';
import {
    BookingCalendarDatesChangesInformation,
    handleBookingCalendarDatesChanges,
} from 'helpers/handleBookingCalendarDatesChanges';
import { useRemoteConfig, useSharedData, useCalendarLimits } from 'hooks';
import { DateRangeFocus } from 'components/base/dateRangePicker';
import { TimeSlots } from 'models/sharedData/TimeSlots';
import styles from './index.module.css';

export type MobileDateTimeSectionProps = {
    onBack?: (stepNumber?: number) => void;
    stepNumber?: number;
    onConfirmClick: (startDate: Moment, endDate: Moment) => void;
    clearButtonLocation: 'header' | 'footer';
    selectedDatesComponentVariant?: 'variant1' | 'variant2';
    headerTitle?: string;
    footerComponent?: any;
    initialStartDate: Moment | null;
    initialEndDate: Moment | null;
    className?: string;
    asset?: any;
    blockedDates?: string[];
};

function MobileDateTimeSelectSection(props: MobileDateTimeSectionProps & Partial<WizardStepProps>) {
    const {
        initialStartDate,
        initialEndDate,
        blockedDates,
        asset,
        onBack,
        className,
        clearButtonLocation,
        stepNumber,
        selectedDatesComponentVariant,
        headerTitle,
        footerComponent,
        jumpToStep,
    } = props;

    const { bookingMaximumDuration } = useRemoteConfig();
    const { shared } = useSharedData();

    const [startDate, setStartDate] = useState<Moment | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Moment | null>(initialEndDate);
    const [startTime, setStartTime] = useState<TimeSlots>((initialStartDate?.format('HH:mm') as TimeSlots) || '09:00');
    const [endTime, setEndTime] = useState<TimeSlots>(shared.end?.time || '09:00');
    const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(false);
    const [displayFooter, setDisplayFooter] = useState(false);
    const [dateRangeFocus, setDateRangeFocus] = useState<DateRangeFocus>('startDate');

    const { startLimit, updateStartLimit, endLimit } = useCalendarLimits({
        startDate,
        startTime,
        endDate,
        endTime,
        setEndTime,
    });

    const pricePerDay = asset ? DineroFactory(asset.pricePerDay) : undefined;

    useEffect(() => {
        if (initialEndDate || initialStartDate) {
            setDisplayFooter(true);
        }

        if (initialStartDate) {
            updateStartLimit(initialStartDate);
            setStartTime(initialStartDate.format('HH:mm') as TimeSlots);
        }
    }, [initialEndDate, initialStartDate, updateStartLimit]);

    useEffect(() => {
        if (startDate && endDate && startTime && endTime) {
            setConfirmButtonEnabled(true);
        }

        if (
            startDate?.format('MM/DD/YYYY') === initialStartDate?.format('MM/DD/YYYY') &&
            endDate?.format('MM/DD/YYYY') === initialEndDate?.format('MM/DD/YYYY') &&
            startTime === initialStartDate?.format('HH:mm') &&
            endTime === initialEndDate?.format('HH:mm')
        ) {
            setConfirmButtonEnabled(false);
        }

        if (endDate === undefined && startDate === undefined && clearButtonLocation !== 'header') {
            setDisplayFooter(false);
        } else {
            setDisplayFooter(true);
        }
    }, [startDate, endDate, clearButtonLocation, initialStartDate, initialEndDate, startTime, endTime]);

    const onInputDateRange = useCallback(
        (newStartDate: Moment | null, newEndDate: Moment | null) => {
            const bookingCalendarInformation = new BookingCalendarDatesChangesInformation({
                newStartDate,
                newEndDate,
                previousStartDate: startDate,
                startTime,
                previousEndDate: endDate,
                endTime,
                dateRangeFocus,
                bookingMaximumDuration,
                updateStartLimit,
            });

            const data = handleBookingCalendarDatesChanges(bookingCalendarInformation);

            setDateRangeFocus(data.focusedDate);
            setStartDate(data.startDate);
            setStartTime(data.startTime);
            setEndDate(data.endDate);
            setEndTime(data.endTime);

            return data;
        },
        [startDate, endDate, updateStartLimit, bookingMaximumDuration, dateRangeFocus, startTime, endTime],
    );

    function onDropdownClick(inputName: 'startTime' | 'endTime') {
        const hiddenInput = document.getElementById(inputName);

        if (hiddenInput === null) {
            return;
        }

        setTimeout(() => {
            hiddenInput.click();
            hiddenInput.focus();
        }, 1);
    }

    function onClickClear() {
        setEndDate(null);
        setStartDate(null);
        setStartTime('09:00');
        setEndTime('09:00');
        updateStartLimit();
    }

    function onClickBack() {
        onBack && onBack(stepNumber);

        if (stepNumber && jumpToStep) {
            jumpToStep(stepNumber - 1);
        }
    }

    function onConfirmClick() {
        if (!startDate || !endDate) {
            return;
        }

        const newStartDate = startDate && startDate.clone().setTime(startTime);
        const newEndDate = endDate && endDate.clone().setTime(endTime);

        props.onConfirmClick(newStartDate, newEndDate);
    }

    function renderClearButton() {
        return (
            <Button variant="link" colorScheme="black" onClick={onClickClear}>
                {i18n.t('mobileDateTimeSection.button.clearDates')}
            </Button>
        );
    }

    function displayDates() {
        if (!startDate || !endDate) {
            return 'Choose dates to check availability';
        }

        return `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`;
    }

    function renderSelectedDates() {
        switch (selectedDatesComponentVariant) {
            case 'variant2':
                return (
                    <>
                        <Box>
                            <DatesLengthDisplay startDate={startDate} endDate={endDate} />
                            <Margin value="8px" />
                            <ChText variant="sm" fontWeight="light" color={colors.grayish}>
                                {displayDates()}
                            </ChText>
                        </Box>
                    </>
                );
            case 'variant1':
            default:
                return (
                    <div className={styles.dateRange}>
                        <DateRangeDisplay
                            startDate={startDate}
                            endDate={endDate}
                            startTime={startTime}
                            endTime={endTime}
                            timeFormat="hh:mma"
                        />
                    </div>
                );
        }
    }

    function renderHeader() {
        if (clearButtonLocation === 'header') {
            return (
                <Column className={styles.header}>
                    <Row className={styles.headerClearBtn}>
                        <ArrowLeftIcon className={styles.arrowBack} onClick={onClickBack} />
                        {(startDate || endDate) && renderClearButton()}
                    </Row>
                    {renderSelectedDates()}
                </Column>
            );
        }

        if (headerTitle) {
            return (
                <Column className={styles.headerContainer}>
                    <Row className={styles.header}>
                        <ArrowLeftIcon className={styles.arrowBack} onClick={onClickBack} />
                        <Text className={styles.headerTitle}>{headerTitle}</Text>
                    </Row>
                    {renderSelectedDates()}
                </Column>
            );
        }

        return (
            <Row className={styles.header}>
                <ArrowLeftIcon className={styles.arrowBack} onClick={onClickBack} />
                {renderSelectedDates()}
            </Row>
        );
    }

    const onTimeChange = useCallback((name: string, time: TimeSlots) => {
        switch (name) {
            case 'startTime':
                setStartTime(time);
                break;
            case 'endTime':
                setEndTime(time);
                break;
            default:
        }
    }, []);

    function renderTimesInput() {
        return (
            <HStack spacing="8" p="4" mb="6">
                <TimePicker
                    time={startTime}
                    startLimit={startLimit}
                    endLimit="23:30"
                    containerStyle={styles.pickerContainer}
                    style={styles.picker}
                    onChange={time => onTimeChange('startTime', time)}
                    label={i18n.t('delivery.return.modal.deliveryTime')}
                    format="hh:mma"
                />

                <TimePicker
                    time={endTime}
                    startLimit="00:00"
                    endLimit={endLimit}
                    containerStyle={styles.pickerContainer}
                    style={styles.picker}
                    onChange={time => onTimeChange('endTime', time)}
                    label={i18n.t('delivery.return.modal.returnTime')}
                    format="hh:mma"
                />
            </HStack>
        );
    }

    function renderDailyPrice() {
        if (startDate && endDate) {
            return (
                <ChText variant="h5" fontWeight="medium">
                    {pricePerDay?.toFormat('$0,0.00')}
                    <ChText ml="4px" variant="sm" fontWeight="light" as="span">
                        {i18n.t('page.assets.price.perDay')}
                    </ChText>
                </ChText>
            );
        }
        return (
            <ChText variant="sm" color={colors.grayish} fontWeight="light" pr="8px">
                {i18n.t('delivery.return.modal.noPrice')}
            </ChText>
        );
    }

    function renderTotal() {
        let duration;
        if (startDate && endDate) duration = calculateDuration(startDate, endDate);

        if (duration) {
            return (
                <ChText variant="sm" color={colors.grayish} fontWeight="light">
                    {pricePerDay?.multiply(duration).toFormat('$0,0.00')} {i18n.t('default.total')}
                </ChText>
            );
        }
        return null;
    }

    function renderFooter() {
        if (footerComponent) {
            return footerComponent({
                onTimeChange,
                onDropdownClick,
                dates: { selectedEndDate: endDate, selectedStartDate: startDate },
            });
        }

        if (displayFooter === false) {
            return null;
        }

        if (clearButtonLocation === 'footer') {
            return (
                <Column className={styles.footer}>
                    <PageContainer>
                        <Row justify="space-between">{renderTimesInput()}</Row>
                        <Row justify="space-between">
                            {renderClearButton()}
                            <Button
                                variant="solid"
                                colorScheme="black"
                                size="md"
                                disabled={!confirmButtonEnabled}
                                onClick={onConfirmClick}
                            >
                                {i18n.t('mobileDateTimeSection.button.confirmDates')}
                            </Button>
                        </Row>
                    </PageContainer>
                </Column>
            );
        }

        if (clearButtonLocation === 'header') {
            return (
                <Column className={styles.footer}>
                    <PageContainer>
                        {renderTimesInput()}
                        <Row>
                            <Row justify="space-between">
                                <Column>
                                    <Row>{renderDailyPrice()}</Row>
                                    <Row>{renderTotal()}</Row>
                                </Column>
                                <Button
                                    variant="solid"
                                    colorScheme="black"
                                    size="md"
                                    disabled={!confirmButtonEnabled}
                                    onClick={onConfirmClick}
                                >
                                    {i18n.t('mobileDateTimeSection.button.confirmDates')}
                                </Button>
                            </Row>
                        </Row>
                    </PageContainer>
                </Column>
            );
        }
    }

    return (
        <div className={joinClasses(styles.container, className)}>
            {renderHeader()}
            <ScrollableDateRange
                id="date_range"
                startDateProps={startDate}
                endDateProps={endDate}
                onChange={onInputDateRange}
                blockedDates={blockedDates}
            />
            {renderFooter()}
        </div>
    );
}

export default MobileDateTimeSelectSection;
