import React from 'react';

import { ReactComponent as Icon } from '../../../../assets/icons/star.svg';
import styles from './index.module.css';

export default class StarIcon extends React.Component<any, any> {
    render() {
        return <Icon {...this.props} className={[styles.active, this.props.className].join(' ')} />;
    }
}
