import moment, { Moment } from 'moment';

export type IsOutsideRangeProps = {
    startDate: Moment | null | undefined;
    endDate: Moment | null | undefined;
    date: Moment;
    dateRangeFocus: string | null;
    blockedDates: string[] | null | undefined;
    bookingMaximumDuration: number;
};

export default function checkIfIsOutsideRange({
    startDate,
    endDate,
    date,
    dateRangeFocus,
    blockedDates,
    bookingMaximumDuration,
}: IsOutsideRangeProps) {
    const startDateExists = !!startDate;
    const endDateExists = !!endDate;

    if (startDateExists && endDateExists) {
        if (dateRangeFocus === 'endDate') {
            const afterEndtDateBlockedDates = blockedDates?.filter(blockedDate => moment(blockedDate).isAfter(endDate));

            if (afterEndtDateBlockedDates?.some(blockedDate => date.isSameOrAfter(blockedDate))) {
                return true;
            }

            return date.isAfter(startDate!.clone().add(bookingMaximumDuration, 'days'));
        }

        return false;
    }

    if (startDateExists && !endDateExists) {
        const afterStartDateBlockedDates = blockedDates?.filter(blockedDate => moment(blockedDate).isAfter(startDate));

        if (afterStartDateBlockedDates?.some(blockedDate => date.isSameOrAfter(blockedDate))) {
            return true;
        }

        return date.isAfter(startDate!.clone().add(bookingMaximumDuration, 'days')) || date.isBefore(startDate);
    }

    if (!startDateExists && endDateExists) {
        const afterEndtDateBlockedDates = blockedDates?.filter(blockedDate => moment(blockedDate).isBefore(endDate));

        if (afterEndtDateBlockedDates?.some(blockedDate => date.isSameOrBefore(blockedDate))) {
            return true;
        }

        return date.isSameOrBefore(endDate!.clone().subtract(bookingMaximumDuration, 'days'));
    }

    return false;
}
