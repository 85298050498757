import React from 'react';
import { Flex, Button } from '@chakra-ui/react';

import { useRemoteConfig } from 'hooks';
import i18n from 'language/i18n';
import styles from './styles';
import { SignModalTypes } from '..';

export type SignButtonsProps = {
    onClick: (parameter: SignModalTypes) => void;
};

export function SignButtons(props: SignButtonsProps) {
    const { loginEnabled, signupEnabled } = useRemoteConfig();
    const { onClick } = props;

    return (
        <>
            <Flex {...styles.buttonsFlex}>
                {signupEnabled && (
                    <Button variant="register" onClick={() => onClick('register')}>
                        {i18n.t('sections.header.signup')}
                    </Button>
                )}
                {loginEnabled && (
                    <Button variant="logIn" onClick={() => onClick('login')}>
                        {i18n.t('sections.header.login')}
                    </Button>
                )}
            </Flex>
        </>
    );
}
