import React from 'react';
import Carousel, { CarouselProps } from 'react-multi-carousel';
import { joinClasses } from '../../../helpers/joinClasses';
import { ArrowRightIcon } from '../icon/arrowRight';
import styles from './index.module.css';

export default function CarouselSimple(props: CarouselProps) {
    const { responsive, partialVisbile, className, customLeftArrow, customRightArrow } = props;

    return (
        <Carousel
            {...props}
            responsive={responsive}
            minimumTouchDrag={0}
            partialVisible={partialVisbile || false}
            className={joinClasses(styles.carousel, className || '')}
            customLeftArrow={
                customLeftArrow || <ArrowRightIcon className={joinClasses(styles.arrow, styles.arrowLeft)} />
            }
            customRightArrow={
                customRightArrow || <ArrowRightIcon className={joinClasses(styles.arrow, styles.arrowRight)} />
            }
        >
            {props.children}
        </Carousel>
    );
}
