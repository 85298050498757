import React from 'react';

import imgBlack from '../../../../assets/icons/chevron-back.svg';
import Icon from '..';

export default class ChevronLeftIcon extends React.Component<any> {
    render() {
        const { color } = this.props;

        const alt = 'chevron-back';
        switch (color) {
            default:
                return <Icon {...this.props} img={imgBlack} alt={alt} />;
        }
    }
}
