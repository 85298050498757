import PageContainer from 'components/base/pageContainer';
import { Text } from 'components/base/textV2';
import { useOperatingAreas, useWindowSize } from 'hooks';
import { useSharedData } from 'hooks/sharedData';
import { createDateSearchObject, createLocationSearchObject } from 'models/sharedData/SearchObject';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ArrowDownIcon } from 'components/base/icon/arrowDown';
import Column from '../../components/base/column';
import FabController, { FabControllerOptions } from '../../components/base/fabController';
import FilterIcon from '../../components/base/icon/filter';
import Pagination from '../../components/base/pagination';
import Row from '../../components/base/row';
import AssetFilter from '../../components/sections/assets/filter';
import { AssetFilterType } from '../../components/sections/assets/filter/types';
import AssetList from '../../components/sections/assets/list';
import HeaderSearch from '../../components/sections/standalone/header/search';
import i18n from '../../language/i18n';
import AssetService, { Asset } from '../../services/asset.service';
import { UrlService } from '../../services/url.service';
import styles from './index.module.css';
import { AssetsFilters } from './types';

function AssetsPage(props: any) {
    const { shared, sharedDataService, setAssetDetailsBookingDataContext } = useSharedData();
    const { operatingCity } = useOperatingAreas();
    const [assetList, setAssetList] = useState<Asset[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(-1);
    const [pageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [fixedOptions, setFixedOptions] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [activeSort, setActiveSort] = useState('asc');
    const [filters, setFilters] = useState<AssetsFilters | undefined>(undefined);
    const [searchResults, setSearchResults] = useState<number>();
    const windowSize = useWindowSize();
    let filtersSelected = 0;

    const trackScrolling = useCallback(() => {
        const footer = document.getElementById('footer');
        const isOnBottom = footer
            ? document.body.getBoundingClientRect().bottom - footer.clientHeight - 10 <= window.innerHeight
            : false;
        const isAboveController = footer ? footer.clientHeight <= window.innerHeight : false;

        if (isOnBottom) {
            if (!fixedOptions) {
                setFixedOptions(true);
            }
        } else if (isAboveController) {
            if (fixedOptions) {
                setFixedOptions(false);
            }
        }
    }, [fixedOptions]);

    const getDateFilters = useCallback(
        (dates?: { start: Moment | null; end: Moment | null }) => {
            let start;
            let end;

            if (dates) {
                start = { date: dates.start };
                end = { date: dates.end };
            } else {
                start = shared.start;
                end = shared.end;
            }

            if (!start.date && !end.date) {
                return {};
            }

            const from = filters?.filters?.from ? filters.filters.from : start?.date?.format();
            const to = filters?.filters?.to ? filters.filters.to : end?.date?.format();

            return {
                from,
                to,
            };
        },
        [shared, filters],
    );

    const refreshAssets = async (dates?: { start: Moment | null; end: Moment | null }) => {
        if (page < 0) {
            return;
        }
        setLoading(true);

        try {
            const assetsCriteria = {
                size: pageSize,
                from: pageSize * page,
                operatingCityRef: operatingCity?.id,
                sort: [
                    {
                        field: 'pricePerDay',
                        order: activeSort || 'asc',
                    },
                ],
                ...(filters ? { ...filters } : {}),
                filters: {
                    enabled: true,
                    ...(filters ? filters.filters : {}),
                    ...getDateFilters(dates),
                },
            };

            const assetsData = await AssetService.getAll(assetsCriteria);

            const availableAssets: Asset[] = assetsData.result;

            const pagesNumber = Math.ceil(assetsData.total.value / pageSize);

            setAssetList(availableAssets);
            setTotalPages(pagesNumber);
            setLoading(false);
        } catch (err) {
            setAssetList([]);
            setTotalPages(0);
            setLoading(false);
            if (err instanceof Error) {
                console.log('Error refreshing assets :: ', err.message);
                return null;
            }
            return null;
        }
    };

    // at the moment, filters and getDateFilters send request on date change.
    // The request should be sent when the "Save changes button is clicked"
    // [activeSort, filters, getDateFilters, location, page, pageSize]

    const refreshAssetsCb = useCallback(refreshAssets, [activeSort, page, pageSize, filters]);

    const onMount = () => {
        const { start, end } = shared;
        setAssetDetailsBookingDataContext && setAssetDetailsBookingDataContext(shared);

        // Cover specific case when /assets is accessed directly (start and end dates are undefined)
        if (start.date === null && end.date === null) {
            const startDate = moment().add(1, 'day').hour(9).minutes(0);
            const endDate = startDate.clone().add(3, 'days').hour(9).minutes(0);

            const deliveryObject = createDateSearchObject(startDate, '09:00');
            const returnObject = createDateSearchObject(endDate, '09:00');
            sharedDataService.updateDates(deliveryObject, returnObject);
        }
    };

    useEffect(() => {
        function getPageNumberFromUrl() {
            UrlService.initialize(props);
            const [currentPage] = UrlService.getParams(['page']);
            if (currentPage) {
                const pageNumber = Number(currentPage);
                setPage(pageNumber - 1 > 0 ? pageNumber - 1 : 0);
            } else {
                setPage(0);
            }
        }

        // set the filtered results to 3 no matter what the length of the assetList
        const filter = assetList.length;
        setSearchResults(filter);

        getPageNumberFromUrl();
    }, [props, assetList.length]);

    useEffect(() => {
        refreshAssetsCb();
    }, [refreshAssetsCb]);

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
    }, [trackScrolling]);

    useEffect(onMount, [shared]);

    function toggleSort() {
        setShowSort(prevState => !prevState);
    }

    async function onSelectAddress(address: { label: string; value: Array<number> }) {
        if (address.label === undefined) {
            sharedDataService.unsetDeliveryAddress();
            return;
        }

        const [longitude, latitude] = address.value;
        const newLocation = {
            latitude,
            longitude,
        };

        const newLocationObject = createLocationSearchObject(newLocation, address.label);
        sharedDataService.updateDeliveryAddress(newLocationObject);
    }

    function hasSelectedFilter(filter?: Array<any> | number | string) {
        return Array.isArray(filter) && filter.length > 0;
    }

    const onFilterChange = useCallback((selectedFilters: AssetFilterType) => {
        const { category, propulsionType, greenVehicle, sort, transmission, seats, feature, doors } = selectedFilters;

        const newFilter = {
            filters: {
                terms: {
                    ...(hasSelectedFilter(category) ? { category } : {}),
                    ...(hasSelectedFilter(seats) ? { seats } : {}),
                    ...(hasSelectedFilter(doors) ? { doors } : {}),
                    ...(hasSelectedFilter(transmission) ? { transmission } : {}),
                    ...(hasSelectedFilter(propulsionType) ? { propulsionType } : {}),
                    ...(hasSelectedFilter(greenVehicle) ? { greenVehicle } : {}),
                    ...(hasSelectedFilter(feature) ? { feature } : {}),
                },
            },
        };

        setActiveSort(sort);
        setFilters(newFilter);
    }, []);

    if (filters !== undefined) {
        Object.values(filters!.filters!.terms!).map(item => {
            filtersSelected += item.length;
            return filtersSelected;
        });
    }

    function onSortChange(newSort: string) {
        setActiveSort(newSort);
    }

    function onPageChange(newPage: { selected: number }) {
        const { selected } = newPage;
        UrlService.setParams([{ key: 'page', data: selected + 1 }]);

        if (selected !== page) {
            setPage(selected);
        } else {
            setPage(selected);
        }
    }

    const renderArrowIcon = (iconProps: any) => {
        return <ArrowDownIcon stroke="#F2F2F4" {...iconProps} />;
    };

    function toggleFilters() {
        setShowFilters(prevState => !prevState);
    }

    const bottomActions: FabControllerOptions[] = [
        {
            label: i18n.t('assetListFilter.filter'),
            action: toggleFilters,
            icon: FilterIcon,
        },
        {
            label: i18n.t('assetListFilter.sort'),
            action: toggleSort,
            icon: renderArrowIcon,
        },
    ];

    function renderResultsText() {
        const { start, end } = shared;
        const text = [`${searchResults} ${i18n.t('pages.assets.results')}`];

        let formattedStartDate = shared.start?.date?.format('MMM DD');
        const formattedEndDate = shared.end?.date?.format('MMM DD Y');

        if (!shared.start || shared.start === undefined) {
            formattedStartDate = 'choose dates';
        }

        if (!shared.end || shared.end === undefined) {
            formattedStartDate = 'choose dates';
        }

        if (start.date && end.date) {
            text.push(`${formattedStartDate} - ${formattedEndDate}`);
        } else {
            text.push('Enter dates to see total prices and availability');
        }

        return <Text className={styles.resultsText}>{text.join(' | ')}</Text>;
    }

    return (
        <>
            <HeaderSearch
                onSelectAddress={onSelectAddress}
                onSubmit={refreshAssets}
                isMobile={windowSize.width < 928}
            />
            <PageContainer>
                <Column className={styles.leftListWrapper}>
                    <Row className={styles.leftListHeader}>{renderResultsText()}</Row>
                    <Row className={styles.leftListHeader}>
                        <Text className={styles.headerTitle}>
                            {operatingCity && operatingCity.name
                                ? `${i18n.t('pages.assets.header')} ${operatingCity?.name}`
                                : `${i18n.t('pages.assets.header')} New York City`}
                        </Text>
                    </Row>

                    <AssetFilter
                        showMore={showFilters}
                        onCloseFilters={() => setShowFilters(false)}
                        onFilterStateChanged={onFilterChange}
                        sort={activeSort}
                        showMobileSort={showSort}
                        onCloseSort={toggleSort}
                        onSortSubmit={onSortChange}
                    />

                    <AssetList assets={assetList} loading={loading} />
                    <Pagination
                        disableInitialCallback
                        currentPage={page}
                        totalPages={loading ? 0 : totalPages}
                        onPageChange={onPageChange}
                        className={styles.pagination}
                    />
                </Column>
            </PageContainer>
            {windowSize.width < 692 && (
                <FabController fixed={fixedOptions} options={bottomActions} selectedFilters={filtersSelected} />
            )}
        </>
    );
}

export default withRouter(AssetsPage);
