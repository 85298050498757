import React, { ReactNode } from 'react';
import ReactBottomDrawer from 'react-bottom-drawer';

import styles from './index.module.css';

interface BottomDrawerProps {
    children: ReactNode;
    show: boolean;
    onClose: () => void;
}

export function BottomDrawer(props: BottomDrawerProps) {
    const { children, show, onClose } = props;

    return (
        <div className={styles.container}>
            <ReactBottomDrawer className="drawer" isVisible={show} onClose={onClose} hideScrollbars>
                {children}
            </ReactBottomDrawer>
        </div>
    );
}
