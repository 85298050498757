import i18n from 'language/i18n';
import React, { useState } from 'react';
import ModalCancelation from 'components/base/modalCancelation';
import { useToast } from '@chakra-ui/react';
import paymentMethodsService from 'services/paymentMethods.service';

export type DeleteCardModalProps = {
    isOpen: boolean;
    onClose: () => void;
    paymentMethodRef?: any;
    loadingCancelModal?: boolean;
};

export default function DeleteCardModal(props: DeleteCardModalProps) {
    const { isOpen, onClose, paymentMethodRef } = props;
    const [loadingCancelModal, setIsLoadingCancelModal] = useState<boolean>(false);
    const toast = useToast();

    async function onSubmit() {
        setIsLoadingCancelModal(true);
        try {
            const request = {
                action: 'delete',
                payload: {
                    paymentMethodRef,
                },
            };

            await paymentMethodsService.delete(request);
        } catch (error) {
            console.log('Error while deleting payment method::', error);
            setIsLoadingCancelModal(false);
            // show toast
            toast({
                title: i18n.t('pages.profile.documents.toast.error.creation.title'),
                description: 'There was an error while deleting the payment method',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            onClose();
            setIsLoadingCancelModal(false);
        }
    }

    return (
        <>
            <ModalCancelation
                title={i18n.t('pages.profile.personalWallet.cancelPaymentModal.title')}
                isOpen={isOpen}
                onClose={onClose}
                dontCancelButtonText={i18n.t('pages.profile.personalWallet.cancelPaymentModal.dontCancelbutton')}
                cancelButtonText={i18n.t('pages.profile.personalWallet.cancelPaymentModal.cancelButton')}
                cancelSmallButtonText={i18n.t('pages.profile.personalWallet.cancelPaymentModal.cancelSmallButton')}
                sureMessage={i18n.t('pages.profile.personalWallet.cancelPaymentModal.sureMessage')}
                cancelText={i18n.t(`pages.profile.personalWallet.cancelPaymentModal.message`)}
                onClick={onSubmit}
                loading={loadingCancelModal}
            />
        </>
    );
}
