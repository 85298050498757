import React from 'react';
import Column from '../../../../base/column';
import Row from '../../../../base/row';
import Text from '../../../../base/text';
import { Modal } from '../../../../base/modal';
import Splitter from '../../../../base/splitter';
import BookingReviewCard, { BookingReview } from '../booking-review-card';
import Margin from '../../../../base/margin';
import { Asset } from '../../../../../services/asset.service';
import styles from './index.module.css';
import StarIcon from '../../../../base/icon/star';
import StarsRating from '../../../../base/stars-rating';
import i18n from '../../../../../language/i18n';
import BodyText from '../../../../base/text/body';

type BookingReviewModalProps = {
    reviews: BookingReview[];
    rating: number;
    asset: Asset;
    isOpen: boolean;
    onClose: () => void;
    className?: string;
};

export default function BookingReviewModal(props: BookingReviewModalProps) {
    const { isOpen, reviews, onClose, asset, rating, className } = props;

    return (
        <Modal
            className={[styles.modal, className].join(' ')}
            size="50%"
            show={isOpen}
            onClose={onClose}
            containerClassName={styles.container}
            headerClassName={styles.modalHeader}
            title={i18n.t('sections.bookingReviews.reviews')}
        >
            <Row>
                <Column>
                    <Text className={styles.modalHeader}>
                        {i18n.t('sections.bookingReviews.reviewsModal.header')} {asset.brand} {asset.model}
                    </Text>
                    <Margin />
                    <Row className={styles.modalRating}>
                        <StarIcon className={styles.icon} />
                        <Text className={styles.avgRating}>{rating.toFixed(2)}</Text>
                        <Text className={styles.ratings}>
                            | {reviews.length} {i18n.t('sections.bookingReviews.reviews')}
                        </Text>
                    </Row>
                    <Margin />
                    <Column>
                        <Row className={styles.optionalRating}>
                            <Text noMargin>{i18n.t('sections.bookingReviews.reviewsModal.confortability')}</Text>
                            <span>
                                <StarsRating rating={4} />
                                {rating.toFixed(2)}
                            </span>
                        </Row>
                        <Row className={styles.optionalRating}>
                            <Text noMargin>{i18n.t('sections.bookingReviews.reviewsModal.easyToDrive')}</Text>
                            <span>
                                <StarsRating rating={4} />
                                {rating.toFixed(2)}
                            </span>
                        </Row>
                        <Row className={styles.optionalRating}>
                            <Text noMargin>{i18n.t('sections.bookingReviews.reviewsModal.accuracy')}</Text>
                            <span>
                                <StarsRating rating={4} />
                                {rating.toFixed(2)}
                            </span>
                        </Row>
                        <Row className={styles.optionalRating}>
                            <Text noMargin>{i18n.t('sections.bookingReviews.reviewsModal.safety')}</Text>
                            <span>
                                <StarsRating rating={4} />
                                {rating.toFixed(2)}
                            </span>
                        </Row>
                        <Row className={styles.optionalRating}>
                            <Text noMargin>{i18n.t('sections.bookingReviews.reviewsModal.value')}</Text>
                            <span>
                                <StarsRating rating={4} />
                                {rating.toFixed(2)}
                            </span>
                        </Row>
                    </Column>
                </Column>
                <Splitter />
                <Column className={styles.reviews}>
                    {reviews.map(review => (
                        <div>
                            <BookingReviewCard review={review} />
                            <Splitter className={styles.splitter} />
                        </div>
                    ))}
                    <BodyText>{i18n.t('sections.bookingReviews.reviewsModal.noMoreReviews')}</BodyText>
                </Column>
            </Row>
        </Modal>
    );
}
