import { ButtonProps, InputProps, StackProps, TextProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<InputProps & ButtonProps & StackProps & TextProps> = {
    cancelModal: {
        display: 'flex',
        flexDirection: 'column',
        height: '274px',
    },
    modalCancelBody: {
        padding: '0px',
    },
    modalFooter: {
        alignItems: 'flex-end !important',
        paddingBottom: '0px',
        paddingRight: '0px',
        bottom: '0',
    },

    cancelTextSmallButton: {
        display: { base: 'flex', sm: 'none' },
        fontSize: { base: 'var(--font-xsmall)', sm: 'var(--font)' },
    },

    cancelTextButton: {
        display: { base: 'none', sm: 'flex' },
    },
    dontCancelTextButton: {
        display: { base: 'none', sm: 'flex' },
    },
    dontCancelSmallTextButton: {
        display: { base: 'flex', sm: 'none' },
        fontSize: { base: 'var(--font-xsmall)', sm: 'var(--font)' },
    },
    sureMessage: {
        fontWeight: 'var(--font-weight-semibold)',
    },
};

export default styles;
