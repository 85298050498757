import React from 'react';
import { Box, ChakraProps } from '@chakra-ui/react';

import Row from 'components/base/row';
import BodyText from 'components/base/text/body';

import { User, Phone } from 'hooks/auth';
import i18n from 'language/i18n';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/check-circle.svg';

import styles from './index.module.css';

interface DriverInfoProps extends ChakraProps {
    user?: User;
    hideLicense?: boolean;
}

function DriverInfo({ user, hideLicense, ...rest }: DriverInfoProps) {
    const rawPhoneNumber = (phoneObject: Phone | undefined) => {
        if (!phoneObject) {
            return 'N/A';
        }

        if (phoneObject.number && phoneObject.code) {
            return `${phoneObject.code}  ${phoneObject.number}`;
        }

        return 'N/A';
    };

    return (
        <Box {...rest}>
            <Row>
                <BodyText className={styles.labelText}>
                    {i18n.t('sections.assetDetails.booking.driverInfo.name')}:
                </BodyText>
                <BodyText className={styles.valueText}>{user?.name}</BodyText>
            </Row>
            {!hideLicense && (
                <Row>
                    <BodyText className={styles.labelText}>
                        {i18n.t('sections.assetDetails.booking.driverInfo.license')}:
                    </BodyText>
                    <BodyText className={styles.valueText}>
                        {user?.license || 'N/A'}
                        {user?.license && <CheckCircleIcon />}
                    </BodyText>
                </Row>
            )}
            <Row>
                <BodyText className={styles.labelText}>
                    {i18n.t('sections.assetDetails.booking.driverInfo.phone')}:
                </BodyText>
                <BodyText className={styles.valueText}>
                    {rawPhoneNumber(user?.phoneObject) || 'N/A'}
                    {user?.phoneObject && <CheckCircleIcon />}
                </BodyText>
            </Row>
            <Row>
                <BodyText className={styles.labelText}>
                    {i18n.t('sections.assetDetails.booking.driverInfo.email')}:
                </BodyText>
                <BodyText className={styles.valueText}>
                    {user?.email || 'N/A'}
                    {user?.emailVerified && <CheckCircleIcon />}
                </BodyText>
            </Row>
        </Box>
    );
}

export default DriverInfo;
