import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';

import Column from 'components/base/column';
import Row from 'components/base/row';
import { H3 } from 'components/base/text/h3';
import i18n from 'language/i18n';
import { ProfileInfoDetails } from './details';
import { ProfileInfoEdit } from './edit';

import styles from './index.module.css';

export function ProfileInfo() {
    const [editMode, setEditMode] = useState(false);

    function closeEditMode() {
        setEditMode(false);
    }

    return (
        <Column className={styles.container}>
            <Row className={styles.cardHeader}>
                <H3>{i18n.t('pages.profile.info.title')}</H3>
                {!editMode && <FiEdit className={styles.editIcon} onClick={() => setEditMode(true)} />}
            </Row>
            {editMode ? <ProfileInfoEdit closeEditMode={closeEditMode} /> : <ProfileInfoDetails />}
        </Column>
    );
}
