import { Button, Link } from '@chakra-ui/react';
import { Asset } from 'components/../services/asset.service';
import Column from 'components/base/column';
import DoorsIcon from 'components/base/icon/doors';
import SeatsIcon from 'components/base/icon/seats';
import SuitcasesIcon from 'components/base/icon/suitcases';
import TransmissionIcon from 'components/base/icon/transmission';
import TransmissionAutomaticIcon from 'components/base/icon/transmission-automatic';
import Row from 'components/base/row';
import BodyText from 'components/base/text/body';
import Caption from 'components/base/text/caption';
import { H3 } from 'components/base/text/h3';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { getCarDescription } from 'helpers/getCarDescription';
import { joinClasses } from 'helpers/joinClasses';
import { useSharedData, useRemoteConfig, useOperatingAreas } from 'hooks';
import i18n from 'language/i18n';
import { PriceCalculator } from 'services/price.formatter';

import StarIcon from 'components/base/icon/star';
import { calculateDuration } from 'helpers/calculateDuration';
import AssetDetailsModal from '../asset-details-modal';
import { AssetListItemGallery } from '../list-item-gallery';
import styles from './index.module.css';

export type AssetListItemProps = {
    asset: Asset;
    loading?: boolean;
    design?: boolean;
    onClick?: (asset: Asset) => void;
};

function AssetListItem(props: AssetListItemProps) {
    const { pagesConfig } = useRemoteConfig();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { asset, loading } = props;
    const { operatingCity } = useOperatingAreas();

    const context = useSharedData();
    const name = `${asset.brand} ${asset.model}`;
    const transmission = asset.transmission ? i18n.t(`asset.transmission.${asset.transmission.toUpperCase()}`) : 'N/A';
    const category = asset.category ? i18n.t(`asset.category.${asset.category.toUpperCase()}`) : 'N/A';
    const pricePerDay = new PriceCalculator(asset.pricePerDay || 0, operatingCity!.currency).format(2);
    const seats = asset.seats || 'N/A';
    const doors = asset.doors || 'N/A';
    const { displayPhoto } = asset;

    // also checking if the word should be singular if only 1 suitcase
    const suitcases = asset.luggages
        ? `${Number(asset.luggages)} Suitcase${Number(asset.luggages) !== 1 && 's'}`
        : 'N/A';

    function handleDetailsClick(e: any) {
        e.stopPropagation();
        setModalOpen(true);
    }

    function handleCloseModal(e: any) {
        setModalOpen(false);
    }

    function renderReservationButton() {
        return (
            <Button size="md" colorScheme="brand" className={styles.bookLink} onClick={() => props.onClick?.(asset)}>
                {i18n.t('page.assets.reserve')}
            </Button>
        );
    }

    // only for tablet version
    function renderReservationLeftButton() {
        return (
            <Button
                size="md"
                colorScheme="brand"
                className={styles.bookLinkLeft}
                onClick={() => props.onClick?.(asset)}
            >
                {i18n.t('page.assets.reserve')}
            </Button>
        );
    }

    function renderPrice() {
        const startDate = context.shared.start?.date;
        const endDate = context.shared.end?.date;

        if (!startDate || !endDate) {
            return null;
        }

        const totalDays = calculateDuration(startDate, endDate)!;
        const priceInDays = new PriceCalculator(asset.pricePerDay);
        const rentalPrice = priceInDays.clone().multiply(totalDays);

        return (
            <Row className={styles.totalContainer}>
                <Caption>{`${rentalPrice.format()} total`}</Caption>
            </Row>
        );
    }

    return (
        <div className={[styles.item, loading ? styles.loading : '', styles.listItem].join(' ')}>
            <Column className={styles.left}>
                <AssetListItemGallery
                    onClickAsset={() => props.onClick?.(asset)}
                    className={styles.photo}
                    displayPhoto={displayPhoto}
                />
                <Row className={styles.bookLeftButtonContainer}>{renderReservationLeftButton()}</Row>
            </Column>

            <Column className={styles.itemSpecsCol}>
                <Column className={joinClasses(styles.header, styles.listHeader)}>
                    {pagesConfig.asset_page_config.reviews && (
                        <>
                            <StarIcon className={styles.ratingIcon} />
                            <Caption className={styles.rating}>4.7</Caption>
                            <Caption>(14 reviews)</Caption>
                        </>
                    )}
                    <Row>
                        <Caption className={styles.category}>{category}</Caption>
                    </Row>
                    <Row className={styles.titleContainer}>
                        <Link as={RouterLink} to={`/assets/${asset.id}`} variant="noStyle">
                            <H3 className={styles.title}>{name}</H3>
                        </Link>
                    </Row>
                    <Row className={styles.iconsContainer}>
                        <Row className={styles.iconsWrapper}>
                            <SeatsIcon className={joinClasses(styles.detailsIcon, styles.seatsIcon)} />
                            <Caption>{seats}</Caption>
                        </Row>
                        <Row className={styles.iconsWrapper}>
                            <DoorsIcon className={styles.detailsIcon} />
                            <Caption>{doors}</Caption>
                        </Row>
                        <Row className={styles.iconsWrapper}>
                            {transmission.toLowerCase() === 'manual' ? (
                                <TransmissionIcon
                                    className={joinClasses(styles.detailsIcon, styles.transmissionIcon)}
                                />
                            ) : (
                                <TransmissionAutomaticIcon
                                    className={joinClasses(styles.detailsIcon, styles.transmissionIcon)}
                                />
                            )}
                            <Caption>{transmission}</Caption>
                        </Row>
                        <Row className={joinClasses(styles.iconsWrapper, styles.suitcasesInfo)}>
                            <SuitcasesIcon />
                            <Caption>{suitcases}</Caption>
                        </Row>
                    </Row>

                    <Row className={styles.seeDetailsContainer}>
                        <span className={styles.seeDetails} onClick={() => setModalOpen(true)}>
                            {' '}
                            {i18n.t('page.assets.seeMoreDetails')}
                        </span>
                    </Row>

                    <Row>
                        <BodyText className={styles.assetDescription}> {getCarDescription(asset.category)}</BodyText>
                    </Row>
                </Column>

                <Column className={styles.bottomContainer}>
                    <Row>
                        <Column className={styles.priceContainer}>
                            <Row className={styles.priceDay}>
                                <BodyText className={styles.price}>{pricePerDay}</BodyText>
                                <BodyText className={styles.priceInfo}>{i18n.t('page.assets.price.perDay')}</BodyText>
                            </Row>
                            {renderPrice()}
                        </Column>
                    </Row>
                    <Row className={styles.bookButtonContainer}>{renderReservationButton()}</Row>
                </Column>
                <AssetDetailsModal asset={asset} isOpen={modalOpen} onClose={handleCloseModal} />
            </Column>
        </div>
    );
}

export default AssetListItem;
