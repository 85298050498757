import * as moment from 'moment';
import { momentWithLocale } from '../services/moment.service';

declare module 'moment' {
    interface Moment {
        setTime(time: string): moment.Moment;
    }
}

(moment as any).prototype.setTime = function SetMoment(time: string): moment.Moment {
    const dateTime = momentWithLocale(time, 'LT');
    const hours = dateTime.hours();
    const minutes = dateTime.minutes();
    return this.set({ hours, minutes, second: 0, millisecond: 0 });
};
