const UtilityService = {
    deepFind(obj: any, path: any) {
        const paths = path.split('.');
        let current = obj;
        let i;

        for (i = 0; i < paths.length; ++i) {
            if (current[paths[i]] === undefined) {
                return undefined;
            }
            current = current[paths[i]];
        }
        return current;
    },
    getArrayDiff(array1: any, array2: any, key: string) {
        return array1.filter((object1: any) => {
            return !array2.some((object2: any) => {
                return object1[key] === object2[key];
            });
        });
    },
};

export default UtilityService;
