import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useBreakpointValue } from '@chakra-ui/react';

import { useWindowSize } from 'hooks';
import HamburguerIcon from 'components/base/icon/hamburguerMenu';
import DrawerMenu from 'components/sections/standalone/drawer/menu';
import AuthenticationModal from '../../../authentication/modal';
import { SignButtons } from './signButtons';

export type SignMenuProps = {
    loading?: boolean;
};

export type SignModalTypes = 'login' | 'register' | 'forgotPassword' | undefined;
export type HeaderModalTypes = 'login' | 'register' | 'forgotPassword' | undefined;

export function SignButtonsSection(props: SignMenuProps) {
    const history = useHistory();
    const display = useBreakpointValue({ sm: 'flex', md: 'flex', lg: 'none' });
    const { loading } = props;
    const windowSize = useWindowSize();

    const [showAuth, setShowAuth] = useState(false);
    const [modal, setModal] = useState<SignModalTypes>('login');
    const [showSidebar, setShowSidebar] = useState<boolean>(false);

    function openAuthModal(modalType: SignModalTypes) {
        setModal(modalType);
        setShowAuth(true);
        showSidebar && setShowSidebar(false);
    }

    function onMenuClick() {
        setShowSidebar(true);
    }

    function onMenuClose() {
        setShowSidebar(false);
    }

    if (loading === true) {
        return null;
    }

    return (
        <>
            {windowSize.width < 928 && <HamburguerIcon display={display} onClick={() => onMenuClick()} />}
            <DrawerMenu open={showSidebar} onClose={onMenuClose} onClick={openAuthModal} />
            {windowSize.width >= 928 && <SignButtons onClick={openAuthModal} />}
            <AuthenticationModal
                section={modal}
                history={history}
                isOpen={showAuth}
                onModalClosed={() => {
                    setShowAuth(false);
                    setModal(undefined);
                }}
            />
        </>
    );
}
