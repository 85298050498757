import { SharedDataContext as SharedDataContextType } from 'models/sharedData/SharedDataContext';
import React, { useContext, useReducer, useState } from 'react';
import { sharedDataReducer } from 'reducers/SharedDataReducer';
import { SharedDataService } from 'services/sharing.service';
import { SharedData } from 'models/sharedData/SharedData';

const initialSharedData: SharedData = {
    returnLocationHistory: [],
    start: { date: null, time: '09:00', location: null, query: null },
    end: { date: null, time: '09:00', location: null, query: null },
    insurances: { main: null, extras: [] },
    extras: [],
};

const SharedDataContext = React.createContext<SharedDataContextType>({
    shared: initialSharedData,
    sharedDataService: new SharedDataService(),
    assetDetailsBookingDataContext: undefined,
    setAssetDetailsBookingDataContext: undefined,
});

function SharedDataProvider({ children }: { children: React.ReactNode }) {
    const [assetDetailsBookingDataContext, setAssetDetailsBookingDataContext] = useState<Partial<SharedData>>();
    const [sharedData, dispatch] = useReducer(sharedDataReducer, initialSharedData);

    const sharedDataService = new SharedDataService();
    sharedDataService.dispatcher = dispatch;

    return (
        <SharedDataContext.Provider
            value={{
                shared: sharedData,
                sharedDataService,
                assetDetailsBookingDataContext,
                setAssetDetailsBookingDataContext,
            }}
        >
            {children}
        </SharedDataContext.Provider>
    );
}

function useSharedData() {
    const context = useContext(SharedDataContext);

    if (!context) {
        throw new Error('useSharedData must be used within an SharedData Provider');
    }

    return context;
}

export { SharedDataProvider, useSharedData };
