import React from 'react';

import Row from 'components/base/row';

import { H3 } from 'components/base/text/h3';

import { Text } from 'components/base/textV2';

import i18n from 'language/i18n';

import { useAuth, useRemoteConfig } from 'hooks';
import { Box } from '@chakra-ui/react';
import Column from 'components/base/column';
import styles from './index.module.css';

import BusinessWallet from '../businessWallet';
import { PersonalWallet } from '../personalWalletCard';

export function MyWalletInfo() {
    const { user } = useAuth();
    const { paymentsEnabled } = useRemoteConfig();

    return (
        <>
            <Row className={styles.cardHeader}>
                <H3>{i18n.t('pages.profile.myWallet.title')}</H3>
            </Row>

            <Text className={styles.description}>{i18n.t('pages.profile.myWallet.description')}</Text>

            {user.companyRef ? (
                <Box className={styles.container}>
                    <Column className={styles.businessWalletCard}>
                        <BusinessWallet companyRef={user.companyRef} />
                    </Column>
                    <Column className={styles.personalWalletCard}>{paymentsEnabled && <PersonalWallet />}</Column>
                </Box>
            ) : (
                paymentsEnabled && <PersonalWallet />
            )}
        </>
    );
}
