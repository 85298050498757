import React from 'react';
import Text from '../index';
import styles from './index.module.css';

interface LabelProps {
    children: React.ReactNode;
    className?: string;
}

export default function Label({ children, className }: LabelProps) {
    const getStyle = (): string => {
        return styles.label;
    };

    return <Text className={[getStyle(), className].join(' ')}>{children}</Text>;
}
