import React, { useCallback, useEffect, useState } from 'react';
import { Moment } from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

import AssetPanel from 'components/sections/_layout/asset-panel';
import Column from 'components/base/column';
import Row from 'components/base/row';
import AssetDetailsExtras from 'components/sections/asset-details/extras';
import Insurances from 'components/sections/assets-extras-insurance/insurances';
import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';
import { H2 } from 'components/base/text/h2';

import { Insurance, InsurancesDataOptions } from 'services/insurances.service';
import { useSharedData, useAssetDetails, useInsurances, useWindowSize, useRemoteConfig } from 'hooks';
import i18n from 'language/i18n';

import Loading from 'components/base/loading';
import MobilePriceDrawer from 'components/sections/_layout/mobile-price-drawer';
import Splitter from 'components/base/splitter';
import { SharedData } from 'models/sharedData/SharedData';
import styles from './index.module.css';

interface StateProps {
    startDate: Moment;
    endDate: Moment;
    paymentOption: string;
    selectedExtras: string[];
}

function AssetsExtrasAndInsurancePage() {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const { asset, loading } = useAssetDetails(params.id);
    const windowSize = useWindowSize();
    const [isPriceDrawerOpen, setIsPriceDrawerOpen] = useState<boolean>(false);
    const { bookingCancellationConfig } = useRemoteConfig();

    const { shared, sharedDataService, assetDetailsBookingDataContext } = useSharedData();

    const { state } = useLocation<StateProps>();
    const insurances = useInsurances();
    const [selectedExtras, setSelectedExtras] = useState<string[]>(shared.extras);
    const [selectedMainInsurance, setSelectedMainInsurance] = useState<Insurance | null>(null);
    const [selectedExtInsurances, setSelectedExtInsurances] = useState<Insurance[]>(shared.insurances.extras);

    const auxSharedData = (assetDetailsBookingDataContext || shared) as SharedData;

    const { start, end } = auxSharedData;

    const noContext = !start || !end;
    const noDates = !start.date || !end.date;
    const noLocation = !start.location || !end.location;

    if (!state || noContext || noDates || noLocation) {
        history.replace(`/`, state);
    }

    useEffect(() => {
        setSelectedMainInsurance(shared.insurances.main || insurances.mainInsurance);
    }, [insurances.mainInsurance, shared.insurances.main]);

    function openPriceDrawer() {
        setIsPriceDrawerOpen(true);
    }

    function closePriceDrawer() {
        setIsPriceDrawerOpen(false);
    }

    function onClickBackArrow() {
        sharedDataService.updateSelectedExtras(selectedExtras);
        history.goBack();
    }

    function updateSelectedOption(option: InsurancesDataOptions) {
        const mainDeepCopy = _.cloneDeep(selectedMainInsurance);

        const currentSelected = mainDeepCopy!.options!.find((item: InsurancesDataOptions) => item.selected === true);

        if (currentSelected && currentSelected?.optionId !== option.optionId) {
            currentSelected.selected = false;
            const newSelected = mainDeepCopy!.options!.find(item => item.optionId === option.optionId);
            if (newSelected) newSelected.selected = true;
            setSelectedMainInsurance(mainDeepCopy);
        }
    }

    function updateSelectedInsurances(item: Insurance) {
        const isIncluded = selectedExtInsurances.some(insurance => insurance.id === item.id);
        if (isIncluded) {
            setSelectedExtInsurances(selectedExtInsurances.filter(insurance => insurance.id !== item.id));
        } else {
            setSelectedExtInsurances([...selectedExtInsurances, item]);
        }
    }

    function onExtrasChange(items: string[]) {
        setSelectedExtras([...items]);
    }

    function submit() {
        sharedDataService.updateSelectedExtras(selectedExtras);
        sharedDataService.updateSelectedInsurances({ main: selectedMainInsurance, extras: selectedExtInsurances });

        history.push(`/assets/${params.id}/checkout`, {
            paymentOption: 'pay-per-day',
        });
    }

    if ((loading && !asset) || insurances.insurancesLoading) {
        return <Loading spinnerSize={40} halfScreen />;
    }

    asset.id = params.id;

    return (
        <>
            <Column className={styles.container}>
                <ArrowLeftIcon className={styles.backIcon} onClick={onClickBackArrow} />
                <H2 className={styles.pageTitle}>{i18n.t('pages.assetsExtrasAndInsurance.title')}</H2>
                <Row className={styles.content}>
                    <Column className={styles.contentDetails}>
                        <Insurances
                            onOptionChange={updateSelectedOption}
                            onInsurancesChange={updateSelectedInsurances}
                            insurances={insurances.data}
                            mainInsurance={selectedMainInsurance}
                            selectedExtraInsurances={selectedExtInsurances}
                        />
                        {!!asset.extras && (
                            <AssetDetailsExtras
                                extras={asset.extras}
                                selectedExtras={selectedExtras}
                                onChange={onExtrasChange}
                            />
                        )}
                    </Column>

                    <Splitter spacing="xlarge" />
                    <Column align="end" className={styles.panelContainer}>
                        <AssetPanel
                            mode="checkout-1"
                            asset={asset}
                            onSubmit={submit}
                            extras={selectedExtras}
                            insurances={[...selectedExtInsurances, { ...selectedMainInsurance! }]}
                            bookingCancellationConfig={bookingCancellationConfig}
                        />
                    </Column>
                </Row>
                {windowSize.width < 692 && (
                    <MobilePriceDrawer
                        asset={asset}
                        extras={selectedExtras}
                        insurances={[...selectedExtInsurances, { ...selectedMainInsurance! }]}
                        mode="checkout-1"
                        onSubmit={submit}
                        isOpen={isPriceDrawerOpen}
                        onToggle={openPriceDrawer}
                        onClose={closePriceDrawer}
                        bookingCancellationConfig={bookingCancellationConfig}
                    />
                )}
            </Column>
        </>
    );
}

export default AssetsExtrasAndInsurancePage;
