import React, { ReactNode, SetStateAction } from 'react';
import { motion, AnimatePresence, Variants, Transition } from 'framer-motion';

import { joinClasses } from 'helpers/joinClasses';
import styles from './index.module.css';
import { ChevDownIcon } from '../icon/chev-down';

interface AccordionProps {
    i: number | string | boolean;
    title: string;
    children: ReactNode;
    expanded: number | boolean | string;
    setExpanded?: SetStateAction<any>;
    className?: string;
    headerClassName?: string;
    icon?: ReactNode;
    onToggle?: () => void;
    variants?: Variants;
    transition?: Transition;
    header?: ReactNode;
    footer?: ReactNode;
    onClick?: (...args: any[]) => any;
}

export const Accordion = ({
    i,
    title,
    expanded,
    setExpanded,
    children,
    className,
    headerClassName,
    icon,
    onToggle,
    variants,
    transition,
    header,
    footer,
    onClick,
}: AccordionProps) => {
    const isOpen = i === expanded;
    const defaultIconStyles = joinClasses(styles.cardIcon, isOpen ? styles.openedIcon : styles.closedIcon);

    function toggleAccordion() {
        setExpanded(isOpen ? false : i);
    }

    return (
        <div className={joinClasses(styles.cardContainer, className || '')} onClick={onClick}>
            {header || (
                <motion.header
                    className={joinClasses(styles.cardHeader, headerClassName || '')}
                    initial={false}
                    onClick={onToggle || toggleAccordion}
                >
                    <motion.span className={styles.cardTitle}>{title}</motion.span>
                    {icon || <ChevDownIcon className={defaultIconStyles} color="black" />}
                </motion.header>
            )}

            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={
                            variants || {
                                open: { opacity: 1, height: 'auto' },
                                collapsed: { opacity: 0, height: 0 },
                            }
                        }
                        transition={
                            transition || {
                                duration: 0.5,
                                ease: [0.04, 0.62, 0.23, 0.98],
                            }
                        }
                    >
                        <motion.div>{children}</motion.div>
                    </motion.section>
                )}
            </AnimatePresence>
            {footer}
        </div>
    );
};
