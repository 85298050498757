import React from 'react';
import styles from './index.module.css';

function Division(props: DivisionProps) {
    const classes = [styles.division];

    if (props.className !== undefined) {
        classes.push(props.className);
    }

    return <div className={classes.join(' ')} />;
}

export type DivisionProps = {
    className?: string;
    children?: any;
};

export default Division;
