import React, { useState } from 'react';
import Card from '../../../base/card';
import Text from '../../../base/text';
import BodyText from '../../../base/text/body';
import { joinClasses } from '../../../../helpers/joinClasses';

import styles from './index.module.css';

export interface LeaderCardProps {
    name: string;
    mission: string;
    exp: string;
    jobTitle: string;
    picture: string;
    className?: string;
}

function LeaderCard({ name, mission, jobTitle, picture, exp }: LeaderCardProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    function handleCardOpening() {
        setIsOpen(!isOpen);
    }

    return (
        <Card className={[styles.leaderCard].join(' ')}>
            <img src={picture} alt={name} className={styles.img} />
            <Text className={styles.name}>{name}</Text>
            <Text className={styles.jobTitle}>{jobTitle}</Text>
            <BodyText className={joinClasses(styles.body, !isOpen ? styles.closed : '')}>
                {mission}
                <br />
                <br />
                {exp}
            </BodyText>
            <Text className={styles.seeMore} onClick={handleCardOpening}>
                {isOpen ? 'See less' : 'See more'}
            </Text>
        </Card>
    );
}

export default LeaderCard;
