import React from 'react';
import i18n from '../../../../language/i18n';
import styles from './index.module.css';
import { PriceCalculator } from '../../../../services/price.formatter';
import { Extra } from '../../../../services/extras.service';

function AssetDetailsExtrasItem(props: AssetDetailsExtrasItemProps) {
    const { className, extra, selected, onClick } = props;

    const hasFee = extra.hasFees ? `+ ${i18n.t('sections.assetDetails.extras.items.fees')}` : '';

    return (
        <div className={[styles.itemContainer, className, selected ? styles.selected : ''].join(' ')} onClick={onClick}>
            <div className={styles.title}>{extra.label}</div>
            <div className={styles.subtitle}>
                {new PriceCalculator(extra.price).format()}
                <span>/{i18n.t('default.day').toLowerCase()}</span> {hasFee}
            </div>
            <div className={styles.selectionIndicator} />
        </div>
    );
}

export default AssetDetailsExtrasItem;

export type AssetDetailsExtrasItemProps = {
    extra: Extra;
    className?: any;
    onClick?: any;
    selected?: boolean;
};
