import React, { useState } from 'react';
import {
    Box,
    ChakraProps,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Text,
    useDisclosure,
    Link as LinkButton,
} from '@chakra-ui/react';
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward.svg';
import MobileSearchSection from 'components/sections/mobile-search/location';
import { AddressAutocompleteValue } from 'components/sections/home/addressComplete/types';
import i18n from 'language/i18n';
import cssStyles from './index.module.css';
import styles from './styles';

interface LocationDeliveryPickerProps {
    title?: string;
    defaultValue?: string;
    onChange?: (loc: string, value: number[]) => void;
}

function LocationDeliveryDisplay({
    title,
    defaultValue,
    onChange,
    ...rest
}: LocationDeliveryPickerProps & ChakraProps) {
    const drawer = useDisclosure();
    const [location, setLocation] = useState<string>(defaultValue || '');

    function handleAddressClick(address: AddressAutocompleteValue) {
        setLocation(address.label!);
        onChange?.(address.label!, address.value);
        drawer.onClose();
    }

    return (
        <Box {...rest}>
            <Text {...styles.title}>{title}</Text>
            <Flex
                fontSize="16px"
                p="0 16px"
                alignItems="center"
                border="1px solid var(--color-gray-light-border)"
                borderRadius="8px"
                height="48px"
                onClick={() => drawer.onOpen()}
            >
                <Text
                    w="100%"
                    fontWeight="light"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    {...(location ? {} : { color: '#9DA0AA' })}
                >
                    {location || i18n.t('location.search.modal.input.placeholder')}
                </Text>
            </Flex>

            <Flex mt="16px" alignItems="center" onClick={() => drawer.onOpen()}>
                <LinkButton as="button" mr="8px">
                    {i18n.t('sections.deliveryPicker.editDelivery')}
                </LinkButton>
                <ArrowForward className={cssStyles.arrowForward} />
            </Flex>

            {drawer.isOpen && (
                <Drawer isOpen onClose={drawer.onClose} placement="bottom">
                    <DrawerOverlay />
                    <DrawerContent height="100%" padddingX="0" {...styles.drawerContent}>
                        <MobileSearchSection
                            className={cssStyles.searchSection}
                            onBack={() => drawer.onClose()}
                            onAddressClick={handleAddressClick}
                            initialAddress={location}
                        />
                    </DrawerContent>
                </Drawer>
            )}
        </Box>
    );
}

export default LocationDeliveryDisplay;
