import React from 'react';
import styles from './index.module.css';
import Column from '../column';

export type CardProps = {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
};

function Card(props: CardProps) {
    return (
        <Column className={[styles.card, props.className].join(' ')} style={props.style}>
            {props.children}
        </Column>
    );
}

export default Card;
