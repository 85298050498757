import { firestore } from './persistence';

const UserProfileCollection = 'users';

export default {
    /**
     * Get user from firestore
     * @param uid
     * @param callback
     */
    async get(uid: string) {
        try {
            const result = await firestore.collection(UserProfileCollection).doc(uid).get();
            if (!result.exists) return null;

            return result.data();
        } catch (err) {
            console.log(err);
            return null;
        }
    },

    /**
     * Edit user
     * @param name
     * @param phone
     * @param taxNumber
     */
    async edit(uid: string, data: any) {
        const userRef = firestore.collection(UserProfileCollection).doc(uid);
        const editRequestRef = await firestore.collection('request_edit_profile').add({
            action: 'edit',
            status: 'pending',
            usersRef: userRef,
            issuedBy: userRef,
            creationDate: new Date(),
            updatedDate: new Date(),
            data,
        });

        return new Promise<void>((resolve, reject) => {
            // wait for the snapshot to be resolved and the value to change
            let timeout: any;
            const unsubscribe = editRequestRef.onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
                const dataSnapshot = snapshot.data();
                switch (dataSnapshot && dataSnapshot.status) {
                    case 'accepted':
                        unsubscribe();
                        resolve();
                        clearTimeout(timeout);
                        break;
                    case 'failure':
                        unsubscribe();
                        reject();
                        clearTimeout(timeout);
                        break;
                    default:
                }
            });

            timeout = setTimeout(() => {
                unsubscribe();
                reject();
                clearTimeout(timeout);
            }, 10000);
        });
    },
};
