import { joinClasses } from 'helpers/joinClasses';
import React from 'react';

import { ReactComponent as LogoFull } from '../../../../assets/images/Logo_Full.svg';
import styles from './index.module.css';

interface stylesCmd {
    [name: string]: string;
}

interface LogoLargeProps {
    color?: string;
    className?: string;
}

function LogoLarge({ color, className }: LogoLargeProps) {
    const stylesCommand: stylesCmd = {
        red: styles.red,
        default: '',
    };

    return <LogoFull className={joinClasses(stylesCommand[color || 'default'], className || '')} />;
}

export default LogoLarge;
