import React from 'react';

import styles from './index.module.css';

export type RowProps = {
    children?: React.ReactNode;
    end?: boolean;
    className?: string;
    onClick?: any;
    style?: React.CSSProperties;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
    id?: string;
    justify?: 'start' | 'space-between' | 'space-around' | 'space-even' | 'end' | 'center';
    align?: 'start' | 'center' | 'stretch' | 'end';
    ref?: React.Ref<any>;
};

export default class Row extends React.Component<RowProps> {
    render() {
        const classes = [styles.row];

        if (this.props.end) {
            classes.push(styles.rowEnd);
        }

        classes.push(this.props.className!);

        const alignment = {
            ...(this.props.justify ? { justifyContent: this.props.justify } : {}),
            ...(this.props.align ? { alignItems: this.props.align } : {}),
        };

        return (
            <div style={alignment} {...this.props} ref={this.props.ref} className={classes.join(' ')}>
                {this.props.children}
            </div>
        );
    }
}
