import React from 'react';

import { ReactComponent as Icon } from '../../../../assets/icons/chevron-down.svg';

import { IconProps } from '..';

import styles from './index.module.css';

export const ChevUpIcon = (props: IconProps) => {
    const { className, color, ...rest } = props;

    return <Icon className={[styles.chevUp, className].join(' ')} stroke={color || 'white'} {...rest} />;
};
