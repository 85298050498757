import React from 'react';
import { Button } from '@chakra-ui/react';
import Card from '../../../../base/card';
import Text from '../../../../base/text';
import cssModule from './index.module.css';
import BodyText from '../../../../base/text/body';
import RectangleLgIcon from '../../../../base/icon/rectangle-lg';
import SuitCasesIcon from '../../../../base/icon/car-suitcases';
import Row from '../../../../base/row';
import SeatsIcon from '../../../../base/icon/car-seats';
import Margin from '../../../../base/margin';
import styles from './styles';

export type BusinessFleetsCardProps = {
    title?: string;
    category?: string;
    image?: string;
    price?: number;
};

export default function BusinessFleetsCard({ title, category, image, price }: BusinessFleetsCardProps) {
    function redirectButton() {
        window.open('https://mqqe7k3fksx.typeform.com/rummobusiness', '_blank', 'noopener noreferrer');
    }

    return (
        <div className={cssModule.container}>
            <Card className={cssModule.card}>
                <div className={cssModule.header}>
                    <BodyText className={cssModule.description}>
                        <span>{category}</span>
                    </BodyText>
                    <Text noMargin ellipsis className={cssModule.title}>
                        {title}
                    </Text>
                    <Margin value="0.5rem" />
                    <Row justify="start" align="start">
                        <div className={cssModule.stats}>
                            <SeatsIcon /> <span>5 Seats</span>
                        </div>
                        <div className={cssModule.stats}>
                            <SuitCasesIcon /> <span>2 Suitcases</span>
                        </div>
                    </Row>
                </div>
                <div className={cssModule.imageContainer}>
                    <RectangleLgIcon color="red" className={cssModule.rectangleLeft} />
                    <RectangleLgIcon color="red" className={cssModule.rectangleRight} />
                    {image && (
                        <img
                            alt={title}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                left: '2rem',
                                backgroundSize: 'cover',
                            }}
                            width="100%"
                            src={image}
                        />
                    )}
                </div>
                <Button
                    variant="solid"
                    style={{ width: 'auto' }}
                    {...styles.buttonLight}
                    onClick={() => redirectButton()}
                >{`from $${price} / 28 days`}</Button>
            </Card>
        </div>
    );
}
