export const colors = {
    red: '#e14436',
    white: '#fff',
    gray: '#70798c',
    grayShadow: '#73737380',
    pearl: '#f5f1ed',
    beige: '#c7bfab',
    grayDarker: '#0b0b0d',
    grayDark: '#3e424d',
    grayTransparent: '#70798c66',
    grayish: '#9da0aa',
    grayLight: '#b1b5c0',
    grayLightBorder: '#e2e3e7',
    grayLighter: '#f2f2f4',
    grayLighterFade: '#f2f2f4b3',
    grayFaded: '#f9f9f9',
    black: '#000',
};
