import Column from 'components/base/column';
import Photo from 'components/base/photo';
import i18n from 'language/i18n';
import React from 'react';
import { Asset } from 'services/asset.service';
import styles from './index.module.css';

type AssetHeroProps = {
    asset: Asset;
};

function AssetHero({ asset }: AssetHeroProps) {
    if (!asset) return null;

    const displayPhoto = asset.displayPhoto ? asset.displayPhoto : null;
    const photo = asset.photos ? asset.photos[0] : displayPhoto;

    function renderAssetPhoto() {
        if (!photo) return <Photo className={styles.image} size="relative" wide />;
        <Photo className={styles.image} size="relative" wide src={photo} />;
    }

    return (
        <Column className={styles.container}>
            {renderAssetPhoto()}
            <Column>
                <div className={styles.title}>
                    {asset.brand} - {asset.model}
                </div>
                <div className={styles.subtitle}>
                    {asset.category ? i18n.t(`asset.category.${asset.category.toUpperCase()}`) : 'N/A'}
                </div>
            </Column>
        </Column>
    );
}

export default AssetHero;
