import { auth, functions } from './persistence';

export default {
    /**
     *
     */
    async login(email: string, password: string) {
        return auth.signInWithEmailAndPassword(email, password);
    },

    /**
     *
     * @param name
     * @param email
     * @param password
     */
    async register(email: string, password: string, name?: string) {
        // requires registration
        const userRegistration = functions.httpsCallable('userRegistration');
        return userRegistration({
            email: email.toLowerCase(),
            password,
            name: name || '',
            creationSource: 'web',
        });
    },

    sendEmailVerification: async () => {
        return functions.httpsCallable('resendVerificationEmail')();
    },

    sendResetPasswordEmail: async (email: string) => {
        return functions.httpsCallable('sendResetPasswordEmail')({ email });
    },

    /**
     *
     */
    onAuthStateChanged(callback: (user: firebase.User | null) => void) {
        return auth.onAuthStateChanged(callback);
    },

    /**
     *
     */
    async logout() {
        return auth.signOut();
    },
};
