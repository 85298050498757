import React from 'react';
import styles from './index.module.css';
import Column from '../../../../base/column';
import Row from '../../../../base/row';
import StarsRating from '../../../../base/stars-rating';

export type OptionalRatings = {
    confort: number;
    drive: number;
    accuracy: number;
    safety: number;
    value: number;
};

export type BookingReview = {
    userName: string;
    userPhotoUrl: string;
    rating: number;
    description: string;
    optionalRatings?: OptionalRatings;
};

type BookingReviewCardProps = {
    review: BookingReview;
};

export default function BookingReviewCard(props: BookingReviewCardProps) {
    const { review } = props;

    return (
        <Column className={styles.card}>
            <Row className={styles.cardHeader}>
                <img className={styles.profileImg} alt="userPhoto" src={review.userPhotoUrl} />
                <Column className={styles.userRating}>
                    <Row>
                        <StarsRating rating={review.rating} />
                    </Row>
                    {review.userName}
                </Column>
            </Row>
            <Row className={styles.description}>{review.description}</Row>
        </Column>
    );
}

export const provision_booking_reviews: BookingReview[] = [
    {
        userName: 'userName001',
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        rating: 5,
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        optionalRatings: {
            confort: 4,
            drive: 4,
            accuracy: 5,
            safety: 4,
            value: 3,
        },
    },
    {
        userName: 'userName002',
        rating: 5,
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
        userName: 'userName003',
        rating: 3,
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        optionalRatings: {
            confort: 4,
            drive: 4,
            accuracy: 5,
            safety: 4,
            value: 3,
        },
    },
    {
        userName: 'userName004',
        rating: 4,
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
        userName: 'userName001',
        rating: 4,
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
        userName: 'userName002',
        rating: 3,
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        optionalRatings: {
            confort: 4,
            drive: 4,
            accuracy: 5,
            safety: 4,
            value: 3,
        },
    },
    {
        userName: 'userName003',
        rating: 5,
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        optionalRatings: {
            confort: 4,
            drive: 4,
            accuracy: 5,
            safety: 4,
            value: 3,
        },
    },
    {
        userName: 'userName004',
        rating: 4,
        userPhotoUrl: 'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
];
