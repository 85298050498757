import { ThemeComponentProps } from '@chakra-ui/react';

// Button theme
const ButtonTheme = {
    baseStyle: {
        w: 'fit-content',
        h: 'fit-content',
        _disabled: { color: 'white', bg: '#E2E3E7' },
        _hover: {
            _disabled: {
                bg: '#E2E3E7',
            },
        },
    },
    sizes: {
        sm: {
            w: 'fit-content',
            h: 'fit-content',
            borderRadius: '48px',
            padding: '8px 20px 8px 20px',
            fontSize: 'var(--font)',
            lineHeight: 'var(--line)',
        },
        md: {
            w: 'fit-content',
            h: 'fit-content',
            borderRadius: '48px',
            padding: '12px 24px 12px 24px',
            fontSize: 'var(--font)',
            lineHeight: 'var(--line)',
        },
        lg: {
            w: 'fit-content',
            h: 'fit-content',
            borderRadius: '88px',
            padding: '12px 32px 12px 32px',
            fontSize: 'var(--font-medium)',
            lineHeight: 'var(--line-medium)',
        },
        xl: {
            w: 'fit-content',
            h: 'fit-content',
            borderRadius: '56px',
            padding: '16px 40px 16px 40px',
            fontSize: 'var(--font-large)',
            lineHeight: 'var(--line-large)',
        },
    },
    variants: {
        logIn: {
            w: { base: '100%', lg: 'unset' },
            borderRadius: '4px',
            padding: '8px 24px 8px 24px',
            fontSize: 'var(--font-xsmall)',
            lineHeight: 'var(--line-xsmall)',
            fontWeight: 'var(--font-weight-medium)',
            bg: 'white',
            color: '#0B0B0D',
            border: '1px',
            borderColor: '#0B0B0D',
            _hover: {
                borderColor: 'rgba(11, 11, 13, 0.6)',
                color: 'rgba(11, 11, 13, 0.6)',
            },
        },
        register: {
            w: { base: '100%', lg: 'unset' },
            borderRadius: '4px',
            padding: '8px 24px 8px 24px',
            fontSize: 'var(--font-xsmall)',
            lineHeight: 'var(--line-xsmall)',
            fontWeight: 'var(--font-weight-light)',
            bg: '#0B0B0D',
            color: 'white',
            _hover: {
                bg: 'rgba(11, 11, 13, 0.6)',
            },
        },
        outline: (props: ThemeComponentProps) => ({
            border: '1px',
            bg: 'white',
            borderColor: `${props.colorScheme}.500`,
            color: `${props.colorScheme}.500`,
            fontWeight: 'var(--font-weight-regular)',
            _disabled: {
                color: '#B1B5C0',
                bg: 'white',
            },
            _active: { bg: 'none' },
            _hover: {
                outline: `2px solid`,
                outlineColor: `${props.colorScheme}.500`,
                outlineOffset: '-1px',
                _disabled: {
                    borderColor: '#B1B5C0',
                    color: '#B1B5C0',
                    bg: 'white',
                },
            },
        }),
        solid: {
            fontWeight: 'var(--font-weight-medium)',
            _hover: {
                _disabled: {
                    bg: '#E2E3E7',
                },
            },
        },
        ghost: (props: ThemeComponentProps) => ({
            color: `${props.colorScheme}.500`,
            textDecorationLine: 'underline',
            fontWeight: 'var(--font-weight-medium)',
            _disabled: {
                color: '#E2E3E7',
                bg: 'white',
            },
            _hover: {
                bg: '#F9F9F9',
                _disabled: {
                    color: '#E2E3E7',
                    bg: 'white',
                },
            },
        }),
        link: (props: ThemeComponentProps) => ({
            fontSize: 'var(--font-small)',
            lineHeight: 'var(--line-small)',
            fontWeight: 'var(--font-weight-medium)',
            _active: { color: `${props.colorScheme}.500` },
            _hover: {
                textDecorationLine: 'underline',
                _disabled: {
                    color: '#E2E3E7',
                    bg: 'white',
                },
            },
        }),
    },
    defaultProps: {
        size: 'mdFitContentWidth',
        variant: 'solid',
    },
};

export default ButtonTheme;
