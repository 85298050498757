import React, { useState } from 'react';
import _ from 'lodash';

import Column from 'components/base/column';
import ChevronRightIcon from 'components/base/icon/chevron-right';
import Row from 'components/base/row';
import { Dropdown } from 'components/base/dropdownV2';
import { ArrowLeftIcon } from 'components/base/icon/arrowLeft';
import { Text } from 'components/base/textV2';
import { AddressAutocompleteValue } from 'components/sections/home/addressComplete/types';
import { WizardStepProps } from 'pages/search-mobile';

import { useWindowSize } from 'hooks/useWindowSize';
import i18n from 'language/i18n';
import { Button } from '@chakra-ui/react';
import MobileSearchSection from '../location';

import styles from './index.module.css';

export type MobileAddressConfirmationSectionProps = {
    onBack?: (stepNumber?: number) => void;
    stepNumber?: number;
    initialReturnAddress: AddressAutocompleteValue;
    currentDeliveryAddress: AddressAutocompleteValue;
    onConfirmClick: (address: AddressAutocompleteValue) => void;
    onNewReturnAddress?: (address: AddressAutocompleteValue) => void;
    searchPlaceholder?: string;
};

function MobileAddressConfirmationSection(props: MobileAddressConfirmationSectionProps & Partial<WizardStepProps>) {
    const options = [
        {
            value: 'same_address',
            label: i18n.t('mobileAddressConfirmationSection.options.sameAddress'),
        },
        {
            value: 'another_address',
            label: i18n.t('mobileAddressConfirmationSection.options.anotherAddress'),
        },
    ];
    const { initialReturnAddress, currentDeliveryAddress } = props;

    const isSameAddress =
        !initialReturnAddress.label || _.isEqual(initialReturnAddress.value, currentDeliveryAddress.value);
    const [selectedAddress, setSelectedAddress] = useState<AddressAutocompleteValue>(props.initialReturnAddress);
    const [selectedOption, setSelectedOption] = useState(isSameAddress ? options[0] : options[1]);
    const [displaySearch, setDisplaySearch] = useState(false);
    const [newSearch, setNewSearch] = useState(false);
    const windowSize = useWindowSize();

    function onConfirmClick() {
        if (selectedOption === options[0]) {
            props.onConfirmClick(props.currentDeliveryAddress);
            props.jumpToStep && props.jumpToStep(2); // Explicitly jump to final step if in wizard
        } else if (selectedAddress) {
            props.onConfirmClick(selectedAddress);
            props.jumpToStep && props.jumpToStep(2); // Explicitly jump to final step if in wizard
        }
    }

    function onClickBack() {
        props.onBack && props.onBack(props.stepNumber);

        if (props.stepNumber && props.jumpToStep) {
            props.jumpToStep(props.stepNumber - 1);
        }
    }

    function onUpdateCurrentAddress() {
        // Display search with current return address on input to update address
        setNewSearch(false);
        setDisplaySearch(true);
    }

    function onSelectOption(option: any) {
        setSelectedOption(option);

        if (option.value === 'another_address') {
            // Display search with empty input to select new address
            setNewSearch(true);
            setDisplaySearch(true);
        } else {
            setSelectedAddress(props.currentDeliveryAddress); // Revert back to old address
            props.onNewReturnAddress && props.onNewReturnAddress(props.currentDeliveryAddress);
        }
    }

    function onNewAddressClick(newAddress: AddressAutocompleteValue) {
        const sameAddress = _.isEqual(newAddress.value, currentDeliveryAddress.value);

        setSelectedAddress(newAddress);
        setDisplaySearch(false);
        props.onNewReturnAddress && props.onNewReturnAddress(newAddress);

        const newOption = sameAddress ? options[0] : options[1];
        setSelectedOption(newOption);
    }

    function onNewAddressSelectBack() {
        setDisplaySearch(false);
        setSelectedOption(options[0]);
    }

    function displayCurrentLocation() {
        if (windowSize.width >= 692) {
            return (
                <Column className={styles.currentLocationContainer} onClick={onUpdateCurrentAddress}>
                    <Text className={styles.currentLocation}>
                        {selectedAddress.label! ||
                            props.initialReturnAddress.label! ||
                            props.currentDeliveryAddress?.label}
                    </Text>
                    <Text className={styles.updateAddressButton}>
                        {i18n.t('mobileAddressConfirmationSection.updateAddressButton')}
                    </Text>
                </Column>
            );
        }
        return (
            <Row className={styles.currentLocationContainer} onClick={onUpdateCurrentAddress}>
                <Text className={styles.currentLocation}>
                    {selectedAddress.label || props.initialReturnAddress.label || props.currentDeliveryAddress?.label}
                </Text>
                <ChevronRightIcon className={styles.currentLocationChevron} color="red" />
            </Row>
        );
    }

    function renderContent() {
        if (displaySearch) {
            return (
                <MobileSearchSection
                    onAddressClick={onNewAddressClick}
                    onBack={onNewAddressSelectBack}
                    placeholder={props.searchPlaceholder}
                    initialAddress={newSearch ? undefined : props.initialReturnAddress.label}
                    stepNumber={props.stepNumber}
                />
            );
        }

        return (
            <Column className={styles.container}>
                <div>
                    <Column className={styles.header}>
                        <ArrowLeftIcon fill="black" onClick={onClickBack} />
                    </Column>
                    <Text className={styles.mainText}>{i18n.t('mobileAddressConfirmationSection.header')}</Text>
                    <Dropdown
                        className={styles.dropdown}
                        value={selectedOption}
                        options={options}
                        onChange={onSelectOption}
                        isSearchable={false}
                    />
                    {displayCurrentLocation()}
                    <Text className={styles.description}>{i18n.t('mobileAddressConfirmationSection.description')}</Text>
                </div>
                <Column className={styles.confirmLocationButtonContainer}>
                    <Button variant="solid" colorScheme="black" size="md" onClick={onConfirmClick} alignSelf="center">
                        {i18n.t('mobileAddressConfirmationSection.confirmButton')}
                    </Button>
                </Column>
            </Column>
        );
    }

    return renderContent();
}

export default MobileAddressConfirmationSection;
