import React from 'react';
import { Insurance, InsurancesDataOptions } from 'services/insurances.service';
import { Flex, Text, Box, Link } from '@chakra-ui/react';
import Splitter from 'components/base/splitter';
import i18n from '../../../../language/i18n';
import Column from '../../../base/column';
import styles from './index.module.css';
import InsuranceCard from '../insurance-card';
import InsuranceOption from '../insurance-option';

type InsuranceProps = {
    insurances: Insurance[];
    selectedExtraInsurances: Insurance[];
    onOptionChange: (item: InsurancesDataOptions) => void;
    onInsurancesChange: (item: Insurance) => void;
    mainInsurance: Insurance | null;
};

export default function Insurances(props: InsuranceProps) {
    const { insurances, onOptionChange, onInsurancesChange, selectedExtraInsurances, mainInsurance } = props;

    function renderOptions() {
        return (
            <Flex direction="column">
                <Text variant="h5" fontWeight="medium" mb="24px">
                    {i18n.t('sections.assetDetails.booking.additionalInsurance')}
                </Text>
                {mainInsurance?.options?.map(item => {
                    return (
                        <InsuranceOption
                            data={item}
                            onOptionChange={onOptionChange}
                            isSelected={!!item.selected}
                            key={item.optionId}
                        />
                    );
                })}

                <Link target="_blank" isExternal href={process.env.REACT_APP_INSURANCES_LINK}>
                    {i18n.t('sections.insurance.seeInformation')}
                </Link>
                <Splitter spacing="xlarge" className={styles.splitter} />
            </Flex>
        );
    }

    function renderExtraInsurances() {
        const extraInsurances = insurances.filter(insurance => insurance.multipleOptions === false);
        const enabledInsurance = extraInsurances.filter(mappedInsurance => mappedInsurance.enabled === true);

        return enabledInsurance.length > 0 ? (
            <Box>
                <Text className={styles.header}>{i18n.t('sections.insurances.extrasInsurances.title')}</Text>
                {enabledInsurance.map(insurance => {
                    const isSelected = selectedExtraInsurances.some(item => item.id === insurance.id);

                    return (
                        <InsuranceCard
                            isSelected={isSelected}
                            onInsurancesChange={() => onInsurancesChange(insurance)}
                            insurance={insurance}
                            key={insurance.id}
                        />
                    );
                })}
                <Link target="_blank" isExternal href={process.env.REACT_APP_INSURANCES_LINK}>
                    {i18n.t('sections.insurance.seeInformation')}
                </Link>
                <Splitter spacing="xlarge" className={styles.splitter} />
            </Box>
        ) : null;
    }

    return (
        <Column className={styles.container}>
            <Box>{renderOptions()}</Box>
            <Box>{renderExtraInsurances()}</Box>
        </Column>
    );
}
