import { useState, useEffect } from 'react';
import GeolocationService from '../services/geolocation.service';

export function useUserLocation() {
    const [userLocation, setUserLocation] = useState<Position | undefined>(undefined);

    useEffect(() => {
        GeolocationService.getCurrentLocation()
            .then(position => {
                setUserLocation(position);
            })
            .catch(error => {
                setUserLocation(error);
            });
    }, []);

    return userLocation;
}
