import { Text } from '@chakra-ui/react';
import { calculateDuration } from 'helpers/calculateDuration';
import { Moment } from 'moment';
import React from 'react';
import styles from './styles';

interface DatesLengthDisplayProps {
    endDate: Moment | null;
    startDate: Moment | null;
}

function DatesLengthDisplay({ endDate, startDate }: DatesLengthDisplayProps) {
    function getLength() {
        if (!endDate || !startDate) {
            return 'Rental Dates';
        }
        const diff = calculateDuration(startDate, endDate);

        return `${diff}-day rental`;
    }

    return <Text {...styles.title}>{getLength()}</Text>;
}

export default DatesLengthDisplay;
