import React from 'react';
import { isMobile } from 'react-device-detect';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import withSizes from 'react-sizes';
import Photo from '../../../base/photo';
import styles from './index.module.css';

export type PhotoGalleryProps = {
    isMobile?: boolean;
    displayPhoto: string;
    className?: any;
    onClickAsset?: () => void;
};

export function AssetListItemGallery(props: PhotoGalleryProps) {
    const { displayPhoto, className, onClickAsset } = props;

    const renderDisplayPhoto = () => {
        const url = displayPhoto;

        const classes = [className];

        if (!isMobile) {
            classes.push(styles.large);
        }

        if (!displayPhoto) {
            return (
                <div className={classes.join(' ')}>
                    <Photo onClick={onClickAsset} size="relative" wide alt="test" />
                </div>
            );
        }

        return (
            <div className={classes.join(' ')}>
                <Photo onClick={onClickAsset} size="relative" src={url} wide alt="test" />
            </div>
        );
    };

    return renderDisplayPhoto();
}

const mapSizesToProps = ({ width }: { width: number }) =>
    ({
        isMobile: width < 770,
    } as PhotoGalleryProps);

export default withSizes(mapSizesToProps)(AssetListItemGallery);
