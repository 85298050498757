import React from 'react';

import { ReactComponent as Icon } from '../../../../assets/icons/map-pin.svg';
import { ReactComponent as WhiteIcon } from '../../../../assets/icons/map-pin-white.svg';

export type MapPinIconProps = {
    variant?: 'grey' | 'white';
};
export default class MapPinIcon extends React.Component<MapPinIconProps | any, any> {
    render() {
        let icon;
        switch (this.props.variant) {
            case 'white':
                icon = <WhiteIcon {...this.props} />;
                break;
            case 'grey':
            default:
                icon = <Icon {...this.props} />;
                break;
        }

        return icon;
    }
}
