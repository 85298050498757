import React from 'react';
import styles from './index.module.css';
import SectionTitle from '../../../base/text/sectionTitle';
import i18n from '../../../../language/i18n';
import BodyText from '../../../base/text/body';
import Column from '../../../base/column';
import BusinessIntroCard, { BusinessIntroCardProps } from './card';
import CarouselSimple from '../../../base/carouselSimple';

import BusinessIntroImage1 from '../../../../assets/images/business/illustration_1.svg';
import BusinessIntroImage2 from '../../../../assets/images/business/illustration_2.svg';
import BusinessIntroImage3 from '../../../../assets/images/business/illustration_3.svg';
import BusinessIntroImage4 from '../../../../assets/images/business/illustration_4.svg';
import { useWindowSize } from '../../../../hooks/useWindowSize';

// carousel breakpoints
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1681 },
        items: 4,
    },
    size4: {
        breakpoint: { max: 1680, min: 1361 },
        items: 4,
    },
    size3: {
        breakpoint: { max: 1360, min: 929 },
        items: 3,
    },
    size2: {
        breakpoint: { max: 928, min: 692 },
        items: 2,
    },
    size1: {
        breakpoint: { max: 691, min: 0 },
        items: 1,
    },
};

const items: BusinessIntroCardProps[] = [
    {
        title: 'Get your pick and drive your Rummo',
        description: "No need to ever guess which car you will get, it will always be the car you've chosen.",
        image: BusinessIntroImage1,
    },
    {
        title: "Don't limit yourself by mileage",
        description: 'Go all the way and back with unlimited miles included.',
        image: BusinessIntroImage2,
    },
    {
        title: 'Stop wasting time and money',
        description: 'Delivery and pick-up wherever you need plus refuel at no extra cost.',
        image: BusinessIntroImage3,
    },
    {
        title: "Don't worry about maintenance",
        description: 'We take care of any car trouble that may occur during your rental.',
        image: BusinessIntroImage4,
    },
];

export default function BusinessIntro() {
    const { width } = useWindowSize();

    if (width < 1360 && width > 692) {
        return (
            <Column>
                <CarouselSimple responsive={responsive}>
                    <div className={styles.fakecard}>
                        <div>
                            <SectionTitle className={styles.sectionTitle}>
                                {i18n.t('page.business.intro.title')}
                            </SectionTitle>
                            <BodyText className={styles.sectionInfo}>
                                {i18n.t('page.business.intro.description')}
                            </BodyText>
                            <a
                                href="https://mqqe7k3fksx.typeform.com/rummobusiness"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.buttonBlack}
                            >
                                {i18n.t('page.business.intro.button')}
                            </a>
                        </div>
                    </div>
                    {items.map(BusinessIntroCard)}
                </CarouselSimple>
            </Column>
        );
    }

    return (
        <Column>
            <SectionTitle className={styles.sectionTitle}>{i18n.t('page.business.intro.title')}</SectionTitle>
            <BodyText className={styles.sectionInfo}>{i18n.t('page.business.intro.description')}</BodyText>
            {width <= 692 ? (
                <a
                    href="https://mqqe7k3fksx.typeform.com/rummobusiness"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.buttonBlack}
                >
                    {i18n.t('page.business.intro.button')}
                </a>
            ) : null}
            <CarouselSimple responsive={responsive}>{items.map(BusinessIntroCard)}</CarouselSimple>
        </Column>
    );
}
