import React from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import LogoLarge from 'components/base/icon/logoLarge';
import { useWindowSize } from 'hooks/useWindowSize';
import Row from '../../../../base/row';
import Logo from '../../../../base/icon/logo';
import { joinClasses } from '../../../../../helpers/joinClasses';

import i18n from '../../../../../language/i18n';

import styles from './index.module.css';

export type HeaderModalTypes = 'login' | 'register' | 'forgotPassword' | undefined;

export default function HeaderLink() {
    const history = useHistory();
    const windowSize = useWindowSize();

    function renderClassNames() {
        if (history.location.pathname !== '/') {
            return styles.headerLogoPages;
        }
    }

    const darkMode = history.location.pathname === '/team' || history.location.pathname === '/business';
    const linkColor = darkMode ? 'var(--color-white)' : 'var(--color-gray-dark)';

    function renderLogo() {
        if (history.location.pathname === '/') {
            return <LogoLarge />;
        }

        if (windowSize.width > 927) {
            return <LogoLarge color="red" />;
        }

        return <LogoLarge color="red" />;
    }

    function renderRentLink() {
        if (history.location.pathname !== '/') {
            return (
                <Link
                    as={RouterLink}
                    to="/"
                    className={joinClasses(styles.text, linkColor)}
                    variant="noStyle"
                    color={linkColor}
                >
                    {i18n.t('sections.header.rentARummo')}
                </Link>
            );
        }
        return null;
    }

    return (
        <>
            <Row className={styles.left}>
                <div onClick={() => history.push('/')} className={[styles.logo, renderClassNames()].join(' ')}>
                    {renderLogo()}
                </div>
                <div className={styles.linksContainer}>
                    {renderRentLink()}
                    <Link
                        to="/business"
                        as={RouterLink}
                        className={joinClasses(styles.text, linkColor)}
                        variant="noStyle"
                        color={linkColor}
                    >
                        {i18n.t('sections.header.forBusinessSection')}
                    </Link>
                    <Link
                        to="/faq"
                        as={RouterLink}
                        className={joinClasses(styles.text, linkColor)}
                        variant="noStyle"
                        color={linkColor}
                    >
                        {i18n.t('sections.header.FAQ')}
                    </Link>
                </div>
            </Row>
        </>
    );
}
