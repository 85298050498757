import React, { useState, useEffect, useCallback } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Appearance } from '@stripe/stripe-js';

import ModalNew from 'components/base/modalNew';

import PaymentMethodsService from 'services/paymentMethods.service';
import stripeConfig from '../../../../configuration/stripe/stripe-config.json';
import { PaymentMethodForm } from './form';

const stripePromise = loadStripe(stripeConfig.publishableKey);

const appearance: Appearance = {
    variables: {
        fontFamily: 'Poppins, Open Sans, sans-serif',
        borderRadius: '2px',
        colorText: '#0b0b0d',
    },
    rules: {
        '.Label': {
            fontSize: '14px',
            fontWeight: '500',
        },
    },
};

interface PaymentMethodFormProps {
    isOpen: boolean;
    onClose: () => void;
    onComplete?: () => void;
}

export function PaymentMethodModal({ isOpen, onClose, onComplete }: PaymentMethodFormProps) {
    const [loading, setLoading] = useState(true);
    const [clientSecret, setClientSecret] = useState('');

    const requestSetupIntent = useCallback(async () => {
        try {
            const data = await PaymentMethodsService.create();
            setClientSecret(data.response.setupIntent.client_secret);
        } catch (error) {
            console.log({ error });
        } finally {
            setLoading(false);
        }
    }, []);

    const onMount = useCallback(() => {
        requestSetupIntent();
    }, [requestSetupIntent]);

    useEffect(() => {
        if (isOpen) {
            onMount();
        }
    }, [isOpen, onMount]);

    return (
        <ModalNew
            isOpen={isOpen}
            loading={loading}
            onClose={onClose}
            minH={600}
            bodyStyles={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <Elements key={clientSecret} stripe={stripePromise} options={{ clientSecret, appearance }}>
                <PaymentMethodForm onClose={onClose} onComplete={onComplete} />
            </Elements>
        </ModalNew>
    );
}
