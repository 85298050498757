import i18n from '../../language/i18n';

export const leaders = {
    gf: {
        name: i18n.t('page.team.ceo.name'),
        jobTitle: i18n.t('page.team.ceo.jobTitle'),
        currentMission: i18n.t('page.team.ceo.currentMission'),
        exp: i18n.t('page.team.ceo.exp'),
    },

    pb: {
        name: i18n.t('page.team.cro.name'),
        jobTitle: i18n.t('page.team.cro.jobTitle'),
        currentMission: i18n.t('page.team.cro.currentMission'),
        exp: i18n.t('page.team.cro.exp'),
    },
    ao: {
        name: i18n.t('page.team.cto.name'),
        jobTitle: i18n.t('page.team.cto.jobTitle'),
        currentMission: i18n.t('page.team.cto.currentMission'),
        exp: i18n.t('page.team.cto.exp'),
    },
};
