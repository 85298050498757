import React, { useState } from 'react';
import useOuterClick from '../../../../hooks/useOuterClick';
import Checkbox from '../../../base/checkbox';
import Row from '../../../base/row';
import styles from './index.module.css';

export type AssetFilterOrderFields = {
    label: string;
    value: string;
    action?: any;
    options: AssetFilterOrderOptions[];
};

export type AssetFilterOrderOptions = {
    label: string;
    value: string;
};

export type AssetFilterOrderProps = {
    className?: any;
    fields: AssetFilterOrderFields[];
    onChange?: any;
    filters?: any;
};

export default function AssetFilterOrder(props: AssetFilterOrderProps) {
    const [isFocus, setFocus] = useState<string | null>(null);

    function onChange(key: string, value: any) {
        props.onChange &&
            props.onChange({
                key,
                value,
            });
    }

    const NO_CLOSE = 'DISABLE_CLOSE';

    function renderOptions(field: AssetFilterOrderFields) {
        const { options } = field;

        const item: string[] = props.filters[field.value];

        const checkAllOptions = () => {
            options.map(opt => {
                const checked = item ? item.includes(opt.value) : false;
                if (!checked) {
                    return onChange(field.value, opt.value);
                }

                return undefined;
            });
        };

        const uncheckAllOptions = () => {
            options.map(opt => {
                const checked = item ? item.includes(opt.value) : false;

                if (checked) {
                    return onChange(field.value, opt.value);
                }

                return undefined;
            });
        };

        const click = (option: AssetFilterOrderOptions) => {
            if (option.value === 'ALL') {
                if (props.filters[field.value].includes('ALL')) {
                    uncheckAllOptions();
                } else {
                    checkAllOptions();
                }
            } else {
                onChange(field.value, option.value);
                if (props.filters[field.value].includes('ALL')) onChange(field.value, 'ALL');
            }
        };

        return !options
            ? null
            : options.map((option: AssetFilterOrderOptions, index: number) => {
                  const checked = item ? item.includes(option.value) : false;

                  return (
                      <div key={index} className={[styles.dropdownItem, NO_CLOSE].join(' ')}>
                          <Checkbox
                              className={NO_CLOSE}
                              label={option.label}
                              checked={checked}
                              onClick={() => click(option)}
                          />
                      </div>
                  );
              });
    }

    const innerRef = useOuterClick((e: any) => {
        if (!e.target.classList.contains(NO_CLOSE)) {
            setFocus(null);
        }
    });

    function onBarClick(field: AssetFilterOrderFields) {
        if (field.value === 'ACTION') {
            setFocus(null);
            field.action();
        } else {
            setFocus(isFocus === field.value ? null : field.value);
        }
    }

    function renderOrderButtons() {
        return props.fields.map((field: any, index: any) => {
            const hasFilters = props.filters[field.value] ? props.filters[field.value].length > 0 : false;
            return (
                <div
                    key={index}
                    className={[styles.sortBarContainer, isFocus === field.value ? styles.focus : '', NO_CLOSE].join(
                        ' ',
                    )}
                >
                    <div
                        className={[styles.sortBar, NO_CLOSE, hasFilters ? styles.filteredBox : ''].join(' ')}
                        onClick={() => onBarClick(field)}
                        ref={innerRef}
                    >
                        <span className={[styles.text, NO_CLOSE].join(' ')} id={field.value}>
                            {field.label}
                        </span>
                    </div>
                    <div className={[styles.dropdown, NO_CLOSE].join(' ')}>{renderOptions(field)}</div>
                    <div
                        className={[NO_CLOSE, styles.filterIndicator, hasFilters ? styles.hasFilters : ''].join(' ')}
                        onClick={() => onBarClick(field)}
                    >
                        <p className={styles.filterIndicatorText}>
                            {props.filters[field.value].length > 9 ? `+${9}` : props.filters[field.value].length}
                        </p>
                    </div>
                </div>
            );
        });
    }

    const { className } = props;
    return <Row className={[styles.dataOrder, className].join(' ')}>{renderOrderButtons()}</Row>;
}
