import { Text, useBreakpoint } from '@chakra-ui/react';
import Column from 'components/base/column';
import InfoIcon from 'components/base/icon/info';
import Row from 'components/base/row';
import { useWindowSize } from 'hooks';
import i18n from 'language/i18n';
import React from 'react';
import styles from './index.module.css';
import chakraStyles from './styles';

type PriceDayProps = {
    price: string;
    total?: string;
    mode?: 'details' | 'checkout-1' | 'checkout-2';
    onClick?: () => void;
    onClickOpenEditModal?: () => void;
};

function PriceDay({ price, total, onClick, mode, onClickOpenEditModal }: PriceDayProps) {
    const currentBreakpoint = useBreakpoint();
    const { width } = useWindowSize();

    function renderDetailsPriceDay(isClickable: boolean) {
        return (
            <>
                <Row align="center">
                    <div className={styles.price}>{price}</div>
                    <div className={styles.slash}>/ {i18n.t('sections.assetDetails.booking.pricePerDayLabel')}</div>
                </Row>
                {width < 692 ? (
                    <Row className={styles.totalContainer}>
                        <div
                            className={[styles.message, isClickable && styles.underline].join(' ')}
                        >{`${total} ${i18n.t('sections.assetDetails.booking.totalLabel')}`}</div>
                        {isClickable && <InfoIcon className={styles.infoIcon} />}
                    </Row>
                ) : (
                    <div className={styles.message}>{`${total} ${i18n.t(
                        'sections.assetDetails.booking.totalLabel',
                    )}`}</div>
                )}
            </>
        );
    }

    function renderCheckoutPriceDay(isClickable: boolean) {
        return (
            <>
                <Row align="center">
                    <div className={styles.price}>{total}</div>
                    <div className={styles.slash}>{i18n.t('sections.assetDetails.booking.totalLabel')}</div>
                </Row>
                {width < 692 ? (
                    <Row className={styles.totalContainer}>
                        <div
                            className={[styles.message, isClickable ? styles.underline : undefined].join(' ')}
                        >{`${price} / ${i18n.t('sections.assetDetails.booking.pricePerDayLabel')}`}</div>
                        {isClickable && <InfoIcon className={styles.infoIcon} />}
                    </Row>
                ) : (
                    <div className={styles.message}>{`${price} / ${i18n.t(
                        'sections.assetDetails.booking.pricePerDayLabel',
                    )}`}</div>
                )}
            </>
        );
    }

    function renderPriceDay(isClickable: boolean) {
        switch (mode) {
            case 'checkout-1':
            case 'checkout-2':
                return renderCheckoutPriceDay(isClickable);
            case 'details':
            default:
                return renderDetailsPriceDay(isClickable);
        }
    }

    const isClickable = total !== undefined && mode !== 'checkout-2';
    return (
        <Column className={styles.priceDayContainer} onClick={isClickable ? onClick : undefined}>
            {total ? (
                renderPriceDay(isClickable)
            ) : (
                <Text onClick={onClickOpenEditModal} {...chakraStyles.noPrice(currentBreakpoint)}>
                    {i18n.t('sections.assetDetails.booking.noPrice')}
                </Text>
            )}
        </Column>
    );
}

export default PriceDay;
