import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import styles from './index.module.css';
import Column from '../../../base/column';
import Text from '../../../base/text';
import i18n from '../../../../language/i18n';
import PageContainer from '../../../base/pageContainer';

interface SectionHomeIntroBannerProps {
    onClick?: () => void;
}

function SectionHomeIntroBanner(props: SectionHomeIntroBannerProps) {
    function onBecomeDriverButtonClick() {
        // window.open("to be defined", '_blank');
    }

    const { onClick } = props;
    return (
        <PageContainer>
            <Column className={styles.container}>
                <Column className={styles.wrapper}>
                    <Text className={styles.title}>{i18n.t('pages.home.intro.title')}</Text>
                    <Text className={styles.description}>{i18n.t('pages.home.intro.description')}</Text>
                    <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="var(--margin)">
                        <Button
                            border={{ base: '1px solid white', md: 'hidden' }}
                            size="md"
                            onClick={onClick}
                            variant="solid"
                            colorScheme="brand"
                        >
                            {i18n.t('pages.home.intro.howItWorksButton')}
                        </Button>
                        <Button
                            border={{ base: '1px solid white', md: 'hidden' }}
                            size="md"
                            onClick={onBecomeDriverButtonClick}
                            variant="solid"
                            colorScheme="black"
                        >
                            {i18n.t('pages.home.intro.becomeDriverButton')}
                        </Button>
                    </Flex>
                </Column>
            </Column>
        </PageContainer>
    );
}

export default SectionHomeIntroBanner;
