import React from 'react';
import styles from './index.module.css';
import SectionTitle from '../../../base/text/sectionTitle';
import i18n from '../../../../language/i18n';
import Column from '../../../base/column';
import BusinessFleetsCard, { BusinessFleetsCardProps } from './card';
import Margin from '../../../base/margin';
import CarouselSimple from '../../../base/carouselSimple';

import BusinessFleetsImage1 from '../../../../assets/images/business/car1.png';
import BusinessFleetsImage2 from '../../../../assets/images/business/car2.png';
import BusinessFleetsImage3 from '../../../../assets/images/business/car3.png';
import { useWindowSize } from '../../../../hooks/useWindowSize';

// carousel breakpoints
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1681 },
        items: 3,
    },
    size4: {
        breakpoint: { max: 1680, min: 1360 },
        items: 3,
    },
    size3: {
        breakpoint: { max: 1359, min: 929 },
        items: 2,
    },
    size2: {
        breakpoint: { max: 928, min: 692 },
        items: 2,
    },
    size1: {
        breakpoint: { max: 691, min: 0 },
        items: 1,
    },
};

const items: BusinessFleetsCardProps[] = [
    {
        title: 'Volkswagen Jetta',
        category: 'Economy',
        image: BusinessFleetsImage1,
        price: 471,
    },
    {
        title: 'Audi A4',
        category: 'Sedan Premium',
        image: BusinessFleetsImage2,
        price: 512,
    },
    {
        title: 'Volkswagen Atlas',
        category: 'SUV',
        image: BusinessFleetsImage3,
        price: 603,
    },
];

export default function BusinessFleets() {
    const { width } = useWindowSize();

    return (
        <Column id="fleets">
            <SectionTitle className={styles.sectionTitle}>{i18n.t('page.business.fleets.title')}</SectionTitle>
            <Margin value="5rem" />
            {width < 692 ? (
                items.map(BusinessFleetsCard)
            ) : (
                <CarouselSimple responsive={responsive}>{items.map(BusinessFleetsCard)}</CarouselSimple>
            )}
        </Column>
    );
}
