import { Fields } from 'persona/dist/lib/interfaces';
import FirestoreService, { FirebaseFilter } from './firestore.service';
import { firestore } from './persistence';
import profileService from './profile.service';

const DocumentValidationRequestCollection = 'request_document_validation';
const DocumentValidationRequestSubCollection = 'validations';

const UsersCollection = 'users';

const DocumentValidationRequestService = {
    /**
     * @param uid
     * @returns one document validation
     */
    get(uid: string) {
        return FirestoreService.get(DocumentValidationRequestCollection, uid);
    },

    getAllByUser(uid: string, onNext: (snapshot: firebase.firestore.QuerySnapshot) => void) {
        const userRef = firestore.collection(UsersCollection).doc(uid);

        const filter = {
            field: 'issuedBy',
            operator: '==',
            value: userRef,
            orderBy: {
                order: 'desc',
                field: 'creationDate',
            },
        };

        return FirestoreService.getAllSnapshot(DocumentValidationRequestCollection, onNext, filter as FirebaseFilter);
    },

    /* Create validation request
     *
     * */
    async create(uid: string): Promise<any> {
        const userRef = firestore.collection(UsersCollection).doc(uid);

        const createDocumentValidationRequestRef = await firestore.collection(DocumentValidationRequestCollection).add({
            status: 'pending',
            issuedBy: userRef,
            creationDate: new Date(),
        });

        let unsubscribeSnapshot: () => void | undefined;
        let timeoutId: NodeJS.Timeout | undefined;
        return new Promise((resolve, reject) => {
            unsubscribeSnapshot = createDocumentValidationRequestRef.onSnapshot(
                (snapshot: firebase.firestore.DocumentSnapshot) => {
                    const data = {
                        id: snapshot.id,
                        ...snapshot.data(),
                    } as firebase.firestore.DocumentData;

                    switch (data && data.status) {
                        case 'processing':
                            resolve(data);
                            break;
                        case 'failure':
                            reject({});
                            break;
                        default:
                    }
                },
            );

            timeoutId = setTimeout(() => {
                reject({});
            }, 10000);
        }).finally(() => {
            // Clear listener and timeout on promise reject/resolve
            if (unsubscribeSnapshot) unsubscribeSnapshot();
            if (timeoutId) clearTimeout(timeoutId);
        });
    },

    async approveValidationRequest(uid: string, requestId: string, inquiryId: string) {
        const requestValidationRef = await firestore
            .collection(`${DocumentValidationRequestCollection}/${requestId}/${DocumentValidationRequestSubCollection}`)
            .add({
                action: 'approve',
                status: 'pending',
                issuedBy: firestore.collection(UsersCollection).doc(uid),
                inquiryId,
                creationDate: new Date(),
            });

        let unsubscribeSnapshot: () => void | undefined;
        let timeoutId: NodeJS.Timeout | undefined;
        return new Promise((resolve, reject) => {
            unsubscribeSnapshot = requestValidationRef.onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
                const data = snapshot.data();

                switch (data && data.status) {
                    case 'accepted':
                        resolve(data);
                        break;
                    case 'rejected':
                        reject({
                            status: 'error.rejected',
                            origin: 'DocumentValidationRequestService.approveValidationRequest',
                        });
                        break;
                    default:
                }
            });

            timeoutId = setTimeout(() => {
                reject({
                    status: 'error.timeout',
                    origin: 'DocumentValidationRequestService.approveValidationRequest',
                });
            }, 10000);
        }).finally(() => {
            // Clear listener and timeout on promise reject/resolve
            if (unsubscribeSnapshot) unsubscribeSnapshot();
            if (timeoutId) clearTimeout(timeoutId);
        });
    },

    async revokeDocumentsValidationRequest(uid: string, requestId: string, action: string) {
        const requestValidationRef = await firestore
            .collection(`${DocumentValidationRequestCollection}/${requestId}/${DocumentValidationRequestSubCollection}`)
            .add({
                action,
                status: 'pending',
                issuedBy: firestore.collection(UsersCollection).doc(uid),
                creationDate: new Date(),
            });

        let unsubscribeSnapshot: () => void | undefined;
        let timeoutId: NodeJS.Timeout | undefined;
        return new Promise((resolve, reject) => {
            unsubscribeSnapshot = requestValidationRef.onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
                const data = snapshot.data();

                switch (data && data.status) {
                    case 'accepted':
                        resolve(data);
                        break;
                    case 'rejected':
                        reject({
                            status: 'error.rejected',
                            origin: 'DocumentValidationRequestService.revokeDocumentsValidationRequest',
                        });
                        break;
                    default:
                }
            });

            timeoutId = setTimeout(() => {
                reject({
                    status: 'error.timeout',
                    origin: 'DocumentValidationRequestService.revokeDocumentsValidationRequest',
                });
            }, 10000);
        }).finally(() => {
            // Clear listener and timeout on promise reject/resolve
            if (unsubscribeSnapshot) unsubscribeSnapshot();
            if (timeoutId) clearTimeout(timeoutId);
        });
    },

    async updateUser(uid: string, fields: Fields) {
        function extractAddress() {
            const addressSection = [
                (fields['address-city'] as any).value,
                (fields['address-country-code'] as any).value,
                (fields['address-postal-code'] as any).value,
                (fields['address-street-1'] as any).value,
                (fields['address-street-2'] as any).value,
                (fields['address-subdivision'] as any).value,
            ].filter(elem => elem !== null);

            if (addressSection.length === 0) {
                return undefined;
            }

            return addressSection.join(' ');
        }

        const mappedFields: any = {};
        const extractedAddress = extractAddress();

        if (extractedAddress) {
            mappedFields.address = extractedAddress;
        }

        if (fields.birthdate) {
            mappedFields.birthdate = (fields.birthdate as any).value;
        }

        return profileService.edit(uid, mappedFields);
    },
};

export default DocumentValidationRequestService;
