import React from 'react';

import Select from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { getOptionLabel, getOptionValue } from 'react-select/src/builtins';
import { Box } from '@chakra-ui/react';
import Label from '../text/label';
import styles from './index.module.css';

type DropdownProps = {
    /**
     * If the provided options are searchable or not
     */
    isSearchable?: boolean;

    /**
     * Apply round borders
     */
    round?: boolean;

    /**
     * The default selected option of the provided ones
     */
    value: any;

    /**
     *
     */
    placeholder?: string;

    /**
     * CSS Class Name
     */
    className?: string;

    /**
     * The prefix given to all css classes in the Select component.
     *
     * More information at @link https://react-select.com/styles#using-classnames
     */
    classNamePrefix?: string;

    /**
     * The options to be displayed in the dropdown menu
     */
    options: any[];

    /**
     * Event that handles the click on an option
     * @param selectedOption The content associated with the selected option
     */
    onChange: (selectedOption: any) => void;

    /**
     *
     */
    components?: Partial<SelectComponents<any, any>>;

    /**
     *
     */
    getOptionLabelProp?: getOptionLabel<any>;

    /**
     *
     */
    getOptionValueProp?: getOptionValue<any>;

    onFocus?: any;

    onBlur?: any;

    onMouseEnter?: any;

    onMouseLeave?: any;

    label?: string;

    noOptionsMessage?: string;

    onTextChange?: any;
};

export default function Dropdown(props: DropdownProps) {
    const {
        isSearchable,
        value,
        className,
        classNamePrefix,
        options,
        onChange,
        components,
        getOptionLabelProp,
        getOptionValueProp,
        placeholder,
        onFocus,
        onBlur,
        label,
        noOptionsMessage,
        onTextChange,
        onMouseEnter,
        onMouseLeave,
        round,
    } = props;

    const componentStyle = [styles.dropdown, className];

    function render() {
        if (round) {
            componentStyle.push(styles.round);
        }
        return (
            <>
                {label ? <Label className={styles.label}>{label}</Label> : null}
                <Select
                    placeholder={placeholder}
                    components={components}
                    isSearchable={isSearchable}
                    value={value}
                    className={componentStyle.join(' ')}
                    classNamePrefix={classNamePrefix || 'dropdown'}
                    options={options}
                    noOptionsMessage={msg => noOptionsMessage || null}
                    onChange={onChange}
                    getOptionLabel={getOptionLabelProp}
                    getOptionValue={getOptionValueProp}
                    onFocus={onFocus}
                    onInputChange={onTextChange}
                    onBlur={onBlur}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    arrowRenderer={() => <Box w="10px" h="10px" bgColor="red" />}
                />
            </>
        );
    }

    return render();
}
