import { Box, Button, Divider, Fade, Flex, HStack, Icon, Text, Img } from '@chakra-ui/react';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DateRangePicker, { DateRangeFocus } from 'components/base/dateRangePicker';
import { ErrorText } from 'components/base/errorText';
import CloseCircleIcon from 'components/base/icon/closeCircle';
import MapPinIcon from 'components/base/icon/map-pin';
import PageContainer from 'components/base/pageContainer';
import Popover from 'components/base/popover';
import Switcher from 'components/base/switcher';
import TimePicker from 'components/base/timePicker';
import { Location } from 'pages/assets/types';
import OperatingCitiesService, { OperatingCity } from 'services/operatingCities.service';

import { useOperatingAreas, useRemoteConfig, useSharedData, useWindowSize } from 'hooks';
import i18n from 'language/i18n';
import { Coordinate } from 'services/operatingAreas.service';

import {
    BookingCalendarDatesChangesInformation,
    handleBookingCalendarDatesChanges,
} from 'helpers/handleBookingCalendarDatesChanges';
import checkIfIsOutsideRange from 'helpers/isOutsideRange';
import { createSearchObject } from 'models/sharedData/SearchObject';
import { TimeSlots } from 'models/sharedData/TimeSlots';
import { colors } from 'styles/colors';
import { useCalendarLimits } from 'hooks/useCalendarLimits';
import LisbonBg from '../../../../assets/images/Lisbon.jpeg';
import NewYorkBg from '../../../../assets/images/NewYork.jpeg';
import AddressAutocomplete from '../addressComplete';
import { DateInput } from './DateInput';
import styles from './index.module.css';
import { styles as chakraStyles } from './styles';

export type SearchBarProps = {
    onSubmit: () => void;
};

function SearchBar(props: SearchBarProps) {
    const { onSubmit } = props;

    const history = useHistory();
    const { homepageSearchEnabled, bookingMaximumDuration } = useRemoteConfig();
    const { width } = useWindowSize();
    const { checkLocationInBoundingBox, openWarningModal, storeSelectedOperatingCity, operatingCity } =
        useOperatingAreas();
    const { shared, sharedDataService } = useSharedData();

    const searchEnabled = homepageSearchEnabled;

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [dateRangeFocus, setDateRangeFocus] = useState<DateRangeFocus>(null);
    const [startTime, setStartTime] = useState('09:00' as TimeSlots);
    const [endTime, setEndTime] = useState('09:00' as TimeSlots);
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [deliveryQuery, setDeliveryQuery] = useState<string>();
    const [returnQuery, setReturnQuery] = useState<string>();
    const [deliveryLocation, setDeliveryLocation] = useState<Location | undefined>(undefined);
    const [returnLocation, setReturnLocation] = useState<Location | undefined>(undefined);
    const [deliveryIsValid, setDeliveryIsValid] = useState<boolean>(true);
    const [returnIsValid, setReturnIsValid] = useState<boolean>(true);
    const [switcherChecked, setSwitcherChecked] = useState(true);
    const [showReturnAddressInput, setShowReturnAddressInput] = useState(false);
    const [currReturnLocationHistory, setCurrReturnLocationHistory] = useState(
        shared.returnLocationHistory ? [...shared.returnLocationHistory] : [],
    );
    const [deliveryLocationerror, setDeliveryLocationError] = useState('');
    const [returnLocationError, setReturnLocationError] = useState('');
    const [datesError, setDatesError] = useState('');
    const [operatingCities, setOperatingCities] = useState<OperatingCity[]>([]);

    const { startLimit, updateStartLimit, endLimit } = useCalendarLimits({
        startDate,
        startTime,
        endDate,
        endTime,
        setEndTime,
    });

    function clearCity() {
        storeSelectedOperatingCity(undefined);
        setDeliveryQuery(undefined);
        setDeliveryLocation(undefined);
        setReturnQuery(undefined);
        setReturnLocation(undefined);
        setStartDate(null);
        setEndDate(null);
        setStartTime('09:00');
        setEndTime('09:00');
    }

    async function getOperatingCities() {
        const operatingCitiesResult = await OperatingCitiesService.getAll();
        setOperatingCities(operatingCitiesResult);
    }

    useEffect(() => {
        getOperatingCities();
    }, []);

    const sameDeliveryReturnLocation = useMemo(() => {
        return (
            returnLocation?.latitude === deliveryLocation?.latitude &&
            returnLocation?.longitude === deliveryLocation?.longitude
        );
    }, [deliveryLocation, returnLocation]);

    function handleReturnInputChange(term: string) {
        if (term === '') {
            setReturnLocation(undefined);
        }
        setReturnQuery(term);
    }

    function handleDeliveryInputChange(term: string) {
        if (term === '') {
            setDeliveryLocation(undefined);
        }
        setDeliveryQuery(term);
    }

    function isSameAsReturn(location: Coordinate) {
        return location.latitude === returnLocation?.latitude && location.longitude === returnLocation?.longitude;
    }

    function isSameAsDelivery(location: Coordinate) {
        return location.latitude === deliveryLocation?.latitude && location.longitude === deliveryLocation?.longitude;
    }

    function onInputSearch(addressParams: { label?: string; value: Array<number> }, inputName?: string) {
        const address = { label: addressParams.label, valueArray: addressParams.value };

        setDeliveryLocationError('');
        setReturnLocationError('');

        let isAvailable = true;
        const [longitude, latitude] = address.valueArray;

        if (longitude && latitude) {
            isAvailable = checkLocationInBoundingBox({ latitude, longitude });
        }

        const newLocation = longitude ? { latitude, longitude } : undefined;

        if (inputName === 'deliveryLocation') {
            if (switcherChecked) {
                const endLocation = switcherChecked ? ({ ...newLocation } as Location) : returnLocation;
                const endQuery = switcherChecked ? address.label : returnQuery;

                setReturnQuery(endQuery);
                setReturnLocation(endLocation);
            }

            const sameAsReturn = isSameAsReturn({ latitude, longitude });

            if (sameAsReturn && isAvailable) {
                setSwitcherChecked(true);
                setShowReturnAddressInput(false);
            }

            setDeliveryQuery(address.label);
            setDeliveryLocation(newLocation);
            setDeliveryIsValid(isAvailable);
            return;
        }

        if (inputName === 'returnLocation') {
            const sameAsDelivery = isSameAsDelivery({ latitude, longitude });

            if (sameAsDelivery && isAvailable) {
                setSwitcherChecked(true);
                setShowReturnAddressInput(false);
            }

            setReturnQuery(address.label);
            setReturnIsValid(isAvailable);

            if (isAvailable === false) {
                return;
            }

            setReturnLocation(newLocation);
            setShowReturnAddressInput(false);

            const newValue = `custom_location_${newLocation?.longitude},${newLocation?.latitude}`;

            if (!currReturnLocationHistory.some(custom => custom.value === newValue)) {
                const newCurrCustomLocations = [...currReturnLocationHistory];
                newCurrCustomLocations.push({
                    label: address.label!,
                    value: newValue,
                });
                setCurrReturnLocationHistory(newCurrCustomLocations);
            }
        }
    }

    function handleSwitcher() {
        if (!switcherChecked) {
            setReturnLocationError('');
            setShowReturnAddressInput(false);
            if (deliveryLocation) {
                setReturnQuery(deliveryQuery);
                setReturnLocation(deliveryLocation);
            }
            setReturnIsValid(deliveryIsValid);
        }

        if (switcherChecked) {
            setReturnQuery('');
            setShowReturnAddressInput(true);
            setReturnLocation(undefined);
            setReturnIsValid(true);
        }
        setSwitcherChecked(prevState => !prevState);
    }

    function handleSubmitSearch() {
        if (!deliveryQuery) {
            setDeliveryLocationError(i18n.t('sections.searchBar.errors.deliveryLocation'));
            return;
        }

        if (!switcherChecked && !returnQuery && !returnLocation) {
            setReturnLocationError(i18n.t('sections.searchBar.errors.returnLocation'));
            return;
        }

        if (!startDate || !endDate) {
            setDatesError(i18n.t('sections.searchBar.errors.dates'));
            return;
        }

        if (returnIsValid === false || deliveryIsValid === false) {
            openWarningModal();
            return;
        }

        const startTime24format = convertAmPmTo24(startTime);
        const endTime24format = convertAmPmTo24(endTime);
        const startDateTime = startDate.clone().setTime(startTime24format);
        const endDateTime = endDate.clone().setTime(endTime24format);

        const deliveryObject = createSearchObject(startDateTime!, startTime, deliveryLocation!, deliveryQuery);
        const returnObject = createSearchObject(endDateTime!, endTime, returnLocation!, returnQuery!);

        sharedDataService.updateSharedData(deliveryObject, returnObject, [...currReturnLocationHistory]);
        onSubmit();
    }

    function onMount() {
        setDeliveryQuery(undefined);
        setDeliveryLocation(undefined);
        setReturnQuery(undefined);
        setReturnLocation(undefined);
    }

    useEffect(onMount, []);

    function convertAmPmTo24(time: string) {
        return moment(time, 'hh:mma').format('HH:mm');
    }

    const onInputDateRange = useCallback(
        (newStartDate: Moment | null, newEndDate: Moment | null) => {
            setDatesError('');

            const bookingCalendarInformation = new BookingCalendarDatesChangesInformation({
                newStartDate,
                newEndDate,
                previousStartDate: startDate,
                startTime,
                previousEndDate: endDate,
                endTime,
                dateRangeFocus,
                bookingMaximumDuration,
                updateStartLimit,
            });

            const data = handleBookingCalendarDatesChanges(bookingCalendarInformation);

            setDateRangeFocus(data.focusedDate);
            setStartDate(data.startDate);
            setStartTime(data.startTime);
            setEndDate(data.endDate);
            setEndTime(data.endTime);
        },
        [dateRangeFocus, startDate, endDate, updateStartLimit, startTime, endTime, bookingMaximumDuration],
    );

    function handleClearDate(event: React.MouseEvent<SVGElement, MouseEvent>) {
        event.stopPropagation();
        event.preventDefault();
        setDateRangeFocus('startDate');
        setStartDate(null);
        setEndDate(null);
        setStartTime('09:00');
        setEndTime('09:00');
        updateStartLimit();
    }

    function renderReturnAddress() {
        const shouldShowReturnInput = !switcherChecked && showReturnAddressInput;

        return (
            <Fade in={shouldShowReturnInput}>
                <Flex
                    hidden={!shouldShowReturnInput}
                    alignItems="center"
                    position="relative"
                    className={styles.searchInput}
                    {...chakraStyles.input()}
                    {...chakraStyles.addressInputWidth}
                >
                    {width > 927 && <MapPinIcon className={styles.inputIcon} />}
                    <AddressAutocomplete
                        disabled={!searchEnabled}
                        onInputSelected={address => onInputSearch(address, 'returnLocation')}
                        value={returnQuery}
                        onChange={handleReturnInputChange}
                        placeholder={
                            width < 928
                                ? i18n.t('sections.searchBar.where&when')
                                : i18n.t('sections.searchBar.placeholder-where-return')
                        }
                    />
                </Flex>

                {returnLocationError && <ErrorText>{returnLocationError}</ErrorText>}
            </Fade>
        );
    }

    function renderSelectedReturnAddress() {
        return (
            <Flex>
                <Flex>
                    <Text variant="sm" color={colors.grayDarker} ml="5" fontWeight="medium">
                        {i18n.t('sections.searchBar.returnTo')}
                    </Text>
                    <Text variant="sm" color={colors.grayDarker} ml="1" fontWeight="light">
                        {returnQuery}
                    </Text>
                </Flex>
                <Button
                    variant="unstyled"
                    fontWeight="medium"
                    fontSize="var(--font-small)"
                    lineHeight="var(--line-small)"
                    h="auto"
                    p="0"
                    ml="5"
                    color="brand.primary.900"
                    textDecoration="underline"
                    onClick={() => setShowReturnAddressInput(true)}
                >
                    {i18n.t('sections.searchBar.editReturnAddress')}
                </Button>
            </Flex>
        );
    }

    function renderCloseIcon() {
        return (
            <Icon
                as={CloseCircleIcon}
                boxSize="24px"
                ml="4"
                onClick={handleClearDate}
                transition="filter 0.2s ease-in-out"
                _hover={{ cursor: 'pointer', filter: 'brightness(0.5)' }}
            />
        );
    }

    function selectOperatingCity(selectedCity: OperatingCity) {
        storeSelectedOperatingCity(selectedCity);
        if (width < 928) {
            history.push(`/search?origin=&step=0`);
        }
    }

    function getImage(cityCode: string) {
        switch (cityCode) {
            case 'NYC':
                return NewYorkBg;
                break;
            case 'LIS':
                return LisbonBg;
                break;
            default:
                return '';
        }
    }

    const renderOperatingCities = () => {
        if (operatingCities?.length === 0 || operatingCities === null) {
            return (
                <Text {...chakraStyles.text} alignSelf="center">
                    {i18n.t('pages.home.search.cityNotAvailable')}
                </Text>
            );
        }

        if (operatingCities?.length > 0) {
            return operatingCities.map((city: OperatingCity, index: number) => {
                const lastElem = index === operatingCities.length - 1;
                return (
                    <Box {...chakraStyles.regionContainer(lastElem)} key={city.code}>
                        <Button
                            variant="solid"
                            colorScheme="black"
                            size="sm"
                            onClick={e => selectOperatingCity(city)}
                            {...chakraStyles.cityButton}
                        >
                            {city.name}
                        </Button>
                        <Img src={getImage(city.code)} alt={city.name} {...chakraStyles.image} />
                    </Box>
                );
            });
        }
    };

    function renderDates() {
        return (
            <Box flex={1}>
                <HStack spacing="4">
                    <DateInput
                        className={styles.dateInput}
                        label={i18n.t('sections.searchBar.labelFrom')}
                        placeholder={i18n.t('sections.searchBar.startDate.placeholder')}
                        date={startDate}
                        time={startTime}
                        onClick={() => {
                            setDateRangeFocus('startDate');
                            setIsCalendarOpen(true);
                        }}
                        isFocused={dateRangeFocus === 'startDate'}
                    />

                    <DateInput
                        className={styles.dateInput}
                        label={i18n.t('sections.searchBar.labelTo')}
                        placeholder={i18n.t('sections.searchBar.endDate.placeholder')}
                        date={endDate}
                        time={endTime}
                        onClick={() => {
                            setDateRangeFocus('endDate');
                            setIsCalendarOpen(true);
                        }}
                        isFocused={dateRangeFocus === 'endDate'}
                        clearIcon={renderCloseIcon()}
                    />
                </HStack>
                {datesError && <ErrorText>{datesError}</ErrorText>}
            </Box>
        );
    }

    function setBlockedDays(dateToCompare: Moment) {
        const now = moment().setTime('0');
        if (now.isAfter(dateToCompare)) {
            return true;
        }
        return false;
    }

    const getCalendarInfo = useCallback(() => {
        function isOutsideRange(date: Moment) {
            const isItOutsideRange = checkIfIsOutsideRange({
                startDate,
                endDate,
                date,
                dateRangeFocus,
                blockedDates: undefined,
                bookingMaximumDuration,
            });

            return isItOutsideRange;
        }

        return (
            <>
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    focusedDate={dateRangeFocus}
                    onChange={onInputDateRange}
                    blockedDates={setBlockedDays}
                    isOutsideRange={isOutsideRange}
                />

                <Flex p="4">
                    <Divider />
                </Flex>

                <HStack spacing="8" p="4" mb="6" width="44rem">
                    <TimePicker
                        time={startTime}
                        startLimit={startLimit}
                        endLimit="23:30"
                        containerStyle={styles.pickerContainer}
                        style={styles.picker}
                        onChange={setStartTime}
                        label={i18n.t('delivery.return.modal.deliveryTime')}
                        format="hh:mma"
                    />

                    <TimePicker
                        time={endTime}
                        startLimit="00:00"
                        endLimit={endLimit}
                        containerStyle={styles.pickerContainer}
                        style={styles.picker}
                        onChange={setEndTime}
                        label={i18n.t('delivery.return.modal.returnTime')}
                        format="hh:mma"
                    />
                </HStack>
            </>
        );
    }, [dateRangeFocus, endDate, endTime, endLimit, onInputDateRange, startDate, startLimit, startTime]);

    function renderSearchInput() {
        if (width <= 927) {
            return (
                <Flex
                    alignItems="center"
                    position="relative"
                    {...chakraStyles.searchInput}
                    {...chakraStyles.mobileInput()}
                >
                    <HStack {...chakraStyles.mobileContainer}>
                        <Flex flexDir="column" {...chakraStyles.mobileTextBox}>
                            <Text {...chakraStyles.title}>{i18n.t('pages.home.search.selectOperatingCity.title')}</Text>
                            <Text w="100%" marginTop="var(--margin)" {...chakraStyles.text}>
                                {i18n.t('pages.home.search.selectOperatingCity.text')}
                            </Text>
                        </Flex>
                        <Flex {...chakraStyles.mobileBoxes}>{renderOperatingCities()}</Flex>
                    </HStack>
                </Flex>
            );
        }

        return (
            <Box className={styles.container}>
                {!operatingCity ? (
                    <Box display="flex">
                        <Flex flexDir="column">
                            <Text {...chakraStyles.title}>{i18n.t('pages.home.search.selectOperatingCity.title')}</Text>
                            <Text w="320px" mt="var(--margin)" {...chakraStyles.text}>
                                {i18n.t('pages.home.search.selectOperatingCity.text')}
                            </Text>
                        </Flex>
                        <Flex width="100%" justify="center">
                            {renderOperatingCities()}
                        </Flex>
                    </Box>
                ) : (
                    <>
                        <HStack w="100%" spacing="5" alignItems="flex-start">
                            <Box flex={1}>
                                <Text variant="md" fontWeight="medium">
                                    {i18n.t('sections.searchBar.outerText.main')}
                                </Text>
                                <Flex
                                    alignItems="center"
                                    position="relative"
                                    className={styles.searchInput}
                                    {...chakraStyles.input()}
                                    {...chakraStyles.addressInputWidth}
                                >
                                    <MapPinIcon className={styles.inputIcon} />
                                    <AddressAutocomplete
                                        disabled={!searchEnabled}
                                        onInputSelected={address => onInputSearch(address, 'deliveryLocation')}
                                        value={deliveryQuery}
                                        onChange={handleDeliveryInputChange}
                                        placeholder={i18n.t('sections.searchBar.placeholder-where')}
                                    />
                                </Flex>

                                {deliveryLocationerror && <ErrorText>{deliveryLocationerror}</ErrorText>}
                            </Box>

                            <Box flex={1} width="100%">
                                <Flex flex={1} width="100%">
                                    <Popover
                                        maximumWidth
                                        placement="bottom"
                                        renderTrigger={renderDates()}
                                        isOpen={isCalendarOpen}
                                        ousideClick={() => {
                                            setIsCalendarOpen(false);
                                            setDateRangeFocus(null);
                                        }}
                                        closeOnBlur={false}
                                        hideArrow
                                    >
                                        {getCalendarInfo()}
                                    </Popover>
                                </Flex>
                            </Box>

                            <Flex>
                                <Button
                                    colorScheme="brand"
                                    variant="solid"
                                    size="md"
                                    {...chakraStyles.submitButton}
                                    onClick={handleSubmitSearch}
                                >
                                    {i18n.t('sections.searchBar.searchButton')}
                                </Button>
                            </Flex>
                        </HStack>

                        <Box mt="4">
                            <Flex alignItems="center" justifyItems="center">
                                <Switcher checked={switcherChecked} onChange={handleSwitcher} />

                                {!returnQuery ||
                                (returnQuery && sameDeliveryReturnLocation) ||
                                showReturnAddressInput ? (
                                    <Text variant="sm" color={colors.grayDarker} ml="5">
                                        {switcherChecked
                                            ? i18n.t('sections.searchBar.returnToSame')
                                            : i18n.t('sections.searchBar.returnToAnother')}
                                    </Text>
                                ) : (
                                    <>{renderSelectedReturnAddress()}</>
                                )}
                                <Text
                                    {...chakraStyles.textGoBack}
                                    display="block"
                                    marginLeft="auto"
                                    onClick={() => clearCity()}
                                >
                                    {i18n.t('pages.home.search.changeOperatingCity.text')}
                                </Text>
                            </Flex>

                            {renderReturnAddress()}
                        </Box>
                    </>
                )}
            </Box>
        );
    }

    return <PageContainer>{renderSearchInput()}</PageContainer>;
}

export default SearchBar;
