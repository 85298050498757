import { useSharedData } from 'hooks';
import { LocationItem } from 'models/sharedData/LocationItem';
import { SearchObject } from 'models/sharedData/SearchObject';
import React from 'react';
import { SharedData } from 'models/sharedData/SharedData';
import DeliveryReturnModal, { DeliveryReturnModalProps } from '../delivery-return-modal';

export type SharedDataDeliveryReturnModalProps = Omit<DeliveryReturnModalProps, 'initFilters'> & {
    onAssetDetailsDatesChange: (arg: Partial<SharedData>) => void;
};

function SharedDataDeliveryReturnModal({
    isOpen,
    onClose,
    asset,
    blockedDates,
    loading,
    onAssetDetailsDatesChange,
}: SharedDataDeliveryReturnModalProps) {
    const { shared } = useSharedData();

    function onSave(deliveryObject: SearchObject, returnObject: SearchObject, returnLocationHistory?: LocationItem[]) {
        onAssetDetailsDatesChange({ start: deliveryObject, end: returnObject, returnLocationHistory });
        onClose();
    }

    return (
        <DeliveryReturnModal
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={onSave}
            asset={asset}
            blockedDates={blockedDates}
            loading={loading}
            initFilters={shared}
        />
    );
}

export default SharedDataDeliveryReturnModal;
