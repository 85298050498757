import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import styles from './index.module.css';

export interface MarginProps extends BoxProps {
    size?: 'internal' | 'small' | 'normal' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
    value?: string;
}

export default function Margin({ size, value, children, ...rest }: MarginProps) {
    const styleSize = {
        ...(size ? { marginBottom: `var(--margin-${size})` } : { marginBottom: `var(--margin)` }),
    };

    const val = value ? (value.includes('--') ? { marginBottom: `var(${value})` } : { marginBottom: value }) : {};

    return (
        <Box className={styles.margin} style={{ ...styleSize, ...val }} {...rest}>
            {children}
        </Box>
    );
}
