import { ChakraStyles } from 'theme';

const styles: ChakraStyles = {
    lottieContainer: {
        display: 'flex',
        flexDirection: 'column',
        pos: 'relative',
    },

    loadingMessage: {
        textAlign: 'center',
        marginBottom: '100px',
        font: 'Poppins',
        fontWeight: 'var(--font-weight-light)',
        lineHeight: 'var(--line)',
        marginTop: 'var(--margin-medium)',
    },

    lottieWrapper: {
        left: 0,
        right: 0,
        marginTop: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'fit-content;',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
};

export default styles;
