import React from 'react';
import { TextProps } from '../index';
import styles from './index.module.css';

export default function BodyText(props: TextProps) {
    const { className, onClick, style, children } = props;
    const getStyle = (): string => {
        return styles.body;
    };

    function render() {
        const classNames = [getStyle(), className];
        return (
            <div className={classNames.join(' ')} onClick={onClick} style={style}>
                {children}
            </div>
        );
    }

    return render();
}
