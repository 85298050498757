import { ClientParameters, PersonaEnvironment, PersonaOptions, PersonaStatus } from 'models/persona';
import Persona, { Client } from 'persona';
import React, { ReactNode, RefObject, useContext, useRef, useState } from 'react';
import personaConfig from '../configuration/persona/persona-config.json';
import { useRemoteConfig } from './remoteConfig';

export interface PersonaContextType {
    createClient: (parameters?: ClientParameters) => void;
    status: PersonaStatus;
    client: RefObject<Client | null>;
}

interface PersonaProviderProps {
    children: ReactNode;
}

const PersonaContext = React.createContext<PersonaContextType>({} as PersonaContextType);

function PersonaProvider({ children }: PersonaProviderProps) {
    const [options] = useState<PersonaOptions>({
        templateId: (personaConfig as any).templateId || '',
        environment: ((personaConfig as any).environment as PersonaEnvironment) || PersonaEnvironment.SANDBOX,
    });
    const embeddedClientRef = useRef<Client | null>(null);
    const [status, setStatus] = useState<PersonaStatus>(PersonaStatus.CLOSED);
    const { documentValidationEnabled } = useRemoteConfig();

    function createClient(parameters?: ClientParameters) {
        if (documentValidationEnabled === false) {
            return;
        }

        if (options.templateId === '') {
            console.log('templateId not provided, cant initialize client');
            return;
        }

        setStatus(PersonaStatus.LOADING);

        const client = new Persona.Client({
            ...(parameters?.referenceId && {
                referenceId: parameters.referenceId,
            }),
            templateId: options.templateId,
            environment: options.environment,
            onLoad: () => {
                setStatus(PersonaStatus.LOADED);
                parameters && parameters.onLoad && parameters.onLoad();
            },
            onReady: () => {
                setStatus(PersonaStatus.READY);
                parameters && parameters.onReady && parameters.onReady();
            },
            onComplete: response => {
                setStatus(PersonaStatus.COMPLETED);
                parameters && parameters.onComplete && parameters.onComplete(response as any);
            },
            onCancel: () => {
                setStatus(PersonaStatus.CLOSED);
                parameters && parameters.onCancel && parameters.onCancel();
            },
        });

        embeddedClientRef.current = client;
    }

    return (
        <PersonaContext.Provider
            value={{
                client: embeddedClientRef,
                createClient,
                status,
            }}
        >
            {children}
        </PersonaContext.Provider>
    );
}

function usePersona() {
    const context = useContext(PersonaContext);

    if (!context) {
        throw new Error('usePersona must be used within a Persona Provider');
    }

    return context;
}

export { PersonaProvider, usePersona };
