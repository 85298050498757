import React from 'react';
import i18n from 'language/i18n';

function unusualDamagesQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.duringRummoTrip.unusualDamages.stepOne')}</p>
            <p>{i18n.t('sections.faq.duringRummoTrip.unusualDamages.stepTwo')}</p>
        </>
    );
}

function roadsideAssistanceQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.duringRummoTrip.roadsideAssistance.stepOne')}</p>
            <p>{i18n.t('sections.faq.duringRummoTrip.roadsideAssistance.stepTwo')}</p>
            <strong>{i18n.t('sections.faq.duringRummoTrip.roadsideAssistance.note')}</strong>
        </>
    );
}

function softServicesQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.duringRummoTrip.softServices.stepOne')}</p>
            <p>{i18n.t('sections.faq.duringRummoTrip.softServices.stepTwo')}</p>
        </>
    );
}

function accidentQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.duringRummoTrip.accident.stepOne')}</p>
            <p>{i18n.t('sections.faq.duringRummoTrip.accident.stepTwo')}</p>
            <ul>
                <li>{i18n.t('sections.faq.duringRummoTrip.accident.stepThree.firstDot')}</li>
                <li>{i18n.t('sections.faq.duringRummoTrip.accident.stepThree.secondDot')}</li>
                <li>{i18n.t('sections.faq.duringRummoTrip.accident.stepThree.thirdDot')}</li>
                <li>{i18n.t('sections.faq.duringRummoTrip.accident.stepThree.fourthDot')}</li>
                <li>{i18n.t('sections.faq.duringRummoTrip.accident.stepThree.fifthDot')}</li>
                <li>{i18n.t('sections.faq.duringRummoTrip.accident.stepThree.sixthDot')}</li>
            </ul>
        </>
    );
}

function anotherCarDamageQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.duringRummoTrip.anotherCarDamage.stepOne')}</p>
            <p>{i18n.t('sections.faq.duringRummoTrip.anotherCarDamage.stepTwo')}</p>
        </>
    );
}

const body = [
    {
        title: i18n.t('sections.faq.duringRummoTrip.agreement.title'),
        content: i18n.t('sections.faq.duringRummoTrip.agreement.description'),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.damageLiability.title'),
        content: i18n.t('sections.faq.duringRummoTrip.damageLiability.description'),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.unusualDamages.title'),
        content: unusualDamagesQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.roadsideAssistance.title'),
        content: roadsideAssistanceQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.softServices.title'),
        content: softServicesQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.accident.title'),
        content: accidentQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.anotherCarDamage.title'),
        content: anotherCarDamageQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.noFaultAccident.title'),
        content: i18n.t('sections.faq.duringRummoTrip.noFaultAccident.description'),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.lostKeys.title'),
        content: i18n.t('sections.faq.duringRummoTrip.lostKeys.description'),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.towing.title'),
        content: i18n.t('sections.faq.duringRummoTrip.towing.description'),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.continentalDrive.title'),
        content: i18n.t('sections.faq.duringRummoTrip.continentalDrive.description'),
    },
    {
        title: i18n.t('sections.faq.duringRummoTrip.interstateDrive.title'),
        content: i18n.t('sections.faq.duringRummoTrip.interstateDrive.description'),
    },
];

export const duringRummoTripSection = {
    title: i18n.t('sections.faq.duringRummoTrip.title'),
    description: i18n.t('sections.faq.duringRummoTrip.description'),
    body,
};
