import React from 'react';
import Caption from '../../../base/text/caption';
import styles from './index.module.css';

export type AssetDetailsFeaturesItemProps = {
    icon: any;
    label?: any;
    row?: boolean;
};

function AssetDetailsFeaturesItem(props: AssetDetailsFeaturesItemProps) {
    return (
        <div className={props.row ? styles.itemContainerRow : styles.itemContainer}>
            <props.icon className={styles.icon} />
            <Caption>{props.label}</Caption>
        </div>
    );
}

export default AssetDetailsFeaturesItem;
