import * as yup from 'yup';

interface Validation {
    type: string;
    params: any[];
}

interface SchemaObject {
    id: string;
    validationType: string;
    typeError?: string;
    validations?: Validation[];
}

export type YupSchemaObject = yup.ObjectSchema<Record<string, any>, object>;

export function CreateSchema(schema: SchemaObject[]): YupSchemaObject {
    const yupSchema: any = {};

    schema?.forEach(schemaObject => {
        const { id, validationType, typeError, validations = [] } = schemaObject;
        const isObject = id.indexOf('.') >= 0;

        if (!(yup as any)[validationType]) {
            return schemaObject;
        }

        let validator = (yup as any)[validationType]();

        if (typeError) {
            validator = validator.typeError(typeError);
        }

        validations.forEach(validation => {
            const { params, type } = validation;
            if (!validator[type]) {
                return;
            }

            validator = validator[type](...params);
        });

        if (!isObject) {
            yupSchema[id] = validator;
            return undefined;
        }

        const reversePath = id.split('.').reverse();
        let isArray = false;
        const currNestedObject = reversePath.slice(1).reduce(
            (yupObj, path) => {
                if (!Number.isNaN(Number(path))) {
                    isArray = true;
                    return yup.array().of(yup.object().shape(yupObj));
                }

                if (yupObj.array) {
                    return yupObj.array;
                }

                return yupObj;
            },
            { [reversePath[0]]: validator },
        );

        const lastPosition = reversePath.length - 1;
        const validatorName = reversePath[lastPosition];

        const newSchema = isArray ? currNestedObject : yup.object().shape(currNestedObject);
        yupSchema[validatorName] = newSchema;
        return undefined;
    });

    return yup.object().shape(yupSchema);
}
