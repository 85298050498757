import { auth } from '../persistence';

export class AuthenticatedService {
    private _userId?: string;

    set userId(userId: string | undefined) {
        this._userId = userId;
    }

    // the assignment is done here because firebase takes a few seconds to authenticate the user
    // therefore, we only assign the uid when it is used;
    get userId() {
        if (!this._userId) {
            this.userId = auth.currentUser?.uid;
        }

        return this._userId;
    }
}
