import React from 'react';
import i18n from 'language/i18n';

function worksQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.works.intro')}</p>
            <ol>
                <li>{i18n.t('sections.faq.works.stepOne')}</li>
                <li>{i18n.t('sections.faq.works.stepTwo')}</li>
                <li>{i18n.t('sections.faq.works.stepThree')}</li>
                <li>{i18n.t('sections.faq.works.stepFour')}</li>
            </ol>
            <p>{i18n.t('sections.faq.works.ending')}</p>
        </>
    );
}

function ageRequirementQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.ageRequirement.intro.stepOne')}</p>
            <p>{i18n.t('sections.faq.ageRequirement.intro.stepTwo')}</p>
            <ul>
                <li>{i18n.t('sections.faq.ageRequirement.additionalTax.stepOne')}</li>
                <li>{i18n.t('sections.faq.ageRequirement.additionalTax.stepTwo')}</li>
                <li>{i18n.t('sections.faq.ageRequirement.additionalTax.stepThree')}</li>
            </ul>
            <strong>{i18n.t('sections.faq.ageRequirement.ending')}</strong>
        </>
    );
}

function accessQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.access.self')}</p>
            <p>{i18n.t('sections.faq.access.verification')}</p>
        </>
    );
}

function depositQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.deposit.description')}</p>
            <p>{i18n.t('sections.faq.deposit.note')}</p>
        </>
    );
}

function includedQuestionBody() {
    return (
        <>
            <p>{i18n.t('sections.faq.included.intro')}</p>
            <ul>
                <li>{i18n.t('sections.faq.included.stepOne')}</li>
                <li>{i18n.t('sections.faq.included.stepTwo')}</li>
                <li>{i18n.t('sections.faq.included.stepThree')}</li>
            </ul>
        </>
    );
}

const body = [
    {
        title: i18n.t('sections.faq.works.title'),
        content: worksQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.ageRequirement.title'),
        content: ageRequirementQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.access.title'),
        content: accessQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.deposit.title'),
        content: depositQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.smokeRestriction.title'),
        content: i18n.t('sections.faq.smokeRestriction.description'),
    },
    {
        title: i18n.t('sections.faq.cleaning.title'),
        content: i18n.t('sections.faq.cleaning.description'),
    },
    {
        title: i18n.t('sections.faq.refuel.title'),
        content: i18n.t('sections.faq.refuel.description'),
    },
    {
        title: i18n.t('sections.faq.cars.title'),
        content: i18n.t('sections.faq.cars.description'),
    },
    {
        title: i18n.t('sections.faq.dailyRate.title'),
        content: i18n.t('sections.faq.dailyRate.description'),
    },
    {
        title: i18n.t('sections.faq.mileage.title'),
        content: i18n.t('sections.faq.mileage.description'),
    },
    {
        title: i18n.t('sections.faq.included.title'),
        content: includedQuestionBody(),
    },
    {
        title: i18n.t('sections.faq.reservedCar.title'),
        content: i18n.t('sections.faq.reservedCar.description'),
    },
];

export const howDoesRummoWorkSection = {
    title: i18n.t('sections.faq.howRummoWork.title'),
    description: i18n.t('sections.faq.howRummoWork.description'),
    body,
};
