import React, { useState, FC, useEffect, useContext, createContext } from 'react';
import { useRemoteConfig as getRemoteConfig } from '@procimo/feature-flag-web';
import { RemoteConfigKeys } from 'models/core/RemoteConfigKeys';
import { LoginConfig } from 'models/config/LoginConfig';
import { SignupConfig } from 'models/config/SignupConfig';
import { SearchConfig } from 'models/config/SearchConfig';
import { DocumentValidationConfig } from 'models/config/DocumentValidationConfig';
import { PasswordRecoveryConfig } from 'models/config/PasswordRecoveryConfig';

interface PageConfig {
    asset_page_config: {
        reviews: boolean;
    };
}

export interface BookingCancellationConfig {
    cancellation_time: number;
    cancellation_fee: number;
}

export type RemoteConfigContextType = {
    homepageSearchEnabled: boolean;
    loginEnabled: boolean;
    signupEnabled: boolean;
    documentValidationEnabled: boolean;
    pagesConfig: PageConfig;
    noticeTime: string;
    bookingMaximumDuration: number;
    passwordRecoveryEnabled: boolean;
    paymentsEnabled: boolean;
    bookingCancellationConfig: BookingCancellationConfig;
    modificationsTime: number;
};

const initialPagesConfig = {
    asset_page_config: { reviews: false },
};

const RemoteConfigContext = createContext<RemoteConfigContextType>({
    homepageSearchEnabled: false,
    loginEnabled: false,
    signupEnabled: false,
    documentValidationEnabled: true,
    pagesConfig: initialPagesConfig,
    noticeTime: '2',
    bookingMaximumDuration: 28,
    passwordRecoveryEnabled: false,
    paymentsEnabled: false,
    modificationsTime: 2,
    bookingCancellationConfig: {
        cancellation_time: 48,
        cancellation_fee: 0,
    } as BookingCancellationConfig,
});

const RemoteConfigProvider: FC = ({ children }) => {
    const { loading: loginConfigLoading, value: loginConfigValue } = getRemoteConfig(RemoteConfigKeys.LOGIN_CONFIG);
    const { loading: signupConfigLoading, value: signupConfigValue } = getRemoteConfig(RemoteConfigKeys.SIGNUP_CONFIG);
    const { loading: searchConfigLoading, value: searchConfigValue } = getRemoteConfig(RemoteConfigKeys.SEARCH_CONFIG);
    const { loading: assetPageConfigLoading, value: assetPageConfigValue } = getRemoteConfig(
        RemoteConfigKeys.ASSET_PAGE_CONFIG,
    );
    const { loading: documentValidationConfigLoading, value: documentValidationConfigValue } = getRemoteConfig(
        RemoteConfigKeys.DOCUMENT_VALIDATION_CONFIG,
    );
    const { loading: passwordRecoveryConfigLoading, value: passwordRecoveryConfigValue } = getRemoteConfig(
        RemoteConfigKeys.PASSWORD_RECOVERY_CONFIG,
    );
    const { loading: paymentConfigLoading, value: paymentConfigValue } = getRemoteConfig(
        RemoteConfigKeys.PAYMENTS_CONFIG,
    );
    const { loading: bookingCancellationConfigLoading, value: bookingCancellationConfigValue } = getRemoteConfig(
        RemoteConfigKeys.BOOKING_CANCELLATION_CONFIG,
    );
    const { loading: modificationsTimeConfigLoading, value: modificationsTimeConfigValue } = getRemoteConfig(
        RemoteConfigKeys.MODIFICATIONS_TIME_CONFIG,
    );

    const [loginConfig, setLoginConfigValue] = useState<LoginConfig>({
        enabled: false,
    });
    const [signupConfig, setSignupConfigValue] = useState<SignupConfig>({
        enabled: false,
    });
    const [passwordRecoveryConfig, setPasswordRecoveryConfigValue] = useState<PasswordRecoveryConfig>({
        enabled: false,
    });

    const [searchConfig, setSearchConfigValue] = useState<SearchConfig>({
        homepage_search_enabled: false,
        notice_time: '2',
        booking_maximum_duration: 28,
    });
    const [documentValidationConfig, setDocumentValidationConfigValue] = useState<DocumentValidationConfig>({
        enabled: true,
    });
    const [paymentsConfig, setPaymentsConfig] = useState<{ enabled: boolean }>({
        enabled: false,
    });
    const [modificationsConfigTime, setModificationsConfigTime] = useState<number>(2);

    const [pages, setPages] = useState<PageConfig>(initialPagesConfig);
    const [bookingCancellationConfig, setBookingCancellationConfig] = useState<BookingCancellationConfig>({
        cancellation_time: 48,
        cancellation_fee: 0,
    } as BookingCancellationConfig);

    function setPageConfig(key: string, value: object) {
        setPages(prevState => ({ ...prevState, [key]: value }));
    }

    useEffect(() => {
        if (!loginConfigLoading && typeof loginConfigValue === 'string') {
            setLoginConfigValue(JSON.parse(loginConfigValue));
        }

        if (!signupConfigLoading && typeof signupConfigValue === 'string') {
            setSignupConfigValue(JSON.parse(signupConfigValue));
        }

        if (!passwordRecoveryConfigLoading && typeof passwordRecoveryConfigValue === 'string') {
            setPasswordRecoveryConfigValue(JSON.parse(passwordRecoveryConfigValue));
        }

        if (!paymentConfigLoading && typeof paymentConfigValue === 'string') {
            setPaymentsConfig(JSON.parse(paymentConfigValue));
        }

        if (!searchConfigLoading && typeof searchConfigValue === 'string') {
            setSearchConfigValue(JSON.parse(searchConfigValue));
        }

        if (!assetPageConfigLoading && typeof assetPageConfigValue === 'string') {
            const assetPageConfig = JSON.parse(assetPageConfigValue);
            setPageConfig('asset_page_config', assetPageConfig);
        }

        if (!documentValidationConfigLoading && typeof documentValidationConfigValue === 'string') {
            setDocumentValidationConfigValue(JSON.parse(documentValidationConfigValue));
        }

        if (!bookingCancellationConfigLoading && typeof bookingCancellationConfigValue === 'string') {
            setBookingCancellationConfig(JSON.parse(bookingCancellationConfigValue));
        }

        if (!modificationsTimeConfigLoading && typeof modificationsTimeConfigValue === 'string') {
            setModificationsConfigTime(JSON.parse(modificationsTimeConfigValue));
        }
    }, [
        loginConfigLoading,
        loginConfigValue,
        signupConfigLoading,
        signupConfigValue,
        searchConfigLoading,
        searchConfigValue,
        assetPageConfigLoading,
        assetPageConfigValue,
        documentValidationConfigLoading,
        documentValidationConfigValue,
        passwordRecoveryConfigValue,
        passwordRecoveryConfigLoading,
        paymentConfigLoading,
        paymentConfigValue,
        bookingCancellationConfigLoading,
        bookingCancellationConfigValue,
        modificationsTimeConfigLoading,
        modificationsTimeConfigValue,
    ]);

    return (
        <RemoteConfigContext.Provider
            value={{
                homepageSearchEnabled: searchConfig.homepage_search_enabled,
                loginEnabled: loginConfig.enabled,
                signupEnabled: signupConfig.enabled,
                passwordRecoveryEnabled: passwordRecoveryConfig.enabled,
                documentValidationEnabled: documentValidationConfig.enabled,
                pagesConfig: pages,
                noticeTime: searchConfig.notice_time,
                bookingMaximumDuration: searchConfig.booking_maximum_duration,
                paymentsEnabled: paymentsConfig.enabled,
                bookingCancellationConfig,
                modificationsTime: modificationsConfigTime,
            }}
        >
            {children}
        </RemoteConfigContext.Provider>
    );
};

function useRemoteConfig(): RemoteConfigContextType {
    const context = useContext(RemoteConfigContext);

    if (!context) {
        throw new Error('useRemoteConfig must be used within an RemoteConfigProvider');
    }

    return context;
}

export { RemoteConfigProvider, useRemoteConfig };
