import React from 'react';

import styles from './index.module.css';

export function CheckboxRounded(props: CheckboxRoundedProps) {
    const { className, labelClassName, checked, label, onClick } = props;

    return (
        <div className={[styles.container, className, checked ? styles.checked : ''].join(' ')} onClick={onClick}>
            <span className={[styles.label, labelClassName].join(' ')}>{label}</span>
            <span className={[styles.checkmark, className, checked ? styles.checked : ''].join(' ')} />
        </div>
    );
}

export type CheckboxRoundedProps = {
    label?: string;
    checked?: boolean;
    className?: string;
    labelClassName?: string;
    onClick?: () => void;
};
