import { Button, Text } from '@chakra-ui/react';
import { ChevDownIcon } from 'components/base/icon/chev-down';
import { ChevUpIcon } from 'components/base/icon/chev-up';
import Margin from 'components/base/margin';
import Row from 'components/base/row';
import Splitter from 'components/base/splitter';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useState } from 'react';
import { PriceCalculator } from 'services/price.formatter';
import { PlusCircleIcon } from 'components/base/icon/plusCircle';
import { useLocation } from 'react-router-dom';
import i18n from 'language/i18n';
import styles from './index.module.css';

type Opts = { value: PriceCalculator; label: string; info?: string };

type PriceBreakdownProps = {
    open?: boolean;
    options?: Opts[];
    title?: string;
    titleInfo?: string;
    showAddInsurances?: boolean;
    mode?: string;
};

function PriceBreakdown({ options, open, title, titleInfo, showAddInsurances, mode }: PriceBreakdownProps) {
    const { width } = useWindowSize();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(open);

    const Chev = isOpen ? ChevUpIcon : ChevDownIcon;

    function renderPriceIcon() {
        const endpoint = location.pathname.split('/');

        switch (endpoint[endpoint.length - 1]) {
            case 'checkout':
                return;
            default:
                return <Chev color="black" className={styles.priceBreakdownIcon} />;
        }
    }

    function renderItem({ label, value, info }: Opts) {
        return (
            <Row className={styles.item} key={label}>
                <Row className={styles.label}>
                    <span>{label}</span>
                    <span className={styles.info}>{info}</span>
                </Row>
                <div>{value.format(2)}</div>
            </Row>
        );
    }

    function handleCollapse() {
        const isCheckout = mode?.includes('checkout');
        if (width < 692 || isCheckout) {
            return;
        }

        setIsOpen(!isOpen);
    }

    return (
        <div>
            <button className={styles.collapse} onClick={handleCollapse}>
                <div className={styles.titleContainer}>
                    <Text variant="h5" fontWeight="500">
                        {title}
                    </Text>
                    <span className={styles.titleInfo}>{titleInfo ? `(${titleInfo})` : ''}</span>
                </div>
                <div className={styles.chevContainer}>{renderPriceIcon()}</div>
            </button>
            {isOpen && <Margin />}
            {isOpen && options?.map(renderItem)}
            {showAddInsurances && (
                <Button gap="2" variant="link" colorScheme="brand">
                    <PlusCircleIcon width="24px" height="24px" color="var(--color-red)" />
                    {i18n.t('sections.priceBreakdown.addExtrasOrInsurances')}
                </Button>
            )}
            <Splitter spacing="normal" className={styles.splitter} />
        </div>
    );
}

export default PriceBreakdown;
