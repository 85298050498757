import React, { forwardRef, LegacyRef, useEffect, useRef } from 'react';

import styles from './index.module.css';
import Column from '../../column';
import ErrorText from '../../text/error';
import Label from '../../text/label';

export const Input = forwardRef((props: any, ref) => {
    const inputRef = useRef<HTMLInputElement>();

    const {
        name,
        label,
        type,
        onChange,
        errorMessage,
        value,
        required,
        placeholder,
        hideError,
        onBlur,
        onFocus,
        onMouseEnter,
        onKeyDown,
        onMouseLeave,
        disabled,
        className,
        focusOnRender,
        id,
    } = props;

    useEffect(() => {
        if (focusOnRender) {
            inputRef.current && inputRef.current.focus();
        }
    }, [focusOnRender]);

    return (
        <Column className={[styles.inputContainer, className].join(' ')}>
            {label ? <Label className={disabled ? styles.labelDisabled : ''}>{label}</Label> : null}
            <input
                id={id}
                className={styles.input}
                name={name}
                type={type}
                disabled={disabled}
                value={value}
                required={required}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={(ref || inputRef) as LegacyRef<HTMLInputElement> | undefined}
            />
            {!hideError || <ErrorText>{errorMessage}</ErrorText>}
        </Column>
    );
});
