import { Moment } from 'moment';
import { BookingModificationsStatus } from 'models/booking/BookingModificationStatus';
import { firestore, functions } from './persistence';
import FirestoreService from './firestore.service';

const BookingCollection = 'booking';
const RequestModificationsBookingCollection = 'request_booking_modifications';
const BookingModificationsCollection = 'booking_modifications';
const UsersCollection = 'users';

interface RequestPayload {
    startDate?: Moment;
    endDate?: Moment;
    deliveryLocation?: number[];
    deliveryLocationName?: string;
    returnLocation?: number[];
    returnLocationName?: string;
}

interface Response {
    data: {
        bookingModificationsRef: {
            id: string;
        };
    };
}

export const BookingModificationsService = {
    /**
     * GET all booking modifications
     */

    async getAll(criteria?: any) {
        return functions.httpsCallable('searchBookingModifications')(criteria || {});
    },

    /* Create booking modifications
     *
     * */
    create: async (uid: string, bookingId: string, requestPayload: RequestPayload) => {
        const userRef = firestore.collection(UsersCollection).doc(uid);
        const bookingRef = firestore.collection(BookingCollection).doc(bookingId);
        const requestBookingModificationsCollection = firestore.collection(RequestModificationsBookingCollection);
        const bookingModificationsCollection = firestore.collection(BookingModificationsCollection);

        const { startDate, endDate } = requestPayload;

        const requestDates = startDate &&
            endDate && {
                startDate: startDate?.toISOString(true),
                endDate: endDate?.toISOString(true),
            };

        const request = {
            ...requestPayload,
            bookingRef,
            issuedBy: userRef,
            status: 'pending',
            action: 'create',
            ...requestPayload,
            ...requestDates,
        };

        const expected = {
            success: [BookingModificationsStatus.SUCCESS],
            failure: [BookingModificationsStatus.FAILURE],
        };

        const response: Response = (await FirestoreService.createSnapshotRequest(
            requestBookingModificationsCollection,
            request,
        )) as Response;

        const doc = bookingModificationsCollection.doc(response.data.bookingModificationsRef.id);

        return FirestoreService.createParentRequestSnapshot(response, doc, expected);
    },
};
