import React from 'react';
import { Spinner } from '../spinner';
import Column from '../column';
import styles from './index.module.css';
import Text from '../text';

type LoadingProps = {
    spinnerSize?: number;
    fullScreen?: boolean;
    halfScreen?: boolean;
    message?: string;
};
export default function Loading(props: LoadingProps) {
    const { spinnerSize, fullScreen, message, halfScreen } = props;
    return (
        <Column
            className={[styles.container, fullScreen && styles.fullScreen, halfScreen && styles.halfScreen].join(' ')}
        >
            <Spinner full size={spinnerSize || 35} color="red" />
            {/* {message && <Text className={styles.spinnerText}>{message}</Text>} */}
        </Column>
    );
}
