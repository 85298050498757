import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Breadcrumb, { BreadcrumbItem } from '../breadcrumb';
import PageContainer from '../pageContainer';
import PageSubtitle from '../text/pageSubtitle';
import PageTitle from '../text/pageTitle';

import styles from './index.module.css';

export interface PageTemplateProps {
    children?: ReactNode;
    title: string;
    subtitle?: string;
    breadcrumbs?: BreadcrumbItem[];
    templateClassName?: string;
    breadcrumbsClassName?: string;
    titleClassName?: string;
    subtitleClassName?: string;
    background?: string;
    backgroundStyles?: BoxProps;
    renderRight?: () => React.ReactNode;
}

const PageTemplate = (props: PageTemplateProps) => {
    const {
        children,
        title,
        breadcrumbs,
        subtitleClassName,
        templateClassName,
        breadcrumbsClassName,
        titleClassName,
        background,
        backgroundStyles,
        subtitle,
        renderRight,
    } = props;

    return (
        <>
            {background && (
                <Box
                    pos="absolute"
                    zIndex="-10"
                    backgroundImage={background}
                    overflow="hidden"
                    w="full"
                    h="full"
                    maxH={{ base: 424, xl: 550, '2xl': 600 }}
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    backgroundPosition="center"
                    {...backgroundStyles}
                />
            )}
            <PageContainer className={[styles.container, templateClassName].join(' ')}>
                {breadcrumbs && <Breadcrumb items={breadcrumbs} className={breadcrumbsClassName} />}
                <Box className={styles.titleContainer}>
                    <PageTitle className={[titleClassName, background && !subtitle && styles.withBackground].join(' ')}>
                        {title}
                    </PageTitle>
                    <Box>{renderRight?.()}</Box>
                </Box>
                {subtitle && (
                    <PageSubtitle className={[styles.withBackgroundSub, subtitleClassName].join(' ')}>
                        {subtitle}
                    </PageSubtitle>
                )}
                {children}
            </PageContainer>
        </>
    );
};

export default PageTemplate;
