import { ChangeDateAction } from 'models/sharedData/actions/ChangeDateAction';
import { ChangeSharedExtrasAction } from 'models/sharedData/actions/ChangeExtrasAction';
import { ChangeSharedInsurancesAction } from 'models/sharedData/actions/ChangeInsurancesAction';
import { ChangeLocationAction, ChangeLocationActionLocationType } from 'models/sharedData/actions/ChangeLocationAction';
import { ChangeSharedDataAction } from 'models/sharedData/actions/ChangeSharedData';
import { ChangeTimeAction, ChangeTimeActionTimeType } from 'models/sharedData/actions/ChangeTimeAction';
import { SearchObject } from 'models/sharedData/SearchObject';
import { SharedDataAction, SharedDataActionType } from 'models/sharedData/SharedDataActions';
import { SharedData } from 'models/sharedData/SharedData';

function extractSearchObjectPropertyName(
    type: ChangeLocationActionLocationType | ChangeTimeActionTimeType,
): 'start' | 'end' {
    switch (type) {
        case ChangeLocationActionLocationType.DELIVERY_LOCATION:
        case ChangeTimeActionTimeType.START_TIME:
            return 'start';
        case ChangeLocationActionLocationType.RETURN_LOCATION:
        case ChangeTimeActionTimeType.END_TIME:
            return 'end';
        default:
            throw new Error();
    }
}

function mapSearchObject(searchObject?: Partial<SearchObject>): undefined | Partial<SearchObject> {
    if (!searchObject) {
        return undefined;
    }

    return {
        ...(searchObject.date === undefined ? {} : { date: searchObject.date }),
        ...(searchObject.time === undefined ? {} : { time: searchObject.time }),
        ...(searchObject.location === undefined ? {} : { location: searchObject.location }),
        ...(searchObject.query === undefined ? {} : { query: searchObject.query }),
    };
}

function updateSharedData(state: SharedData, { payload }: ChangeSharedDataAction) {
    const { returnObject, deliveryObject, returnLocationHistory } = payload;

    return {
        ...state,
        returnLocationHistory,
        start: {
            ...state.start,
            ...mapSearchObject(deliveryObject),
        },
        end: {
            ...state.end,
            ...mapSearchObject(returnObject),
        },
    } as SharedData;
}

function updateSharedDataDate(state: SharedData, { payload }: ChangeDateAction) {
    const { returnDateObject, deliveryDateObject } = payload;

    return {
        ...state,
        start: {
            ...state.start!,
            ...mapSearchObject(deliveryDateObject),
        },
        end: {
            ...state.end!,
            ...mapSearchObject(returnDateObject),
        },
    };
}

function updateSharedDataLocation(state: SharedData, { payload }: ChangeLocationAction) {
    const { location, query, locationType } = payload;
    const searchObjectPropertyName = extractSearchObjectPropertyName(locationType);

    return {
        ...state,
        [searchObjectPropertyName]: {
            ...state[searchObjectPropertyName],
            ...(location === undefined ? {} : { location }),
            ...(query === undefined ? {} : { query }),
        },
    };
}

function updateSharedDataTime(state: SharedData, { payload }: ChangeTimeAction) {
    const { time, timeType } = payload;
    const searchObjectPropertyName = extractSearchObjectPropertyName(timeType);

    const currentDate = state[searchObjectPropertyName]!.date!;
    let newDate = currentDate;
    if (time && currentDate) {
        const [hours, minutes] = time.split(':');
        const dateClone = currentDate.clone();
        dateClone.hours(Number(hours)).minutes(Number(minutes));
        newDate = dateClone;
    }

    return {
        ...state,
        [searchObjectPropertyName]: {
            ...state[searchObjectPropertyName],
            date: newDate,
            time,
        },
    };
}
function updateSharedExtras(state: SharedData, { payload }: ChangeSharedExtrasAction) {
    const { extras } = payload;

    return {
        ...state,
        extras,
    };
}

function updateSharedInsurances(state: SharedData, { payload }: ChangeSharedInsurancesAction) {
    const { insurances } = payload;

    return {
        ...state,
        insurances,
    };
}

export function sharedDataReducer(state: SharedData, action: SharedDataAction): SharedData {
    switch (action.type) {
        case SharedDataActionType.CHANGE_DATE:
            return updateSharedDataDate(state, action);
        case SharedDataActionType.CHANGE_LOCATION:
            return updateSharedDataLocation(state, action);
        case SharedDataActionType.CHANGE_TIME:
            return updateSharedDataTime(state, action);
        case SharedDataActionType.CHANGE_SHARED_DATA:
            return updateSharedData(state, action);
        case SharedDataActionType.CHANGE_EXTRAS:
            return updateSharedExtras(state, action);
        case SharedDataActionType.CHANGE_INSURANCES:
            return updateSharedInsurances(state, action);
        default:
            throw new Error('Invalid action');
    }
}
