import { ButtonProps, InputProps } from '@chakra-ui/react';
import { ChakraStyles } from 'theme';

const styles: ChakraStyles<InputProps & ButtonProps & any> = {
    content: {
        padding: '0',
    },
    modalHeader: {
        px: 7,
        py: 'var(--margin)',
    },
    title: {
        mb: 2,
        fontWeight: 'var(--font-weight-medium)',
        fontSize: 'var(--font-medium)',
    },
    desktopModalBody: {
        alignSelf: 'center',
        px: 7,
        py: 0,
    },
    tabletModalBody: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    returnDropdownMobile: {
        height: '48px !important',
    },
    mobileDrawer: {
        justifyContent: 'space-between',
        padding: '0 24px !important',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh' /* 100% was removed temporarily due to datepicker removal */,
    },
    containerDrawer: {
        borderRadium: '8px 8px 0px 0px',
    },
    footer: {
        padding: 'var(--margin-medium)',
        boxShadow: '0px -2px 16px rgba(11, 11, 13, 0.08)',
    },
};

export default styles;
