import React, { SetStateAction } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import styles from './index.module.css';

interface AccordionProps {
    i: number | string;
    title: string;
    content: JSX.Element | string;
    expanded: number | boolean;
    setExpanded: SetStateAction<any>;
    className?: string;
}

export const Accordion = ({ i, title, expanded, setExpanded, content, className }: AccordionProps) => {
    const isOpen = i === expanded;

    function toggleAccordion() {
        setExpanded(isOpen ? false : i);
    }

    return (
        <div className={[styles.cardContainer, className].join(' ')}>
            <motion.header className={styles.cardHeader} initial={false} onClick={toggleAccordion}>
                <motion.span className={styles.cardTitle}>{title}</motion.span>
                <span className={styles.cardIcon}>{isOpen ? '-' : '+'}</span>
            </motion.header>

            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: {
                                opacity: 1,
                                height: 'auto',
                                transition: {
                                    duration: 0.4,
                                },
                            },
                            collapsed: { opacity: 0, height: 0, transition: { duration: 0.5 } },
                        }}
                        transition={{ ease: [0.04, 0.62, 0.23, 1] }}
                    >
                        <motion.div
                            className={styles.contentPlaceholder}
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.3 }}
                        >
                            <p className={styles.text}>{content}</p>
                        </motion.div>
                    </motion.section>
                )}
            </AnimatePresence>
        </div>
    );
};
