const outline = {
    width: 'fit-content',
    field: {
        width: 'fit-content',
        _focus: {
            borderColor: 'gray.300',
        },
    },
};

const InputTheme = {
    variants: {
        outline,
    },
    defaultProps: {
        variant: 'outline',
    },
};

export default InputTheme;
